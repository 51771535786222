<div style="width: 100%;height:100%;display:  flex; justify-content: center;align-items: center;" >
    <div mat-dialog-content style="border-radius: 20px;width: 30px;width: 300px;display: flex;flex-direction: column;gap: 20px;">
        <span class="span-text-dark">You want to Logout?</span>
        <div style="display: flex;flex-direction: row;gap: 15px;">
          <button class="auth-button" style="border: none;outline: none; background-color: #0366DE;" matDialogClose>Cancel</button>
          <button class="auth-button" style="border: none;outline: none; background-color: #0366DE;" matDialogClose (click)="logout()" >Logout</button>
        </div>
      </div>
</div>

