<div
  class="terminal-3"
>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 781.081 288.84">
  <g id="Group_2373" data-name="Group 2373" transform="translate(-628.591 -238.617)">
    <path stroke="#313131" stroke-width="1.5" id="Path_4498" data-name="Path 4498" d="M16194.708,5737.352v27.979h-51.771V5614.083h51.447v71.445h210.536l40.811-71.715h30.174v59h127.777v-59h35.988l38.852,71.527h245.5v52.011h-236.15v165.3h-293.6v-165.3Z" transform="translate(-15514.346 -5375.196)" fill="#528c7d"/>
    <text id="A" transform="translate(906.5 413.5)" fill="#fff" stroke="#fff" stroke-width="1" font-size="52" font-family="Numans, sans-serif"><tspan x="0" y="47">A</tspan></text>
    <text id="B" transform="translate(1106.5 413.5)" fill="#fff" stroke="#fff" stroke-width="1" font-size="52" font-family="Numans, sans-serif"><tspan x="0" y="47">B</tspan></text>
    <text id="TRANSFER" transform="translate(889.5 398.5)" fill="#fff" stroke="#fff" stroke-width="0.5" font-size="12" font-family="Numans, sans-serif"><tspan x="0" y="11">TRANSFER</tspan></text>
    <text id="TRANSFER-2" data-name="TRANSFER" transform="translate(1089.5 398.5)" fill="#fff" stroke="#fff" stroke-width="0.5" font-size="12" font-family="Numans, sans-serif"><tspan x="0" y="11">TRANSFER</tspan></text>
  </g>
</svg>

  
  
</div>