import { Component, Input } from '@angular/core';


@Component({
  selector: 'Coil',
  templateUrl: './coil.component.html',
  styleUrls: ['./coil.component.css']
})
export class CoilComponent {
  @Input() state!:string;
  urlActive: string;
  urlInActive: string;
  constructor(){
    this.state='active'
    this.urlActive="/assets/bert-components/chilledwater-coil.svg";
    this.urlInActive="/assets/bert-components/hotwater-coil.svg";
  }
}
