<div
  class="side-bar-container"
  style="display: flex; flex-direction: row; height: 100%"
>
  <div class="main-side-bar">
    <div style="color: white; display: flex; flex-direction: column">
      <div class="icon-container" (click)="drawer.toggle()" style="cursor: pointer;">
        <mat-icon matTooltip="Menu">menu</mat-icon>
      </div>
      <div class="icon-container" routerLink="home" style="cursor: pointer;">
        <mat-icon matTooltip="Home">home</mat-icon>
      </div>

      <div class="icon-container" (click)="goback()" style="cursor: pointer;">
        <mat-icon matTooltip="Go Back">reply</mat-icon>
      </div>

      <div class="icon-container" routerLink="insights" style="cursor: pointer;">
        <mat-icon matTooltip="Insights">code</mat-icon>
      </div>
      <!--
      <div class="icon-container">
        <mat-icon matTooltip="Notifications">notifications</mat-icon>
      </div> -->
    </div>

    <div
      style="
        border-top: 1px solid white;
        color: white;
        display: flex;
        flex-direction: column;
      "
    >
      <div class="icon-container">
        <mat-icon matTooltip="Settings">settings_outline</mat-icon>
      </div>

      <!-- <div class="icon-container">
        <mat-icon matTooltip="Error">error_outline</mat-icon>
      </div> -->
      <div class="icon-container" (click)="showLogoutDialog()" style="cursor: pointer;">
        <mat-icon matTooltip="Logout">logout</mat-icon>
      </div>
    </div>
  </div>

  <mat-drawer-container
    style="height: 100%; border-top: 1px solid var(--blue-two)"
  >
    <mat-drawer
      #drawer
      style="display: flex; width: 250px;"
      mode="side"
      opened="false"
    >
      <div
        style="
          min-width: 100%;
          height: 100%;
          max-width: 100%;
          background-color: white;
          overflow-y: auto;
        "
      >
        <div
          style="
            width: 100%;
            height: 30px;
            color: var(--blue-one);
            font-size: 15px;
            font-weight: 600;
            padding-left: 15px;
            padding-top: 15px;
            margin-bottom: 20px;
          "
        >
          Dashboard
        </div>

        <mat-divider></mat-divider>

        <mat-action-list
          *ngFor="let item of items"
          style="padding: 0px 0px 0px 0px"
        >

          <!-- case of chillerplant having 3 levels -->
          <ng-container
            *ngIf="item.title === 'Chiller Plant'; else elseTemplate"
          >
            <button
              mat-list-item
              (click)="toggleExpanded(item); selectedItem = item"
              routerLink="{{ item.route }}"
            >
              <span class="span-text-dark">{{ item.title }}</span>

              <div *ngIf="item.subComponents.length!==0">
              <div *ngIf="item.expanded; else other_content">
                <mat-icon class="arrow-icon" style="color: var(--blue-one)"
                  >keyboard_arrow_up</mat-icon
                >
              </div>

              <ng-template #other_content
                ><mat-icon class="arrow-icon" style="color: var(--blue-one)"
                  >keyboard_arrow_down</mat-icon
                ></ng-template
              >

            </div>
            </button>

            <div *ngIf="item.expanded">
              <mat-action-list
                *ngFor="let subComponent of item.subComponents"
                style="padding: 0px 0px 0px 0px"
              >
                <button
                  mat-list-item
                  (click)="
                    toggleExpanded(subComponent); selectedItem = subComponent
                  "
                  routerLink="./{{ subComponent.route }}"
                >
                  <span class="span-text-dark">{{ subComponent.title }}</span>

                  <div *ngIf="subComponent.title !== 'Condensor Pumps'">
                    <div *ngIf="subComponent.expanded; else other_content">
                      <mat-icon
                        class="arrow-icon"
                        style="color: var(--blue-one)"
                        >keyboard_arrow_up</mat-icon
                      >
                    </div>

                    <ng-template #other_content
                      ><mat-icon
                        class="arrow-icon"
                        style="color: var(--blue-one)"
                        >keyboard_arrow_down</mat-icon
                      ></ng-template
                    >
                  </div>
                </button>

                <div *ngIf="subComponent.expanded">
                  <button
                    mat-list-item
                    *ngFor="let listitem of subComponent.listItems"
                    (click)="selectedItem = listitem"
                    routerLink="{{ listitem.route }}"
                  >
                    <span class="span-text-dark">{{ listitem.title }}</span>
                  </button>
                </div>
              </mat-action-list>
            </div>
          </ng-container>

          <!-- rest of the items -->
          <ng-template #elseTemplate>
            <button
              mat-list-item
              (click)="toggleExpanded(item); selectedItem = item"
            >
              <span class="span-text-dark">{{ item.title }}</span>



              <div *ngIf="item.expanded; else other_content">
                <mat-icon class="arrow-icon" style="color: var(--blue-one)"
                  >keyboard_arrow_up</mat-icon
                >
              </div>

              <ng-template #other_content
                ><mat-icon class="arrow-icon" style="color: var(--blue-one)"
                  >keyboard_arrow_down</mat-icon
                ></ng-template
              >
            </button>

            <div *ngIf="item.expanded">
              <mat-action-list
                *ngFor="let subComponent of item.subComponents"
                style="padding: 0px 0px 0px 0px"
              >


              <div  [ngClass]="{'item-disabled': subComponent.disabled}">
                <button
                mat-list-item

                (click)="
                  toggleExpanded(subComponent); selectedItem = subComponent
                "
                routerLink="./{{ subComponent.route }}"
              >
                <span class="span-text-dark">{{ subComponent.title }}</span>
              </button>
              </div>








              </mat-action-list>
            </div>

            <!-- <ng-template #elseblock>
            <button
              mat-list-item
              (click)="toggleExpanded(subComponent); selectedItem = subComponent;"
            >
              <span class="span-text-dark">{{ subComponent.title }}</span>
            </button>
          </ng-template> -->
          </ng-template>
        </mat-action-list>
      </div>
    </mat-drawer>
  </mat-drawer-container>
</div>
