import { Component, Input } from '@angular/core';

@Component({
  selector: 'C-pier-gate-hold-room-two',
  templateUrl: './c-pier-gate-hold-room-two.component.html',
  styleUrls: ['./c-pier-gate-hold-room-two.component.css']
})
export class CPierGateHoldRoomTwoComponent {
  @Input()pmv!:number;

  constructor(){
    this.pmv=0;
  }

}
