<div
  [ngClass]="{
    'pmv-hot-three': pmv >= 1,
    'pmv-hot-two': (pmv >= 0.5) && (pmv<1),
    'pmv-neutral': (pmv > -0.5) && (pmv<0.5),
    'pmv-cold-two': (pmv > -1) && (pmv<=-0.5),
    'pmv-cold-three': (pmv <=-1),
    'pmv-disabled':pmv===-4,
    'pmv-default':pmv===4
  }"

  style="height: 100%;width: 100%;"
>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 588.737 216.01">
    <path id="Path_4308" data-name="Path 4308" d="M9290.346,4954.522v103.512H9052.692l-111.656,112.5-239.428-66V4954.862Z" transform="translate(-8701.608 -4954.522)" fill="#cd9d9c"/>
  </svg>
  
  
</div>
