import { Component, Input } from '@angular/core';

@Component({
  selector: 'Burner',
  templateUrl: './burner.component.html',
  styleUrls: ['./burner.component.css']
})
export class BurnerComponent {
  @Input() state!:string;
  urlActive:string;
  urlInActive:string;
  urlTrip:string;
  constructor(){
    this.urlActive="/assets/bert-components/active-burner.svg";
    this.urlInActive="/assets/bert-components/active-burner.svg";
    this.urlTrip="/assets/bert-components/active-burner.svg";
  }
}
