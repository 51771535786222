import { Component, Input } from '@angular/core';

@Component({
  selector: 'Electric-load-center-two',
  templateUrl: './electric-load-center-two.component.html',
  styleUrls: ['./electric-load-center-two.component.css']
})
export class ElectricLoadCenterTwoComponent {
  @Input()pmv!:number;

  constructor(){
    this.pmv=0;
  }
}
