<div
  [ngClass]="{
    'pmv-hot-three': pmv === 3,
    'pmv-hot-two': pmv === 2,
    'pmv-hot-one': pmv === 1,
    'pmv-neutral': pmv === 0,
    'pmv-cold-one': pmv === -1,
    'pmv-cold-two': pmv === -2,
    'pmv-cold-three': pmv === -3,
    'pmv-disabled':pmv===-4,
    'pmv-default':pmv===4
  }"

  style="width: 100%; height: 100%;"
>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 284 149">
    <rect id="Rectangle_1222" data-name="Rectangle 1222" width="284" height="149" fill="#f4f6ed"/>
  </svg>
  
  
</div>
