import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-gate-hold-room-d-one-map',
  templateUrl: './gate-hold-room-d-one-map.component.html',
  styleUrls: ['./gate-hold-room-d-one-map.component.css']
})
export class GateHoldRoomDOneMapComponent {
  progress: boolean = false;
  disableOptimise: boolean = true;
  predict_input: any;
  predict_response: any;
  pmv_avg:number;
  xinput:any;


  //predict input params
  // inputs from form

  ZT10: number;
  RH10: number;
  Velocity10: number;

  ZT11: number;
  RH11: number;
  Velocity11: number;

  ZT12: number;
  RH12: number;
  Velocity12: number;

  ZT13: number;
  RH13: number;
  Velocity13: number;

  ZT14: number;
  RH14: number;
  Velocity14: number;

  ZT15: number;
  RH15: number;
  Velocity15: number;

  ZT16: number;
  RH16: number;
  Velocity16: number;

  ZT17: number;
  RH17: number;
  Velocity17: number;

  ZT19: number;
  RH19: number;
  Velocity19: number;

  // output fields predict
  pmv_out10: number;
  pmv_out11: number;
  pmv_out12: number;
  pmv_out13: number;
  pmv_out14: number;
  pmv_out15: number;
  pmv_out16: number;
  pmv_out17: number;
  pmv_out19: number;

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {

    // 'Zone Temp D7-L2-10': Number(this.ZT10),
    // 'RH D7-L2-10': Number(this.RH10),
    // 'Velocity D7-L2-10': Number(this.Velocity10),

    // 'Zone Temp D7-L2-11': Number(this.ZT11),
    // 'RH D7-L2-11': Number(this.RH11),
    // 'Velocity D7-L2-11': Number(this.Velocity11),

    // 'Zone Temp D7-L2-12':Number(this.ZT12),
    // 'RH D7-L2-12': Number(this.RH12),
    // 'Velocity D7-L2-12': Number(this.Velocity12),

    // 'Zone Temp D7-L2-13':Number(this.ZT13),
    // 'RH D7-L2-13': Number(this.RH13),
    // 'Velocity D7-L2-13': Number(this.Velocity13),

    // 'Zone Temp D7-L2-14':Number(this.ZT14),
    // 'RH D7-L2-14': Number(this.RH14),
    // 'Velocity D7-L2-14': Number(this.Velocity14),

    // 'Zone Temp D7-L2-15':Number(this.ZT15),
    // 'RH D7-L2-15': Number(this.RH15),
    // 'Velocity D7-L2-15': Number(this.Velocity15),

    // 'Zone Temp D7-L2-16':Number(this.ZT16),
    // 'RH D7-L2-16': Number(this.RH16),
    // 'Velocity D7-L2-16': Number(this.Velocity16),

    // 'Zone Temp D7-L2-17':Number(this.ZT17),
    // 'RH D7-L2-17': Number(this.RH17),
    // 'Velocity D7-L2-17 ': Number(this.Velocity17),

    // 'Zone Temp D7-L2-19':Number(this.ZT19),
    // 'RH D7-L2-19': Number(this.RH19),
    // 'Velocity D7-L2-19': Number(this.Velocity19),

    if(localStorage.getItem('gate-one-map')){
      this.xinput=localStorage.getItem('gate-one-map');
      this.xinput=JSON.parse(this.xinput);

      this.ZT10 = Number( this.xinput['Zone Temp D7-L2-10']);
      this.RH10 = Number( this.xinput['RH D7-L2-10']);
      this.Velocity10 = Number( this.xinput['Velocity D7-L2-10']);
  
      this.ZT11 = Number( this.xinput['Zone Temp D7-L2-11']);
      this.RH11 = Number( this.xinput['RH D7-L2-11']);
      this.Velocity11 = Number( this.xinput['Velocity D7-L2-11']);
  
      this.ZT12 = Number( this.xinput['Zone Temp D7-L2-12']);
      this.RH12 = Number( this.xinput['RH D7-L2-12']);
      this.Velocity12 = Number( this.xinput['Velocity D7-L2-12']);
  
      this.ZT13 = Number( this.xinput['Zone Temp D7-L2-13']);
      this.RH13 = Number( this.xinput['RH D7-L2-13']);
      this.Velocity13 = Number( this.xinput['Velocity D7-L2-13']);
  
      this.ZT14 = Number( this.xinput['Zone Temp D7-L2-14']);
      this.RH14 = Number( this.xinput['RH D7-L2-14']);
      this.Velocity14 = Number( this.xinput['Velocity D7-L2-14']);
  
      this.ZT15 = Number( this.xinput['Zone Temp D7-L2-15']);
      this.RH15 = Number( this.xinput['RH D7-L2-15']);
      this.Velocity15 = Number( this.xinput['Velocity D7-L2-15']);
  
      this.ZT16 = Number( this.xinput['Zone Temp D7-L2-16']);
      this.RH16 = Number( this.xinput['RH D7-L2-16']);
      this.Velocity16 = Number( this.xinput['Velocity D7-L2-16']);
  
      this.ZT17 = Number( this.xinput['Zone Temp D7-L2-17']);
      this.RH17 = Number( this.xinput['RH D7-L2-17']);
      this.Velocity17 = Number( this.xinput['Velocity D7-L2-17 ']);
  
      this.ZT19 = Number( this.xinput['Zone Temp D7-L2-19']);
      this.RH19 = Number( this.xinput['RH D7-L2-19']);
      this.Velocity19 = Number( this.xinput['Velocity D7-L2-19']);
    }else{
      this.ZT10 = 0;
      this.Velocity10 = 0;
      this.RH10 = 0;
  
      this.ZT11 = 0;
      this.Velocity11 = 0;
      this.RH11 = 0;
  
      this.ZT12 = 0;
      this.Velocity12 = 0;
      this.RH12 = 0;
  
      this.ZT13 = 0;
      this.Velocity13 = 0;
      this.RH13 = 0;
  
      this.ZT14 = 0;
      this.Velocity14 = 0;
      this.RH14 = 0;
  
      this.ZT15 = 0;
      this.Velocity15 = 0;
      this.RH15 = 0;
  
      this.ZT16 = 0;
      this.Velocity16 = 0;
      this.RH16 = 0;
  
      this.ZT17 = 0;
      this.Velocity17 = 0;
      this.RH17 = 0;
  
      this.ZT19 = 0;
      this.Velocity19 = 0;
      this.RH19 = 0;
    }


    // output_field

    
    if(localStorage.getItem('PMV D7-L2-10')){
      this.pmv_out10= Number(localStorage.getItem('PMV D7-L2-10'));
     }else{
       this.pmv_out10 = 0;
     }
 
     
     if(localStorage.getItem('PMV D7-L2-11')){
       this.pmv_out11= Number(localStorage.getItem('PMV D7-L2-11'));
      }else{
        this.pmv_out11 = 0;
      }
 
      
     if(localStorage.getItem('PMV D7-L2-12')){
       this.pmv_out12= Number(localStorage.getItem('PMV D7-L2-12'));
      }else{
        this.pmv_out12 = 0;
     }
     
     if(localStorage.getItem('PMV D7-L2-13')){
      this.pmv_out13= Number(localStorage.getItem('PMV D7-L2-13'));
     }else{
       this.pmv_out13 = 0;
    }
    
    if(localStorage.getItem('PMV D7-L2-14')){
      this.pmv_out14= Number(localStorage.getItem('PMV D7-L2-14'));
     }else{
       this.pmv_out14 = 0;
    }
    
    if(localStorage.getItem('PMV D7-L2-15')){
      this.pmv_out15= Number(localStorage.getItem('PMV D7-L2-15'));
     }else{
       this.pmv_out15 = 0;
    }
    
    if(localStorage.getItem('PMV D7-L2-16')){
      this.pmv_out16= Number(localStorage.getItem('PMV D7-L2-16'));
     }else{
       this.pmv_out16 = 0;
    }
    
    if(localStorage.getItem('PMV D7-L2-17')){
      this.pmv_out17= Number(localStorage.getItem('PMV D7-L2-17'));
     }else{
       this.pmv_out17 = 0;
    }
    
    if(localStorage.getItem('PMV D7-L2-19')){
      this.pmv_out19= Number(localStorage.getItem('PMV D7-L2-19'));
     }else{
       this.pmv_out19 = 0;
    }
    
    
    // this.pmv_out10=0;
    // this.pmv_out11=0;
    // this.pmv_out12=0;
    // this.pmv_out13=0;
    // this.pmv_out14=0;
    // this.pmv_out15=0;
    // this.pmv_out16=0;
    // this.pmv_out17=0;
    // this.pmv_out19=0;

  if(localStorage.getItem('PMV D7-L2-10') && localStorage.getItem('PMV D7-L2-11') && localStorage.getItem('PMV D7-L2-12') &&  localStorage.getItem('PMV D7-L2-13') &&  localStorage.getItem('PMV D7-L2-14') &&  localStorage.getItem('PMV D7-L2-15') &&  localStorage.getItem('PMV D7-L2-16') &&  localStorage.getItem('PMV D7-L2-17') &&  localStorage.getItem('PMV D7-L2-19')){
    this.pmv_avg=(this.pmv_out10+this.pmv_out11+this.pmv_out12+this.pmv_out13+this.pmv_out14+this.pmv_out15+this.pmv_out16+this.pmv_out17+this.pmv_out19)/9;

  }else{
    this.pmv_avg = 0;
  } 

  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if(this.ZT10<21 || this.ZT10>23.8){
      this.openSnackBar('ZT10 should be in range [21,23.8]');
      return false;
    }else if(this.Velocity10<0.46 || this.Velocity10>0.71){
      this.openSnackBar('Velocity10 should be in range [0.46,0.71]');
      return false;
    }else if(this.RH10<45.98 || this.RH10>57.95){
      this.openSnackBar('RH10 should be in range [45.98,57.95]');
      return false;
    }else  if(this.ZT11<21 || this.ZT11>23.8){
      this.openSnackBar('ZT11 should be in range [21,23.8]');
      return false;
    }else if(this.Velocity11<0.66 || this.Velocity11>0.97){
      this.openSnackBar('Velocity11 should be in range [0.66,0.97]');
      return false;
    }else if(this.RH11<45.98 || this.RH11>57.95){
      this.openSnackBar('RH11 should be in range [45.98,57.95]');
      return false;
    }else  if(this.ZT12<21.9 || this.ZT12>29.4){
      this.openSnackBar('ZT12 should be in range [21.9,29.4]');
      return false;
    }else if(this.Velocity12<0 || this.Velocity12>3.11){
      this.openSnackBar('Velocity12 should be in range [0,3.11]');
      return false;
    }else if(this.RH12<34.43 || this.RH12>51){
      this.openSnackBar('RH12 should be in range [34.43,51]');
      return false;
    }else  if(this.ZT13<21.6 || this.ZT13>24.8){
      this.openSnackBar('ZT13 should be in range [21.6,24.8]');
      return false;
    }else if(this.Velocity13<0.15 || this.Velocity13>3.11){
      this.openSnackBar('Velocity13 should be in range [0.15,3.11]');
      return false;
    }else if(this.RH13<42.88 || this.RH13>55.25){
      this.openSnackBar('RH13 should be in range [42.88,55.25]');
      return false;
    }else  if(this.ZT14<21.6 || this.ZT14>24.8){
      this.openSnackBar('ZT14 should be in range [21.6,24.8]');
      return false;
    }else if(this.Velocity14<0.65 || this.Velocity14>4.85){
      this.openSnackBar('Velocity14 should be in range [0.65,4.85]');
      return false;
    }else if(this.RH14<42.88 || this.RH14>55.25){
      this.openSnackBar('RH14 should be in range [42.88,55.25]');
      return false;
    }else  if(this.ZT15<21.6 || this.ZT15>24.8){
      this.openSnackBar('ZT15 should be in range [21.6,24.8]');
      return false;
    }else if(this.Velocity15<0.41 || this.Velocity15>3.11){
      this.openSnackBar('Velocity15 should be in range [0.41,3.11]');
      return false;
    }else if(this.RH15<42.88 || this.RH15>55.25){
      this.openSnackBar('RH15 should be in range [42.88,55.25]');
      return false;
    }else  if(this.ZT16<22.3 || this.ZT16>29){
      this.openSnackBar('ZT16 should be in range [22.3,29]');
      return false;
    }else if(this.Velocity16<0.61 || this.Velocity16>4.85){
      this.openSnackBar('Velocity16 should be in range [0.61,4.85]');
      return false;
    }else if(this.RH16<35.21 || this.RH16>50.77){
      this.openSnackBar('RH16 should be in range [35.21,50.77]');
      return false;
    }else  if(this.ZT17<22 || this.ZT17>24.8){
      this.openSnackBar('ZT17 should be in range [22,24.8]');
      return false;
    }else if(this.Velocity17<0.42 || this.Velocity17>3.11){
      this.openSnackBar('Velocity17 should be in range [0.42,3.11]');
      return false;
    }else if(this.RH17<42.62 || this.RH17>53.92){
      this.openSnackBar('RH17 should be in range [42.62,53.92]');
      return false;
    }else  if(this.ZT19<22 || this.ZT19>24.8){
      this.openSnackBar('ZT19 should be in range [22,24.8]');
      return false;
    }else if(this.Velocity19<0.42 || this.Velocity19>3.11){
      this.openSnackBar('Velocity19 should be in range [0.42,3.11]');
      return false;
    }else if(this.RH19<42.62 || this.RH19>53.92){
      this.openSnackBar('RH19 should be in range [42.62,53.92]');
      return false;
    }

    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  // this fuction is use to predict PMV
  async predictData() {
    if (this.validate()) {
      this.progress = true;
      console.log('predict input', this.predict_input);

      
      this.predict_input= {
        'Zone Temp D7-L2-10': Number(this.ZT10),
        'RH D7-L2-10': Number(this.RH10),
        'Velocity D7-L2-10': Number(this.Velocity10),

        'Zone Temp D7-L2-11': Number(this.ZT11),
        'RH D7-L2-11': Number(this.RH11),
        'Velocity D7-L2-11': Number(this.Velocity11),

        'Zone Temp D7-L2-12':Number(this.ZT12),
        'RH D7-L2-12': Number(this.RH12),
        'Velocity D7-L2-12': Number(this.Velocity12),

        'Zone Temp D7-L2-13':Number(this.ZT13),
        'RH D7-L2-13': Number(this.RH13),
        'Velocity D7-L2-13': Number(this.Velocity13),

        'Zone Temp D7-L2-14':Number(this.ZT14),
        'RH D7-L2-14': Number(this.RH14),
        'Velocity D7-L2-14': Number(this.Velocity14),

        'Zone Temp D7-L2-15':Number(this.ZT15),
        'RH D7-L2-15': Number(this.RH15),
        'Velocity D7-L2-15': Number(this.Velocity15),

        'Zone Temp D7-L2-16':Number(this.ZT16),
        'RH D7-L2-16': Number(this.RH16),
        'Velocity D7-L2-16': Number(this.Velocity16),

        'Zone Temp D7-L2-17':Number(this.ZT17),
        'RH D7-L2-17': Number(this.RH17),
        'Velocity D7-L2-17 ': Number(this.Velocity17),

        'Zone Temp D7-L2-19':Number(this.ZT19),
        'RH D7-L2-19': Number(this.RH19),
        'Velocity D7-L2-19': Number(this.Velocity19),

        'model_no.': 6,
      };

      try {
        const response = await this.http
          .post<any>(
            'https://bpsliveatchangiairport.bertlabs.com/predictsec6/',
            this.predict_input,
            this.httpOptions
          )
          .toPromise();

        this.predict_response = response;
        console.log('predict outout', this.predict_response);

        this.openSnackBar('Predicted Succesfully');


        this.pmv_out10 =  this.predict_response['PMV D7-L2-10'];
        this.pmv_out11 =  this.predict_response['PMV D7-L2-11'];
        this.pmv_out12 =  this.predict_response['PMV D7-L2-12'];
        this.pmv_out13 =  this.predict_response['PMV D7-L2-13'];
        this.pmv_out14 =  this.predict_response['PMV D7-L2-14'];
        this.pmv_out15 =  this.predict_response['PMV D7-L2-15'];
        this.pmv_out16 =  this.predict_response['PMV D7-L2-16'];
        this.pmv_out17 =  this.predict_response['PMV D7-L2-17'];
        this.pmv_out19 =  this.predict_response['PMV D7-L2-19'];
       
        
        this.pmv_avg=(this.pmv_out10+this.pmv_out11+this.pmv_out12+this.pmv_out13+this.pmv_out14+this.pmv_out15+this.pmv_out16+this.pmv_out17+this.pmv_out19)/9;



        this.progress = false;
        this.disableOptimise = false;
        localStorage.setItem('model_six', JSON.stringify(this.pmv_avg));
        localStorage.setItem('PMV D7-L2-10',JSON.stringify(this.pmv_out10));
        localStorage.setItem('PMV D7-L2-11',JSON.stringify(this.pmv_out11));
        localStorage.setItem('PMV D7-L2-12',JSON.stringify(this.pmv_out12));
        localStorage.setItem('PMV D7-L2-13',JSON.stringify(this.pmv_out13));
        localStorage.setItem('PMV D7-L2-14',JSON.stringify(this.pmv_out14));
        localStorage.setItem('PMV D7-L2-15',JSON.stringify(this.pmv_out15));
        localStorage.setItem('PMV D7-L2-16',JSON.stringify(this.pmv_out16));
        localStorage.setItem('PMV D7-L2-17',JSON.stringify(this.pmv_out17));
        localStorage.setItem('PMV D7-L2-19',JSON.stringify(this.pmv_out19));

        localStorage.setItem('gate-one-map', JSON.stringify(this.predict_input));
        
      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }
} 
