<div
  class="terminal-4"
  style="width: 100%;height: 100%;"
>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 345.406 198.091">
  <g id="Group_2374" data-name="Group 2374" transform="translate(-269.068 -660.156)">
    <path id="Path_4493" stroke="#313131" stroke-width="1.5" data-name="Path 4493" d="M16015.578,6024.352v162.409h82.215V6058.8h34.721v163.641h-345.406V6024.352Z" transform="translate(-15518.039 -5364.196)" fill="#712e83"/>
    <text id="TRANSFER" transform="translate(406.589 689.397)" fill="#fff" stroke="#fff" stroke-width="0.5" font-size="12" font-family="Numans, sans-serif"><tspan x="0" y="11">TRANSFER</tspan></text>
  </g>
</svg>


  
</div>