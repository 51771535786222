import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-c18-part5',
  templateUrl: './c18-part5.component.html',
  styleUrls: ['./c18-part5.component.css']
})


export class C18Part5Component  {
  isLoading = true;
  progress: boolean = false;
  disableOptimise: boolean = true;
  predict_input: any;
  predict_response: any;

  predictionResult: any = {};
  jsonData: any = {};

  interface_input: any = {};

  ZONE_TEMP_out_C4_L2_16: any = 0;
  ZONE_TEMP_out_C4_L2_18: any = 0;

  Zone_Temp_C4_L2_16_out_L: any = 0;
  Zone_Temp_C4_L2_16_out_U: any = 0;
  Zone_Temp_C4_L2_18_out_L: any = 0;
  Zone_Temp_C4_L2_18_out_U: any = 0;

  Zone_Temp_C4_L2_16_out: any = 0;
  PMV_C4_L2_16_out: any = 0;
  room_RH_C4_L2_16_out: any = 0;
  room_temp_C4_L2_16_out: any = 0;
  radiantTemp_C4_L2_16_out: any = 0;
  Zone_Temp_C4_L2_18_out: any = 0;
  PMV_C4_L2_18_out: any = 0;
  room_RH_C4_L2_18_out: any = 0;
  room_temp_C4_L2_18_out: any = 0;
  radiantTemp_C4_L2_18_out: any = 0;


  ZONE_TEMP_out_C4_L2_19: any = 0;
  ZONE_TEMP_out_C4_L2_20: any = 0;

  Zone_Temp_C4_L2_19_out_L: any = 0;
  Zone_Temp_C4_L2_19_out_U: any = 0;
  Zone_Temp_C4_L2_20_out_L: any = 0;
  Zone_Temp_C4_L2_20_out_U: any = 0;

  Zone_Temp_C4_L2_19_out: any = 0;
  PMV_C4_L2_19_out: any = 0;
  room_RH_C4_L2_19_out: any = 0;
  room_temp_C4_L2_19_out: any = 0;
  radiantTemp_C4_L2_19_out: any = 0;
  Zone_Temp_C4_L2_20_out: any = 0;
  PMV_C4_L2_20_out: any = 0;
  room_RH_C4_L2_20_out: any = 0;
  room_temp_C4_L2_20_out: any = 0;
  radiantTemp_C4_L2_20_out: any = 0;

  time: string = '';


  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {

    if(localStorage.getItem('interface_input')){
      this.interface_input=localStorage.getItem('interface_input');
      this.interface_input=JSON.parse(this.interface_input);

      this.Zone_Temp_C4_L2_16_out = this.interface_input['Zone_Temp_C4-L2-16'];
      this.PMV_C4_L2_16_out = this.interface_input['PMV_C4-L2-16'];
      this.room_RH_C4_L2_16_out = this.interface_input['room_RH_C4-L2-16'];
      this.room_temp_C4_L2_16_out = this.interface_input['room_temp_C4-L2-16'];
      this.radiantTemp_C4_L2_16_out = this.interface_input['radiantTemp_C4-L2-16'];
      this.Zone_Temp_C4_L2_18_out = this.interface_input['Zone_Temp_C4-L2-18'];
      this.PMV_C4_L2_18_out = this.interface_input['PMV_C4-L2-18'];
      this.room_RH_C4_L2_18_out = this.interface_input['room_RH_C4-L2-18'];
      this.room_temp_C4_L2_18_out = this.interface_input['room_temp_C4-L2-18'];
      this.radiantTemp_C4_L2_18_out = this.interface_input['radiantTemp_C4-L2-18'];

      this.Zone_Temp_C4_L2_19_out = this.interface_input['Zone_Temp_C4-L2-19'];
      this.PMV_C4_L2_19_out = this.interface_input['PMV_C4-L2-19'];
      this.room_RH_C4_L2_19_out = this.interface_input['room_RH_C4-L2-19'];
      this.room_temp_C4_L2_19_out = this.interface_input['room_temp_C4-L2-19'];
      this.radiantTemp_C4_L2_19_out = this.interface_input['radiantTemp_C4-L2-19'];
      this.Zone_Temp_C4_L2_20_out = this.interface_input['Zone_Temp_C4-L2-20'];
      this.PMV_C4_L2_20_out = this.interface_input['PMV_C4-L2-20'];
      this.room_RH_C4_L2_20_out = this.interface_input['room_RH_C4-L2-20'];
      this.room_temp_C4_L2_20_out = this.interface_input['room_temp_C4-L2-20'];
      this.radiantTemp_C4_L2_20_out = this.interface_input['radiantTemp_C4-L2-20'];
      this.time = this.interface_input['Time'];


     }else{
      this.Zone_Temp_C4_L2_16_out = 0;
      this.PMV_C4_L2_16_out = 0;
      this.room_RH_C4_L2_16_out = 0;
      this.room_temp_C4_L2_16_out = 0;
      this.radiantTemp_C4_L2_16_out = 0;
      this.Zone_Temp_C4_L2_18_out = 0;
      this.PMV_C4_L2_18_out = 0;
      this.room_RH_C4_L2_18_out = 0;
      this.room_temp_C4_L2_18_out = 0;
      this.radiantTemp_C4_L2_18_out = 0;

      this.Zone_Temp_C4_L2_19_out = 0;
      this.PMV_C4_L2_19_out = 0;
      this.room_RH_C4_L2_19_out = 0;
      this.room_temp_C4_L2_19_out = 0;
      this.radiantTemp_C4_L2_19_out = 0;
      this.Zone_Temp_C4_L2_20_out = 0;
      this.PMV_C4_L2_20_out = 0;
      this.room_RH_C4_L2_20_out = 0;
      this.room_temp_C4_L2_20_out = 0;
      this.radiantTemp_C4_L2_20_out = 0;
     }


     if(localStorage.getItem('predictionResult')){
        this.predictionResult = localStorage.getItem('predictionResult');
        this.predictionResult = JSON.parse(this.predictionResult);
        this.ZONE_TEMP_out_C4_L2_16 = this.predictionResult['Zone_Temp_C4-L2-16'];
        this.ZONE_TEMP_out_C4_L2_18 = this.predictionResult['Zone_Temp_C4-L2-18'];
        this.Zone_Temp_C4_L2_16_out_L = this.ZONE_TEMP_out_C4_L2_16 - 0.5;
        this.Zone_Temp_C4_L2_16_out_U = this.ZONE_TEMP_out_C4_L2_16 + 0.5;
        this.Zone_Temp_C4_L2_18_out_L = this.ZONE_TEMP_out_C4_L2_18 - 0.5;
        this.Zone_Temp_C4_L2_18_out_U = this.ZONE_TEMP_out_C4_L2_18 + 0.5;

        this.ZONE_TEMP_out_C4_L2_19 = this.predictionResult['Zone_Temp_C4-L2-19'];
        this.ZONE_TEMP_out_C4_L2_20 = this.predictionResult['Zone_Temp_C4-L2-20'];
        this.Zone_Temp_C4_L2_19_out_L = this.ZONE_TEMP_out_C4_L2_19 - 0.5;
        this.Zone_Temp_C4_L2_19_out_U = this.ZONE_TEMP_out_C4_L2_19 + 0.5;
        this.Zone_Temp_C4_L2_20_out_L = this.ZONE_TEMP_out_C4_L2_20 - 0.5;
        this.Zone_Temp_C4_L2_20_out_U = this.ZONE_TEMP_out_C4_L2_20 + 0.5;
     }


    // output_field




  }

  ngOnInit(): void {
    this.getInputResult();
    this.getPredictionResult();
    setInterval(() => {
      this.getPredictionResult();
      this.getInputResult();
    }, 300000); // 300000 milliseconds = 5 minutes
    setTimeout(() => {
      this.isLoading = false;
    }, 400); // Wait for 1 second before displaying the content
  }

  async getInputResult() {
    this.http.get<any>('https://airportinternational.bertlabs.com/get_result')
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Input response:', response);

            // Check if the response is a string and parse it if necessary
            if (typeof response.answer === 'string') {
              this.interface_input = JSON.parse(response.answer);
            } else {
              this.interface_input = response.answer;
            }

            console.log('Interface result:', this.interface_input);
            this.Zone_Temp_C4_L2_16_out = this.interface_input['Zone_Temp_C4-L2-16'];
            this.PMV_C4_L2_16_out = this.interface_input['PMV_C4-L2-16'];
            this.room_RH_C4_L2_16_out = this.interface_input['room_RH_C4-L2-16'];
            this.room_temp_C4_L2_16_out = this.interface_input['room_temp_C4-L2-16'];
            this.radiantTemp_C4_L2_16_out = this.interface_input['radiantTemp_C4-L2-16'];
            this.Zone_Temp_C4_L2_18_out = this.interface_input['Zone_Temp_C4-L2-18'];
            this.PMV_C4_L2_18_out = this.interface_input['PMV_C4-L2-18'];
            this.room_RH_C4_L2_18_out = this.interface_input['room_RH_C4-L2-18'];
            this.room_temp_C4_L2_18_out = this.interface_input['room_temp_C4-L2-18'];
            this.radiantTemp_C4_L2_18_out = this.interface_input['radiantTemp_C4-L2-18'];

            this.Zone_Temp_C4_L2_19_out = this.interface_input['Zone_Temp_C4-L2-19'];
            this.PMV_C4_L2_19_out = this.interface_input['PMV_C4-L2-19'];
            this.room_RH_C4_L2_19_out = this.interface_input['room_RH_C4-L2-19'];
            this.room_temp_C4_L2_19_out = this.interface_input['room_temp_C4-L2-19'];
            this.radiantTemp_C4_L2_19_out = this.interface_input['radiantTemp_C4-L2-19'];
            this.Zone_Temp_C4_L2_20_out = this.interface_input['Zone_Temp_C4-L2-20'];
            this.PMV_C4_L2_20_out = this.interface_input['PMV_C4-L2-20'];
            this.room_RH_C4_L2_20_out = this.interface_input['room_RH_C4-L2-20'];
            this.room_temp_C4_L2_20_out = this.interface_input['room_temp_C4-L2-20'];
            this.radiantTemp_C4_L2_20_out = this.interface_input['radiantTemp_C4-L2-20'];
            // console.log("Zone_Temp_C4_L2_15", this.Zone_Temp_C4_L2_15);
            // Adjust the time here
            const adjustedTime = this.adjustTime(this.interface_input['Time']);
            console.log("Adjusted Time:", adjustedTime);

            // Update the time in the interface_input object and store it in localStorage
            this.interface_input['Time'] = adjustedTime;
            localStorage.setItem('interface_input', JSON.stringify(this.interface_input));

            this.time = adjustedTime; // Now set the adjusted time to display it
            console.log("Time to display:", this.time);

            this.jsonData['interface_input'] = this.interface_input;
            console.log(this.jsonData);
            localStorage.setItem('interface_input', JSON.stringify(this.interface_input));
            console.log('predict_output', this.predict_response);
          } catch (error) {
            console.error('Error parsing Input result:', error);
          }
        },
        (error) => {
          console.error('Error fetching Input result:', error);
        }
      );
  }

  adjustTime(timeString: string): string {
    // Parse the time string into a Date object
    const date = new Date(timeString);

    date.setHours(date.getHours());
    date.setMinutes(date.getMinutes());
    date.setSeconds(date.getSeconds());

    // Format the date back to YYYY-MM-DD HH:mm:ss
    const adjustedDatePart = date.toISOString().split('T')[0];
    const adjustedTimePart = date.toTimeString().split(' ')[0];

    return `${adjustedDatePart} ${adjustedTimePart}`;
  }

  async getPredictionResult() {
    this.http.get<any>('https://airportinternational.bertlabs.com/get_prediction_result')
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Prediction response:', response);
            this.ZONE_TEMP_out_C4_L2_16 = this.predictionResult['Zone_Temp_C4-L2-16'];
            this.ZONE_TEMP_out_C4_L2_18 = this.predictionResult['Zone_Temp_C4-L2-18'];
            this.Zone_Temp_C4_L2_16_out_L = this.ZONE_TEMP_out_C4_L2_16 - 0.5;
            this.Zone_Temp_C4_L2_16_out_U = this.ZONE_TEMP_out_C4_L2_16 + 0.5;
            this.Zone_Temp_C4_L2_18_out_L = this.ZONE_TEMP_out_C4_L2_18 - 0.5;
            this.Zone_Temp_C4_L2_18_out_U = this.ZONE_TEMP_out_C4_L2_18 + 0.5;

            this.ZONE_TEMP_out_C4_L2_19 = this.predictionResult['Zone_Temp_C4-L2-19'];
            this.ZONE_TEMP_out_C4_L2_20 = this.predictionResult['Zone_Temp_C4-L2-20'];
            this.Zone_Temp_C4_L2_19_out_L = this.ZONE_TEMP_out_C4_L2_19 - 0.5;
            this.Zone_Temp_C4_L2_19_out_U = this.ZONE_TEMP_out_C4_L2_19 + 0.5;
            this.Zone_Temp_C4_L2_20_out_L = this.ZONE_TEMP_out_C4_L2_20 - 0.5;
            this.Zone_Temp_C4_L2_20_out_U = this.ZONE_TEMP_out_C4_L2_20 + 0.5;
            // Check if the response is a string and parse it if necessary
            if (typeof response.result === 'string') {
              this.predictionResult = JSON.parse(response.result);
            } else {
              this.predictionResult = response.result;
            }

            console.log('Prediction result:', this.predictionResult);
            this.jsonData['predictionResult'] = this.predictionResult;
            console.log(this.jsonData);
            localStorage.setItem('predictionResult', JSON.stringify(this.predictionResult));
            console.log('predict_output', this.predict_response);
          } catch (error) {
            console.error('Error parsing prediction result:', error);
          }
        },
        (error) => {
          console.error('Error fetching prediction result:', error);
        }
      );
  }








  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };



  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  // this fuction is use to predict PMV

}
