import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-electric-load-centre-two-map',
  templateUrl: './electric-load-centre-two-map.component.html',
  styleUrls: ['./electric-load-centre-two-map.component.css']
})
export class ElectricLoadCentreTwoMapComponent {
    
  progress: boolean = false;
  disableOptimise: boolean = true;
  predict_input: any;
  predict_response: any;
  xinput:any;

  //predict input params
  // inputs from form

  ZT21: number;
  RH21:number;
  Velocity21: number;

  ZT22: number;
  RH22:number;
  Velocity22: number;

  ZT23: number;
  RH23:number;
  Velocity23: number;

  // output fields predict
  pmv_out21: number;
  pmv_out22: number;
  pmv_out23: number;
  pmv_avg: number;

 
  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {

    // 'Zone Temp D7-L2-21'     : Number(this.ZT21),
    // 'RH D7-L2-21'      : Number(this.RH21),
    // 'Velocity D7-L2-21'      : Number(this.Velocity21),
    // 'Zone Temp D7-L2-22'     : Number(this.ZT22),
    // 'RH D7-L2-22'      : Number(this.RH22),
    // 'Velocity D7-L2-22'      : Number(this.Velocity22),
    // 'Zone Temp D7-L2-23'     : Number(this.ZT23),
    // 'RH D7-L2-23'      : Number(this.RH23),
    // 'Velocity D7-L2-23'      : Number(this.Velocity23),

    if(localStorage.getItem('electric-two-map')){
      this.xinput=localStorage.getItem('electric-two-map');
      this.xinput=JSON.parse(this.xinput);

      this.ZT21 = Number( this.xinput['Zone Temp D7-L2-21']);
      this.RH21 = Number( this.xinput['RH D7-L2-21' ]);
      this.Velocity21 = Number( this.xinput['Velocity D7-L2-21' ]);
      this.ZT22 = Number( this.xinput['Zone Temp D7-L2-22']);
      this.RH22 = Number( this.xinput['RH D7-L2-22' ]);
      this.Velocity22 = Number( this.xinput['Velocity D7-L2-22' ]);
      this.ZT23 = Number( this.xinput['Zone Temp D7-L2-23']);
      this.RH23 = Number( this.xinput['RH D7-L2-23' ]);
      this.Velocity23 = Number( this.xinput['Velocity D7-L2-23' ]);

    }else{
      this.ZT21=0;
      this.RH21=0;
      this.Velocity21=0;
      this.ZT22=0;
      this.RH22=0;
      this.Velocity22=0;
      this.ZT23=0;
      this.RH23=0;
      this.Velocity23=0;
    }


    if(localStorage.getItem('PMV D7-L2-21')){
     this.pmv_out21= Number(localStorage.getItem('PMV D7-L2-21'));
    }else{
      this.pmv_out21 = 0;
    }

    
    if(localStorage.getItem('PMV D7-L2-22')){
      this.pmv_out22= Number(localStorage.getItem('PMV D7-L2-22'));
     }else{
       this.pmv_out22 = 0;
     }

     
    if(localStorage.getItem('PMV D7-L2-23')){
      this.pmv_out23= Number(localStorage.getItem('PMV D7-L2-23'));
     }else{
       this.pmv_out23 = 0;
    }
    
    if(localStorage.getItem('PMV D7-L2-21') && localStorage.getItem('PMV D7-L2-22') &&localStorage.getItem('PMV D7-L2-23')){
      this.pmv_avg = (this.pmv_out21+this.pmv_out22+this.pmv_out23)/3;
    }else{
      this.pmv_avg = 0;
    } 
   
    


  }


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if(this.ZT21<21.8 || this.ZT21>24.7){
      this.openSnackBar('ZT21 should be in range [21.8,24.7]');
      return false;
    }else if(this.Velocity21<0.43 || this.Velocity21>3.11){
      this.openSnackBar('Velocity21 should be in range [0.43,3.11]');
      return false;
    }else if(this.RH21<43.14 || this.RH21>54.55){
      this.openSnackBar('RH21 should be in range [43.14,54.55]');
      return false;
    }else  if(this.ZT22<22.7 || this.ZT22>27){
      this.openSnackBar('ZT22 should be in range [22.7,27]');
      return false;
    }else if(this.Velocity22<0.41 || this.Velocity22>3.11){
      this.openSnackBar('Velocity22 should be in range [0.41,3.11]');
      return false;
    }else if(this.RH22<39.34 || this.RH22>54.22){
      this.openSnackBar('RH22 should be in range [39.34,54.22]');
      return false;
    }else  if(this.ZT23<22.3 || this.ZT23>27.5){
      this.openSnackBar('ZT23 should be in range [22.3,27.5]');
      return false;
    }else if(this.Velocity23<0.43 || this.Velocity23>3.11){
      this.openSnackBar('Velocity23 should be in range [0.43,3.11]');
      return false;
    }else if(this.RH23<36.91 || this.RH23>51.68){
      this.openSnackBar('RH23 should be in range [36.91,51.68]');
      return false;
    }

    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }


  // this fuction is use to predict PMV
  async predictData(){
    
    if (this.validate()) {
      this.progress = true;
      // console.log('predict input', this.predict_input);

      this.predict_input= {
        'Zone Temp D7-L2-21': Number(this.ZT21),
        'RH D7-L2-21': Number(this.RH21),
        'Velocity D7-L2-21': Number(this.Velocity21),
        'Zone Temp D7-L2-22': Number(this.ZT22),
        'RH D7-L2-22': Number(this.RH22),
        'Velocity D7-L2-22': Number(this.Velocity22),
        'Zone Temp D7-L2-23': Number(this.ZT23),
        'RH D7-L2-23': Number(this.RH23),
        'Velocity D7-L2-23': Number(this.Velocity23),
        'model_no.': 5,
      };
    

      try {
        const response = await this.http
          .post<any>(
            // 'http://4.224.40.114:5001/predictsec1/',
            'https://bpsliveatchangiairport.bertlabs.com/predictsec5/',
            this.predict_input,
            this.httpOptions
          )
          .toPromise();

        this.predict_response = response;
        console.log('predict outout', this.predict_response);

        this.openSnackBar('Predicted Succesfully');

        this.pmv_out21 = this.predict_response['PMV D7-L2-21'];
        this.pmv_out22 = this.predict_response['PMV D7-L2-22'];
        this.pmv_out23 = this.predict_response['PMV D7-L2-23'];


        this.pmv_avg = (this.pmv_out21+this.pmv_out22+this.pmv_out23)/3;

        this.progress = false;
        this.disableOptimise = false;
        localStorage.setItem('model_five', JSON.stringify(this.pmv_avg));
        localStorage.setItem('PMV D7-L2-21',JSON.stringify(this.pmv_out21));
        localStorage.setItem('PMV D7-L2-22',JSON.stringify(this.pmv_out22));
        localStorage.setItem('PMV D7-L2-23',JSON.stringify(this.pmv_out23));

        localStorage.setItem('electric-two-map', JSON.stringify(this.predict_input));
        
      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }

}   
