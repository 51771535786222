import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-c-pier-gate-hold-room-one-map',
  templateUrl: './c-pier-gate-hold-room-one-map.component.html',
  styleUrls: ['./c-pier-gate-hold-room-one-map.component.css']
})
// export class CPierGateHoldRoomOneMapComponent {
//   progress:boolean=false;


// }
export class CPierGateHoldRoomOneMapComponent {
  progress: boolean = false;
  disableOptimise: boolean = true;
  predict_input: any;
  predict_response: any;
  pmv_avg:number;
  gate_input:any;
  predictionResult: any = {};
  jsonData: any = {};

  ZONE_TEMP_out_C4_L2_16: any = 0;
  ZONE_TEMP_out_C4_L2_18: any = 0;
  ZONE_TEMP_out_C4_L2_19: any = 0;
  ZONE_TEMP_out_C4_L2_20: any = 0;
  ZONE_TEMP_out_C4_L2_22: any = 0;
  ZONE_TEMP_out_C4_L2_23: any = 0;
  ZONE_TEMP_out_C4_L2_25: any = 0;
  ZONE_TEMP_out_C4_L2_26: any = 0;
  ZONE_TEMP_out_C4_L2_27: any = 0;

  //predict input params
  // inputs from form

  ZT16: number;
  RH16: number;
  Velocity16: number;

  ZT17: number;
  RH17: number;
  Velocity17: number;

  ZT18: number;
  RH18: number;
  Velocity18: number;

  ZT19: number;
  RH19: number;
  Velocity19: number;

  ZT20: number;
  RH20: number;
  Velocity20: number;

  ZT21: number;
  RH21: number;
  Velocity21: number;

  ZT22: number;
  RH22: number;
  Velocity22: number;

  ZT23: number;
  RH23: number;
  Velocity23: number;

  ZT25: number;
  RH25: number;
  Velocity25: number;

  ZT26: number;
  RH26: number;
  Velocity26: number;

  // output fields predict
  pmv_out16: number;
  pmv_out17: number;
  pmv_out18: number;
  pmv_out19: number;
  pmv_out20: number;
  pmv_out21: number;
  pmv_out22: number;
  pmv_out23: number;
  pmv_out25: number;
  pmv_out26: number;

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {

    if(localStorage.getItem('gate_input_1')){
      this.gate_input=localStorage.getItem('gate_input_1');
      this.gate_input=JSON.parse(this.gate_input);

      this.ZT16 = this.gate_input['Zone Temp C4-L2-16'];
      this.RH16 = this.gate_input['RH-L2-16'];
      this.Velocity16 = this.gate_input['Velocity - C4 - L2 - 16'];

      this.ZT17 = this.gate_input['Zone Temp C4-L2-17'];
      this.RH17 = this.gate_input['RH-L2-17'];
      this.Velocity17 = this.gate_input['Velocity - C4 - L2 - 17'];

      this.ZT18 = this.gate_input['Zone Temp C4-L2-18'];
      this.RH18 = this.gate_input['RH-L2-18'];
      this.Velocity18 = this.gate_input['Velocity - C4 - L2 - 18'];

      this.ZT19 = this.gate_input['Zone Temp C4-L2-19'];
      this.RH19 = this.gate_input['RH-L2-19'];
      this.Velocity19 = this.gate_input['Velocity - C4 - L2 - 19'];

      this.ZT20 = this.gate_input['Zone Temp C4-L2-20'];
      this.RH20 = this.gate_input['RH-L2-20'];
      this.Velocity20 = this.gate_input['Velocity - C4 - L2 - 20'];

      this.ZT21 = this.gate_input['Zone Temp C4-L2-21'];
      this.RH21 = this.gate_input['RH-L2-21'];
      this.Velocity21 = this.gate_input['Velocity - C4 - L2 - 21'];

      this.ZT22 = this.gate_input['Zone Temp C4-L2-22'];
      this.RH22 = this.gate_input['RH-L2-22'];
      this.Velocity22 = this.gate_input['Velocity - C4 - L2 - 22'];

      this.ZT23 = this.gate_input['Zone Temp C4-L2-23'];
      this.RH23 = this.gate_input['RH-L2-23'];
      this.Velocity23 = this.gate_input['Velocity - C4 - L2 - 23'];

      this.ZT25 = this.gate_input['Zone Temp C4-L2-25'];
      this.RH25 = this.gate_input['RH-L2-25'];
      this.Velocity25 = this.gate_input['Velocity - C4 - L2 - 25'];

      this.ZT26 = this.gate_input['Zone Temp C4-L2-26'];
      this.RH26 = this.gate_input['RH-L2-26'];
      this.Velocity26 = this.gate_input['Velocity - C4 - L2 - 26'];

     }else{

      this.ZT16 = 0;
      this.RH16 = 0;
      this.Velocity16 = 0;

      this.ZT17 = 0;
      this.RH17 = 0;
      this.Velocity17 = 0;

      this.ZT18 = 0;
      this.RH18 = 0;
      this.Velocity18 = 0;

      this.ZT19 = 0;
      this.RH19 = 0;
      this.Velocity19 = 0;

      this.ZT20 = 0;
      this.RH20 = 0;
      this.Velocity20 = 0;

      this.ZT21 = 0;
      this.RH21 = 0;
      this.Velocity21 = 0;

      this.ZT22 = 0;
      this.RH22 = 0;
      this.Velocity22 = 0;

      this.ZT23 = 0;
      this.RH23 = 0;
      this.Velocity23 = 0;

      this.ZT25 = 0;
      this.RH25 = 0;
      this.Velocity25 = 0;

      this.ZT26 = 0;
      this.RH26 = 0;
      this.Velocity26 = 0;

     }


     if(localStorage.getItem('predictionResult')){
        this.predictionResult = localStorage.getItem('predictionResult');
        this.predictionResult = JSON.parse(this.predictionResult);
        this.ZONE_TEMP_out_C4_L2_16 = this.predictionResult['Zone_Temp_C4-L2-16'];
        this.ZONE_TEMP_out_C4_L2_18 = this.predictionResult['Zone_Temp_C4-L2-18'];
        this.ZONE_TEMP_out_C4_L2_19 = this.predictionResult['Zone_Temp_C4-L2-19'];
        this.ZONE_TEMP_out_C4_L2_20 = this.predictionResult['Zone_Temp_C4-L2-20'];
        this.ZONE_TEMP_out_C4_L2_22 = this.predictionResult['Zone_Temp_C4-L2-22'];
        this.ZONE_TEMP_out_C4_L2_23 = this.predictionResult['Zone_Temp_C4-L2-23'];
        this.ZONE_TEMP_out_C4_L2_25 = this.predictionResult['Zone_Temp_C4-L2-25'];
        this.ZONE_TEMP_out_C4_L2_26 = this.predictionResult['Zone_Temp_C4-L2-26'];
        this.ZONE_TEMP_out_C4_L2_27 = this.predictionResult['Zone_Temp_C4-L2-27'];
     }


    // output_field


    if(localStorage.getItem('PMV- C4 - L2 - 16_out') || localStorage.getItem('PMV- C4 - L2 - 16')){
      this.pmv_out16= Number(localStorage.getItem('PMV- C4 - L2 - 16_out')) || Number(localStorage.getItem('PMV- C4 - L2 - 16'));
     }else{
       this.pmv_out16 = 0;
     }


     if(localStorage.getItem('PMV- C4 - L2 - 17_out') || localStorage.getItem('PMV- C4 - L2 - 17')){
       this.pmv_out17= Number(localStorage.getItem('PMV- C4 - L2 - 17_out')) || Number(localStorage.getItem('PMV- C4 - L2 - 17'));
      }else{
        this.pmv_out17 = 0;
      }


     if(localStorage.getItem('PMV- C4 - L2 - 18_out') || localStorage.getItem('PMV- C4 - L2 - 18')){
       this.pmv_out18= Number(localStorage.getItem('PMV- C4 - L2 - 18_out')) || Number(localStorage.getItem('PMV- C4 - L2 - 18'));
      }else{
        this.pmv_out18 = 0;
     }

     if(localStorage.getItem('PMV- C4 - L2 - 19_out') || localStorage.getItem('PMV- C4 - L2 - 19')){
      this.pmv_out19= Number(localStorage.getItem('PMV- C4 - L2 - 19_out')) || Number(localStorage.getItem('PMV- C4 - L2 - 19'));
     }else{
       this.pmv_out19 = 0;
    }

    if(localStorage.getItem('PMV- C4 - L2 - 20_out') || localStorage.getItem('PMV- C4 - L2 - 20')){
      this.pmv_out20= Number(localStorage.getItem('PMV- C4 - L2 - 20_out')) || Number(localStorage.getItem('PMV- C4 - L2 - 20'));
     }else{
       this.pmv_out20 = 0;
    }

    if(localStorage.getItem('PMV- C4 - L2 - 21_out') || localStorage.getItem('PMV- C4 - L2 - 21')){
      this.pmv_out21= Number(localStorage.getItem('PMV- C4 - L2 - 21_out')) || Number(localStorage.getItem('PMV- C4 - L2 - 21'));
     }else{
       this.pmv_out21 = 0;
    }

    if(localStorage.getItem('PMV- C4 - L2 - 22_out') || localStorage.getItem('PMV- C4 - L2 - 22')){
      this.pmv_out22= Number(localStorage.getItem('PMV- C4 - L2 - 22_out')) || Number(localStorage.getItem('PMV- C4 - L2 - 22'));
     }else{
       this.pmv_out22 = 0;
    }

    if(localStorage.getItem('PMV- C4 - L2 - 23_out') || localStorage.getItem('PMV- C4 - L2 - 23')){
      this.pmv_out23= Number(localStorage.getItem('PMV- C4 - L2 - 23_out')) || Number(localStorage.getItem('PMV- C4 - L2 - 23'));
     }else{
       this.pmv_out23 = 0;
    }

    if(localStorage.getItem('PMV- C4 - L2 - 25_out') || localStorage.getItem('PMV- C4 - L2 - 25')){
      this.pmv_out25= Number(localStorage.getItem('PMV- C4 - L2 - 25_out')) || Number(localStorage.getItem('PMV- C4 - L2 - 25'));
     }else{
       this.pmv_out25 = 0;
    }

    if(localStorage.getItem('PMV- C4 - L2 - 26_out') || localStorage.getItem('PMV- C4 - L2 - 26')){
      this.pmv_out26= Number(localStorage.getItem('PMV- C4 - L2 - 26_out')) || Number(localStorage.getItem('PMV- C4 - L2 - 26'));
     }else{
       this.pmv_out26 = 0;
    }


    // this.pmv_out10=0;
    // this.pmv_out11=0;
    // this.pmv_out12=0;
    // this.pmv_out13=0;
    // this.pmv_out14=0;
    // this.pmv_out15=0;
    // this.pmv_out16=0;
    // this.pmv_out17=0;
    // this.pmv_out19=0;

  if(localStorage.getItem('PMV- C4 - L2 - 16') && localStorage.getItem('PMV- C4 - L2 - 17') && localStorage.getItem('PMV- C4 - L2 - 18') &&  localStorage.getItem('PMV- C4 - L2 - 19') &&  localStorage.getItem('PMV- C4 - L2 - 20') &&  localStorage.getItem('PMV- C4 - L2 - 21') &&  localStorage.getItem('PMV- C4 - L2 - 22') &&  localStorage.getItem('PMV- C4 - L2 - 23') &&  localStorage.getItem('PMV- C4 - L2 - 25') &&  localStorage.getItem('PMV- C4 - L2 - 26')){
    this.pmv_avg=(this.pmv_out16+this.pmv_out17+this.pmv_out18+this.pmv_out19+this.pmv_out20+this.pmv_out21+this.pmv_out22+this.pmv_out23+this.pmv_out25+this.pmv_out26)/10;

  }else{
    this.pmv_avg = 0;
  }

  }

  ngOnInit(): void {
    this.getPredictionResult();
  }



  async getPredictionResult() {
    this.http.get<any>('https://airportinternational.bertlabs.com/get_prediction_result')
     .subscribe(
       (response: { result: string }) => {
         try {
           this.predictionResult = JSON.parse(response.result);
           console.log('Prediction result:', this.predictionResult);
           this.ZONE_TEMP_out_C4_L2_16 = this.predictionResult['Zone_Temp_C4-L2-16'];
           console.log("ZONE_TEMP_out_C4_L2_16", this.ZONE_TEMP_out_C4_L2_16);

           this.jsonData['predictionResult'] = this.predictionResult;

           console.log(this.jsonData);
           localStorage.setItem('predictionResult', JSON.stringify(this.predictionResult)); // Corrected line
           console.log('pedict_output', this.predict_response )
         } catch (error) {
           console.error('Error parsing prediction result:', error);
         }
       },
       (error) => {
         console.error('Error fetching prediction result:', error);
       }
     );
 }









  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if(this.ZT16<21 || this.ZT16>23.8){
      this.openSnackBar('ZT16 should be in range [21,23.8]');
      return false;
    }else if(this.Velocity16<0.46 || this.Velocity16>0.71){
      this.openSnackBar('Velocity16 should be in range [0.46,0.71]');
      return false;
    }else if(this.RH16<45.98 || this.RH16>57.95){
      this.openSnackBar('RH16 should be in range [45.98,57.95]');
      return false;
    }else  if(this.ZT17<21 || this.ZT17>23.8){
      this.openSnackBar('ZT17 should be in range [21,23.8]');
      return false;
    }else if(this.Velocity17<0.66 || this.Velocity17>0.97){
      this.openSnackBar('Velocity17 should be in range [0.66,0.97]');
      return false;
    }else if(this.RH17<45.98 || this.RH17>57.95){
      this.openSnackBar('RH17 should be in range [45.98,57.95]');
      return false;
    }else  if(this.ZT18<21.9 || this.ZT18>29.4){
      this.openSnackBar('ZT18 should be in range [21.9,29.4]');
      return false;
    }else if(this.Velocity18<0 || this.Velocity18>3.11){
      this.openSnackBar('Velocity18 should be in range [0,3.11]');
      return false;
    }else if(this.RH18<34.43 || this.RH18>51){
      this.openSnackBar('RH18 should be in range [34.43,51]');
      return false;
    }else  if(this.ZT19<21.6 || this.ZT19>24.8){
      this.openSnackBar('ZT19 should be in range [21.6,24.8]');
      return false;
    }else if(this.Velocity19<0.15 || this.Velocity19>3.11){
      this.openSnackBar('Velocity19 should be in range [0.15,3.11]');
      return false;
    }else if(this.RH19<42.88 || this.RH19>55.25){
      this.openSnackBar('RH19 should be in range [42.88,55.25]');
      return false;
    }else  if(this.ZT20<21.6 || this.ZT20>24.8){
      this.openSnackBar('ZT20 should be in range [21.6,24.8]');
      return false;
    }else if(this.Velocity20<0.65 || this.Velocity20>4.85){
      this.openSnackBar('Velocity20 should be in range [0.65,4.85]');
      return false;
    }else if(this.RH20<42.88 || this.RH20>55.25){
      this.openSnackBar('RH20 should be in range [42.88,55.25]');
      return false;
    }else  if(this.ZT21<21.6 || this.ZT21>24.8){
      this.openSnackBar('ZT21 should be in range [21.6,24.8]');
      return false;
    }else if(this.Velocity21<0.41 || this.Velocity21>3.11){
      this.openSnackBar('Velocity21 should be in range [0.41,3.11]');
      return false;
    }else if(this.RH21<42.88 || this.RH21>55.25){
      this.openSnackBar('RH21 should be in range [42.88,55.25]');
      return false;
    }else  if(this.ZT22<22.3 || this.ZT22>29){
      this.openSnackBar('ZT22 should be in range [22.3,29]');
      return false;
    }else if(this.Velocity22<0.61 || this.Velocity22>4.85){
      this.openSnackBar('Velocity22 should be in range [0.61,4.85]');
      return false;
    }else if(this.RH22<35.21 || this.RH22>50.77){
      this.openSnackBar('RH22 should be in range [35.21,50.77]');
      return false;
    }else  if(this.ZT23<22 || this.ZT23>24.8){
      this.openSnackBar('ZT23 should be in range [22,24.8]');
      return false;
    }else if(this.Velocity23<0.42 || this.Velocity23>3.11){
      this.openSnackBar('Velocity23 should be in range [0.42,3.11]');
      return false;
    }else if(this.RH23<42.62 || this.RH23>53.92){
      this.openSnackBar('RH23 should be in range [42.62,53.92]');
      return false;
    }else  if(this.ZT25<22 || this.ZT25>24.8){
      this.openSnackBar('ZT25 should be in range [22,24.8]');
      return false;
    }else if(this.Velocity25<0.42 || this.Velocity25>3.11){
      this.openSnackBar('Velocity25 should be in range [0.42,3.11]');
      return false;
    }else if(this.RH25<42.62 || this.RH25>53.92){
      this.openSnackBar('RH25 should be in range [42.62,53.92]');
      return false;
    }else  if(this.ZT26<22 || this.ZT26>24.8){
      this.openSnackBar('ZT26 should be in range [22,24.8]');
      return false;
    }else if(this.Velocity26<0.42 || this.Velocity26>3.11){
      this.openSnackBar('Velocity26 should be in range [0.42,3.11]');
      return false;
    }else if(this.RH26<42.62 || this.RH26>53.92){
      this.openSnackBar('RH26 should be in range [42.62,53.92]');
      return false;
    }

    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  // this fuction is use to predict PMV
  async predictData() {
    if (this.validate()) {
      this.progress = true;
      console.log('predict input', this.predict_input);


      this.predict_input= {
        "Zone Temp C4-L2-16":Number(this.ZT16),
        "RH-L2-16":Number(this.RH16),
        "Velocity - C4 - L2 - 16":Number(this.Velocity16),
        "Zone Temp C4-L2-17":Number(this.ZT17),
        "RH-L2-17":Number(this.RH17),
        "Velocity - C4 - L2 - 17":Number(this.Velocity17),
        "Zone Temp C4-L2-18":Number(this.ZT18),
        "RH-L2-18":Number(this.RH18),
        "Velocity - C4 - L2 - 18":Number(this.Velocity18),
        "Zone Temp C4-L2-19":Number(this.ZT19),
        "RH-L2-19":Number(this.RH19),
        "Velocity - C4 - L2 - 19":Number(this.Velocity19),
        "Zone Temp C4-L2-20":Number(this.ZT20),
        "RH-L2-20":Number(this.RH20),
        "Velocity - C4 - L2 - 20":Number(this.Velocity20),
        "Zone Temp C4-L2-21":Number(this.ZT21),
        "RH-L2-21":Number(this.RH21),
        "Velocity - C4 - L2 - 21":Number(this.Velocity21),
        "Zone Temp C4-L2-22":Number(this.ZT22),
        "RH-L2-22":Number(this.RH22),
        "Velocity - C4 - L2 - 22":Number(this.Velocity22),
        "Zone Temp C4-L2-23":Number(this.ZT23),
        "RH-L2-23":Number(this.RH23),
        "Velocity - C4 - L2 - 23":Number(this.Velocity23),
        "Zone Temp C4-L2-25":Number(this.ZT25),
        "RH-L2-25":Number(this.RH25),
        "Velocity - C4 - L2 - 25":Number(this.ZT26),
        "Zone Temp C4-L2-26":Number(this.Velocity25),
        "RH-L2-26":Number(this.RH26),
        "Velocity - C4 - L2 - 26":Number(this.Velocity26),
        "model_no.":2
      };

      localStorage.setItem('Zone Temp C4-L2-16', String(this.ZT16));
      localStorage.setItem('Zone Temp C4-L2-17', String(this.ZT17));
      localStorage.setItem('Zone Temp C4-L2-18', String(this.ZT18));
      localStorage.setItem('Zone Temp C4-L2-19', String(this.ZT19));
      localStorage.setItem('Zone Temp C4-L2-20', String(this.ZT20));
      localStorage.setItem('Zone Temp C4-L2-21', String(this.ZT21));
      localStorage.setItem('Zone Temp C4-L2-22', String(this.ZT22));
      localStorage.setItem('Zone Temp C4-L2-23', String(this.ZT23));
      localStorage.setItem('Zone Temp C4-L2-25', String(this.ZT25));
      localStorage.setItem('Zone Temp C4-L2-26', String(this.ZT26));

      localStorage.setItem('RH-L2-16', String(this.RH16));
      localStorage.setItem('RH-L2-17', String(this.RH17));
      localStorage.setItem('RH-L2-18', String(this.RH18));
      localStorage.setItem('RH-L2-19', String(this.RH19));
      localStorage.setItem('RH-L2-20', String(this.RH20));
      localStorage.setItem('RH-L2-21', String(this.RH21));
      localStorage.setItem('RH-L2-22', String(this.RH22));
      localStorage.setItem('RH-L2-23', String(this.RH23));
      localStorage.setItem('RH-L2-25', String(this.RH25));
      localStorage.setItem('RH-L2-26', String(this.RH26));

      localStorage.setItem('Velocity - C4 - L2 - 16', String(this.Velocity16))
      localStorage.setItem('Velocity - C4 - L2 - 17', String(this.Velocity17))
      localStorage.setItem('Velocity - C4 - L2 - 18', String(this.Velocity18))
      localStorage.setItem('Velocity - C4 - L2 - 19', String(this.Velocity19))
      localStorage.setItem('Velocity - C4 - L2 - 20', String(this.Velocity20))
      localStorage.setItem('Velocity - C4 - L2 - 21', String(this.Velocity21))
      localStorage.setItem('Velocity - C4 - L2 - 22', String(this.Velocity22))
      localStorage.setItem('Velocity - C4 - L2 - 23', String(this.Velocity23))
      localStorage.setItem('Velocity - C4 - L2 - 25', String(this.Velocity25))
      localStorage.setItem('Velocity - C4 - L2 - 26', String(this.Velocity26))

      localStorage.setItem('gate_input_1', JSON.stringify(this.predict_input))

      try {
        const response = await this.http
          .post<any>(
            'https://bpsliveatchangiairport1.bertlabs.com/sec_2',
            // 'https://bpsliveatchangiairport.bertlabs.com/predictsec6/',
            this.predict_input,
            this.httpOptions
          )
          .toPromise();

        this.predict_response = response;
        console.log('predict outout', this.predict_response);

        localStorage.removeItem('PMV- C4 - L2 - 01_out');
        localStorage.removeItem('PMV- C4 - L2 - 02_out');
        localStorage.removeItem('PMV- C4 - L2 - 03_out');
        localStorage.removeItem('PMV- C4 - L2 - 04_out');
        localStorage.removeItem('PMV- C4 - L2 - 05_out');
        localStorage.removeItem('PMV- C4 - L2 - 06_out');
        localStorage.removeItem('PMV- C4 - L2 - 07_out');
        localStorage.removeItem('PMV- C4 - L2 - 08_out');
        localStorage.removeItem('PMV- C4 - L2 - 09_out');
        localStorage.removeItem('PMV- C4 - L2 - 10_out');
        localStorage.removeItem('PMV- C4 - L2 - 11_out');
        localStorage.removeItem('PMV- C4 - L2 - 12_out');
        localStorage.removeItem('PMV- C4 - L2 - 13_out');
        localStorage.removeItem('PMV- C4 - L2 - 14_out');
        localStorage.removeItem('PMV- C4 - L2 - 15_out');
        localStorage.removeItem('PMV- C4 - L2 - 16_out');
        localStorage.removeItem('PMV- C4 - L2 - 17_out');
        localStorage.removeItem('PMV- C4 - L2 - 18_out');
        localStorage.removeItem('PMV- C4 - L2 - 19_out');
        localStorage.removeItem('PMV- C4 - L2 - 20_out');
        localStorage.removeItem('PMV- C4 - L2 - 21_out');
        localStorage.removeItem('PMV- C4 - L2 - 22_out');
        localStorage.removeItem('PMV- C4 - L2 - 23_out');
        localStorage.removeItem('PMV- C4 - L2 - 24_out');
        localStorage.removeItem('PMV- C4 - L2 - 25_out');
        localStorage.removeItem('PMV- C4 - L2 - 26_out');
        localStorage.removeItem('PMV- C4 - L2 - 27_out');
        localStorage.removeItem('Fan Power - C4A_out');
        localStorage.removeItem('Fan Power - C4B_out');
        localStorage.removeItem('VSD Freq - C4A_out');
        localStorage.removeItem('VSD Freq - C4B_out');
        localStorage.removeItem('Valve Feedback - C4A_out');
        localStorage.removeItem('Valve Feedback - C4B_out');

        this.openSnackBar('Predicted Succesfully');


        this.pmv_out16 =  this.predict_response['PMV- C4 - L2 - 16'];
        this.pmv_out17 =  this.predict_response['PMV- C4 - L2 - 17'];
        this.pmv_out18 =  this.predict_response['PMV- C4 - L2 - 18'];
        this.pmv_out19 =  this.predict_response['PMV- C4 - L2 - 19'];
        this.pmv_out20 =  this.predict_response['PMV- C4 - L2 - 20'];
        this.pmv_out21 =  this.predict_response['PMV- C4 - L2 - 21'];
        this.pmv_out22 =  this.predict_response['PMV- C4 - L2 - 22'];
        this.pmv_out23 =  this.predict_response['PMV- C4 - L2 - 23'];
        this.pmv_out25 =  this.predict_response['PMV- C4 - L2 - 25'];
        this.pmv_out26 =  this.predict_response['PMV- C4 - L2 - 26'];

        localStorage.setItem('PMV- C4 - L2 - 16', this.predict_response['PMV- C4 - L2 - 16'])
        localStorage.setItem('PMV- C4 - L2 - 17', this.predict_response['PMV- C4 - L2 - 17'])
        localStorage.setItem('PMV- C4 - L2 - 18', this.predict_response['PMV- C4 - L2 - 18'])
        localStorage.setItem('PMV- C4 - L2 - 19', this.predict_response['PMV- C4 - L2 - 19'])
        localStorage.setItem('PMV- C4 - L2 - 20', this.predict_response['PMV- C4 - L2 - 20'])
        localStorage.setItem('PMV- C4 - L2 - 21', this.predict_response['PMV- C4 - L2 - 21'])
        localStorage.setItem('PMV- C4 - L2 - 22', this.predict_response['PMV- C4 - L2 - 22'])
        localStorage.setItem('PMV- C4 - L2 - 23', this.predict_response['PMV- C4 - L2 - 23'])
        localStorage.setItem('PMV- C4 - L2 - 25', this.predict_response['PMV- C4 - L2 - 25'])
        localStorage.setItem('PMV- C4 - L2 - 26', this.predict_response['PMV- C4 - L2 - 26'])

        this.pmv_avg=(this.pmv_out16+this.pmv_out17+this.pmv_out18+this.pmv_out19+this.pmv_out20+this.pmv_out21+this.pmv_out22+this.pmv_out23+this.pmv_out25+this.pmv_out26)/9;



        this.progress = false;
        this.disableOptimise = false;
        localStorage.setItem('model_two', JSON.stringify(this.pmv_avg));
        localStorage.setItem('PMV- C4 - L2 - 16',JSON.stringify(this.pmv_out16));
        localStorage.setItem('PMV- C4 - L2 - 17',JSON.stringify(this.pmv_out17));
        localStorage.setItem('PMV- C4 - L2 - 18',JSON.stringify(this.pmv_out18));
        localStorage.setItem('PMV- C4 - L2 - 19',JSON.stringify(this.pmv_out19));
        localStorage.setItem('PMV- C4 - L2 - 20',JSON.stringify(this.pmv_out20));
        localStorage.setItem('PMV- C4 - L2 - 21',JSON.stringify(this.pmv_out21));
        localStorage.setItem('PMV- C4 - L2 - 22',JSON.stringify(this.pmv_out22));
        localStorage.setItem('PMV- C4 - L2 - 23',JSON.stringify(this.pmv_out23));
        localStorage.setItem('PMV- C4 - L2 - 25',JSON.stringify(this.pmv_out25));
        localStorage.setItem('PMV- C4 - L2 - 26',JSON.stringify(this.pmv_out26));

      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }
}
