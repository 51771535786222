import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-c-pier-pmv',
  templateUrl: './c-pier-pmv.component.html',
  styleUrls: ['./c-pier-pmv.component.css']
})
// export class CPierPmvComponent {
//     progress:boolean=false;
// }
export class CPierPmvComponent {

  model_one:number;
  model_two:number;
  model_three:number;
  model_four:number;
  model_five:number;
  model_six:number;
  progress:boolean=false;
  sub:any;



  

  constructor(private route:ActivatedRoute){

    if(localStorage.getItem('model_one')){
      this.model_one=Number(localStorage.getItem('model_one'));
    }else{
      this.model_one=0;
    }

    if(localStorage.getItem('model_two')){
      this.model_two=Number(localStorage.getItem('model_two'));
    }else{
      this.model_two=0;
    }

    if(localStorage.getItem('model_three')){
      this.model_three=Number(localStorage.getItem('model_three'));
    }else{
      this.model_three=0;
    }
    
    if(localStorage.getItem('model_four')){
      this.model_four=Number(localStorage.getItem('model_four'));
    }else{
      this.model_four=0;
    }

    if(localStorage.getItem('model_five')){
      this.model_five=Number(localStorage.getItem('model_five'));
    }else{
      this.model_five=0;
    }

    if(localStorage.getItem('model_six')){
      this.model_six=Number(localStorage.getItem('model_six'));
    }else{
      this.model_six=0;
    }
    
     this.sub=this.route.snapshot.paramMap.get('data');
     console.log(this.sub);
  }

  


}