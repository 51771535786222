<div class="scaffold">
  <div style="height: 100%; width: 100%; position: relative">
    <div
      style="
        height: 80%;
        width: 100%;
        background:
          url('/assets/changi-images/home-screen.svg');
        position: absolute;
        object-fit: cover;
        background-repeat: no-repeat;
        background-size: cover;
      "
    ></div>

    <div
      style="
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        style="
          width: 90%;
          max-width: 500px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          gap: 140px;
        "
        class="client-logo"
      >



        <div style="position: relative;">
          <img src="/assets/changi-images/logo-login.svg" width="350px" style="border-radius: 5px;" />
          <div style="position: absolute; top: 84%; left: 52%; transform: translate(-50%, -50%);">
              <img src="/assets/changi-images/logo animation mulish 1.gif" width="280px" />
          </div>
      </div>
        <!-- <form class="form" (ngSubmit)="handleLogin()" style="height: 250px;border-radius: 20px">
          <div
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 10px;
              justify-content: center;
              align-items: center;
              border-radius: 20px;
              padding-left: 50px;
              padding-right: 50px;
            "
          >


        <input
        type="text"
        matInput
        [formControl]="usernameFormControl"
        [errorStateMatcher]="matcher"
        [(ngModel)]="email"
        placeholder="Username"
        style="font-size: 14px;border-radius: 25px;width: 100%; height: 40px; padding: 10px;box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;border:none;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; outline: none;"



    />



      <input
          type="text"
          id="password"
          [formControl]="passwordFormControl"
          [(ngModel)]="password"
          placeholder="Password"

          style="font-size: 14px;border-radius: 25px;width: 100%; height: 40px; padding: 10px;box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;border:none;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;outline: none;"
      />
      <div *ngIf="passwordFormControl.hasError('required')" style="color: red;">Password is <strong>required</strong></div>




            <button
              mat-raised-button
              style="
                width: 100%;
                background-color: #53A574;
                height: 45px;
                color: white;
                font-family: sans-serif;
                border-radius: 50px;
                margin-top: 10px;
              "
              type="submit"
            >
              Login
            </button>

            <a
              class="hover-text-dark"
              [routerLink]="['/forgot-password']"
              style="margin-bottom: 7px;margin-top: 2px;"
              >Forgot Password?</a
            >
          </div>
        </form> -->

        <form action="" class="form">
          <input type="text" id="username" name="username" class="form-item" placeholder="Username" [(ngModel)]="email">
          <input type="password" id="password" name="password" class="form-item" placeholder="Password" [(ngModel)]="password">
          <button mat-raised-button class="btn" (click)="handleLogin()" style="
          color: white;
          font-family: sans-serif;
        ">Login</button>
          <!-- <a
              class="hover-text-dark"
              [routerLink]="['/forgot-password']"
              style="margin-top: 15px; color:black; margin-bottom: 0px"
              >Forgot Password?</a
            > -->
      </form>



      </div>
    </div>
  </div>
</div>
