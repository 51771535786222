<div style="position: relative; height: 100%; width: 100%;">
  
    <div
      style="
        width: 100%;
        height: 100%;
        min-width: 1200px;
        display: flex;
        flex-direction: column;
        position: absolute;
      "
    >

    <mat-progress-bar
    mode="indeterminate"
    [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"
  ></mat-progress-bar>
    
      
      <div style="display: flex; flex-direction: row; height: 100%; width: 100%">

       
      <!-- main body -->
      <div
      style="
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        align-items: center;
        gap: 10px;
        overflow-y: scroll;
         margin-top:4%;
      "
    >

        <div
          style="
            height: 550px;
            width: 1200px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          "
        >
          <!-- componets -->
          <div style="height: 500px; width: 1000px; position: absolute">
            <div style="margin-top: -5px;
            margin-left: 0px;
            height: 495px;">
              <!-- <C-pier-gate-hold-room-two [pmv]="pmv_avg"></C-pier-gate-hold-room-two> -->
            </div>
          </div>
  
          <!-- map -->
          <div
            style="
              height: 500px;
              width: 1200px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
            "
          >
            <img
              src="/assets/images/Changi-Gate hold room C - 17.svg"
              width="auto"
              height="450px"
            />
          </div>
  
          <!-- fields -->
  <!-- 1 -->
  <!-- <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 185px; margin-left: 965px">
      <div class="input-group">
        <img src="assets/images/pmv.png" />
        <input [ngModel]="pmv_out01 | number : '1.2-2'"
        (ngModelChange)="pmv_out01 = $event"/>
        
      </div>
    </div>
  </div>
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 220px; margin-left: 965px">
      <div class="input-group">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="pmv_out01 | number : '1.2-2'"
        (ngModelChange)="pmv_out01 = $event"/>
        
      </div>
    </div>
  </div> -->
  <!-- 2 -->
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 285px; margin-left: 1045px">
      <div class="input-group">
        <img src="assets/images/pmv.png" />
        <input [ngModel]="pmv_out02 | number : '1.2-2'"
        (ngModelChange)="pmv_out02 = $event"/>
        
      </div>
    </div>
  </div>
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 328px; margin-left: 1045px">
      <div class="input-group">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="ZONE_TEMP_out_C4_L2_02 | number : '1.2-2'"
        (ngModelChange)="ZONE_TEMP_out_C4_L2_02 = $event"/>
        
      </div>
    </div>
  </div>
  <!-- 3 -->
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 375px; margin-left: 1045px">
      <div class="input-group">
        <img src="assets/images/pmv.png" />
        <input [ngModel]="pmv_out03 | number : '1.2-2'"
        (ngModelChange)="pmv_out03 = $event"/>
        
      </div>
    </div>
  </div>
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 418px; margin-left: 1045px">
      <div class="input-group">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="ZONE_TEMP_out_C4_L2_03 | number : '1.2-2'"
        (ngModelChange)="ZONE_TEMP_out_C4_L2_03 = $event"/>
        
      </div>
    </div>
  </div>
  <!-- 4 -->
  <!-- <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 372px; margin-left: 875px">
      <div class="input-group">
        <img src="assets/images/pmv.png" />
        <input [ngModel]="pmv_out04 | number : '1.2-2'"
        (ngModelChange)="pmv_out04 = $event"/>
        
      </div>
    </div>
  </div>
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 407px; margin-left: 875px">
      <div class="input-group">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="pmv_out04 | number : '1.2-2'"
        (ngModelChange)="pmv_out04 = $event"/>
        
      </div>
    </div>
  </div> -->
  <!-- 5 -->
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 285px; margin-left: 850px">
      <div class="input-group">
        <img src="assets/images/pmv.png" />
        <input [ngModel]="pmv_out05 | number : '1.2-2'"
        (ngModelChange)="pmv_out05 = $event"/>
        
      </div>
    </div>
  </div>
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 328px; margin-left: 850px">
      <div class="input-group">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="ZONE_TEMP_out_C4_L2_05 | number : '1.2-2'"
        (ngModelChange)="ZONE_TEMP_out_C4_L2_05 = $event"/>
        
      </div>
    </div>
  </div>
<!-- 6 -->
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 375px; margin-left: 850px">
    <div class="input-group">
      <img src="assets/images/pmv.png" />
      <input [ngModel]="pmv_out06 | number : '1.2-2'"
      (ngModelChange)="pmv_out06 = $event"/>
      
    </div>
  </div>
</div>
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 418px; margin-left: 850px">
    <div class="input-group">
      <img src="assets/images/temp.svg" />
      <input [ngModel]="ZONE_TEMP_out_C4_L2_06 | number : '1.2-2'"
      (ngModelChange)="ZONE_TEMP_out_C4_L2_06 = $event"/>
      
    </div>
  </div>
</div>
<!-- 7 -->
<!-- <div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 336px; margin-left: 643px">
    <div class="input-group">
      <img src="assets/images/pmv.png" />
      <input [ngModel]="pmv_out07 | number : '1.2-2'"
      (ngModelChange)="pmv_out07 = $event"/>
      
    </div>
  </div>
</div>
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 371px; margin-left: 643px">
    <div class="input-group">
      <img src="assets/images/temp.svg" />
      <input [ngModel]="pmv_out07 | number : '1.2-2'"
      (ngModelChange)="pmv_out07 = $event"/>
      
    </div>
  </div>
</div> -->
<!-- 8 -->
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 290px; margin-left: 620px">
    <div class="input-group">
      <img src="assets/images/pmv.png" />
      <input [ngModel]="pmv_out08 | number : '1.2-2'"
      (ngModelChange)="pmv_out08 = $event"/>
      
    </div>
  </div>
</div>
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 333px; margin-left: 620px">
    <div class="input-group">
      <img src="assets/images/temp.svg" />
      <input [ngModel]="ZONE_TEMP_out_C4_L2_08 | number : '1.2-2'"
      (ngModelChange)="ZONE_TEMP_out_C4_L2_08 = $event"/>
      
    </div>
  </div>
</div>
<!-- 9 -->
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 170px; margin-left: 618px">
    <div class="input-group">
      <img src="assets/images/pmv.png" />
      <input [ngModel]="pmv_out09 | number : '1.2-2'"
      (ngModelChange)="pmv_out09 = $event"/>
      
    </div>
  </div>
</div>
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 215px; margin-left: 618px">
    <div class="input-group">
      <img src="assets/images/temp.svg" />
      <input [ngModel]="ZONE_TEMP_out_C4_L2_09 | number : '1.2-2'"
      (ngModelChange)="ZONE_TEMP_out_C4_L2_09 = $event"/>
      
    </div>
  </div>
</div>
<!-- 10 -->
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 170px; margin-left: 288px">
    <div class="input-group">
      <img src="assets/images/pmv.png" />
      <input [ngModel]="pmv_out10 | number : '1.2-2'"
      (ngModelChange)="pmv_out10 = $event"/>
      
    </div>
  </div>
</div>
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 215px; margin-left: 288px">
    <div class="input-group">
      <img src="assets/images/temp.svg" />
      <input [ngModel]="ZONE_TEMP_out_C4_L2_10 | number : '1.2-2'"
      (ngModelChange)="ZONE_TEMP_out_C4_L2_10 = $event"/>
      
    </div>
  </div>
</div>
<!-- 11 -->
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 372px; margin-left: 288px">
    <div class="input-group">
      <img src="assets/images/pmv.png" />
      <input [ngModel]="pmv_out11 | number : '1.2-2'"
      (ngModelChange)="pmv_out11 = $event"/>
      
    </div>
  </div>
</div>
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 415px; margin-left: 288px">
    <div class="input-group">
      <img src="assets/images/temp.svg" />
      <input [ngModel]="ZONE_TEMP_out_C4_L2_11 | number : '1.2-2'"
      (ngModelChange)="ZONE_TEMP_out_C4_L2_11 = $event"/>
      
    </div>
  </div>
</div>
<!-- 13 -->
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 285px; margin-left: 288px">
    <div class="input-group">
      <img src="assets/images/pmv.png" />
      <input [ngModel]="pmv_out12 | number : '1.2-2'"
      (ngModelChange)="pmv_out12 = $event"/>
      
    </div>
  </div>
</div>
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 328px; margin-left: 288px">
    <div class="input-group">
      <img src="assets/images/temp.svg" />
      <input [ngModel]="ZONE_TEMP_out_C4_L2_13 | number : '1.2-2'"
      (ngModelChange)="ZONE_TEMP_out_C4_L2_13 = $event"/>
      
    </div>
  </div>
</div>


<!-- 13 -->
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 240px; margin-left: 40px">
    <div class="input-group">
      <img src="assets/images/pmv.png" />
      <input [ngModel]="pmv_out12 | number : '1.2-2'"
      (ngModelChange)="pmv_out12 = $event"/>
      
    </div>
  </div>
</div>
<div style="height: 550px; width: 1000px; position: absolute">
  <div style="width: 100px; margin-top: 285px; margin-left: 40px">
    <div class="input-group">
      <img src="assets/images/temp.svg" />
      <input [ngModel]="ZONE_TEMP_out_C4_L2_15 | number : '1.2-2'"
      (ngModelChange)="ZONE_TEMP_out_C4_L2_15 = $event"/>
      
    </div>
  </div>
</div>



  <!-- TH1 -->
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 35px; margin-left: 935px">
      <div class="input-groupP">
        <img src="assets/images/percentage.png" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 82px; margin-left: 935px">
      <div class="input-groupP">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>
  <!-- TH2 -->
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 190px; margin-left: 1050px">
      <div class="input-groupP">
        <img src="assets/images/percentage.png" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 232px; margin-left: 1050px">
      <div class="input-groupP">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>
  <!-- TH3 -->
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 35px; margin-left: 733px">
      <div class="input-groupP">
        <img src="assets/images/percentage.png" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 82px; margin-left: 733px">
      <div class="input-groupP">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>
  <!-- TH4 -->
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 440px; margin-left: 650px">
      <div class="input-groupP">
        <img src="assets/images/percentage.png" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 485px; margin-left: 650px">
      <div class="input-groupP">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>
  <!-- TH5 -->
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 35px; margin-left: 445px">
      <div class="input-groupP">
        <img src="assets/images/percentage.png" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 82px; margin-left: 445px">
      <div class="input-groupP">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>
  <!-- TH6 -->
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 440px; margin-left: 445px">
      <div class="input-groupP">
        <img src="assets/images/percentage.png" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 485px; margin-left: 445px">
      <div class="input-groupP">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>
  <!-- TH7 -->
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 50px; margin-left: 80px">
      <div class="input-groupP">
        <img src="assets/images/percentage.png" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 95px; margin-left: 80px">
      <div class="input-groupP">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>


  <!-- R1 -->
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 130px; margin-left: 580px">
      <div class="input-groupP">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>


  <!-- R2  -->
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 133px; margin-left: 300px">
      <div class="input-groupP">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>


  <!-- R3 -->
  <div style="height: 550px; width: 1000px; position: absolute">
    <div style="width: 100px; margin-top: 190px; margin-left: 10px">
      <div class="input-groupP">
        <img src="assets/images/temp.svg" />
        <input [ngModel]="pmv_out12 | number : '1.2-2'"
        (ngModelChange)="pmv_out12 = $event"/>
        
      </div>
    </div>
  </div>









        </div>

         <!-- legends -->
         <!-- <div
          style="display: flex; flex-direction: row; width: 1000px; gap: 10px"
        >
          <div
            class="pmv-pallet"
            style="background-color: #6A87BB; color:#e3e3e3; font-weight:500;"
          >
            <span style="font-size: 12px">-3 COLD</span>
          </div>
          <div class="pmv-pallet" style="background-color: #9DB2D5; font-weight:500;">
            <span style="font-size: 12px">-2 COOL</span>
          </div>
          <div class="pmv-pallet" style="background-color: #C2C8D2; font-weight:500;">
            <span style="font-size: 12px">-1 SLIGHTLY COOL</span>
          </div>
          <div class="pmv-pallet" style=" background-color: #ECEFDF; font-weight:500;">
            <span style="font-size: 12px; line-height:14px;">0 NEUTRAL <br/> (COMFORT)</span>
          </div>
          <div class="pmv-pallet" style="background-color: #D9B7B7; font-weight:500;">
            <span style="font-size: 12px">+1 SLIGHTLY WARM</span>
          </div>
          <div class="pmv-pallet" style="background-color: #C89594; font-weight:500;">
            <span style="font-size: 12px">+2 WARM</span>
          </div>
          <div class="pmv-pallet" style="background-color: #A7514F; color:#e3e3e3; font-weight:500;">
            <span style="font-size: 12px">+3 HOT</span>
          </div>
        </div> -->
      </div>

      <!-- input side bar -->
      
      <!-- <div
      style="
        width: 450px;
        height: 100%;
        box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.06),
          0px 0px 2px rgba(25, 25, 25, 0.12);
      "
    >
    </div> -->

      </div>
   
    </div>
  </div>
  