import { Component, Input } from '@angular/core';

@Component({
  selector: 'Input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.css'],
})
export class InputFieldComponent {
  @Input() icon!: String;
  @Input() units!: String;
  @Input() disabled!: Boolean;

  disable: String;

  constructor() {
    this.disable = this.disabled ? 'true' : 'false';
  }
}
