import { Component, Input } from '@angular/core';

@Component({
  selector: 'Condensor-pump',
  templateUrl: './condensor-pump.component.html',
  styleUrls: ['./condensor-pump.component.css'],
})
export class CondensorPumpComponent {
  @Input() state!: string;

  urlActive: string;
  urlInActive: string;
  urlTrip: string;

  constructor() {
    this.urlActive =
      '/assets/bert-components/active-condensor-pump.svg';
    this.urlInActive =
      '/assets/bert-components/inactive-condensor-pump.svg';
    this.urlTrip =
      '/assets/bert-components/triped-condensor-pump.svg';
  }
}
