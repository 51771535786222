<div
  [ngClass]="{
    'pmv-hot-three': pmv >= 1,
    'pmv-hot-two': (pmv >= 0.5) && (pmv<1),
    'pmv-neutral': (pmv > -0.5) && (pmv<0.5),
    'pmv-cold-two': (pmv > -1) && (pmv<=-0.5),
    'pmv-cold-three': (pmv <=-1),
    'pmv-disabled':pmv===-4,
    'pmv-default':pmv===4
  }"

  style="height: 100%;width: 100%;"
>

<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 775.523 295.323">
    <path id="Path_4275" data-name="Path 4275" d="M8320.257,5398.272h192.52V5344.52h138.7s5.37,9.455,17.742,7.246,11.457-17.687,11.457-17.687h235.239l43.842-43.837,22.527,22.526,51.636-50.478-94.323-92.6-59.271-16.426L8699.3,5102.95H8258.4v295.323Z" transform="translate(-8258.4 -5102.95)" fill="#c8d4e7"/>
  </svg>
  
</div>
