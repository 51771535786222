<div
  [ngClass]="{
    'pmv-hot-three': pmv >= 1,
    'pmv-hot-two': (pmv >= 0.5) && (pmv<1),
    'pmv-neutral': (pmv > -0.5) && (pmv<0.5),
    'pmv-cold-two': (pmv > -1) && (pmv<=-0.5),
    'pmv-cold-three': (pmv <=-1),
    'pmv-disabled':pmv===-4,
    'pmv-default':pmv===4
  }"

  style="width: 100%;height: 100%;"
>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 256.235 701.61">
    <path id="Path_4310" data-name="Path 4310" d="M9282.164,5602.709V5495.426h66.917V5447.69h47.158v-48.048h71.771V5096.805H9394.72v-33.293h-69.643v7.642H9289.63V4901.1h133.806v61.845l26.9.065v58.207h52.105s35.959,111.612,35.959,225.552-35.959,230.208-35.959,230.208h-52.105V5537.8h-24.156v64.913Z" transform="translate(-9282.164 -4901.099)" fill="#ecf0f7"/>
  </svg>
  
  
</div>
