<div
  
  [ngClass]="{
    'pmv-hot-three': pmv >= 1,
    'pmv-hot-two': (pmv >= 0.5) && (pmv<1),
    'pmv-neutral': (pmv > -0.5) && (pmv<0.5),
    'pmv-cold-two': (pmv > -1) && (pmv<=-0.5),
    'pmv-cold-three': (pmv <=-1),
    'pmv-disabled':pmv===-4,
    'pmv-default':pmv===4
  }"

  style="height: 100%; width: 100%;"
>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 243.607 149.571">
    <path id="Path_4270" data-name="Path 4270" d="M8261,4955h243.265l.343,91.373H8459.36l-1.186,58.2H8261Z" transform="translate(-8261 -4955)" fill="#a5b7d7"/>
</svg>
  
</div>
