<div style="position: relative; height: 100%; width: 100%">
    <!-- <Water-mark></Water-mark> -->

    <div
      style="
        width: 100%;
        height: 100%;
        min-width: 1200px;
        display: flex;
        flex-direction: column;
        position: absolute;
      "
    >
      <mat-progress-bar
        mode="indeterminate"
        [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"
      ></mat-progress-bar>


        <!-- main body -->

        <div
        style="
          height: auto;
          width: 100%;
          display: flex;
          flex: 7;
          flex-direction: column;
          justify-content: start;
          padding: 10px;
          gap: 20px;
          align-items: center;
        "
      >
        <div
          style="
            height: 550px;
            width: 1000px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          "
        >
          <!-- componets -->
          <!-- <div style="height: 550px; width: 1000px; position: absolute">


            <div style="margin-top: 187px;
            margin-left: -988px;
            height: 153px;" >
              <C-pier-security-screening-room-one [pmv]="model_one" ></C-pier-security-screening-room-one>
            </div>

            <div style="margin-top: -153px;
            margin-left: 284px;
            height: 161px;">
              <C-pier-security-screening-room-two [pmv]="model_three"></C-pier-security-screening-room-two>
            </div>



           <div style="margin-top: -162px;
           margin-left: -465px;
           height: 177px;">
              <C-pier-gate-hold-room-one [pmv]="model_two"></C-pier-gate-hold-room-one>
            </div>



            <div style="margin-top: -176px;
            margin-left: 677px;
            height: 176px;
            width: 434px;">
              <C-pier-gate-hold-room-two [pmv]="model_four"></C-pier-gate-hold-room-two>
            </div>


          </div> -->

          <!-- map -->
          <div
            style="
              height: 550px;
              width: 1200px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
            "
          >
            <img src="/assets/images/T1 Pier1.svg" width="1200px" height="650px" />
          </div>

          <!-- buttons -->
         <div
            style="
              height: 550px;
              width: 1200px;
              position: absolute;
              padding-left: 100px;
            "
          >
              <!-- <div style="margin-top: 227px;
              margin-left: -90px; position:absolute">
              <button
                mat-raised-button
                class="pmv-button"
                style="
                  height: 55px;
                  width:130px;
                  background-color: var(--blue-one);
                  color: white;
                  padding:0px;
                "
                onmouseover="this.style.backgroundColor='#73099F';" onmouseout="this.style.backgroundColor='#4B1A5F'"
                routerLink="../c-pier-security-screening-room-one-map"
              >
                 SECURITY SCREENING<br/>ROOM-1
              </button>
            </div> -->

            <div style="    margin-top: 17%;
            margin-left: 5%; position:absolute">
              <button
                mat-raised-button
                class="pmv-button"
                style="
                  height: 55px;
                  width:130px;
                  background-color: var(--blue-one);
                  color: white;
                  padding:0px;
                  font-size: medium;
                  font-weight: 600;
                "
                onmouseover="this.style.backgroundColor='#1581e6';" onmouseout="this.style.backgroundColor='var(--blue-one)'"
                routerLink="../T1A1"
              >
                 T1 A Gate
              </button>
            </div>


            <!-- <div style="margin-top: 227px;
            margin-left: 560px; position:absolute">
              <button
                mat-raised-button
                class="pmv-button"
                style="
                  height: 55px;
                  width:130px;
                  background-color: var(--blue-one);
                  color: white;
                  padding:0px;
                "
                onmouseover="this.style.backgroundColor='#73099F';" onmouseout="this.style.backgroundColor='#4B1A5F'"
                routerLink="../c-pier-security-screening-room-two-map"
              >
              SECURITY SCREENING<br/>ROOM-2
              </button>
            </div> -->


            <div style="    margin-top: 17%;
            margin-left: 67%; position:absolute">
              <button
                mat-raised-button
                class="pmv-button"
                style="
                  height: 55px;
                  width:130px;
                  background-color: var(--blue-one);
                  color: white;
                  padding:0px;
                  font-size: medium;
                  font-weight: 600;
                "
                onmouseover="this.style.backgroundColor='#1581e6';" onmouseout="this.style.backgroundColor='var(--blue-one)'"
                routerLink="../T1B1"
              >
              T1 B Gate
              </button>
            </div>


          </div>
        </div>

        <!-- legends -->
        <!-- <div
          style="display: flex; flex-direction: row; width: 1000px; gap: 10px"
        >
          <div
            class="pmv-pallet"
            style="background-color: #6A87BB; color:#e3e3e3; font-weight:500;"
          >
            <span style="font-size: 12px">-3 COLD</span>
          </div>
          <div class="pmv-pallet" style="background-color: #9DB2D5; font-weight:500;">
            <span style="font-size: 12px">-2 COOL</span>
          </div>
          <div class="pmv-pallet" style="background-color: #C2C8D2; font-weight:500;">
            <span style="font-size: 12px">-1 SLIGHTLY COOL</span>
          </div>
          <div class="pmv-pallet" style=" background-color: #ECEFDF; font-weight:500;">
            <span style="font-size: 12px; line-height:14px;">0 NEUTRAL <br/> (COMFORT)</span>
          </div>
          <div class="pmv-pallet" style="background-color: #D9B7B7; font-weight:500;">
            <span style="font-size: 12px">+1 SLIGHTLY WARM</span>
          </div>
          <div class="pmv-pallet" style="background-color: #C89594; font-weight:500;">
            <span style="font-size: 12px">+2 WARM</span>
          </div>
          <div class="pmv-pallet" style="background-color: #A7514F; color:#e3e3e3; font-weight:500;">
            <span style="font-size: 12px">+3 HOT</span>
          </div>
        </div> -->



      </div>



    </div>
  </div>
