import { Component } from '@angular/core';

@Component({
  selector: 'Jewel',
  templateUrl: './jewel.component.html',
  styleUrls: ['./jewel.component.css']
})
export class JewelComponent {

}
