<mat-progress-bar
  mode="indeterminate"
  [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"
  style="width: 100%"
></mat-progress-bar>

<div style="display: flex; flex-direction: row; height: 99%; width: 100%">
  <div
    #widgetsContent
    style="
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      overflow-y: scroll;
      overflow-x: scroll;
      scroll-behavior: smooth;
      display: flex;
      overflow-x: 100px;
      scroll-snap-points-x: 200px;
      flex-direction: column;
    "
  >
    <!-- chiller plant connections slab-->

    <div style="width: 1100px; height: 600px; position: absolute; scale: 0.9">
      <img
        src="../../../assets/images/chiller-plant-connections.svg"
        alt="connections"
        height="600px"
        width="1100px"
      />
    </div>

    <!-- chiller-plant-component-slab -->
    <div style="width: 1100px; height: 600px; position: absolute; scale: 0.9;">
      <!--4 chillers-->

      <div
        style="
          height: 60px;
          width: 120px;
          margin-top: 204px;
          margin-left: 167px;
        "
      >
        <Chiller [state]="chillerOneStatus"></Chiller>
      </div>

      <div
        style="height: 60px; width: 120px; margin-top: 42px; margin-left: 167px"
      >
        <Chiller [state]="chillerOneStatus"></Chiller>
      </div>

      <div
        style="
          height: 60px;
          width: 120px;
          margin-top: -59px;
          margin-left: 367px;
        "
      >
        <Chiller [state]="chillerOneStatus"></Chiller>
      </div>

      <div
        style="
          height: 60px;
          width: 120px;
          margin-top: -163px;
          margin-left: 367px;
        "
      >
        <Chiller [state]="chillerOneStatus"></Chiller>
      </div>

      <!--4 condensor-pumps -->
      <div
        style="
          height: 32px;
          width: 64px;
          margin-top: -148px;
          margin-left: 814px;
        "
      >
        <Condensor-pump [state]="chillerOneStatus"></Condensor-pump>
      </div>

      <div
        style="height: 32px; width: 64px; margin-top: -32px; margin-left: 872px"
      >
        <Condensor-pump [state]="chillerOneStatus"></Condensor-pump>
      </div>

      <div
        style="height: 32px; width: 64px; margin-top: -31px; margin-left: 931px"
      >
        <Condensor-pump [state]="chillerOneStatus"></Condensor-pump>
      </div>

      <div
        style="
          height: 32px;
          width: 64px;
          margin-top: -31px;
          margin-left: 991px;
        "
      >
        <Condensor-pump [state]="chillerOneStatus"></Condensor-pump>
      </div>

      <!--4 primary-pumps -->

      <div
        style="height: 32px; width: 64px; margin-top: 354px; margin-left: 137px"
      >
        <Primary-pump [state]="chillerOneStatus"></Primary-pump>
      </div>

      <div
        style="height: 32px; width: 64px; margin-top: -31px; margin-left: 217px"
      >
        <Primary-pump [state]="chillerOneStatus"></Primary-pump>
      </div>

      <div
        style="height: 32px; width: 64px; margin-top: -31px; margin-left: 299px"
      >
        <Primary-pump [state]="chillerOneStatus"></Primary-pump>
      </div>

      <div
        style="height: 32px; width: 64px; margin-top: -31px; margin-left: 378px"
      >
        <Primary-pump [state]="chillerOneStatus"></Primary-pump>
      </div>

      <!--9 secondary-pumps -->

      <div
        style="
          height: 32px;
          width: 64px;
          margin-top: -316px;
          margin-left: 734px;
          rotate: -90deg;
        "
      >
        <Secondary-pump [state]="chillerOneStatus"></Secondary-pump>
      </div>

      <div
        style="
          height: 32px;
          width: 64px;
          margin-top: 32px;
          margin-left: 734px;
          rotate: -90deg;
        "
      >
        <Secondary-pump [state]="chillerOneStatus"></Secondary-pump>
      </div>

      <div
        style="
          height: 32px;
          width: 64px;
          margin-top: 30px;
          margin-left: 734px;
          rotate: -90deg;
        "
      >
        <Secondary-pump [state]="chillerOneStatus"></Secondary-pump>
      </div>

      <div
        style="
          height: 32px;
          width: 64px;
          margin-top: -158px;
          margin-left: 851px;
          rotate: -90deg;
        "
      >
        <Secondary-pump [state]="chillerOneStatus"></Secondary-pump>
      </div>

      <div
        style="
          height: 32px;
          width: 64px;
          margin-top: 32px;
          margin-left: 851px;
          rotate: -90deg;
        "
      >
        <Secondary-pump [state]="chillerOneStatus"></Secondary-pump>
      </div>

      <div
        style="
          height: 32px;
          width: 64px;
          margin-top: 30px;
          margin-left: 851px;
          rotate: -90deg;
        "
      >
        <Secondary-pump [state]="chillerOneStatus"></Secondary-pump>
      </div>

      <div
        style="
          height: 32px;
          width: 64px;
          margin-top: -158px;
          margin-left: 967px;
          rotate: -90deg;
        "
      >
        <Secondary-pump [state]="chillerOneStatus"></Secondary-pump>
      </div>

      <div
        style="
          height: 32px;
          width: 64px;
          margin-top: 32px;
          margin-left: 967px;
          rotate: -90deg;
        "
      >
        <Secondary-pump [state]="chillerOneStatus"></Secondary-pump>
      </div>

      <div
        style="
          height: 32px;
          width: 64px;
          margin-top: 30px;
          margin-left: 967px;
          rotate: -90deg;
        "
      >
        <Secondary-pump [state]="chillerOneStatus"></Secondary-pump>
      </div>

      <!--4 cooling tower -->

      <div
        style="
          height: 50px;
          width: 100px;
          margin-top: -352px;
          margin-left: 84px;
        "
      >
        <Cooling-tower [state]="chillerOneStatus"></Cooling-tower>
      </div>

      <div
        style="
          height: 50px;
          width: 100px;
          margin-top: -50px;
          margin-left: 210px;
        "
      >
        <Cooling-tower [state]="chillerOneStatus"></Cooling-tower>
      </div>

      <div
        style="
          height: 50px;
          width: 100px;
          margin-top: -50px;
          margin-left: 336px;
        "
      >
        <Cooling-tower [state]="chillerOneStatus"></Cooling-tower>
      </div>

      <div
        style="
          height: 50px;
          width: 100px;
          margin-top: -50px;
          margin-left: 461px;
        "
      >
        <Cooling-tower [state]="chillerOneStatus"></Cooling-tower>
      </div>


      
      

      <!-- inputfield  -->

      <!-- this.CT1_VFD_Feedback_F1_out=this.predict_response['CT1 VFD Feedback F1'];
      this.CT1_VFD_Feedback_F2_out=this.predict_response['CT1 VFD Feedback F2'];
      this.CT2_VFD_Feedback_F1_out=this.predict_response['CT2 VFD Feedback F1'];
      this.CT2_VFD_Feedback_F2_out=this.predict_response['CT2 VFD Feedback F2'];
      this.CT3_VFD_Feedback_F1_out=this.predict_response['CT3 VFD Feedback F1'];
      this.CT3_VFD_Feedback_F2_out=this.predict_response['CT3 VFD Feedback F2'];
      this.CT4_VFD_Feedback_F1_out=this.predict_response['CT4 VFD Feedback F1'];
      this.CT4_VFD_Feedback_F2_out=this.predict_response['CT4 VFD Feedback F2'];
      this.Chilled_water_Power_out=this.predict_response['Chilled-water Power'];
      this.Common_Header_Return_temp_After_Decoupler_Line_out =this.predict_response['Common Header Return temp After Decoupler Line'];
      this.Common_Header_Return_temp_After_Decoupler_Line_out=this.predict_response['Common Header Return temp Before Decoupler Line']
      this.Common_Header_Supply_temp_After_Decoupler_Line_out = this.predict_response['Common Header Supply temp After Decoupler Line'];
      this.Common_Header_Supply_temp_Before_Decoupler_Line_out=this.predict_response['Common Header Supply temp Before Decoupler Line'];
      this.Diff_Pressure_Sec_Pump_Zone_A_out=this.predict_response['Diff Pressure Sec Pump Zone A'];
      this.Diff_Pressure_Sec_Pump_Zone_B_out=this.predict_response['Diff Pressure Sec Pump Zone B'];
      this.Diff_Pressure_Sec_Pump_Zone_C_out=this.predict_response['Diff Pressure Sec Pump Zone C'];
      this.Supply_temp_After_Sec_Pump_Zone_A_out=this.predict_response['Supply temp After Sec Pump Zone A'];
      this.Supply_temp_After_Sec_Pump_Zone_B_out=this.predict_response['Supply temp After Sec Pump Zone B'];
      this.Supply_temp_After_Sec_Pump_Zone_C_out=this.predict_response['Supply temp After Sec Pump Zone C'];
      
      this.Total_Sec_Pump_Power_out=this.predict_response['Total Sec Pump Power'];
      this.VFD_Feedback_CP_Pump_1_out=this.predict_response['VFD Feedback CP Pump 1'];
      this.VFD_Feedback_CP_Pump_2_out=this.predict_response['VFD Feedback CP Pump 2'];
      this.VFD_Feedback_CP_Pump_3_out=this.predict_response['VFD Feedback CP Pump 3'];
      this.VFD_Feedback_CP_Pump_4_out=this.predict_response['VFD Feedback CP Pump 4'];
      this.VFD_Feedback_Pr_Pump_3_out=this.predict_response['VFD Feedback Pr Pump 3'];
      this.VFD_Feedback_Pr_Pump_4_out=this.predict_response['VFD Feedback Pr Pump 4'];

      this.VFD_Feedback_Zone_A_Sec_Pump_3_out=this.predict_response['VFD Feedback Zone A Sec Pump 3'];
      this.VFD_Feedback_Zone_B_Sec_Pump_2_out=this.predict_response['VFD Feedback Zone B Sec Pump 2'];
      this.VFD_Feedback_Zone_B_Sec_Pump_3_out=this.predict_response['VFD Feedback Zone B Sec Pump 3'];
      this.VFD_Feedback_Zone_C_Sec_Pump_2_out=this.predict_response['VFD Feedback Zone C Sec Pump 2'];
      this.VFD_Feedback_CP_Pump_3_out=this.predict_response['VFD Feedback Zone C Sec Pump 3']; -->

      <!-- <div class="field" style="margin-top: -84px; margin-left: 135px">
        <span>{{ CT1_VFD_Feedback_F1_out | number : "1.2-2" }}</span>
      </div>
      <div class="field" style="margin-top: -27px; margin-left: 261px">
        <span>{{ CT2_VFD_Feedback_F1_out | number : "1.2-2" }}</span>
      </div>
      <div class="field" style="margin-top: -31px; margin-left: 186px">
        <span>{{ CT1_VFD_Feedback_F2_out | number : "1.2-2" }}</span>
      </div>
      <div class="field" style="margin-top: -31px; margin-left: 313px">
        <span>{{ CT2_VFD_Feedback_F2_out | number : "1.2-2" }}</span>
      </div>
      <div class="field" style="margin-top: -25px; margin-left: 390px">
        <span>{{ CT3_VFD_Feedback_F1_out | number : "1.2-2" }}</span>
      </div>
      <div class="field" style="margin-top: -30px; margin-left: 440px">
        <span>{{ CT3_VFD_Feedback_F2_out | number : "1.2-2" }}</span>
      </div>
      <div class="field" style="margin-top: -27px; margin-left: 513px">
        <span>{{ CT4_VFD_Feedback_F1_out | number : "1.2-2" }}</span>
      </div>
      <div class="field" style="margin-top: -27px; margin-left: 563px">
        <span>{{ CT4_VFD_Feedback_F2_out | number : "1.2-2" }}</span>
      </div> -->

      <div
        class="input-group"
        style="width: 100px; margin-top: 409px; margin-left: 704px"
      >
        <img src="/assets/images/differential-pressure-transmitter.png" />
        <input
          [ngModel]="Diff_Pressure_Sec_Pump_Zone_A_out | number : '1.2-2'"
          (ngModelChange)="Diff_Pressure_Sec_Pump_Zone_A_out = $event"
        />
      </div>

      <div
        class="input-group"
        style="
          width: 100px;
          margin-left: 819px;
          margin-top: -30px;
        "
      >
        <img src="/assets/images/differential-pressure-transmitter.png" />
        <input
          [ngModel]="Diff_Pressure_Sec_Pump_Zone_B_out | number : '1.2-2'"
          (ngModelChange)="Diff_Pressure_Sec_Pump_Zone_B_out = $event"
        />
      </div>

      <div
        class="input-group"
        style="
          width: 100px;
          width: 100px;
          margin-top: -31px;
          margin-left: 939px;
        "
      >
        <img src="/assets/images/differential-pressure-transmitter.png" />
        <input
          [ngModel]="Diff_Pressure_Sec_Pump_Zone_C_out | number : '1.2-2'"
          (ngModelChange)="Diff_Pressure_Sec_Pump_Zone_C_out = $event"
        />
      </div>

      <div
        class="input-group"
        style="width: 80px; margin-top: -127px; margin-left: 778px"
      >
      
        <img src="/assets/images/temperature-celcius.png" />
        <input
          [ngModel]="Supply_temp_After_Sec_Pump_Zone_A_out | number : '1.2-2'"
          (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A_out = $event"
        />
      </div>

      <!-- <div class="field" style="margin-top: -27px; margin-left: 949px">
        <span>{{
          Supply_temp_After_Sec_Pump_Zone_B_out | number : "1.2-2"
        }}</span>
      </div> -->

      <div
        class="input-group"
        style="width: 80px; margin-top: -29px; margin-left: 893px"
      >
        <img src="/assets/images/temperature-celcius.png" />
        <input
          [ngModel]="Supply_temp_After_Sec_Pump_Zone_B_out | number : '1.2-2'"
          (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_B_out = $event"
        />
      </div>

      <div
        class="input-group"
        style="width: 80px; margin-top: -31px; margin-left: 1012px"
      >
        <img src="/assets/images/temperature-celcius.png" />
        <input
          [ngModel]="Supply_temp_After_Sec_Pump_Zone_C_out | number : '1.2-2'"
          (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_C_out = $event"
        />
      </div>

      <!-- <div class="field" style="margin-top: -141px; margin-left: 710px">
        <span>{{ Total_Sec_Pump_Power_out | number : "1.2-2" }}</span>
      </div> -->

      <!-- <div class="field" style="margin-top: -222px; margin-left: 849px">
        <span>{{ VFD_Feedback_CP_Pump_1_out | number : "1.2-2" }}</span>
      </div>
      <div class="field" style="margin-top: -27px; margin-left: 908px">
        <span>{{ VFD_Feedback_CP_Pump_2_out | number : "1.2-2" }}</span>
      </div>
      <div class="field" style="margin-top: -27px; margin-left: 968px">
        <span>{{ VFD_Feedback_CP_Pump_3_out | number : "1.2-2" }}</span>
      </div>
      <div class="field" style="margin-top: -27px; margin-left: 1026px">
        <span>{{ VFD_Feedback_CP_Pump_4_out | number : "1.2-2" }}</span>
      </div> -->
      <!-- <div class="field" style="margin-top: 125px; margin-left: 333px">
        <span>{{ VFD_Feedback_Pr_Pump_1_out | number : "1.2-2" }}</span>
      </div> -->

      <div
        class="input-group"
        style="width: 90px; margin-top: 125px; margin-left: 266px"
      >
        <img src="/assets/images/percentage.png" />
        <input
          [ngModel]="VFD_Feedback_Pr_Pump_1_out | number : '1.2-2'"
          (ngModelChange)="VFD_Feedback_Pr_Pump_1_out = $event"
        />
      </div>

      <!-- <div class="field" style="margin-top: -27px; margin-left: 413px">
        <span>{{ VFD_Feedback_Pr_Pump_2_out | number : "1.2-2" }}</span>
      </div> -->

      <div
        class="input-group"
        style="width: 90px; margin-top: -27px; margin-left: 363px"
      >
        <img src="/assets/images/percentage.png" />
        <input
          [ngModel]="VFD_Feedback_Pr_Pump_2_out | number : '1.2-2'"
          (ngModelChange)="VFD_Feedback_Pr_Pump_2_out = $event"
        />
      </div>

      <!--<div class="field"><span>{{Chilled_water_Power_out| number : '1.2-2'}}</span></div> -->
      <!-- <div class="field" style="    margin-top: 3px;
      margin-left: 553px;"><span>{{Common_Header_Return_temp_After_Decoupler_Line_out| number : '1.2-2'}}</span></div> -->

      <div
        class="input-group"
        style="width: 80px; margin-top: -1px; margin-left: 482px"
      >
        <img src="/assets/images/temperature-celcius.png" />
        <input
          [ngModel]="
            Common_Header_Return_temp_After_Decoupler_Line_out
              | number : '1.2-2'
          "
          (ngModelChange)="
            Common_Header_Return_temp_After_Decoupler_Line_out = $event
          "
        />
      </div>
<!-- 
      <div class="field" style="margin-top: -28px; margin-left: 489px">
        <span>{{
          Common_Header_Return_temp_Before_Decoupler_Line_out | number : "1.2-2"
        }}</span>
      </div> -->

      <div
        class="input-group"
        style="width: 80px; margin-top: -30px; margin-left: 589px"
      >
        <img src="/assets/images/temperature-celcius.png" />
        <input
          [ngModel]="
            Common_Header_Return_temp_Before_Decoupler_Line_out
              | number : '1.2-2'
          "
          (ngModelChange)="
            Common_Header_Return_temp_Before_Decoupler_Line_out = $event
          "
        />
      </div>

      <!-- <div class="field" style="margin-top: -241px; margin-left: 639px">
        <span>{{
          Common_Header_Supply_temp_After_Decoupler_Line_out | number : "1.2-2"
        }}</span>
      </div> -->

      <div
        class="input-group"
        style="width: 80px; margin-top: -241px; margin-left: 589px"
      >
        <img src="/assets/images/temperature-celcius.png" />
        <input
          [ngModel]="
          Common_Header_Supply_temp_After_Decoupler_Line_out
              | number : '1.2-2'
          "
          (ngModelChange)="
          Common_Header_Supply_temp_After_Decoupler_Line_out = $event
          "
        />
      </div>

      <!-- <div class="field" style="margin-top: -25px; margin-left: 565px">
        <span>{{
          Common_Header_Supply_temp_Before_Decoupler_Line_out | number : "1.2-2"
        }}</span>
      </div> -->

      <div
        class="input-group"
        style="width: 80px; margin-top: -29px;
        margin-left: 475px;"
      >
        <img src="/assets/images/temperature-celcius.png" />
        <input
          [ngModel]="
          Common_Header_Supply_temp_Before_Decoupler_Line_out
              | number : '1.2-2'
          "
          (ngModelChange)="
          Common_Header_Supply_temp_Before_Decoupler_Line_out = $event
          "
        />
      </div>

      <div style="
      height: 30px;
      width: 30px;
      margin-top: 49px;
    margin-left: 572px;
    transform: rotate(90deg)
    ">
      <Valve></Valve>
      </div>

      <!-- <div class="field" style="margin-top: 0px;
        margin-left: 0px;"><span>{{VFD_Feedback_Zone_A_Sec_Pump_3_out| number : '1.2-2'}}</span></div> -->
      <!-- <div class="field"><span>{{VFD_Feedback_Zone_B_Sec_Pump_2_out| number : '1.2-2'}}</span></div>
      <div class="field"><span>{{VFD_Feedback_Zone_B_Sec_Pump_3_out| number : '1.2-2'}}</span></div>
      <div class="field"><span>{{VFD_Feedback_Zone_C_Sec_Pump_2_out| number : '1.2-2'}}</span></div>
      <div class="field"><span>{{VFD_Feedback_CP_Pump_3_out| number : '1.2-2'}}</span></div> 
      -->
    </div>
    
  </div>
  <div
    style="
      width: 350px;
      height: 100%;
      box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.06),
        0px 0px 2px rgba(25, 25, 25, 0.12);
       
    "
  >
    <div
      style="
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        gap: 10px;
       
       
      "
    >
      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: space-between;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >VFD PR1</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/percentage.png" />
            <input
              [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
              (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event"
            />
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >VFD PR2</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/percentage.png" />
            <input
              [ngModel]="VFD_Feedback_Pr_Pump_2 | number : '1.2-2'"
              (ngModelChange)="VFD_Feedback_Pr_Pump_2 = $event"
            />
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: space-between;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >VFD SP1<br />ZA</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/percentage.png" />
            <input
              [ngModel]="VFD_Feedback_Zone_A_Sec_Pump_1 | number : '1.2-2'"
              (ngModelChange)="VFD_Feedback_Zone_A_Sec_Pump_1 = $event"
            />
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >VFD SP2<br />ZA</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/percentage.png" />
            <input
              [ngModel]="VFD_Feedback_Zone_A_Sec_Pump_2 | number : '1.2-2'"
              (ngModelChange)="VFD_Feedback_Zone_A_Sec_Pump_2 = $event"
            />
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: space-between;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >VFD SP1<br />ZB</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/percentage.png" />
            <input
              [ngModel]="VFD_Feedback_Zone_B_Sec_Pump_1 | number : '1.2-2'"
              (ngModelChange)="VFD_Feedback_Zone_B_Sec_Pump_1 = $event"
            />
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >VFD SP1<br />ZC</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/percentage.png" />
            <input
              [ngModel]="VFD_Feedback_Zone_C_Sec_Pump_1 | number : '1.2-2'"
              (ngModelChange)="VFD_Feedback_Zone_C_Sec_Pump_1 = $event"
            />
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: space-between;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >CHSTBD</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/temperature-celcius.png" />
            <input
              [ngModel]="
                Common_Header_Supply_temp_Before_Decoupler_Line
                  | number : '1.2-2'
              "
              (ngModelChange)="
                Common_Header_Supply_temp_Before_Decoupler_Line = $event
              "
            />
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 12px; font-weight: 500"
            >CHSTAD</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/temperature-celcius.png" />
            <input
              [ngModel]="
                Common_Header_Supply_temp_After_Decoupler_Line
                  | number : '1.2-2'
              "
              (ngModelChange)="
                Common_Header_Supply_temp_After_Decoupler_Line = $event
              "
            />
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: space-between;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >CHRTBD</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/temperature-celcius.png" />
            <input
              [ngModel]="
                Common_Header_Return_temp_Before_Decoupler_Line
                  | number : '1.2-2'
              "
              (ngModelChange)="
                Common_Header_Return_temp_Before_Decoupler_Line = $event
              "
            />
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >CHRTAD</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/temperature-celcius.png" />
            <input
              [ngModel]="
                Common_Header_Return_temp_After_Decoupler_Line
                  | number : '1.2-2'
              "
              (ngModelChange)="
                Common_Header_Return_temp_After_Decoupler_Line = $event
              "
            />
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: space-between;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >DP ZA</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/differential-pressure-transmitter.png" />
            <input
              [ngModel]="Diff_Pressure_Sec_Pump_Zone_A | number : '1.2-2'"
              (ngModelChange)="Diff_Pressure_Sec_Pump_Zone_A = $event"
            />
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >DP ZB</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/differential-pressure-transmitter.png" />
            <input
              [ngModel]="Diff_Pressure_Sec_Pump_Zone_B | number : '1.2-2'"
              (ngModelChange)="Diff_Pressure_Sec_Pump_Zone_B = $event"
            />
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: space-between;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >DP ZC</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/differential-pressure-transmitter.png" />
            <input
              [ngModel]="Diff_Pressure_Sec_Pump_Zone_C | number : '1.2-2'"
              (ngModelChange)="Diff_Pressure_Sec_Pump_Zone_C = $event"
            />
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >CHW SP</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/temperature-celcius.png" />
            <input
              [ngModel]="Chilled_Water_Set_Point | number : '1.2-2'"
              (ngModelChange)="Chilled_Water_Set_Point = $event"
            />
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: space-between;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >TF SP ZA</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/temperature-celcius.png" />
            <input
              [ngModel]="Supply_temp_After_Sec_Pump_Zone_A | number : '1.2-2'"
              (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
            />
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >TF SP ZB</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/temperature-celcius.png" />
            <input
              [ngModel]="Supply_temp_After_Sec_Pump_Zone_B | number : '1.2-2'"
              (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_B = $event"
            />
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: space-between;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >TF SP ZC</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/temperature-celcius.png" />
            <input
              [ngModel]="Supply_temp_After_Sec_Pump_Zone_C | number : '1.2-2'"
              (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_C = $event"
            />
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
            opacity: 0;
          "
        >
          <span class="span-text-dark" style="font-size: 10px; font-weight: 500"
            >TF SP ZC</span
          >

          <div class="input-group" style="width: 100px">
            <img src="/assets/images/temperature-celcius.png" />
            <input
              [ngModel]="Supply_temp_After_Sec_Pump_Zone_C | number : '1.2-2'"
              (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_C = $event"
            />
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 10px;
          margin-top: 5px;
          padding: 5px 5px 5px 5px;
          gap: 10px;
          margin-bottom: 10px;
        "
      >
        <button
          style="
            width: 50%;
            background-color: var(--blue-one);
            border-radius: 20px;
            border: none;
            height: auto;
            padding: 7px;
            color: white;
            cursor: pointer;
          "
          (click)="predictData()"
        >
          Predict
        </button>
        <button
          style="
            width: 50%;
            border-radius: 20px;
            border: none;
            height: auto;
            padding: 7px;
            color: white;
            cursor: pointer;
          "
          [ngClass]="{
            'button-disabled': disableOptimise,
            'button-enabled': !disableOptimise
          }"
          [disabled]="disableOptimise"
          (click)="optimiseData()"
        >
          Optimise
        </button>
      </div>

      <div class="form" style="height: 150px; width: 100%; padding: 10px">
        <span
          class="span-text-dark"
          style="font-weight: bold; text-align: center"
          >KEY PERFORMANCE INDICATOR</span
        >

        <div
          style="height: 10px; display: flex; flex-direction: row; width: 100%"
        ></div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          "
        >
          <span class="span-text-dark" style="font-size: 12px; font-weight: 500"
            >CHILLER POWER SAVING</span
          >

          <div class="input-group" style="width: 120px">
            <img src="/assets/images/power.png" />
            <input
              [ngModel]="chiller_power_savings | number : '1.2-2'"
              (ngModelChange)="chiller_power_savings = $event"
            />
            <span>kWh</span>
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          "
        >
          <span class="span-text-dark" style="font-size: 12px; font-weight: 500"
            >SECONDARY POWER <br />
            SAVING</span
          >

          <div class="input-group" style="width: 120px">
            <img src="/assets/images/power.png" />
            <input
              [ngModel]="secondary_pump_power | number : '1.2-2'"
              (ngModelChange)="secondary_pump_power = $event"
            />
            <span>kWh</span>
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          gap: 5px;
        "
      >
        <span class="span-text-dark" style="font-size: 12px">powered by </span>
        <img
          src="/assets/logos/bertlabs-logo.png"
          height="20px"
          width="100px"
        />
      </div>
    </div>
  </div>
</div>
