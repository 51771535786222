import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { ChillerPlantComponent } from './pages/chiller-plant/chiller-plant.component';
import { SecondAhuComponent } from './pages/c-pier/second-ahu/second-ahu.component';
import { FirstAhuComponent } from './pages/d-pier/first-ahu/first-ahu.component';
import { PmvComponent } from './pages/d-pier/pmv/pmv.component';
import { ElectricLoadCentreOneMapComponent } from './pages/d-pier/electric-load-centre-one-map/electric-load-centre-one-map.component';
import { SecurityScreeningAreaMapComponent } from './pages/d-pier/security-screening-area-map/security-screening-area-map.component';
import { LadiesWashroomMapComponent } from './pages/d-pier/ladies-washroom-map/ladies-washroom-map.component';
import { GateHoldRoomDTwoMapComponent } from './pages/d-pier/gate-hold-room-d-two-map/gate-hold-room-d-two-map.component';
import { ElectricLoadCentreTwoMapComponent } from './pages/d-pier/electric-load-centre-two-map/electric-load-centre-two-map.component';
import { GateHoldRoomDOneMapComponent } from './pages/d-pier/gate-hold-room-d-one-map/gate-hold-room-d-one-map.component';
import { CPierPmvComponent } from './pages/c-pier/c-pier-pmv/c-pier-pmv.component';
import { CPierSecurityScreeningRoomOneMapComponent } from './pages/c-pier/c-pier-security-screening-room-one-map/c-pier-security-screening-room-one-map.component';
import { CPierGateHoldRoomOneMapComponent } from './pages/c-pier/c-pier-gate-hold-room-one-map/c-pier-gate-hold-room-one-map.component';
import { CPierGateHoldRoomTwoMapComponent } from './pages/c-pier/c-pier-gate-hold-room-two-map/c-pier-gate-hold-room-two-map.component';
import { CPierSecurityScreeningRoomTwoMapComponent } from './pages/c-pier/c-pier-security-screening-room-two-map/c-pier-security-screening-room-two-map.component';
import { ThirdAhuComponent } from './pages/c-pier/third-ahu/third-ahu.component';
import { LoadingScreenComponent } from './pages/loading-screen/loading-screen.component';
import { AuthGuard } from './guards/auth.guard';
import { C17Part1Component } from './pages/c-pier/c17-part1/c17-part1.component';
import { C17Part2Component } from './pages/c-pier/c17-part2/c17-part2.component';
import { C17Part3aComponent } from './pages/c-pier/c17-part3a/c17-part3a.component';
import { C17Part3bComponent } from './pages/c-pier/c17-part3b/c17-part3b.component';
import { C18Part1Component } from './pages/c-pier/c18-part1/c18-part1.component';
import { C18Part2Component } from './pages/c-pier/c18-part2/c18-part2.component';
import { C18Part3Component } from './pages/c-pier/c18-part3/c18-part3.component';
import { C18Part3bComponent } from './pages/c-pier/c18-part3b/c18-part3b.component';
import { C18Part4Component } from './pages/c-pier/c18-part4/c18-part4.component';
import { C18Part5Component } from './pages/c-pier/c18-part5/c18-part5.component';
import { C18Part6Component } from './pages/c-pier/c18-part6/c18-part6.component';
import { C17Part4Component } from './pages/c-pier/c17-part4/c17-part4.component';
import { C17Part5Component } from './pages/c-pier/c17-part5/c17-part5.component';
import { C17Part6Component } from './pages/c-pier/c17-part6/c17-part6.component';
import { InsightsComponent } from './pages/insights/insights.component';


const routes: Routes = [

      {path:'login',component:LoginComponent},
      {path:'loading-screen', component:LoadingScreenComponent,canActivate: [AuthGuard]},
      {path:'',redirectTo:'login',pathMatch:'full'},
      {path:'forgot-password',component:ForgotPasswordComponent},
      {path:'dashboard',component:DashboardComponent,canActivate: [AuthGuard],
        children:[
      {path:'home', component: HomeComponent,},
      {path:'chillerplant',component:ChillerPlantComponent},
      {path:'insights',component:InsightsComponent},

      // d-pier-routes
      {path:'ahud7a',component:FirstAhuComponent},
      {path:'pmv',component:PmvComponent},
      {path:'electric-load-centre-one-map',component:ElectricLoadCentreOneMapComponent},
      {path:'security-screening-area-map',component:SecurityScreeningAreaMapComponent},
      {path:'ladies-washroom-map',component:LadiesWashroomMapComponent},
      {path:'gate-hold-room-d-two-map',component:GateHoldRoomDTwoMapComponent},
      {path:'electric-load-centre-two-map',component:ElectricLoadCentreTwoMapComponent},
      {path:'gate-hold-room-d-one-map',component:GateHoldRoomDOneMapComponent},

      // c-pier-routes
      {path:'ahuc4a',component:SecondAhuComponent},
      {path:'ahuc4b',component:ThirdAhuComponent},
      {path:'T1',component:CPierPmvComponent},
      {path:'c-pier-security-screening-room-one-map',component:CPierSecurityScreeningRoomOneMapComponent},
      {path:'c-pier-security-screening-room-two-map',component:CPierSecurityScreeningRoomTwoMapComponent},
      {path:'c-pier-gate-hold-room-one-map',component:CPierGateHoldRoomOneMapComponent},
      {path:'c-pier-gate-hold-room-two-map',component:CPierGateHoldRoomTwoMapComponent},
      // C17 comp
      {path:'T1A1', component:C17Part1Component},
      {path:'T1A2', component:C17Part2Component},
      {path:'T1A3', component:C17Part3aComponent},
      {path:'T1A4', component:C17Part3bComponent},
      {path:'T1A5', component:C17Part4Component},
      {path:'T1A6', component:C17Part5Component},
      {path:'T1A7', component:C17Part6Component},

      //C18 comp
      {path:'T1B1', component:C18Part1Component},
      {path:'T1B2', component:C18Part2Component},
      {path:'T1B3', component:C18Part3Component},
      {path:'T1B4', component:C18Part3bComponent},
      {path:'T1B5', component:C18Part4Component},
      {path:'T1B6', component:C18Part5Component},
      {path:'T1B7', component:C18Part6Component},
     ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
