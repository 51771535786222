import { Component, ViewEncapsulation } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GraphComponent } from 'src/app/components/graph/graph.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { HttpClient } from '@angular/common/http';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxGaugeModule } from 'ngx-gauge';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-insights',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    GraphComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    NgChartsModule,
    MatPaginatorModule,
    MatTableModule,
    NgxPaginationModule,
    NgxGaugeModule,
  ],
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class InsightsComponent {
  selectedRoom: string = 'C-17';
  selectedVav: string = '';
  selectedType: string = '';
  fromDate: Date | null = null;
  fromTime: string | null = '09:30';
  toDate: Date | null = null;
  toTime: string | null = '09:30';
  selectedTab: string = 'tabular';
  showData: boolean = false;
  anyerror: boolean = true;
  numberoflogs: boolean = true;
  aftermessage: string = '';
  downloadtype: string = 'PDF';
  selectedtypeintable: string = '';
  outOfRangePercentage: any = 0;
  itemsPerPage: number = 50;
  currentPage: number = 1;

  itemsPerPageOptions: number[] = [10, 20, 50, 144];

  thresholdConfig = {
    '0': {color: '#81c91d'},
};

  tableData: any[] = [];
  rooms = [
    { value: 'C-17', name: 'T1 A Gate' },
    { value: 'C-18', name: 'T1 B Gate' },
  ];

  downloadTypes = [
    { value: 'CSV', name: 'CSV' },
    { value: 'PDF', name: 'PDF' },
  ];

  types = [
    { value: 'pmv', name: 'PMV' },
    { value: 'zonetemp', name: 'Zonal Temp' },
  ];

  times = [
    { value: '00:00', name: '00:00' },
    { value: '01:00', name: '01:00' },
    { value: '02:00', name: '02:00' },
    { value: '03:00', name: '03:00' },
    { value: '04:00', name: '04:00' },
    { value: '05:00', name: '05:00' },
    { value: '06:00', name: '06:00' },
    { value: '07:00', name: '07:00' },
    { value: '08:00', name: '08:00' },
    { value: '09:00', name: '09:00' },
    { value: '10:00', name: '10:00' },
    { value: '11:00', name: '11:00' },
    { value: '12:00', name: '12:00' },
    { value: '13:00', name: '13:00' },
    { value: '14:00', name: '14:00' },
    { value: '15:00', name: '15:00' },
    { value: '16:00', name: '16:00' },
    { value: '17:00', name: '17:00' },
    { value: '18:00', name: '18:00' },
    { value: '19:00', name: '19:00' },
    { value: '20:00', name: '20:00' },
    { value: '21:00', name: '21:00' },
    { value: '22:00', name: '22:00' },
    { value: '23:00', name: '23:00' },
  ];

  response1: any;

  vavs = {
    'C-18': [
      { value: 'VAV-AHU-C4-L2-16', name: 'VAV-11' },
      { value: 'VAV-AHU-C4-L2-18', name: 'VAV-12' },
      { value: 'VAV-AHU-C4-L2-19', name: 'VAV-13' },
      { value: 'VAV-AHU-C4-L2-20', name: 'VAV-14' },
      { value: 'VAV-AHU-C4-L2-22', name: 'VAV-15' },
      { value: 'VAV-AHU-C4-L2-23', name: 'VAV-16' },
      { value: 'VAV-AHU-C4-L2-25', name: 'VAV-17' },
      { value: 'VAV-AHU-C4-L2-26', name: 'VAV-18' },
      { value: 'VAV-AHU-C4-L2-27', name: 'VAV-19' },
    ],
    'C-17': [
      { value: 'VAV-AHU-C4-L2-02', name: 'VAV-1' },
      { value: 'VAV-AHU-C4-L2-03', name: 'VAV-2' },
      { value: 'VAV-AHU-C4-L2-05', name: 'VAV-3' },
      { value: 'VAV-AHU-C4-L2-06', name: 'VAV-4' },
      { value: 'VAV-AHU-C4-L2-08', name: 'VAV-5' },
      { value: 'VAV-AHU-C4-L2-09', name: 'VAV-6' },
      { value: 'VAV-AHU-C4-L2-10', name: 'VAV-7' },
      { value: 'VAV-AHU-C4-L2-11', name: 'VAV-8' },
      { value: 'VAV-AHU-C4-L2-13', name: 'VAV-9' },
      { value: 'VAV-AHU-C4-L2-14', name: 'VAV-10' },
    ],
  };

  // Line chart configuration
  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Query Data',
        borderColor: '#94448f',
        fill: false,
      },
    ],
  };

  public lineChartOptions: ChartConfiguration<'line'>['options'] = {
    responsive: true,
  };

  constructor(private http: HttpClient) {}

  getVavOptions() {
    return this.vavs[this.selectedRoom as keyof typeof this.vavs] || [];
  }

  async SendData() {
    this.anyerror = false;

    // Validation for required fields
    if (!this.selectedRoom) {
      this.anyerror = true;
      alert('Room selection is required.');
      return;
    }

    if (!this.selectedVav) {
      this.anyerror = true;
      alert('VAV selection is required.');
      return;
    }

    if (!this.fromDate) {
      this.anyerror = true;
      alert('From Date is required.');
      return;
    }

    if (!this.fromTime) {
      this.anyerror = true;
      alert('From Time is required.');
      return;
    }

    if (!this.toDate) {
      this.anyerror = true;
      alert('To Date is required.');
      return;
    }

    if (!this.toTime) {
      this.anyerror = true;
      alert('To Time is required.');
      return;
    }

    if (!this.selectedType) {
      this.anyerror = true;
      alert('PMV / Zonal Temp selection is required.');
      return;
    }

    if (this.fromDate > this.toDate) {
      this.anyerror = true;
      alert('From date should be less than To date');
      return;
    }
    console.log(this.fromDate, 'check2');
    this.selectedtypeintable = this.selectedType;
    const fromDatetime =
      this.fromDate && this.fromTime
        ? (() => {
            // Create a Date object from the user input
            const date = new Date(this.fromDate);

            // Increase the date by 1 day
            date.setDate(date.getDate() + 1);
            const newDateString = date.toISOString().split('T')[0];
            const formattedTime = this.convertTo24HourFormat(this.fromTime);
            return `${newDateString} ${formattedTime}`;
          })()
        : '';

    const toDatetime =
      this.toDate && this.toTime
        ? (() => {
            // Create a Date object from the user input
            const date = new Date(this.toDate);

            // Increase the date by 1 day
            date.setDate(date.getDate() + 1);

            // Format the adjusted date as "YYYY-MM-DD"
            const newDateString = date.toISOString().split('T')[0];

            // Convert the time to 24-hour format
            const formattedTime = this.convertTo24HourFormat(this.toTime);

            // Combine the adjusted date with the formatted time
            return `${newDateString} ${formattedTime}`;
          })()
        : '';

    console.log('check from', fromDatetime);
    console.log('check to', toDatetime);

    const adjustedFromDatetime = this.adjustTimeBeforeSend(fromDatetime);
    const adjustedToDatetime = this.adjustTimeBeforeSend(toDatetime);
    console.log('hey');
    console.log(adjustedFromDatetime);
    console.log(adjustedToDatetime);

    const data = {
      from_datetime: adjustedFromDatetime,
      to_datetime: adjustedToDatetime,
      deviceid: this.selectedVav,
      query: this.selectedType,
    };

    this.showData = true;

    this.http
      .post<any[]>('https://airportinternational.bertlabs.com/device/fetchLogs', data)
      .subscribe(
        (response: any[]) => {
          console.log('received response', response);
          console.log(data);

          this.response1 = {
            timestamps: response.map(
              (item) => this.adjustTimeByAddingHours(item.time, 0) // Increase time by 8 hours
            ),
            queryData: response.map((item) => item[this.selectedType]),
          };

          // Calculate the percentage of values outside the range -0.5 to +0.5
          const totalCount = this.response1.queryData.length;
          const outOfRangeCount = this.response1.queryData.filter(
            (value: number) => value < -0.51 || value > 0.51
          ).length;
          const outOfRangePercentage =
            (100 - (outOfRangeCount / totalCount) * 100).toFixed(2);
          console.log(
            `Percentage of values outside -0.5 to +0.5 range: ${outOfRangePercentage}%`
          );

          // Optionally store the percentage in a new variable
          this.outOfRangePercentage = outOfRangePercentage;

          this.tableData = this.showtable(
            this.response1.timestamps,
            this.response1.queryData
          );

          if (this.tableData.length == 0) {
            this.aftermessage = 'No data in given Range';
          }

          this.lineChartData.labels = this.response1.timestamps;
          this.lineChartData.datasets[0].data = this.response1.queryData;
        },

        (error) => {
          this.numberoflogs = false;
          console.log(this.numberoflogs);
          console.log(data);
          console.error('Error:', error);
        }
      );
  }

  adjustTimeBeforeSend(datetime: string): string {
    const date = new Date(datetime);
    date.setHours(date.getHours());

    const adjustedDatePart = date.toLocaleDateString('en-CA'); // Format as YYYY-MM-DD
    const adjustedTimePart = date.toTimeString().split(' ')[0]; // Extract time in HH:MM:SS

    return `${adjustedDatePart} ${adjustedTimePart}`;
  }

  onChange() {
    this.showData = false;
    this.currentPage = 1;
  }

  onRoomChange() {
    this.showData = false;
    this.selectedVav = '';
    this.currentPage = 1;
  }

  adjustTimeByAddingHours(datetime: string, hoursToAdd: number): string {
    const date = new Date(datetime);

    // Add the specified number of hours to the current time
    date.setHours(date.getHours() + hoursToAdd);

    const adjustedDatePart = date.toLocaleDateString('en-CA'); // Format as YYYY-MM-DD
    const adjustedTimePart = date.toTimeString().split(' ')[0]; // Extract time in HH:MM:SS

    return `${adjustedDatePart} ${adjustedTimePart}`;
  }

  adjustTimeAfterReceive(timeString: string): string {
    const date = new Date(timeString);

    date.setHours(date.getHours());

    const adjustedDatePart = date.toLocaleDateString('en-CA'); // Format as YYYY-MM-DD
    const adjustedTimePart = date.toTimeString().split(' ')[0]; // Extract time in HH:MM:SS

    return `${adjustedDatePart} ${adjustedTimePart}`;
  }

  showtable(timedata: string[], querydata: number[]) {
    return timedata.map((time, index) => {
      const date = new Date(time);
      return {
        serialNo: index + 1,
        date: date.toLocaleDateString(), // Date in user's locale format
        time: date.toLocaleTimeString('en-US', { hour12: false }), // Time in 24-hour format
        value: querydata[index],
      };
    });
  }

  convertTo24HourFormat(time: string | null): string | null {
    if (!time) return null;

    // Split the time into hours and minutes
    let [hours, minutes] = time.split(':');
    return `${hours}:${minutes}:00`;
  }

  downloadCSV() {
    const headers = ['S.No', 'Date', 'Time', this.selectedType];
    const csvRows = [headers.join(',')];

    this.tableData.forEach((row) => {
      const values = [
        row.serialNo,
        row.date,
        row.time,
        this.selectedType === 'zonetemp' ? `${row.value} °C` : row.value, // Add °C if selectedType is 'zonetemp'
      ];
      csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    const filename = `${this.selectedVav}_${this.selectedType}_data.csv`;

    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  downloadPDF() {
    const doc = new jsPDF('p', 'mm', 'a4'); // Create a new PDF document

    const imgWidth = 200; // Adjust the width of the image
    const imgHeight = 200; // Adjust the height of the image

    // Calculate the center of the page
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const xOffset = (pageWidth - imgWidth) / 2;
    const yOffset = (pageHeight - imgHeight) / 2;

    // Add the title "Powered by Bert Labs" with "Bert Labs" in red and bold
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text('Powered by', 10, 20);
    doc.setTextColor(255, 0, 0);
    doc.setFont('helvetica', 'bold');
    doc.text('Bert Labs', 35, 20);

    // Adjusted headers for correct alignment
    const headers = ['S.No', 'Date', 'Time', this.selectedType];
    let yPos = 40; // Starting y position for table data
    let xPos = 10;
    const lineHeight = 10; // Line height for table rows

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    headers.forEach((header, index) => {
      doc.text(header, xPos + index * 40, yPos); // Adjust xPos as needed
    });

    yPos += lineHeight; // Increment yPos to the next line

    // Display table data starting from the left side
    this.tableData.forEach((row) => {
      const values = [
        row.serialNo,
        row.date,
        row.time,
        this.selectedType === 'zonetemp' ? `${row.value} °C` : row.value, // Add °C if selectedType is 'zonetemp'
      ];

      values.forEach((value, index) => {
        doc.text(String(value), xPos + index * 40, yPos);
      });
      yPos += lineHeight; // Increment yPos to the next line

      // Check if the yPos exceeds the page height and create a new page if needed
      if (yPos > pageHeight - 20) {
        // 20 mm margin from the bottom
        doc.addPage(); // Add a new page
        yPos = 20; // Reset yPos to the top of the new page after a margin
        headers.forEach((header, index) => {
          doc.text(header, xPos + index * 40, yPos); // Add headers to the new page
        });
        yPos += lineHeight; // Increment yPos after headers
      }
    });

    // Generate the PDF
    let selectedVavObject = this.vavs['C-18'].find(
      (vav) => vav.value === this.selectedVav
    );
    if (!selectedVavObject) {
      selectedVavObject = this.vavs['C-17'].find(
        (vav) => vav.value === this.selectedVav
      );
    }
    const selectedVavName = selectedVavObject
      ? selectedVavObject.name
      : 'Unknown VAV';
    const filename = `${selectedVavName}_${this.selectedType}_data.pdf`;

    doc.save(filename);
  }
  onPageChange(page: number) {
    this.currentPage = page;
  }

  onItemsPerPageChange() {
    this.currentPage = 1; // Reset to first page when items per page changes
  }
}
