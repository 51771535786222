import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { ButtonService } from './services/button-service/button.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  currentScreenIndex: number = 0;
  currentPart: string = 'c17'; // Default part
  screens: { [key: string]: string[] } = {
    'c17': ['dashboard/T1A1', 'dashboard/T1A2', 'dashboard/T1A3', 'dashboard/T1A4', 'dashboard/T1A5', 'dashboard/T1A6', 'dashboard/T1A7'],
    'c18': ['dashboard/T1B1', 'dashboard/T1B2', 'dashboard/T1B3', 'dashboard/T1B4', 'dashboard/T1B5', 'dashboard/T1B6', 'dashboard/T1B7']
  };

  constructor(
    private elementRef: ElementRef,
    private buttonService: ButtonService,
    private router: Router,
    public authService: AuthService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const currentUrl = event.url;
        const isNotLoginPage = this.router.url !== '/login';

        if (currentUrl === '/loading-screen' && this.authService.isLoggedIn() && isNotLoginPage) {
          this.authService.logout();
          this.router.navigate(['/login']);
        }
        if (currentUrl === '/login' && this.authService.isLoggedIn()) {
          this.authService.logout();
        }
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const path = event.urlAfterRedirects.split('/').pop();
        if (path) {
          this.updateCurrentPartAndIndex(path);
        } else {
          console.error('Navigation path is undefined');
        }
      }
    });
  }

  updateCurrentPartAndIndex(path: string) {
    for (const part in this.screens) {
      if (this.screens[part].includes(`dashboard/${path}`)) {
        this.currentPart = part;
        this.currentScreenIndex = this.screens[part].indexOf(`dashboard/${path}`);
        console.log(`Navigated to: ${path}`);
        console.log(`Current part: ${this.currentPart}, Current screen index: ${this.currentScreenIndex}`);
        break;
      }
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const currentScreens = this.screens[this.currentPart];
    console.log(`Key pressed: ${event.key}`);
    if (event.key === 'ArrowRight' && this.currentScreenIndex < currentScreens.length - 1) {
      console.log('Navigating to:', currentScreens[this.currentScreenIndex + 1]);
      this.router.navigate([`/${currentScreens[this.currentScreenIndex + 1]}`]);
    } else if (event.key === 'ArrowLeft' && this.currentScreenIndex > 0) {
      console.log('Navigating to:', currentScreens[this.currentScreenIndex - 1]);
      this.router.navigate([`/${currentScreens[this.currentScreenIndex - 1]}`]);
    }
  }

  ngOnInit(): void {
    console.log("elementRef sent!!!");
    this.buttonService.catchElementRef(this.elementRef);
  }

  title = 'changi-airport';
}
