import { Component, Input } from '@angular/core';

@Component({
  selector: 'Preheater',
  templateUrl: './preheater.component.html',
  styleUrls: ['./preheater.component.css']
})
export class PreheaterComponent {
  @Input() state:string;

 

  urlActive:string;

  urlInActive:string;

 

  constructor(){
    this.state = 'active';
    this.textValue = ""
    this.urlActive="/assets/bert-components/active-preheater.svg";

    this.urlInActive="/assets/bert-components/inactive-preheater.svg";

  }



  @Input() textValue: string;
  
}
