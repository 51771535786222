import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChillerComponent } from './chiller/chiller.component';
import { CoolingTowerComponent } from './cooling-tower/cooling-tower.component';
import { CondensorPumpComponent } from './condensor-pump/condensor-pump.component';
import { InputFieldComponent } from './input-field/input-field.component';
import { PrimaryPumpComponent } from './primary-pump/primary-pump.component';
import { SecondaryPumpComponent } from './secondary-pump/secondary-pump.component';
// sarvesh
import { CycloneSeparatorComponent, EvaporatorComponent, PackTowerComponent, PreheaterComponent, PumpComponent, SurfaceCondensorComponent, TankComponent, VapourLiquidSeparatorComponent } from './index';
//sneh and vijay
import { FuelComponent } from './fuel/fuel.component';
import { FuelDamperComponent } from './fuel-damper/fuel-damper.component';
import { BurnerComponent } from './burner/burner.component';
import { AirDamperComponent } from './air-damper/air-damper.component';
import { InsulatorComponent } from './insulator/insulator.component';
import { KilnComponent } from './kiln/kiln.component';
// bhuvan
import { CoilComponent } from './coil/coil.component';
import { DamperComponent } from './damper/damper.component';
import { FanComponent } from './fan/fan.component';
import { FilterFiveComponent } from './filter-five/filter-five.component';
import { FilterFourComponent } from './filter-four/filter-four.component';
import { FilterOneComponent } from './filter-one/filter-one.component';
import { FilterThreeComponent } from './filter-three/filter-three.component';
import { FilterTwoComponent } from './filter-two/filter-two.component';
import { ValveComponent } from './valve/valve.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    // Harpreet
    ChillerComponent,
    CoolingTowerComponent,
    InputFieldComponent,
    PrimaryPumpComponent,
    SecondaryPumpComponent,
    CondensorPumpComponent,
    // Sarvesh
    CycloneSeparatorComponent,
    EvaporatorComponent,
    PackTowerComponent,
    PreheaterComponent,
    PumpComponent,
    SurfaceCondensorComponent,
    TankComponent,
    VapourLiquidSeparatorComponent,
    // Sneh and Vijay
    FuelComponent,
    FuelDamperComponent,
    BurnerComponent,
    AirDamperComponent,
    InsulatorComponent,
    KilnComponent,
    // Bhuvan
    CoilComponent,
    DamperComponent,
    FilterFiveComponent,
    FilterFourComponent,
    FilterOneComponent,
    FilterThreeComponent,
    FilterTwoComponent,
    ValveComponent,
    FanComponent
  ],
  exports: [
    // Harpreet
    ChillerComponent,
    CoolingTowerComponent,
    InputFieldComponent,
    PrimaryPumpComponent,
    SecondaryPumpComponent,
    CondensorPumpComponent,
    // Sarvesh
    CycloneSeparatorComponent,
    EvaporatorComponent,
    PackTowerComponent,
    PreheaterComponent,
    PumpComponent,
    SurfaceCondensorComponent,
    TankComponent,
    VapourLiquidSeparatorComponent,
    // Sneh and Vijay
    FuelComponent,
    FuelDamperComponent,
    BurnerComponent,
    AirDamperComponent,
    InsulatorComponent,
    KilnComponent,
    // Bhuvan
    CoilComponent,
    DamperComponent,
    FilterFiveComponent,
    FilterFourComponent,
    FilterOneComponent,
    FilterThreeComponent,
    FilterTwoComponent,
    ValveComponent,
    FanComponent
  ],
})
export class BertComponentsModule {}
