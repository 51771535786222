import { Component, EventEmitter, Output } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';


@Component({
  selector: 'Main-side-bar',
  templateUrl: './main-side-bar.component.html',
  styleUrls: ['./main-side-bar.component.css'],
})
export class MainSideBarComponent {
  @Output() titleEmmiter: EventEmitter<string> = new EventEmitter<string>();

  constructor(public dialog: MatDialog, private location: Location) {}
  isToggled = false;

  selectedItem: any;
  items: any[] = [
    {
      title: 'Terminal T1',
      expanded: false,
      subComponents: [
        { title: 'T1 A Gate',route:'T1A1',disabled:false},
        { title: 'T1 B Gate',route:'T1B1',disabled:false },
        // { title: 'AHU C4A',route:'ahuc4a',disabled:false},
        // { title: 'AHU C4B',route:'ahuc4b',disabled:false },
      ],
    },
    // {
    //   title:"D Pier",
    //   expanded: false,
    //   subComponents: [
    //     { title: 'AHU D7A',route:'ahud7a',disabled:false},
    //     { title: 'AHU D7B',route:'./',disabled:true },
    //   ],
    // }

    // {
    //   title: 'Chiller Plant',
    //   route: 'chillerplant',
    //   expanded: false,
    //   subComponents: [

    //   ],
    // },

  ];

  toggleExpanded(item: any) {
    item.expanded = !item.expanded;
  }

  isSelectedDescription(item: any, description: any): boolean {
    return this.selectedItem === item && item.expanded;
  }

  toggleButton(event: any): void {
    this.isToggled = event.source.checked;
  }

  goback() {
    this.location.back();
  }

  showLogoutDialog() {
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

}
