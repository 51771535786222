import { Component, Input } from '@angular/core';

@Component({
  selector: 'Gate-hold-room-d-one',
  templateUrl: './gate-hold-room-d-one.component.html',
  styleUrls: ['./gate-hold-room-d-one.component.css']
})
export class GateHoldRoomDOneComponent {
  @Input()pmv!:number;

  constructor(){
    this.pmv=0;
  }
}
