import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-security-screening-area-map',
  templateUrl: './security-screening-area-map.component.html',
  styleUrls: ['./security-screening-area-map.component.css'],
})
export class SecurityScreeningAreaMapComponent {
  progress: boolean = false;
  disableOptimise: boolean = true;
  predict_input: any;
  predict_response: any;
  pmv_avg:number;
  xinput: any;


  //predict input params
  // inputs from form

  ZT2: number;
  RH2: number;
  Velocity2: number;

  ZT3: number;
  RH3: number;
  Velocity3: number;

  ZT4: number;
  RH4: number;
  Velocity4: number;

  ZT5: number;
  RH5: number;
  Velocity5: number;

  ZT6: number;
  RH6: number;
  Velocity6: number;

  ZT7: number;
  RH7: number;
  Velocity7: number;

  ZT8: number;
  RH8: number;
  Velocity8: number;

  // output fields predict
  pmv_out2: number;
  pmv_out3: number;
  pmv_out4: number;
  pmv_out5: number;
  pmv_out6: number;
  pmv_out7: number;
  pmv_out8: number;

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    
    // 'Zone Temp D7-L2-02': Number(this.ZT2),
    // 'RH D7-L2-02': Number(this.RH2),
    // 'Velocity D7-L2-02': Number(this.Velocity2),

    // 'Zone Temp D7-L2-03': Number(this.ZT3),
    // 'RH D7-L2-03': Number(this.RH3),
    // 'Velocity D7-L2-03': Number(this.Velocity3),

    // 'Zone Temp D7-L2-04':Number(this.ZT4),
    // 'RH D7-L2-04': Number(this.RH4),
    // 'Velocity D7-L2-04': Number(this.Velocity4),

    // 'Zone Temp D7-L2-05':Number(this.ZT5),
    // 'RH D7-L2-05': Number(this.RH5),
    // 'Velocity D7-L2-05': Number(this.Velocity5),

    // 'Zone Temp D7-L2-06':Number(this.ZT6),
    // 'RH D7-L2-06': Number(this.RH6),
    // 'Velocity D7-L2-06':Number( this.Velocity6),

    // 'Zone Temp D7-L2-07':Number(this.ZT7),
    // 'RH D7-L2-07': Number(this.RH8),
    // 'Velocity D7-L2-07': Number(this.Velocity7),

    // 'Zone Temp D7-L2-08':Number(this.ZT8),
    // 'RH D7-L2-08': Number(this.RH2),
    // 'Velocity D7-L2-08': Number(this.Velocity8),

    // 'model_no.': 2,

    if (localStorage.getItem('security-map')){
      this.xinput=localStorage.getItem('security-map');
      this.xinput=JSON.parse(this.xinput);

      this.ZT2 = Number( this.xinput['Zone Temp D7-L2-02']);
      this.RH2 = Number( this.xinput['RH D7-L2-02']);
      this.Velocity2 = Number( this.xinput['Velocity D7-L2-02']);
  
      this.ZT3 = Number( this.xinput['Zone Temp D7-L2-03']);
      this.RH3 = Number( this.xinput['RH D7-L2-03']);
      this.Velocity3 = Number( this.xinput['Velocity D7-L2-03']);
  
      this.ZT4 = Number( this.xinput['Zone Temp D7-L2-04']);
      this.RH4 = Number( this.xinput['RH D7-L2-04']);
      this.Velocity4 = Number( this.xinput['Velocity D7-L2-04']);
  
      this.ZT5 = Number( this.xinput['Zone Temp D7-L2-05']);
      this.RH5 = Number( this.xinput['RH D7-L2-05']);
      this.Velocity5 = Number( this.xinput['Velocity D7-L2-05']);
  
      this.ZT6 = Number( this.xinput['Zone Temp D7-L2-06']);
      this.RH6 = Number( this.xinput['RH D7-L2-06']);
      this.Velocity6 = Number( this.xinput['Velocity D7-L2-06']);
  
      this.ZT7 = Number( this.xinput['Zone Temp D7-L2-07']);
      this.RH7 = Number( this.xinput['RH D7-L2-07']);
      this.Velocity7 = Number( this.xinput['Velocity D7-L2-07']);
  
      this.ZT8 = Number( this.xinput['Zone Temp D7-L2-08']);
      this.RH8 = Number( this.xinput['RH D7-L2-08']);
      this.Velocity8 = Number( this.xinput['Velocity D7-L2-08']);

    }else {
    this.ZT2 = 0;
    this.Velocity2 = 0;
    this.RH2 = 0;

    this.ZT3 = 0;
    this.Velocity3 = 0;
    this.RH3 = 0;

    this.ZT4 = 0;
    this.Velocity4 = 0;
    this.RH4 = 0;

    this.ZT5 = 0;
    this.Velocity5 = 0;
    this.RH5 = 0;

    this.ZT6 = 0;
    this.Velocity6 = 0;
    this.RH6 = 0;

    this.ZT7 = 0;
    this.Velocity7 = 0;
    this.RH7 = 0;

    this.ZT8 = 0;
    this.Velocity8 = 0;
    this.RH8 = 0;
    }

    // output_field
    this.pmv_out2 = 0;
    this.pmv_out3 = 0;
    this.pmv_out4 = 0;
    this.pmv_out5 = 0;
    this.pmv_out6 = 0;
    this.pmv_out7 = 0;
    this.pmv_out8 = 0;

    if(localStorage.getItem('PMV D7-L2-2')){
      this.pmv_out2= Number(localStorage.getItem('PMV D7-L2-2'));
     }else{
       this.pmv_out2 = 0;
     }
 
     if(localStorage.getItem('PMV D7-L2-3')){
      this.pmv_out3= Number(localStorage.getItem('PMV D7-L2-3'));
     }else{
       this.pmv_out3 = 0;
     }
 
     if(localStorage.getItem('PMV D7-L2-4')){
      this.pmv_out4= Number(localStorage.getItem('PMV D7-L2-4'));
     }else{
       this.pmv_out4 = 0;
     }
 
     if(localStorage.getItem('PMV D7-L2-5')){
      this.pmv_out5= Number(localStorage.getItem('PMV D7-L2-5'));
     }else{
       this.pmv_out5 = 0;
     }
 
     if(localStorage.getItem('PMV D7-L2-6')){
      this.pmv_out6= Number(localStorage.getItem('PMV D7-L2-6'));
     }else{
       this.pmv_out6 = 0;
     }
 
     if(localStorage.getItem('PMV D7-L2-7')){
      this.pmv_out7= Number(localStorage.getItem('PMV D7-L2-7'));
     }else{
       this.pmv_out7 = 0;
     }
 
     if(localStorage.getItem('PMV D7-L2-8')){
      this.pmv_out8= Number(localStorage.getItem('PMV D7-L2-8'));
     }else{
       this.pmv_out8 = 0;
     }

     if(localStorage.getItem('PMV D7-L2-2') && localStorage.getItem('PMV D7-L2-3') && localStorage.getItem('PMV D7-L2-4') && localStorage.getItem('PMV D7-L2-5') && localStorage.getItem('PMV D7-L2-6') && localStorage.getItem('PMV D7-L2-7') && localStorage.getItem('PMV D7-L2-8')){
      this.pmv_avg=(this.pmv_out2+this.pmv_out3+this.pmv_out4+this.pmv_out5+this.pmv_out6+this.pmv_out7+this.pmv_out8)/7;

     }else{
       this.pmv_avg = 0;
     }

  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if(this.ZT2<22.3 || this.ZT2>24.8){
      this.openSnackBar('ZT2 should be in range [22.3,24.8]');
      return false;
    }else if(this.Velocity2<0.15 || this.Velocity2>5.52){
      this.openSnackBar('Velocity2 should be in range [0.15,5.52]');
      return false;
    }else if(this.RH2<44.19 || this.RH2>53.58){
      this.openSnackBar('RH2 should be in range [44.19,53.58]');
      return false;
    }else if(this.ZT3<22.3 || this.ZT3>24.8){
      this.openSnackBar('ZT3 should be in range [22.3,24.8]');
      return false;
    }else if(this.Velocity3<0.07 || this.Velocity3>0.39){
      this.openSnackBar('Velocity3 should be in range [0.07,0.39]');
      return false;
    }else if(this.RH3<44.19 || this.RH3>53.58){
      this.openSnackBar('RH3 should be in range [44.19,53.58]');
      return false;
    }else if(this.ZT4<22.3 || this.ZT4>24.8){
      this.openSnackBar('ZT4 should be in range [22.3,24.8]');
      return false;
    }else if(this.Velocity4<0.2 || this.Velocity4>2.74){
      this.openSnackBar('Velocity4 should be in range [0.2,2.74]');
      return false;
    }else if(this.RH4<44.19 || this.RH4>53.58){
      this.openSnackBar('RH4 should be in range [44.19,53.58]');
      return false;
    }else if(this.ZT5<22.3 || this.ZT5>24.8){
      this.openSnackBar('ZT5 should be in range [22.3,24.8]');
      return false;
    }else if(this.Velocity5<0.03 || this.Velocity5>5.59){
      this.openSnackBar('Velocity5 should be in range [0.03,5.59]');
      return false;
    }else if(this.RH5<44.19 || this.RH5>53.58){
      this.openSnackBar('RH5 should be in range [44.19,53.58]');
      return false;
    }else if(this.ZT6<22.5 || this.ZT6>24.4){
      this.openSnackBar('ZT6 should be in range [22.5,24.4]');
      return false;
    }else if(this.Velocity6<2.75 || this.Velocity6>5.18){
      this.openSnackBar('Velocity6 should be in range [2.75,5.18]');
      return false;
    }else if(this.RH6<44.72 || this.RH6>53.58){
      this.openSnackBar('RH6 should be in range [44.72,53.58]');
      return false;
    }else if(this.ZT7<22.5 || this.ZT7>24.4){
      this.openSnackBar('ZT7 should be in range [22.5,24.4]');
      return false;
    }else if(this.Velocity7<0.2 || this.Velocity7>4.87){
      this.openSnackBar('Velocity7 should be in range [0.2,4.87]');
      return false;
    }else if(this.RH7<44.72 || this.RH7>53.58){
      this.openSnackBar('RH7 should be in range [44.72,53.58]');
      return false;
    }else if(this.ZT8<22.5 || this.ZT8>24.4){
      this.openSnackBar('ZT8 should be in range [22.5,24.4]');
      return false;
    }else if(this.Velocity8<0.14 || this.Velocity8>2.29){
      this.openSnackBar('Velocity8 should be in range [0.14,2.29]');
      return false;
    }else if(this.RH8<44.72 || this.RH8>53.58){
      this.openSnackBar('RH8 should be in range [44.72,53.58]');
      return false;
    }

    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  // this fuction is use to predict PMV
  async predictData() {
    if (this.validate()) {
      this.progress = true;
      console.log('predict input', this.predict_input);

      this.predict_input= {
        'Zone Temp D7-L2-02': Number(this.ZT2),
        'RH D7-L2-02': Number(this.RH2),
        'Velocity D7-L2-02': Number(this.Velocity2),

        'Zone Temp D7-L2-03': Number(this.ZT3),
        'RH D7-L2-03': Number(this.RH3),
        'Velocity D7-L2-03': Number(this.Velocity3),

        'Zone Temp D7-L2-04':Number(this.ZT4),
        'RH D7-L2-04': Number(this.RH4),
        'Velocity D7-L2-04': Number(this.Velocity4),

        'Zone Temp D7-L2-05':Number(this.ZT5),
        'RH D7-L2-05': Number(this.RH5),
        'Velocity D7-L2-05': Number(this.Velocity5),

        'Zone Temp D7-L2-06':Number(this.ZT6),
        'RH D7-L2-06': Number(this.RH6),
        'Velocity D7-L2-06':Number( this.Velocity6),

        'Zone Temp D7-L2-07':Number(this.ZT7),
        'RH D7-L2-07': Number(this.RH8),
        'Velocity D7-L2-07': Number(this.Velocity7),

        'Zone Temp D7-L2-08':Number(this.ZT8),
        'RH D7-L2-08': Number(this.RH2),
        'Velocity D7-L2-08': Number(this.Velocity8),

        'model_no.': 2,
      };

      try {
        const response = await this.http
          .post<any>(
            'https://bpsliveatchangiairport.bertlabs.com/predictsec2/',
            this.predict_input,
            this.httpOptions
          )
          .toPromise();

        this.predict_response = response;
        console.log('predict outout', this.predict_response);

        this.openSnackBar('Predicted Succesfully');


        this.pmv_out2 =  this.predict_response['PMV D7-L2-02'];
        this.pmv_out3 =  this.predict_response['PMV D7-L2-03'];
        this.pmv_out4 =  this.predict_response['PMV D7-L2-04'];
        this.pmv_out5 =  this.predict_response['PMV D7-L2-05'];
        this.pmv_out6 =  this.predict_response['PMV D7-L2-06'];
        this.pmv_out7 =  this.predict_response['PMV D7-L2-07'];
        this.pmv_out8 =  this.predict_response['PMV D7-L2-08'];
       
        
        this.pmv_avg=(this.pmv_out2+this.pmv_out3+this.pmv_out4+this.pmv_out5+this.pmv_out6+this.pmv_out7+this.pmv_out8)/7;

        this.progress = false;
        this.disableOptimise = false;
        localStorage.setItem('model_two', JSON.stringify(this.pmv_avg));
        localStorage.setItem('PMV D7-L2-2',JSON.stringify(this.pmv_out2));
        localStorage.setItem('PMV D7-L2-3',JSON.stringify(this.pmv_out3));
        localStorage.setItem('PMV D7-L2-4',JSON.stringify(this.pmv_out4));
        localStorage.setItem('PMV D7-L2-5',JSON.stringify(this.pmv_out5));
        localStorage.setItem('PMV D7-L2-6',JSON.stringify(this.pmv_out6));
        localStorage.setItem('PMV D7-L2-7',JSON.stringify(this.pmv_out7));
        localStorage.setItem('PMV D7-L2-8',JSON.stringify(this.pmv_out8));

        localStorage.setItem('security-map', JSON.stringify(this.predict_input));
        
      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }

 
}
