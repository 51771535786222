import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-c-pier-security-screening-room-two-map',
  templateUrl: './c-pier-security-screening-room-two-map.component.html',
  styleUrls: ['./c-pier-security-screening-room-two-map.component.css']
})
// export class CPierSecurityScreeningRoomTwoMapComponent {
//     progress:boolean=false;
// }
export class CPierSecurityScreeningRoomTwoMapComponent {
    
  progress: boolean = false;
  disableOptimise: boolean = true;
  predict_input: any;
  predict_response: any;
  security_input: any;

  //predict input params
  // inputs from form

  ZT13: number;
  RH13:number;
  Velocity13: number;

  ZT14: number;
  RH14:number;
  Velocity14: number;

  ZT15: number;
  RH15:number;
  Velocity15: number;

  // output fields predict
  pmv_out13: number;
  pmv_out14: number;
  pmv_out15: number;
  pmv_avg: number;

 
  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {

    if(localStorage.getItem('security_input_2')){
      this.security_input=localStorage.getItem('security_input_2');
      this.security_input=JSON.parse(this.security_input);
      this.ZT13 = this.security_input['Zone Temp C4-L2-13'];
      this.RH13 = this.security_input['RH-L2-13'];
      this.Velocity13 = this.security_input['Velocity - C4 - L2 - 13'];
      this.ZT14 = this.security_input['Zone Temp C4-L2-14'];
      this.RH14 = this.security_input['RH-L2-14'];
      this.Velocity14 = this.security_input['Velocity - C4 - L2 - 14'];
      this.ZT15 = this.security_input['Zone Temp C4-L2-15'];
      this.RH15 = this.security_input['RH-L2-15'];
      this.Velocity15 = this.security_input['Velocity - C4 - L2 - 15'];
     }else{
      this.ZT13 = 0;
      this.RH13 = 0;
      this.Velocity13 = 0;
      this.ZT14 = 0;
      this.RH14 = 0;
      this.Velocity14 = 0;
      this.ZT15 = 0;
      this.RH15 = 0;
      this.Velocity15 = 0;
     }

    // this.ZT13=22;
    // this.RH13=50;
    // this.Velocity13=0.5;

    // this.ZT14=25;
    // this.RH14=50;
    // this.Velocity14=0.5;

    // this.ZT15=25;
    // this.RH15=50;
    // this.Velocity15=0.5;

    // this.ZT13=0;
    // this.RH13=0;
    // this.Velocity13=0;

    // this.ZT14=0;
    // this.RH14=0;
    // this.Velocity14=0;

    // this.ZT15=0;
    // this.RH15=0;
    // this.Velocity15=0;



    if(localStorage.getItem('PMV- C4 - L2 - 13_out') || localStorage.getItem('PMV- C4 - L2 - 13')){
     this.pmv_out13= Number(localStorage.getItem('PMV- C4 - L2 - 13_out') || localStorage.getItem('PMV- C4 - L2 - 13'));
    }else{
      this.pmv_out13 = 0;
    }

    
    if(localStorage.getItem('PMV- C4 - L2 - 14_out') || localStorage.getItem('PMV- C4 - L2 - 14')){
      this.pmv_out14= Number(localStorage.getItem('PMV- C4 - L2 - 14_out') || localStorage.getItem('PMV- C4 - L2 - 14'));
     }else{
       this.pmv_out14 = 0;
     }

     
    if(localStorage.getItem('PMV- C4 - L2 - 15_out') || localStorage.getItem('PMV- C4 - L2 - 15')){
      this.pmv_out15= Number(localStorage.getItem('PMV- C4 - L2 - 15_out') || localStorage.getItem('PMV- C4 - L2 - 15'));
     }else{
       this.pmv_out15 = 0;
    }
    
    if(localStorage.getItem('PMV- C4 - L2 - 13') && localStorage.getItem('PMV- C4 - L2 - 14') &&localStorage.getItem('PMV- C4 - L2 - 15')){
      this.pmv_avg = (this.pmv_out13+this.pmv_out14+this.pmv_out15)/3;
    }else{
      this.pmv_avg = 0;
    } 
   
    


  }


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if(this.ZT13<21.8 || this.ZT13>24.7){
      this.openSnackBar('ZT13 should be in range [21.8,24.7]');
      return false;
    }else if(this.Velocity13<0.43 || this.Velocity13>3.11){
      this.openSnackBar('Velocity13 should be in range [0.43,3.11]');
      return false;
    }else if(this.RH13<43.14 || this.RH13>54.55){
      this.openSnackBar('RH13 should be in range [43.14,54.55]');
      return false;
    }else  if(this.ZT14<22.7 || this.ZT14>27){
      this.openSnackBar('ZT14 should be in range [22.7,27]');
      return false;
    }else if(this.Velocity14<0.41 || this.Velocity14>3.11){
      this.openSnackBar('Velocity14 should be in range [0.41,3.11]');
      return false;
    }else if(this.RH14<39.34 || this.RH14>54.22){
      this.openSnackBar('RH14 should be in range [39.34,54.22]');
      return false;
    }else  if(this.ZT15<22.3 || this.ZT15>27.5){
      this.openSnackBar('ZT15 should be in range [22.3,27.5]');
      return false;
    }else if(this.Velocity15<0.43 || this.Velocity15>3.11){
      this.openSnackBar('Velocity15 should be in range [0.43,3.11]');
      return false;
    }else if(this.RH15<36.91 || this.RH15>51.68){
      this.openSnackBar('RH15 should be in range [36.91,51.68]');
      return false;
    }

    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }


  // this fuction is use to predict PMV
  async predictData(){
    
    if (this.validate()) {
      this.progress = true;
      console.log('predict input', this.predict_input);

      this.predict_input= {
        "Zone Temp C4-L2-13":Number(this.ZT13),
        "RH-L2-13":Number(this.RH13),
        "Velocity - C4 - L2 - 13":Number(this.Velocity13),
        "Zone Temp C4-L2-14":Number(this.ZT14),
        "RH-L2-14":Number(this.RH14),
        "Velocity - C4 - L2 - 14":Number(this.Velocity14),
        "Zone Temp C4-L2-15":Number(this.ZT15),
        "RH-L2-15":Number(this.RH15),
        "Velocity - C4 - L2 - 15":Number(this.Velocity15),
        "model_no.":3
      };

      localStorage.setItem('Zone Temp C4-L2-13', String(this.ZT13));
      localStorage.setItem('Zone Temp C4-L2-14', String(this.ZT14));
      localStorage.setItem('Zone Temp C4-L2-15', String(this.ZT15));

      localStorage.setItem('Velocity - C4 - L2 - 13', String(this.Velocity13));
      localStorage.setItem('Velocity - C4 - L2 - 14', String(this.Velocity14));
      localStorage.setItem('Velocity - C4 - L2 - 15', String(this.Velocity15));

      localStorage.setItem('RH-L2-13', String(this.RH13));
      localStorage.setItem('RH-L2-14', String(this.RH14));
      localStorage.setItem('RH-L2-15', String(this.RH15));

      localStorage.setItem('security_input_2', JSON.stringify(this.predict_input));

      try {
        const response = await this.http
          .post<any>(
            'https://bpsliveatchangiairport1.bertlabs.com/sec_3',
            // 'https://bpsliveatchangiairport.bertlabs.com/predictsec5/',
            this.predict_input,
            this.httpOptions
          )
          .toPromise();

        this.predict_response = response;
        console.log('predict outout', this.predict_response);

        localStorage.removeItem('PMV- C4 - L2 - 01_out');
        localStorage.removeItem('PMV- C4 - L2 - 02_out');
        localStorage.removeItem('PMV- C4 - L2 - 03_out');
        localStorage.removeItem('PMV- C4 - L2 - 04_out');
        localStorage.removeItem('PMV- C4 - L2 - 05_out');
        localStorage.removeItem('PMV- C4 - L2 - 06_out');
        localStorage.removeItem('PMV- C4 - L2 - 07_out');
        localStorage.removeItem('PMV- C4 - L2 - 08_out');
        localStorage.removeItem('PMV- C4 - L2 - 09_out');
        localStorage.removeItem('PMV- C4 - L2 - 10_out');
        localStorage.removeItem('PMV- C4 - L2 - 11_out');
        localStorage.removeItem('PMV- C4 - L2 - 12_out');
        localStorage.removeItem('PMV- C4 - L2 - 13_out');
        localStorage.removeItem('PMV- C4 - L2 - 14_out');
        localStorage.removeItem('PMV- C4 - L2 - 15_out');
        localStorage.removeItem('PMV- C4 - L2 - 16_out');
        localStorage.removeItem('PMV- C4 - L2 - 17_out');
        localStorage.removeItem('PMV- C4 - L2 - 18_out');
        localStorage.removeItem('PMV- C4 - L2 - 19_out');
        localStorage.removeItem('PMV- C4 - L2 - 20_out');
        localStorage.removeItem('PMV- C4 - L2 - 21_out');
        localStorage.removeItem('PMV- C4 - L2 - 22_out');
        localStorage.removeItem('PMV- C4 - L2 - 23_out');
        localStorage.removeItem('PMV- C4 - L2 - 24_out');
        localStorage.removeItem('PMV- C4 - L2 - 25_out');
        localStorage.removeItem('PMV- C4 - L2 - 26_out');
        localStorage.removeItem('PMV- C4 - L2 - 27_out');
        localStorage.removeItem('Fan Power - C4A_out');
        localStorage.removeItem('Fan Power - C4B_out');
        localStorage.removeItem('VSD Freq - C4A_out');
        localStorage.removeItem('VSD Freq - C4B_out');
        localStorage.removeItem('Valve Feedback - C4A_out');
        localStorage.removeItem('Valve Feedback - C4B_out');

        this.openSnackBar('Predicted Succesfully');

        this.pmv_out13 = this.predict_response['PMV- C4 - L2 - 13'];
        this.pmv_out14 = this.predict_response['PMV- C4 - L2 - 14'];
        this.pmv_out15 = this.predict_response['PMV- C4 - L2 - 15'];


        this.pmv_avg = (this.pmv_out13+this.pmv_out14+this.pmv_out15)/3;

        this.progress = false;
        this.disableOptimise = false;
        localStorage.setItem('model_three', JSON.stringify(this.pmv_avg));
        localStorage.setItem('PMV- C4 - L2 - 13',JSON.stringify(this.pmv_out13));
        localStorage.setItem('PMV- C4 - L2 - 14',JSON.stringify(this.pmv_out14));
        localStorage.setItem('PMV- C4 - L2 - 15',JSON.stringify(this.pmv_out15));
        
      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }

}
