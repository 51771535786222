import { ViewportScroller } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'Chiller-plant',
  templateUrl: './chiller-plant.component.html',
  styleUrls: ['./chiller-plant.component.css'],
})
export class ChillerPlantComponent {
  chillerOneStatus: string;
  fieldstatus: boolean;
  isToggled = false;
  progress: boolean = false;

  predict_input:any={};
  predict_response: any = {};
 
  
  optimise_input:any={};
  optimise_response: any = {};
  disableOptimise: boolean = true;

  //prefict input-fields initialisation
  ahu_no: string = 'chillar';
  VFD_Feedback_Pr_Pump_1: number = 50;
  VFD_Feedback_Pr_Pump_2: number = 50;
  VFD_Feedback_Zone_A_Sec_Pump_1: number = 44;
  VFD_Feedback_Zone_A_Sec_Pump_2: number = 50;
  VFD_Feedback_Zone_B_Sec_Pump_1: number = 80;
  VFD_Feedback_Zone_C_Sec_Pump_1: number = 80;
  Common_Header_Supply_temp_Before_Decoupler_Line: number = 7;
  Common_Header_Supply_temp_After_Decoupler_Line: number = 7.5;
  Common_Header_Return_temp_Before_Decoupler_Line: number = 12.5;
  Common_Header_Return_temp_After_Decoupler_Line: number = 12;
  Diff_Pressure_Sec_Pump_Zone_A: number = 1000;
  Diff_Pressure_Sec_Pump_Zone_B: number = 580;
  Diff_Pressure_Sec_Pump_Zone_C: number = 800;
  Chilled_Water_Set_Point: number = 7;
  Ambient_Temp: number = 32;
  Supply_temp_After_Sec_Pump_Zone_A: number = 8;
  Supply_temp_After_Sec_Pump_Zone_B: number = 8;
  Supply_temp_After_Sec_Pump_Zone_C: number = 8;
  VFD_Feedback_Pr_Pump_3: number = 0;
  VFD_Feedback_Pr_Pump_4: number = 0;
  VFD_Feedback_Zone_A_Sec_Pump_3: number = 0;
  VFD_Feedback_Zone_B_Sec_Pump_2: number = 0;
  VFD_Feedback_Zone_B_Sec_Pump_3: number = 0;
  VFD_Feedback_Zone_C_Sec_Pump_2: number = 0;
  VFD_Feedback_Zone_C_Sec_Pump_3: number = 0;
  VFD_Feedback_CP_Pump_1: number = 0;
  VFD_Feedback_CP_Pump_2: number = 0;
  VFD_Feedback_CP_Pump_3: number = 0;
  VFD_Feedback_CP_Pump_4: number = 0;
  CT1_VFD_Feedback_F1: number = 0;
  CT1_VFD_Feedback_F2: number = 0;
  CT2_VFD_Feedback_F1: number = 0;
  CT2_VFD_Feedback_F2: number = 0;
  CT3_VFD_Feedback_F1: number = 0;
  CT3_VFD_Feedback_F2: number = 0;
  CT4_VFD_Feedback_F1: number = 0;
  CT4_VFD_Feedback_F2: number = 0;

  //predict output fields initialisation
  CT1_VFD_Feedback_F1_out: number = 0;
  CT1_VFD_Feedback_F2_out: number = 0;
  CT2_VFD_Feedback_F1_out: number = 0;
  CT2_VFD_Feedback_F2_out: number = 0;
  CT3_VFD_Feedback_F1_out: number = 0;
  CT3_VFD_Feedback_F2_out: number = 0;
  CT4_VFD_Feedback_F1_out: number = 0;
  CT4_VFD_Feedback_F2_out: number = 0;
  Chilled_water_Power_out: number = 0;
  Common_Header_Return_temp_After_Decoupler_Line_out: number = 0;
  Common_Header_Return_temp_Before_Decoupler_Line_out: number = 0;
  Common_Header_Supply_temp_After_Decoupler_Line_out: number = 0;
  Common_Header_Supply_temp_Before_Decoupler_Line_out: number = 0;
  Diff_Pressure_Sec_Pump_Zone_A_out: number = 0;
  Diff_Pressure_Sec_Pump_Zone_B_out: number = 0;
  Diff_Pressure_Sec_Pump_Zone_C_out: number = 0;
  Supply_temp_After_Sec_Pump_Zone_A_out: number = 0;
  Supply_temp_After_Sec_Pump_Zone_B_out: number = 0;
  Supply_temp_After_Sec_Pump_Zone_C_out: number = 0;
  Total_Sec_Pump_Power_out: number = 0;
  VFD_Feedback_CP_Pump_1_out: number = 0;
  VFD_Feedback_CP_Pump_2_out: number = 0;
  VFD_Feedback_CP_Pump_3_out: number = 0;
  VFD_Feedback_CP_Pump_4_out: number = 0;
  VFD_Feedback_Pr_Pump_1_out: number = 0;
  VFD_Feedback_Pr_Pump_2_out: number = 0;
  VFD_Feedback_Pr_Pump_3_out: number = 0;
  VFD_Feedback_Pr_Pump_4_out: number = 0;
  VFD_Feedback_Zone_A_sec_Pump_1_out: number = 0;
  VFD_Feedback_Zone_A_Sec_Pump_2_out: number = 0;
  VFD_Feedback_Zone_A_Sec_Pump_3_out: number = 0;
  VFD_Feedback_Zone_B_Sec_Pump_2_out: number = 0;
  VFD_Feedback_Zone_B_Sec_Pump_3_out: number = 0;
  VFD_Feedback_Zone_C_Sec_Pump_2_out: number = 0;
  VFD_Feedback_Zone_C_Sec_Pump_3_out: number = 0;
  VFD_Feedback_Zone_C_Sec_Pump_1_out:number=0;
  VFD_Feedback_Zone_B_Sec_Pump_1_out:number=0;


  // kpi variables
  chiller_power_savings:number=0;
  secondary_pump_power:number=0;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
    this.chillerOneStatus = 'active';
    this.fieldstatus = false;

  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    // if (this.RAT < 22 || this.RAT > 25) {
    //   this.openSnackBar('RAT should be in range [22,25]');
    //   return false;
    // } else if (this.CHWVF < 10 || this.CHWVF > 100) {
    //   this.openSnackBar('CHWF should be in range [10,100]');
    //   return false;
    // } else if (this.RAH < 30 || this.RAH > 83) {
    //   this.openSnackBar('RAH should be in range [30,83]');
    //   return false;
    // } else if (this.TD < 0.5 || this.TD > 8) {
    //   this.openSnackBar('TD should be in range [0.5,8]');
    //   return false;
    // } else if (this.VFDF < 55 || this.VFDF > 100) {
    //   this.openSnackBar('FAN SPEED should be in range [55,100]');
    //   return false;
    // } else if (this.FAF < 104 || this.FAF > 890) {
    //   this.openSnackBar('FAF should be in range [104,890]');
    //   return false;
    // }else if(this.SAT < 12 || this.SAT > 16){
    //   this.openSnackBar('SAT should be in range [12,16]');
    //   return false;
    // }

    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  async predictData() {
    if (this.validate()) {
      this.progress = true;

      this.predict_input = {
        'ahu_no.': 'chillar',
        'VFD Feedback Pr Pump 1': Number(this.VFD_Feedback_CP_Pump_1),
        'VFD Feedback Pr Pump 2': Number(this.VFD_Feedback_CP_Pump_2),
        'VFD Feedback Zone A Sec Pump 1': Number(this.VFD_Feedback_Zone_A_Sec_Pump_1),
        'VFD Feedback Zone A Sec Pump 2': Number(this.VFD_Feedback_Zone_A_Sec_Pump_2),
        'VFD Feedback Zone B Sec Pump 1': Number(this.VFD_Feedback_Zone_B_Sec_Pump_1),
        'VFD Feedback Zone C Sec Pump 1': Number(this.VFD_Feedback_Zone_C_Sec_Pump_1),
        'Common Header Supply temp Before Decoupler Line': Number(this.Common_Header_Supply_temp_Before_Decoupler_Line),
        'Common Header Supply temp After Decoupler Line': Number(this.Common_Header_Supply_temp_After_Decoupler_Line),
        'Common Header Return temp Before Decoupler Line': Number(this.Common_Header_Return_temp_Before_Decoupler_Line),
        'Common Header Return temp After Decoupler Line': Number(this.Common_Header_Return_temp_After_Decoupler_Line),
        'Diff Pressure Sec Pump Zone A': Number(this.Diff_Pressure_Sec_Pump_Zone_A),
        'Diff Pressure Sec Pump Zone B': Number(this.Diff_Pressure_Sec_Pump_Zone_B),
        'Diff Pressure Sec Pump Zone C': Number(this.Diff_Pressure_Sec_Pump_Zone_C),
        'Chilled Water Set Point': Number(this.Chilled_Water_Set_Point),
        'Ambient Temp': Number(this.Ambient_Temp),
        'Supply temp After Sec Pump Zone A': Number(this.Supply_temp_After_Sec_Pump_Zone_A),
        'Supply temp After Sec Pump Zone B': Number(this.Supply_temp_After_Sec_Pump_Zone_B),
        'Supply temp After Sec Pump Zone C': Number(this.Supply_temp_After_Sec_Pump_Zone_C),
        'VFD Feedback Pr Pump 3': Number(this.VFD_Feedback_Pr_Pump_3),
        'VFD Feedback Pr Pump 4': Number(this.VFD_Feedback_Pr_Pump_4),
        'VFD Feedback Zone A Sec Pump 3': Number(this.VFD_Feedback_Zone_A_Sec_Pump_3),
        'VFD Feedback Zone B Sec Pump 2': Number(this.VFD_Feedback_Zone_B_Sec_Pump_2),
        'VFD Feedback Zone B Sec Pump 3': Number(this.VFD_Feedback_Zone_B_Sec_Pump_3),
        'VFD Feedback Zone C Sec Pump 2': Number(this.VFD_Feedback_Zone_C_Sec_Pump_2),
        'VFD Feedback Zone C Sec Pump 3': Number(this.VFD_Feedback_Zone_C_Sec_Pump_3),
        'VFD Feedback CP Pump 1': Number(this.VFD_Feedback_CP_Pump_1),
        'VFD Feedback CP Pump 2': Number(this.VFD_Feedback_CP_Pump_2),
        'VFD Feedback CP Pump 3': Number(this.VFD_Feedback_CP_Pump_3),
        'VFD Feedback CP Pump 4': Number(this.VFD_Feedback_CP_Pump_4),
        'CT1 VFD Feedback F1': Number(this.CT1_VFD_Feedback_F1),
        'CT1 VFD Feedback F2': Number(this.CT1_VFD_Feedback_F2),
        'CT2 VFD Feedback F1': Number(this.CT2_VFD_Feedback_F1),
        'CT2 VFD Feedback F2': Number(this.CT2_VFD_Feedback_F2),
        'CT3 VFD Feedback F1': Number(this.CT3_VFD_Feedback_F1),
        'CT3 VFD Feedback F2': Number(this.CT3_VFD_Feedback_F2),
        'CT4 VFD Feedback F1': Number(this.CT4_VFD_Feedback_F1),
        'CT4 VFD Feedback F2': Number(this.CT4_VFD_Feedback_F2),
      };
      // 38

      // 38
      // this.predict_input = {
      //   "VFD Feedback Pr Pump 1": 50,
      //   "VFD Feedback Pr Pump 2": 50,
      //   "VFD Feedback Zone A Sec Pump 1": 44,
      //   "VFD Feedback Zone A Sec Pump 2": 50,
      //   "VFD Feedback Zone B Sec Pump 1": 80,
      //   "VFD Feedback Zone C Sec Pump 1": 80,
      //   "Common Header Supply temp Before Decoupler Line": 7,
      //   "Common Header Supply temp After Decoupler Line": 7.5,
      //   "Common Header Return temp Before Decoupler Line": 12.5,
      //   "Common Header Return temp After Decoupler Line": 12,
      //   "Diff Pressure Sec Pump Zone A": 1000,
      //   "Diff Pressure Sec Pump Zone B": 580,
      //   "Diff Pressure Sec Pump Zone C": 800,
      //   "Chilled Water Set Point": 7,
      //   "Ambient Temp": 32,
      //   "Supply temp After Sec Pump Zone A": 8,
      //   "Supply temp After Sec Pump Zone B": 8,
      //   "Supply temp After Sec Pump Zone C": 8,
      //   "VFD Feedback Pr Pump 3": 0,
      //   "VFD Feedback Pr Pump 4": 0,
      //   "VFD Feedback Zone A Sec Pump 3": 0,
      //   "VFD Feedback Zone B Sec Pump 2": 0,
      //   "VFD Feedback Zone B Sec Pump 3": 0,
      //   "VFD Feedback Zone C Sec Pump 2": 0,
      //   "VFD Feedback Zone C Sec Pump 3": 0,
      //   "VFD Feedback CP Pump 1": 0,
      //   "VFD Feedback CP Pump 2": 0,
      //   "VFD Feedback CP Pump 3": 0,
      //   "VFD Feedback CP Pump 4": 0,
      //   "CT1 VFD Feedback F1": 0,
      //   "CT1 VFD Feedback F2": 0,
      //   "CT2 VFD Feedback F1": 0,
      //   "CT2 VFD Feedback F2": 0,
      //   "CT3 VFD Feedback F1": 0,
      //   "CT3 VFD Feedback F2": 0,
      //   "CT4 VFD Feedback F1": 0,
      //   "CT4 VFD Feedback F2": 0,
      //   "ahu_no.": "chillar"
      // };

      console.log("inputchillerpredict",this.predict_input);

      try {
        const response = await this.http
          .post<any>(
            'https://bpsliveatairports.bertlabs.com/chillerpredict',
            this.predict_input,
            this.httpOptions
          )
          .toPromise();

        this.predict_response = response;

        console.log(this.predict_response);

        this.CT1_VFD_Feedback_F1_out =
          this.predict_response['CT1 VFD Feedback F1'];
        this.CT1_VFD_Feedback_F2_out =
          this.predict_response['CT1 VFD Feedback F2'];
        this.CT2_VFD_Feedback_F1_out =
          this.predict_response['CT2 VFD Feedback F1'];
        this.CT2_VFD_Feedback_F2_out =
          this.predict_response['CT2 VFD Feedback F2'];
        this.CT3_VFD_Feedback_F1_out =
          this.predict_response['CT3 VFD Feedback F1'];
        this.CT3_VFD_Feedback_F2_out =
          this.predict_response['CT3 VFD Feedback F2'];
        this.CT4_VFD_Feedback_F1_out =
          this.predict_response['CT4 VFD Feedback F1'];
        this.CT4_VFD_Feedback_F2_out =
          this.predict_response['CT4 VFD Feedback F2'];
        this.Chilled_water_Power_out =
          this.predict_response['Chilled-water Power'];
        this.Common_Header_Return_temp_After_Decoupler_Line_out =
          this.predict_response[
            'Common Header Return temp After Decoupler Line'
          ];
        this.Common_Header_Return_temp_Before_Decoupler_Line_out =
          this.predict_response[
            'Common Header Return temp Before Decoupler Line'
          ];
        this.Common_Header_Supply_temp_After_Decoupler_Line_out =
          this.predict_response[
            'Common Header Supply temp After Decoupler Line'
          ];
        this.Common_Header_Supply_temp_Before_Decoupler_Line_out =
          this.predict_response[
            'Common Header Supply temp Before Decoupler Line'
          ];
        this.Diff_Pressure_Sec_Pump_Zone_A_out =
          this.predict_response['Diff Pressure Sec Pump Zone A'];
        this.Diff_Pressure_Sec_Pump_Zone_B_out =
          this.predict_response['Diff Pressure Sec Pump Zone B'];
        this.Diff_Pressure_Sec_Pump_Zone_C_out =
          this.predict_response['Diff Pressure Sec Pump Zone C'];
        this.Supply_temp_After_Sec_Pump_Zone_A_out =
          this.predict_response['Supply temp After Sec Pump Zone A'];
        this.Supply_temp_After_Sec_Pump_Zone_B_out =
          this.predict_response['Supply temp After Sec Pump Zone B'];
        this.Supply_temp_After_Sec_Pump_Zone_C_out =
          this.predict_response['Supply temp After Sec Pump Zone C'];
        this.Total_Sec_Pump_Power_out =
          this.predict_response['Total Sec Pump Power'];
        this.VFD_Feedback_CP_Pump_1_out =
          this.predict_response['VFD Feedback CP Pump 1'];
        this.VFD_Feedback_CP_Pump_2_out =
          this.predict_response['VFD Feedback CP Pump 2'];
        this.VFD_Feedback_CP_Pump_3_out =
          this.predict_response['VFD Feedback CP Pump 3'];
        this.VFD_Feedback_CP_Pump_4_out =
          this.predict_response['VFD Feedback CP Pump 4'];
        this.VFD_Feedback_Pr_Pump_3 =
          this.predict_response['VFD Feedback Pr Pump 3'];
        this.VFD_Feedback_Pr_Pump_4_out =
          this.predict_response['VFD Feedback Pr Pump 4'];
        this.VFD_Feedback_Zone_A_Sec_Pump_3_out =
          this.predict_response['VFD Feedback Zone A Sec Pump 3'];
        this.VFD_Feedback_Zone_B_Sec_Pump_2_out =
          this.predict_response['VFD Feedback Zone B Sec Pump 2'];
        this.VFD_Feedback_Zone_B_Sec_Pump_3_out =
          this.predict_response['VFD Feedback Zone B Sec Pump 3'];
        this.VFD_Feedback_Zone_C_Sec_Pump_2_out =
          this.predict_response['VFD Feedback Zone C Sec Pump 2'];
        this.VFD_Feedback_CP_Pump_3_out =
          this.predict_response['VFD Feedback Zone C Sec Pump 3'];

        this.openSnackBar('Predicted Succesfully');
        console.log('resp', this.CT1_VFD_Feedback_F1_out);

        this.progress = false;
        this.disableOptimise = false;
      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }

  async optimiseData() {
    this.progress = true;

    this.optimise_input = {
      'Chilled Water Set Point_old': Number(this.Chilled_water_Power_out),
      'Common Header Return temp After Decoupler Line_old': Number(this.Common_Header_Return_temp_After_Decoupler_Line_out),
      'Common Header Return temp Before Decoupler Line_old': Number(this.Common_Header_Return_temp_Before_Decoupler_Line_out),
      'Common Header Supply temp Before Decoupler Line_old': Number(this.Common_Header_Supply_temp_Before_Decoupler_Line_out),
      'Common Header Supply temp After Decoupler Line_old': Number(this.Common_Header_Supply_temp_After_Decoupler_Line_out),
      'Supply temp After Sec Pump Zone A_old': Number(this.Supply_temp_After_Sec_Pump_Zone_A_out),
      'Supply temp After Sec Pump Zone B_old': Number(this.Supply_temp_After_Sec_Pump_Zone_B_out),
      'Supply temp After Sec Pump Zone C_old': Number(this.Supply_temp_After_Sec_Pump_Zone_C_out),
      'Diff Pressure Sec Pump Zone A_old': Number(this.Diff_Pressure_Sec_Pump_Zone_A_out),
      'Diff Pressure Sec Pump Zone B_old': Number(this.Diff_Pressure_Sec_Pump_Zone_B_out),
      'Diff Pressure Sec Pump Zone C_old': Number(this.Diff_Pressure_Sec_Pump_Zone_C_out),
      'VFD Feedback Pr Pump 1_old': Number(this.VFD_Feedback_Pr_Pump_1),
      'VFD Feedback Pr Pump 2_old': Number(this.VFD_Feedback_Pr_Pump_2),
      'Chilled-water Power_old': Number(this.Chilled_water_Power_out),
      'VFD Feedback Pr Pump 3': Number(this.VFD_Feedback_Pr_Pump_3_out),
      'VFD Feedback Pr Pump 4': Number(this.VFD_Feedback_Pr_Pump_4_out),
      'VFD Feedback Zone A Sec Pump 3': Number(this.VFD_Feedback_Zone_A_Sec_Pump_3_out),
      'VFD Feedback Zone B Sec Pump 2': Number(this.VFD_Feedback_Zone_B_Sec_Pump_2_out),
      'VFD Feedback Zone B Sec Pump 3': Number(this.VFD_Feedback_Zone_B_Sec_Pump_3_out),
      'VFD Feedback Zone C Sec Pump 2': Number(this.VFD_Feedback_Zone_C_Sec_Pump_2_out),
      'VFD Feedback Zone C Sec Pump 3': Number(this.VFD_Feedback_Zone_C_Sec_Pump_3_out),
      'VFD Feedback CP Pump 1': Number(this.VFD_Feedback_CP_Pump_1_out),
      'VFD Feedback CP Pump 2': Number(this.VFD_Feedback_CP_Pump_2_out),
      'VFD Feedback CP Pump 3': Number(this.VFD_Feedback_CP_Pump_3_out),
      'VFD Feedback CP Pump 4': Number(this.VFD_Feedback_CP_Pump_4_out),
      'CT1 VFD Feedback F1': Number(this.CT1_VFD_Feedback_F1_out),
      'CT1 VFD Feedback F2':Number( this.CT1_VFD_Feedback_F2_out),
      'CT2 VFD Feedback F1': Number(this.CT2_VFD_Feedback_F1_out),
      'CT2 VFD Feedback F2': Number(this.CT2_VFD_Feedback_F2_out),
      'CT3 VFD Feedback F1': Number(this.CT3_VFD_Feedback_F1_out),
      'CT3 VFD Feedback F2': Number(this.CT3_VFD_Feedback_F2_out),
      'CT4 VFD Feedback F1': Number(this.CT4_VFD_Feedback_F1_out),
      'CT4 VFD Feedback F2': Number(this.CT4_VFD_Feedback_F2_out),
      'ahu_no.': 'chillar',
    };
    
    // this.optimise_input = {
    //   'Chilled Water Set Point_old': 7,
    //   'Common Header Return temp After Decoupler Line_old': 12,
    //   'Common Header Return temp Before Decoupler Line_old': 12.1,
    //   'Common Header Supply temp Before Decoupler Line_old': 7.2,
    //   'Common Header Supply temp After Decoupler Line_old': 7.22,
    //   'Supply temp After Sec Pump Zone A_old': 7.3,
    //   'Supply temp After Sec Pump Zone B_old': 7.3,
    //   'Supply temp After Sec Pump Zone C_old': 7.3,
    //   'Diff Pressure Sec Pump Zone A_old': 800,
    //   'Diff Pressure Sec Pump Zone B_old': 800,
    //   'Diff Pressure Sec Pump Zone C_old': 800,
    //   'VFD Feedback Pr Pump 1_old': 65,
    //   'VFD Feedback Pr Pump 2_old': 65,
    //   'Chilled-water Power_old': 600,
    //   'VFD Feedback Pr Pump 3': 0,
    //   'VFD Feedback Pr Pump 4': 0,
    //   'VFD Feedback Zone A Sec Pump 3': 0,
    //   'VFD Feedback Zone B Sec Pump 2': 0,
    //   'VFD Feedback Zone B Sec Pump 3': 0,
    //   'VFD Feedback Zone C Sec Pump 2': 0,
    //   'VFD Feedback Zone C Sec Pump 3': 0,
    //   'VFD Feedback CP Pump 1': 0,
    //   'VFD Feedback CP Pump 2': 0,
    //   'VFD Feedback CP Pump 3': 0,
    //   'VFD Feedback CP Pump 4': 0,
    //   'CT1 VFD Feedback F1': 0,
    //   'CT1 VFD Feedback F2': 0,
    //   'CT2 VFD Feedback F1': 0,
    //   'CT2 VFD Feedback F2': 0,
    //   'CT3 VFD Feedback F1': 0,
    //   'CT3 VFD Feedback F2': 0,
    //   'CT4 VFD Feedback F1': 0,
    //   'CT4 VFD Feedback F2': 0,
    //   'ahu_no.': 'chillar',
    // };
    
    console.log('optdata',this.optimise_input);

    try {
      const response = await this.http
        .post<any>(
          'https://bpsliveatairports.bertlabs.com/chilleroptimise',
          this.optimise_input,
          this.httpOptions
        )
        .toPromise();

      this.optimise_response = response;
      console.log('opt_output', this.optimise_response);

      this.CT1_VFD_Feedback_F1_out=this.optimise_response['CT1 VFD Feedback F1']
      this.CT1_VFD_Feedback_F2_out=this.optimise_response['CT1 VFD Feedback F2']
      this.CT2_VFD_Feedback_F1_out=this.optimise_response['CT2 VFD Feedback F1']
      this.CT2_VFD_Feedback_F2_out=this.optimise_response['CT2 VFD Feedback F2']
      this.CT3_VFD_Feedback_F1_out=this.optimise_response['CT3 VFD Feedback F1']
      this.CT3_VFD_Feedback_F2_out=this.optimise_response['CT3 VFD Feedback F2']
      this.CT4_VFD_Feedback_F1_out=this.optimise_response['CT4 VFD Feedback F1']
      this.CT4_VFD_Feedback_F2_out=this.optimise_response['CT4 VFD Feedback F2']
      this.Chilled_water_Power_out=this.optimise_response['Chilled-water Power']
      this.Common_Header_Return_temp_After_Decoupler_Line_out=this.optimise_response['Common Header Return temp After Decoupler Line_new']
      this.Common_Header_Return_temp_Before_Decoupler_Line_out=this.optimise_response['Common Header Return temp Before Decoupler Line_new']
      this.Common_Header_Supply_temp_After_Decoupler_Line_out=this.optimise_response['Common Header Supply temp After Decoupler Line_new']
      this.Common_Header_Supply_temp_Before_Decoupler_Line_out=this.optimise_response['Common Header Supply temp Before Decoupler Line_new']
      this.Diff_Pressure_Sec_Pump_Zone_A_out=this.optimise_response['Diff Pressure Sec Pump Zone A_new'];
      this.Diff_Pressure_Sec_Pump_Zone_B_out=this.optimise_response['Diff Pressure Sec Pump Zone B_new'];
      this.Diff_Pressure_Sec_Pump_Zone_C_out=this.optimise_response['Diff Pressure Sec Pump Zone C_new'];
      this.Supply_temp_After_Sec_Pump_Zone_A_out=this.optimise_response['Supply temp After Sec Pump Zone A_new']
      this.Supply_temp_After_Sec_Pump_Zone_B_out=this.optimise_response['Supply temp After Sec Pump Zone B_new']
      this.Supply_temp_After_Sec_Pump_Zone_C_out=this.optimise_response['Supply temp After Sec Pump Zone C_new']
      this.Total_Sec_Pump_Power_out=this.optimise_response['Total Sec Pump Power'];
      this.VFD_Feedback_CP_Pump_1_out=this.optimise_response['VFD Feedback CP Pump 1'];
      this.VFD_Feedback_CP_Pump_2_out=this.optimise_response['VFD Feedback CP Pump 2'];
      this.VFD_Feedback_CP_Pump_3_out=this.optimise_response['VFD Feedback CP Pump 3'];
      this.VFD_Feedback_CP_Pump_4_out=this.optimise_response['VFD Feedback CP Pump 4'];
      this.VFD_Feedback_Pr_Pump_1_out=this.optimise_response['VFD Feedback Pr Pump 1'];
      this.VFD_Feedback_Pr_Pump_2_out=this.optimise_response['VFD Feedback Pr Pump 2'];
      this.VFD_Feedback_Pr_Pump_3_out=this.optimise_response['VFD Feedback Pr Pump 3'];
      this.VFD_Feedback_Pr_Pump_4_out=this.optimise_response['VFD Feedback Pr Pump 4'];
      this.VFD_Feedback_Zone_A_sec_Pump_1_out=this.optimise_response['VFD Feedback Zone A Sec Pump 1']
      this.VFD_Feedback_Zone_A_Sec_Pump_2_out=this.optimise_response['VFD Feedback Zone A Sec Pump 2']
      this.VFD_Feedback_Zone_A_Sec_Pump_3_out=this.optimise_response['VFD Feedback Zone A Sec Pump 3']
      this.VFD_Feedback_Zone_B_Sec_Pump_1_out=this.optimise_response['VFD Feedback Zone B Sec Pump 1']
      this.VFD_Feedback_Zone_B_Sec_Pump_2_out=this.optimise_response['VFD Feedback Zone B Sec Pump 2']
      this.VFD_Feedback_Zone_B_Sec_Pump_3_out=this.optimise_response['VFD Feedback Zone B Sec Pump 3']
      this.VFD_Feedback_Zone_C_Sec_Pump_1_out=this.optimise_response['VFD Feedback Zone C Sec Pump 1']
      this.VFD_Feedback_Zone_C_Sec_Pump_2_out=this.optimise_response['VFD Feedback Zone C Sec Pump 2']
      this.VFD_Feedback_Zone_C_Sec_Pump_3_out=this.optimise_response['VFD Feedback Zone C Sec Pump 3']


      this.chiller_power_savings=(((605-this.optimise_response['Chilled-water Power'])*100)/605);
      this.secondary_pump_power=(((48-this.optimise_response['Total Sec Pump Power'])*100)/48);

      this.openSnackBar('Optimised Succesfully');


      this.progress = false;
    } catch (error) {
      console.error('Error occurred while making predict API request:', error);
    }
  }
}
