import { Component, Input } from '@angular/core';

@Component({
  selector: 'Fuel',
  templateUrl: './fuel.component.html',
  styleUrls: ['./fuel.component.css']
})
export class FuelComponent {
  @Input() state!:string;
  urlActive:string;
  urlInActive:string;
  urlTrip:string;
  constructor(){
    this.state='active'
    this.urlActive="/assets/bert-components/active-fuel.svg";
    this.urlInActive="/assets/bert-components/inactive-fuel.svg";
    this.urlTrip="/assets/bert-components/inactive-fuel.svg";
  }
}
