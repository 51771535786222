import { Component, ViewChild } from '@angular/core';
import { MyErrorStateMatcher } from '../login/login.component';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  @ViewChild('form') form!: NgForm;

  email:string;

  emailFormControl = new FormControl('', [Validators.required,Validators.email]);

  matcher = new MyErrorStateMatcher();


  public forgotMode: boolean = false;
  public forgotModeSent: boolean = false;

  public $error = new BehaviorSubject<{
    message: string;
    status: number;
  } | null>(null);

  constructor(
    public authService: AuthService,
    private router: Router,
    private snackBar:MatSnackBar
  ) {
    this.email="";
  }

  toggleForgotMode() {
    this.forgotMode = !this.forgotMode;
    this.forgotModeSent = false;
  }

  handleSubmit() {
    if (this.forgotMode) {
      this.handlePasswordReset();
    } else {
      this.handleLogin();
    }
  }

  handlePasswordReset() {
    this.forgotModeSent = true;
    const { email } = this.form.value;
    console.log(email);
    // server call to send reset link
  }

  handleLogin() {
    // const { email, password } = this.form.value;
    // this.authService.logIn(email, password).subscribe({
    //   error: err => {
    //     this.$error.next(err);
    //     // resolve after 2500 ms
    //     setTimeout(() => {
    //       this.$error.next(null);
    //     }, 2500);
    //   }
    // });
  }

  ngOnInit(): void {
    // this.authService.isAuthenticated().subscribe(isAuth => {
    //   // goto app if already logged in
    //   if (isAuth) this.router.navigate(['app']);
    // });
  }
}
