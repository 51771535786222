import { Component } from '@angular/core';

@Component({
  selector: 'Terminal-two',
  templateUrl: './terminal-two.component.html',
  styleUrls: ['./terminal-two.component.css']
})
export class TerminalTwoComponent {

}
