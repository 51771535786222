<div
  style="
    height: 65px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.06),
      0px 0px 2px rgba(25, 25, 25, 0.12);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
  "
>

  <!-- logos -->
  <div style="display: flex;flex-direction: row;align-items: center;padding: 10px 0px 1px 0px;gap: 5px;">
    <!-- <img src="/assets/logos/client-logo-bg.png" height="50px"> -->
    <div style="height: 100%; width: 1px;background-color: #000;"></div>
    <img src="/assets/logos/logo-icon.svg" height="70px">
  </div>

  <!-- title -->
  <span class="title-dark" style="font-size: 26px; font-weight:600; padding-left: 12.5%;">{{title}}</span>

  <!-- account -->

  <div style="display: flex;flex-direction: row;align-items: center;padding: 5px;gap: 5px; margin-right: 20px;">
    <!-- <img src="/assets/logos/client-logo-bg.png" height="50px">
    <div style="height: 100%; width: 1px;background-color: #000;"></div> -->
    <div  style="display:flex; height:30px; font-size: 15px;font-weight: 300; justify-content:center; align-items:center; ">Powered by</div>

    <img src="/assets/logos/bertlabs-logo.png" height="30px">
  </div>

</div>
