import { Component, Input } from '@angular/core';

@Component({
  selector: 'Female-washroom',
  templateUrl: './female-washroom.component.html',
  styleUrls: ['./female-washroom.component.css']
})
export class FemaleWashroomComponent {
  @Input()pmv!:number;

  constructor(){
    this.pmv=0;
  }
}
