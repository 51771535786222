<div style="position: relative; height: 100%; width: 100%">
  <!-- <Water-mark></Water-mark> -->

  <div
    style="
      width: 100%;
      height: 100%;
      min-width: 1200px;
      display: flex;
      flex-direction: column;
      position: absolute;
    "
  >
    <mat-progress-bar
      mode="indeterminate"
      [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"
    ></mat-progress-bar>


      <!-- main body -->

      <div
      style="
        height: auto;
        width: 100%;
        display: flex;
        flex: 7;
        flex-direction: column;
        justify-content: start;
        padding: 10px;
        gap: 20px;
        align-items: center;
      "
    >
      <div
        style="
          height: 550px;
          width: 1000px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        "
      >
        <!-- componets -->
        <div style="height: 550px; width: 1000px; position: absolute">
          <div style="margin-top: 45px; margin-left: -803px; height: 116px">
            <Electric-load-center-one [pmv]="model_one"></Electric-load-center-one>
          </div>

          <div style="margin-top: -116px; margin-left: -496px; height: 116px">
            <Female-washroom [pmv]="model_three"></Female-washroom>
          </div>

          <div style="margin-top: -117px; margin-left: 151px; height: 168px">
            <Gate-hold-room-d-one [pmv]="model_six"></Gate-hold-room-d-one>
          </div>

          <div style="margin-top: -87px; margin-left: 475px; height: 380px">
            <Gate-hold-room-d-two [pmv]="model_four"></Gate-hold-room-d-two>
          </div>

          <div style="margin-top: -220px; margin-left: -99px; height: 221px">
            <Gate-hold-room-d-three [pmv]="-4"></Gate-hold-room-d-three>
          </div>

          <div style="margin-top: -345px; margin-left: -390px; height: 230px">
            <Security-screening-area [pmv]="model_two"></Security-screening-area>
          </div>

          <div style="margin-top: 0px; margin-left: -674px; height: 115px">
            <Male-washroom [pmv]="-4"></Male-washroom>
          </div>

          <div style="margin-top: -504px; margin-left: 798px; height: 548px">
            <Electric-load-center-two [pmv]="model_five"></Electric-load-center-two>
          </div>
        </div>

        <!-- map -->
        <div
          style="
            height: 550px;
            width: 1000px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
          "
        >
          <img src="/assets/images/pmv-map.svg" width="1000px" height="650px" />
        </div>

        <!-- buttons -->
        <div
          style="
            height: 550px;
            width: 1200px;
            position: absolute;
            padding-left: 100px;
          "
        >
          <div style="margin-top: 75px; margin-left: 25px">
            <button
              mat-raised-button
              class="pmv-button"
              style="
                height: 50px;
                background-color: var(--blue-one);
                color: white;
              "
              routerLink="../electric-load-centre-one-map"
            >
              ELECTRIC LOAD<br />CENTRE-1
            </button>
          </div>

          <div style="margin-top: -53px; margin-left: 219px">
            <button
              mat-raised-button
              class="pmv-button"
              style="
                height: 50px;
                background-color: var(--blue-one);
                color: white;
              "
              routerLink="../ladies-washroom-map"
            >
              LADIES<br />WASHROOM
            </button>
          </div>

          <div style="margin-top: -52px; margin-left: 442px">
            <button
              mat-raised-button
              class="pmv-button"
              style="
                height: 50px;
                background-color: var(--blue-one);
                color: white;
              "
              routerLink="../gate-hold-room-d-one-map"
            >
              GATE HOLD ROOM <br />
              D-38
            </button>
          </div>

          <div style="margin-top: 94px; margin-left: 667px">
            <button
              mat-raised-button
              class="pmv-button"
              style="
                height: 50px;
                background-color: var(--blue-one);
                color: white;
              "
              routerLink="../gate-hold-room-d-two-map"
            >
              GATE HOLD ROOM <br />
              D-49
            </button>
          </div>

          <div style="margin-top: 132px; margin-left: 441px">
            <button
              mat-raised-button
              disabled
              class="pmv-button"
              style="
                height: 50px;
                background-color: var(--dark-gray);
                color: white;
              "
            >
              GATE HOLD ROOM <br />
              D-48
            </button>
          </div>

          <div style="margin-top: -14px; margin-left: 105px">
            <button
              mat-raised-button
              disabled
              class="pmv-button"
              style="
                height: 50px;
                background-color: var(--dark-gray);
                color: white;
              "
            >
              GENTS<br />
              WASHROOM
            </button>
          </div>

          <div style="margin-top: -245px; margin-left: 210px">
            <button
              mat-raised-button
              class="pmv-button"
              style="
                height: 50px;
                background-color: var(--blue-one);
                color: white;
              "
              routerLink="../security-screening-area-map"
            >
              SECURITY SCREENING<br />AREA
            </button>
          </div>

          <div style="margin-top: -39px; margin-left: 952px">
            <button
              mat-raised-button
              class="pmv-button"
              style="
                height: 50px;
                background-color: var(--blue-one);
                color: white;
              "
              routerLink="../electric-load-centre-two-map"
            >
              ELECTRIC LOAD<br />CENTRE-2
            </button>
          </div>
        </div>
      </div>

      <!-- legends -->
      <div
        style="display: flex; flex-direction: row; width: 1000px; gap: 10px"
      >
        <div
          class="pmv-pallet"
          style="background-color: var(--pmv-neg-three)"
        >
          <span style="font-size: 12px">-1 SLIGHTLY COOL</span>
        </div>
        <div class="pmv-pallet" style="background-color: var(--pmv-neg-two)">
          <span style="font-size: 12px">-0.5</span>
        </div>
        <!-- <div class="pmv-pallet" style="background-color: var(--pmv-neg-one)">
          <span style="font-size: 12px">-0.2</span>
        </div> -->
        <div class="pmv-pallet" style="background-color: var(--pmv-zero)">
          <span style="font-size: 12px">0 NEUTRAL</span>
        </div>
        <!-- <div class="pmv-pallet" style="background-color: var(--pmv-one)">
          <span style="font-size: 12px">0.2</span>
        </div> -->
        <div class="pmv-pallet" style="background-color: var(--pmv-two)">
          <span style="font-size: 12px">0.5</span>
        </div>
        <div class="pmv-pallet" style="background-color: var(--pmv-three)">
          <span style="font-size: 12px">1 SLIGHTLY WARM</span>
        </div>
      </div>
    </div>



  </div>
</div>
