import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-c17-part1',
  templateUrl: './c17-part1.component.html',
  styleUrls: ['./c17-part1.component.css'],
})
export class C17Part1Component{
  isLoading = true;

  progress: boolean = false;
  disableOptimise: boolean = true;
  predict_input: any;
  predict_response: any;

  predictionResult: any = {};
  jsonData: any = {};

  interface_input: any = {};
  //predict input params
  ZONE_TEMP_out_C4_L2_14: any = 0;
  Zone_Temp_C4_L2_14_out_L: any = 0;
  Zone_Temp_C4_L2_14_out_U: any = 0;
  Zone_Temp_C4_L2_14: any = 0;
  PMV_C4_L2_14: any = 0;
  room_RH_C4_L2_14: any = 0;
  room_temp_C4_L2_14: any = 0;
  radiantTemp_C4_L2_14: any = 0;

  time: string = '';

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    if (localStorage.getItem('interface_input')) {
      this.interface_input = localStorage.getItem('interface_input');
      this.interface_input = JSON.parse(this.interface_input);

      this.Zone_Temp_C4_L2_14 = this.interface_input['Zone_Temp_C4-L2-14'];
      this.PMV_C4_L2_14 = this.interface_input['PMV_C4-L2-14'];
      this.room_RH_C4_L2_14 = this.interface_input['room_RH_C4-L2-14'];
      this.room_temp_C4_L2_14 = this.interface_input['room_temp_C4-L2-14'];
      this.radiantTemp_C4_L2_14 = this.interface_input['radiantTemp_C4-L2-14'];
      this.time = this.interface_input['Time'];

      console.log('Adjusted Time:', this.time);
    } else {
      this.Zone_Temp_C4_L2_14 = 0;
      this.PMV_C4_L2_14 = 0;
      this.room_RH_C4_L2_14 = 0;
      this.room_temp_C4_L2_14 = 0;
      this.radiantTemp_C4_L2_14 = 0;
    }

    if (localStorage.getItem('predictionResult')) {
      this.predictionResult = localStorage.getItem('predictionResult');
      this.predictionResult = JSON.parse(this.predictionResult);
      this.ZONE_TEMP_out_C4_L2_14 = this.predictionResult['Zone_Temp_C4-L2-14'];
      this.Zone_Temp_C4_L2_14_out_L = this.ZONE_TEMP_out_C4_L2_14 - 0.5;
      this.Zone_Temp_C4_L2_14_out_U = this.ZONE_TEMP_out_C4_L2_14 + 0.5;
    } else {
      this.ZONE_TEMP_out_C4_L2_14 = 0;
      this.Zone_Temp_C4_L2_14_out_L = 0;
      this.Zone_Temp_C4_L2_14_out_U = 0;
    }
  }

  ngOnInit(): void {
    this.getInputResult();
    this.getPredictionResult();
    setInterval(() => {
      this.getPredictionResult();
      this.getInputResult();
    }, 300000); // 300000 milliseconds = 5 minutes
    setTimeout(() => {
      this.isLoading = false;
    }, 400); // Wait for 1 second before displaying the content
  }

  async getInputResult() {
    this.http.get<any>('https://airportinternational.bertlabs.com/get_result')
      .subscribe(
        (response: any) => {
          try {
            console.log('Input is given by Sneh:', response);
            if (typeof response.answer === 'string') {
              this.interface_input = JSON.parse(response.answer);
            } else {
              this.interface_input = response.answer;
            }

            this.Zone_Temp_C4_L2_14 = this.interface_input['Zone_Temp_C4-L2-14'];
            this.PMV_C4_L2_14 = this.interface_input['PMV_C4-L2-14'];
            this.room_RH_C4_L2_14 = this.interface_input['room_RH_C4-L2-14'];
            this.room_temp_C4_L2_14 = this.interface_input['room_temp_C4-L2-14'];
            this.radiantTemp_C4_L2_14 = this.interface_input['radiantTemp_C4-L2-14'];

            // Temporarily hide the time until it's adjusted
            // this.time = '';

            // Adjust the time here
            const adjustedTime = this.adjustTime(this.interface_input['Time']);
            console.log("Adjusted Time:", adjustedTime);

            // Update the time in the interface_input object and store it in localStorage
            this.interface_input['Time'] = adjustedTime;
            localStorage.setItem('interface_input', JSON.stringify(this.interface_input));

            this.time = adjustedTime; // Now set the adjusted time to display it
            console.log("Time to display:", this.time);

            this.jsonData['interface_input'] = this.interface_input;
          } catch (error) {
            console.error('Error parsing Input Result:', error);
          }
        },
        (error) => {
          console.error('Error fetching Input Result:', error);
        }
      );
  }

  adjustTime(timeString: string): string {
    // Parse the time string into a Date object
    const date = new Date(timeString);

    date.setHours(date.getHours());
    date.setMinutes(date.getMinutes());
    date.setSeconds(date.getSeconds());

    // Format the date back to YYYY-MM-DD HH:mm:ss
    const adjustedDatePart = date.toISOString().split('T')[0];
    const adjustedTimePart = date.toTimeString().split(' ')[0];

    return `${adjustedDatePart} ${adjustedTimePart}`;
  }

  async getPredictionResult() {
    this.http.get<any>('https://airportinternational.bertlabs.com/get_prediction_result').subscribe(
      (response: any) => {
        try {
          // Log the response to check its content
          console.log('Prediction is by Gaurav:', response);

          // Check if the response is a string and parse it if necessary
          if (typeof response.result === 'string') {
            this.predictionResult = JSON.parse(response.result);
          } else {
            this.predictionResult = response.result;
          }
          this.ZONE_TEMP_out_C4_L2_14 =
            this.predictionResult['Zone_Temp_C4-L2-14'];
          this.Zone_Temp_C4_L2_14_out_L = this.ZONE_TEMP_out_C4_L2_14 - 0.5;
          this.Zone_Temp_C4_L2_14_out_U = this.ZONE_TEMP_out_C4_L2_14 + 0.5;
          console.log('Prediction Result:', this.predictionResult);
          this.jsonData['predictionResult'] = this.predictionResult;
          console.log(this.jsonData);
          localStorage.setItem(
            'predictionResult',
            JSON.stringify(this.predictionResult)
          );
          console.log('predict_output', this.predict_response);
        } catch (error) {
          console.error('Error parsing prediction Result:', error);
        }
      },
      (error) => {
        console.error('Error fetching prediction Result:', error);
      }
    );
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }
}
