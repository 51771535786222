import { Component, Input } from '@angular/core';

@Component({
  selector: 'Cooling-tower',
  templateUrl: './cooling-tower.component.html',
  styleUrls: ['./cooling-tower.component.css'],
})
export class CoolingTowerComponent {
  @Input() state!: string;

  urlActive: string;
  urlInActive: string;
  urlTrip: string;

  constructor() {
    this.urlActive =
      '/assets/bert-components/active-cooling-tower.svg';
    this.urlInActive =
      '/assets/bert-components/inactive-cooling-tower.svg';
    this.urlTrip =
      '/assets/bert-components/triped-cooling-tower.svg';
  }
}
