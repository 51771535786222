<div
    style="
      display: flex;
      flex-direction: row;
      gap: 5px;
      position: absolute;
      padding: 24px;
      width: 100%;
      align-items: flex-end;
      justify-content: end;
      height: 100%;
      min-width: 1200px;
      "
  >

        <span class="span-text-dark" style="font-size: 12px; ">powered by</span>
        <img src="/assets/logos/bertlabs-logo.png" height="20px" width="100px" />
  
</div>