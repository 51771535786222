import { Component } from '@angular/core';

@Component({
  selector: 'Pack-tower',
  templateUrl: './pack-tower.component.html',
  styleUrls: ['./pack-tower.component.css']
})
export class PackTowerComponent {

}
