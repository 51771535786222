import { Component, Input } from '@angular/core';

@Component({
  selector: 'Surface-condensor',
  templateUrl: './surface-condensor.component.html',
  styleUrls: ['./surface-condensor.component.css']
})
export class SurfaceCondensorComponent {
  @Input() state:string;

 

  urlActive:string;

  urlInActive:string;

 

  constructor(){
    this.state = 'active';
    this.textValue = ""
    this.urlActive="/assets/bert-components/active-surface-condensor.svg";
    this.urlInActive="/assets/bert-components/inactive-surface-condensor.svg";
  }

  @Input() textValue: string;
}
