import { Component, Inject, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'Nav-bar',
  standalone: true,
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
  imports: [MatButtonModule, MatDialogModule],
})
export class NavBarComponent {
  title: any;

  constructor(public dialog: MatDialog, private router: Router) {


    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        var endpoint=this.cropUrl(event.url);
        this.title = this.url_dictionary.get(endpoint);
      }
    });
  }

  showLogoutDialog() {
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  cropUrl(url: string): string {
    var splitted = url.split('/');
    return splitted[splitted.length - 1];
  }


  url_dictionary = new Map<string, string>([
    ['home', 'HOME'],


    ['chillerplant', 'Chiller Plant'],
    ['chiller1','Chiller 1'],
    ['chiller2','Chiller 2'],
    ['chiller3','Chiller 3'],
    ['chiller4','Chiller 4'],
    ['coolingtower1','Cooling Tower 1'],
    ['coolingtower2','Cooling Tower 2'],
    ['coolingtower3','Cooling Tower 3'],
    ['coolingtower4','Cooling Tower 4'],
    ['condensorpumps','Condensor Pumps'],
    ['insights', 'Thermal Comfort Insights'],

    // d-pier-routes
    ['ahud7a','AHU D7A'],
    ['pmv','PMV'],
    ['electric-load-centre-one-map','ELECTRIC LOAD CENTRE - 1'],
    ['ladies-washroom-map','LADIES WASHROOM'],
    ['gate-hold-room-d-two-map','GATE HOLD ROOM D - 49'],
    ['security-screening-area-map','SECURITY SCREENING AREA'],
    ['gate-hold-room-d-one-map','GATE HOLD ROOM D - 38'],
    ['electric-load-centre-two-map','ELECTRIC LOAD CENTRE - 2'],

    // c-pier-routes
    ['ahuc4a','AHU C4A'],
    ['ahuc4b','AHU C4B'],
    ['c-pier-pmv','C PIER'],
    ['c-pier-security-screening-room-one-map','SECURITY SCREENING ROOM 1'],
    ['c-pier-security-screening-room-two-map','SECURITY SCREENING ROOM 2'],
    ['c-pier-gate-hold-room-one-map','GATE HOLD ROOM C - 18'],
    ['c-pier-gate-hold-room-two-map','GATE HOLD ROOM C - 17'],
    ['T1B1','T1 B Gate'],
    ['T1B2','T1 B Gate'],
    ['T1B3','T1 B Gate'],
    ['T1B4','T1 B Gate'],
    ['T1B5','T1 B Gate'],
    ['T1B6','T1 B Gate'],
    ['T1B7','T1 B Gate'],
    ['T1A1','T1 A Gate'],
    ['T1A2','T1 A Gate'],
    ['T1A3','T1 A Gate'],
    ['T1A4','T1 A Gate'],
    ['T1A5','T1 A Gate'],
    ['T1A6','T1 A Gate'],
    ['T1A7','T1 A Gate'],
    ['T1','Terminal T1'],
  ]);
}
