import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ladies-washroom-map',
  templateUrl: './ladies-washroom-map.component.html',
  styleUrls: ['./ladies-washroom-map.component.css']
})
export class LadiesWashroomMapComponent {
  progress: boolean = false;
  disableOptimise: boolean = true;
  predict_input: any;
  predict_response: any;
  xinput: any;

  //predict input params
  // inputs from form

  ZT9: number;
  RH9:number;
  Velocity9: number;

  // output fields predict
  pmv_out9: number;

 
  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {

    // this.ZT9 = 23;
    // this.Velocity9 = 2;
    // this.RH9=46;
    if (localStorage.getItem('ladies-washroom-map')){
      this.xinput=localStorage.getItem('ladies-washroom-map');
      this.xinput=JSON.parse(this.xinput);

      this.ZT9 = Number(this.xinput['Zone Temp D7-L2-09']);
      this.Velocity9 = Number(this.xinput['Velocity D7-L2-09']);
      this.RH9 = Number(this.xinput['RH D7-L2-09']);
    }else{
      this.ZT9 = 0;
      this.Velocity9 = 0;
      this.RH9 = 0;
    }

    if(localStorage.getItem('PMV D7-L2-9')){
      this.pmv_out9= Number(localStorage.getItem('PMV D7-L2-9'));
     }else{
       this.pmv_out9 = 0;
     }
 

  }


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if(this.ZT9<21 || this.ZT9>23.8){
      this.openSnackBar('ZT9 should be in range [21,23.8]');
      return false;
    }else if(this.Velocity9<1.39 || this.Velocity9>5.5){
      this.openSnackBar('Velocity9 should be in range [1.39,5.5]');
      return false;
    }else if(this.RH9<45.98 || this.RH9>57.95){
      this.openSnackBar('RH9 should be in range [45.98,57.95]');
      return false;
    }

    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }


  // this fuction is use to predict PMV
  async predictData(){
    
    if (this.validate()) {
      this.progress = true;
      console.log('predict input', this.predict_input);

      this.predict_input= {
        'Zone Temp D7-L2-09': Number(this.ZT9),
        'RH D7-L2-09': Number(this.RH9),
        'Velocity D7-L2-09': Number(this.Velocity9),
        'model_no.': 3,
      };
    

      try {
        const response = await this.http
          .post<any>(
            'https://bpsliveatchangiairport.bertlabs.com/predictsec3/',
            this.predict_input,
            this.httpOptions
          )
          .toPromise();

        this.predict_response = response;
        console.log('predict outout', this.predict_response);

        this.openSnackBar('Predicted Succesfully');

        this.pmv_out9 = this.predict_response['PMV D7-L2-09'];
        this.progress = false;
        this.disableOptimise = false;
        localStorage.setItem('model_three', JSON.stringify(this.pmv_out9));
        localStorage.setItem('PMV D7-L2-9',JSON.stringify(this.pmv_out9));

        localStorage.setItem('ladies-washroom-map', JSON.stringify(this.predict_input));
      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }
}
