import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ButtonService } from 'src/app/services/button-service/button.service';

@Component({
  selector: 'app-third-ahu',
  templateUrl: './third-ahu.component.html',
  styleUrls: ['./third-ahu.component.css']
})
 
export class ThirdAhuComponent {


  isDisabled: boolean = true;
  type!: number;
  predict_response: any = {};
  predict_input: any = {};
  optimise_input: any = {};
  optimise_response: any = {};
  progress: boolean = false;
  disableOptimise: boolean = true;
  disablePMV:boolean=false;
  ahu_out: any;
  ahu_out_C4A: any;
  ahu_out_C4B: any;
  ahu_input:any;

  // input form fields
  RAT: number;
  SAT: number;
  CHWVF: number;
  VSD: number;
  OCT: number;
  CHWRT: number;
  CHWST: number;

  // output form fields
 
  FP_out: number;
  OCT_out:number;
  SADPT_out:number;
  RAT_out: number;
  SAT_out: number;
  CHWVF_out: number;
  VSD_out: number = Number(localStorage.getItem('VSD Freq - C4B')) || 0;
  CHWST_out:number;
  CHWRT_out:number;
  hasdata:boolean=true;
  
  

  // KPI DATA
  KPI: number;

  constructor(
    private buttonService: ButtonService,
    private activeroute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {

    if ( localStorage.getItem('Fan Power - C4A_out') !=  null && localStorage.getItem('Fan Power - C4B_out') !=  null){
      this.KPI = 12.25 * Number(localStorage.getItem('Fan Power - C4A_out')) + 12 * Number(localStorage.getItem('Fan Power - C4B_out')) + 25;
    }
    else{
      this.KPI = 0;
    }
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.type = Number(this.activeroute.snapshot.paramMap.get('type'));
      }
    });

    // input field intialisation
    // this.RAT = 24;
    // this.SAT = 14;
    // this.CHWVF = 45;
    // this.VSD = 50;
    // this.OCT = 12;
    // this.CHWRT = 13;
    // this.CHWST = 7;

    if(localStorage.getItem('ahu_input_C4B')){
      this.ahu_input=localStorage.getItem('ahu_input_C4B');
      this.ahu_input=JSON.parse(this.ahu_input);
      this.RAT = this.ahu_input['RA Temp - C4B'];
      this.SAT = this.ahu_input['SA Temp - C4B'];
      this.CHWVF = this.ahu_input['Valve Feedback - C4B'];
      this.VSD = this.ahu_input['VSD Freq - C4B'];
      this.OCT = this.ahu_input['Off Coil Temp - C4B'];
      this.CHWRT = this.ahu_input['CHWR Temp - C4B'];
      this.CHWST = this.ahu_input['CHWS Temp - C4B'];
     }else{
      this.RAT = 0;
      this.SAT = 0;
      this.CHWVF = 0;
      this.VSD = 0;
      this.OCT = 0;
      this.CHWRT = 0;
      this.CHWST = 0;
     }

    
      // this.RAT = 0;
      // this.SAT = 0;
      // this.CHWVF = 0;
      // this.VSD = 0;
      // this.OCT = 0;
      // this.CHWRT = 0;
      // this.CHWST = 0;

 

    // output field initialisation


    
     if(localStorage.getItem('ahu_out_C4B')){
      this.ahu_out=localStorage.getItem('ahu_out_C4B');
      this.ahu_out=JSON.parse(this.ahu_out);
      console.log(this.ahu_out);
      this.FP_out= localStorage.getItem('Fan Power - C4B_out') || localStorage.getItem('Fan Power - C4B') || this.ahu_out['Fan Power - C4B'];
      this.OCT_out=this.ahu_out['Off Coil Temp - C4B'];
      this.SADPT_out=this.ahu_out['SA DPT - C4B'];
      this.RAT_out=this.ahu_out['RA Temp - C4B'];
      this.SAT_out=this.ahu_out['SAT'];
      this.VSD_out= Number(localStorage.getItem('VSD Freq - C4B_out')) || Number(localStorage.getItem('VSD Freq - C4B')) || this.ahu_out['VSD'];
      this.CHWST_out=this.ahu_out['CHWST'];
      this.CHWRT_out=this.ahu_out['CHWRT'];
      this.CHWVF_out=this.ahu_out['CHWVF'];
      
     }else{
      this.FP_out=0;
      this.OCT_out=0;
      this.SADPT_out=0;
      this.RAT_out=0;
      this.SAT_out=0;
      this.VSD_out=0;
      this.CHWST_out=0;
      this.CHWRT_out=0;
      this.CHWVF_out=0;
     }
    
      

    for (let i = 1; i < 10; i++) {
      if(localStorage.getItem('PMV- C4 - L2 - 0'+i)===null){
        this.hasdata=false;
        console.log(i)
      }
    }
    for (let i = 10; i < 28; i++) {
      if(localStorage.getItem('PMV- C4 - L2 - '+i)===null){
        this.hasdata=false;
        console.log(i)
      }
    }

    if(localStorage.getItem('ahu_input_C4A')!=null  && localStorage.getItem('ahu_input_C4B')!=null){
      this.disablePMV=false;
    }
      
      this.disableOptimise=!this.hasdata;
      
      console.log('hasdata',this.hasdata);
      console.log('disbale',this.disableOptimise);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if (this.RAT < 20.2 || this.RAT > 25.3) {
      this.openSnackBar('RAT should be in range [22.2,25.3]');
      return false;
    } else if (this.CHWVF < 32.5 || this.CHWVF > 100) {
      this.openSnackBar('CHWVF should be in range [32.5,100]');
      return false;
    } else if (this.OCT < 10.7 || this.OCT > 14) {
      this.openSnackBar('OCT should be in range [10.7,14]');
      return false;
    } else if (this.VSD < 25 || this.VSD > 40) {
      this.openSnackBar('VSD should be in range [25,40]');
      return false;
    } else if (this.CHWST < 3.7 || this.CHWST > 11) {
      this.openSnackBar('CHWST should be in range [3.7,11]');
      return false;
    } else if (this.CHWRT < 8.9 || this.CHWRT > 16.6) {
      this.openSnackBar('CHWRT should be in range [8.9,16.6]');
      return false;
    } else if (this.SAT < 12.5 || this.SAT > 15) {
      this.openSnackBar('SAT should be in range [12.5,15]');
      return false;
    }

    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  async predictData() {
   
    if(this.validate()){
      this.progress=true;
    this.predict_input = {
      "RA Temp - C4B": Number(this.RAT),
      "Off Coil Temp - C4B": Number(this.OCT),
      "SA Temp - C4B": Number(this.SAT),
      "VSD Freq - C4B": Number(this.VSD),
      "CHWS Temp - C4B": Number(this.CHWST),
      "CHWR Temp - C4B": Number(this.CHWRT),
      "Valve Feedback - C4B": Number(this.CHWVF),
    };

    // console.log('predict input', this.predict_input);

    try {
      console.log(this.predict_input);
      const response = await this.http
        .post<any>(
          'https://bpsliveatchangiairport1.bertlabs.com/ahupredict1',
          // 'https://bpsliveatchangiairport.bertlabs.com/ahupredict/',
          this.predict_input,
          this.httpOptions
        )
        .toPromise();

      this.predict_response = response;
      console.log('predict output', this.predict_response);
      this.KPI = 0;

      this.openSnackBar('Predicted Succesfully');


      localStorage.removeItem('PMV- C4 - L2 - 01_out');
      localStorage.removeItem('PMV- C4 - L2 - 02_out');
      localStorage.removeItem('PMV- C4 - L2 - 03_out');
      localStorage.removeItem('PMV- C4 - L2 - 04_out');
      localStorage.removeItem('PMV- C4 - L2 - 05_out');
      localStorage.removeItem('PMV- C4 - L2 - 06_out');
      localStorage.removeItem('PMV- C4 - L2 - 07_out');
      localStorage.removeItem('PMV- C4 - L2 - 08_out');
      localStorage.removeItem('PMV- C4 - L2 - 09_out');
      localStorage.removeItem('PMV- C4 - L2 - 10_out');
      localStorage.removeItem('PMV- C4 - L2 - 11_out');
      localStorage.removeItem('PMV- C4 - L2 - 12_out');
      localStorage.removeItem('PMV- C4 - L2 - 13_out');
      localStorage.removeItem('PMV- C4 - L2 - 14_out');
      localStorage.removeItem('PMV- C4 - L2 - 15_out');
      localStorage.removeItem('PMV- C4 - L2 - 16_out');
      localStorage.removeItem('PMV- C4 - L2 - 17_out');
      localStorage.removeItem('PMV- C4 - L2 - 18_out');
      localStorage.removeItem('PMV- C4 - L2 - 19_out');
      localStorage.removeItem('PMV- C4 - L2 - 20_out');
      localStorage.removeItem('PMV- C4 - L2 - 21_out');
      localStorage.removeItem('PMV- C4 - L2 - 22_out');
      localStorage.removeItem('PMV- C4 - L2 - 23_out');
      localStorage.removeItem('PMV- C4 - L2 - 24_out');
      localStorage.removeItem('PMV- C4 - L2 - 25_out');
      localStorage.removeItem('PMV- C4 - L2 - 26_out');
      localStorage.removeItem('PMV- C4 - L2 - 27_out');
      localStorage.removeItem('Fan Power - C4A_out');
      localStorage.removeItem('Fan Power - C4B_out');
      localStorage.removeItem('VSD Freq - C4A_out');
      localStorage.removeItem('VSD Freq - C4B_out');
      localStorage.removeItem('Valve Feedback - C4A_out');
      localStorage.removeItem('Valve Feedback - C4B_out');


      this.FP_out=(this.predict_input["VSD Freq - C4B"]-19.944)/38.888;
      this.OCT_out=this.predict_response["Off Coil Temp - C4B"];
      this.RAT_out=this.predict_response["RA Temp - C4B"];
      this.SADPT_out=this.predict_response["SA DPT - C4B"];
      this.SAT_out=this.SAT;
      this.VSD_out=this.VSD;
      this.CHWST_out=this.CHWST;
      this.CHWRT_out=this.CHWRT;
      this.CHWVF_out=this.CHWVF;

      this.ahu_out={
        'Fan Power - C4B': this.FP_out,
        'Off Coil Temp - C4B': this.OCT_out,
        'RA Temp - C4B': this.RAT_out,
        'SA DPT - C4B': this.SADPT_out,
        'SAT':this.SAT_out,
        'VSD': this.VSD_out,
        'CHWST': this.CHWST_out,
        'CHWRT':this.CHWRT_out,
        'CHWVF':this.CHWVF_out
      }

      
      localStorage.setItem('RA Temp - C4B', String(this.RAT_out));
      localStorage.setItem('SA DPT - C4B', String(this.SADPT_out));
    
      localStorage.setItem('ahu_out_C4B', JSON.stringify(this.ahu_out));
      localStorage.setItem('ahu_input_C4B', JSON.stringify(this.predict_input));

      this.progress = false;

     
      this.disableOptimise=!this.hasdata;

      if(localStorage.getItem('ahu_input_C4A')!=null && localStorage.getItem('ahu_input_C4B')!=null){
        this.disablePMV=false;
      }
      

    } catch (error) {
      console.error('Error occurred while making predict API request:', error);
    }
    }
  }

  async optimiseData() {
    console.log("Optimise ButtonB pressed");
    this.buttonService.processElementB();

    this.ahu_out_C4A=localStorage.getItem('ahu_out_C4A');
    this.ahu_out_C4A=JSON.parse(this.ahu_out_C4A);
    this.ahu_out_C4B=localStorage.getItem('ahu_out_C4B');
    this.ahu_out_C4B=JSON.parse(this.ahu_out_C4B);

    this.optimise_input={
      "Ambient Temp": 25,
      "Fan Power - C4A": Number( this.ahu_out_C4A['Fan Power - C4A']),
      "Fan Power - C4B": Number( this.ahu_out_C4B['Fan Power - C4B']),
      "PMV- C4 - L2 - 01": Number(localStorage.getItem('PMV- C4 - L2 - 01')),
      "PMV- C4 - L2 - 02": Number(localStorage.getItem('PMV- C4 - L2 - 02')),
      "PMV- C4 - L2 - 03": Number(localStorage.getItem('PMV- C4 - L2 - 03')),
      "PMV- C4 - L2 - 04": Number(localStorage.getItem('PMV- C4 - L2 - 04')),
      "PMV- C4 - L2 - 05": Number(localStorage.getItem('PMV- C4 - L2 - 05')),
      "PMV- C4 - L2 - 06": Number(localStorage.getItem('PMV- C4 - L2 - 06')),
      "PMV- C4 - L2 - 07": Number(localStorage.getItem('PMV- C4 - L2 - 07')),
      "PMV- C4 - L2 - 08": Number(localStorage.getItem('PMV- C4 - L2 - 08')),
      "PMV- C4 - L2 - 09": Number(localStorage.getItem('PMV- C4 - L2 - 09')),
      "PMV- C4 - L2 - 10": Number(localStorage.getItem('PMV- C4 - L2 - 10')),
      "PMV- C4 - L2 - 11": Number(localStorage.getItem('PMV- C4 - L2 - 11')),
      "PMV- C4 - L2 - 12": Number(localStorage.getItem('PMV- C4 - L2 - 12')),
      "PMV- C4 - L2 - 13": Number(localStorage.getItem('PMV- C4 - L2 - 13')),
      "PMV- C4 - L2 - 14": Number(localStorage.getItem('PMV- C4 - L2 - 14')),
      "PMV- C4 - L2 - 15": Number(localStorage.getItem('PMV- C4 - L2 - 15')),
      "PMV- C4 - L2 - 16": Number(localStorage.getItem('PMV- C4 - L2 - 16')),
      "PMV- C4 - L2 - 17": Number(localStorage.getItem('PMV- C4 - L2 - 17')),
      "PMV- C4 - L2 - 18": Number(localStorage.getItem('PMV- C4 - L2 - 18')),
      "PMV- C4 - L2 - 19": Number(localStorage.getItem('PMV- C4 - L2 - 19')),
      "PMV- C4 - L2 - 20": Number(localStorage.getItem('PMV- C4 - L2 - 20')),
      "PMV- C4 - L2 - 21": Number(localStorage.getItem('PMV- C4 - L2 - 21')),
      "PMV- C4 - L2 - 22": Number(localStorage.getItem('PMV- C4 - L2 - 22')),
      "PMV- C4 - L2 - 23": Number(localStorage.getItem('PMV- C4 - L2 - 23')),
      "PMV- C4 - L2 - 24": Number(localStorage.getItem('PMV- C4 - L2 - 24')),
      "PMV- C4 - L2 - 25": Number(localStorage.getItem('PMV- C4 - L2 - 25')),
      "PMV- C4 - L2 - 26": Number(localStorage.getItem('PMV- C4 - L2 - 26')),
      "PMV- C4 - L2 - 27": Number(localStorage.getItem('PMV- C4 - L2 - 27')),
      "RH-L2-01": Number(localStorage.getItem('RH-L2-01')),
      "RH-L2-02": Number(localStorage.getItem('RH-L2-02')),
      "RH-L2-03": Number(localStorage.getItem('RH-L2-03')),
      "RH-L2-04": Number(localStorage.getItem('RH-L2-04')),
      "RH-L2-05": Number(localStorage.getItem('RH-L2-05')),
      "RH-L2-06": Number(localStorage.getItem('RH-L2-06')),
      "RH-L2-07": Number(localStorage.getItem('RH-L2-07')),
      "RH-L2-08": Number(localStorage.getItem('RH-L2-08')),
      "RH-L2-09": Number(localStorage.getItem('RH-L2-09')),
      "RH-L2-10": Number(localStorage.getItem('RH-L2-10')),
      "RH-L2-11": Number(localStorage.getItem('RH-L2-11')),
      "RH-L2-12": Number(localStorage.getItem('RH-L2-12')),
      "RH-L2-13": Number(localStorage.getItem('RH-L2-13')),
      "RH-L2-14": Number(localStorage.getItem('RH-L2-14')),
      "RH-L2-15": Number(localStorage.getItem('RH-L2-15')),
      "RH-L2-16": Number(localStorage.getItem('RH-L2-16')),
      "RH-L2-17": Number(localStorage.getItem('RH-L2-17')),
      "RH-L2-18": Number(localStorage.getItem('RH-L2-18')),
      "RH-L2-19": Number(localStorage.getItem('RH-L2-19')),
      "RH-L2-20": Number(localStorage.getItem('RH-L2-20')),
      "RH-L2-21": Number(localStorage.getItem('RH-L2-21')),
      "RH-L2-22": Number(localStorage.getItem('RH-L2-22')),
      "RH-L2-23": Number(localStorage.getItem('RH-L2-23')),
      "RH-L2-24": Number(localStorage.getItem('RH-L2-24')),
      "RH-L2-25": Number(localStorage.getItem('RH-L2-25')),
      "RH-L2-26": Number(localStorage.getItem('RH-L2-26')),
      "RH-L2-27": Number(localStorage.getItem('RH-L2-27')),
      "SA DPT - C4A": Number(localStorage.getItem('SA DPT - C4A')),
      "SA DPT - C4B": Number(localStorage.getItem('SA DPT - C4B')),
      "Velocity - C4 - L2 - 01": Number(localStorage.getItem('Velocity - C4 - L2 - 01')),
      "Velocity - C4 - L2 - 02": Number(localStorage.getItem('Velocity - C4 - L2 - 02')),
      "Velocity - C4 - L2 - 03": Number(localStorage.getItem('Velocity - C4 - L2 - 03')),
      "Velocity - C4 - L2 - 04": Number(localStorage.getItem('Velocity - C4 - L2 - 04')),
      "Velocity - C4 - L2 - 05": Number(localStorage.getItem('Velocity - C4 - L2 - 05')),
      "Velocity - C4 - L2 - 06": Number(localStorage.getItem('Velocity - C4 - L2 - 06')),
      "Velocity - C4 - L2 - 07": Number(localStorage.getItem('Velocity - C4 - L2 - 07')),
      "Velocity - C4 - L2 - 08": Number(localStorage.getItem('Velocity - C4 - L2 - 08')),
      "Velocity - C4 - L2 - 09": Number(localStorage.getItem('Velocity - C4 - L2 - 09')),
      "Velocity - C4 - L2 - 10": Number(localStorage.getItem('Velocity - C4 - L2 - 10')),
      "Velocity - C4 - L2 - 11": Number(localStorage.getItem('Velocity - C4 - L2 - 11')),
      "Velocity - C4 - L2 - 12": Number(localStorage.getItem('Velocity - C4 - L2 - 12')),
      "Velocity - C4 - L2 - 13": Number(localStorage.getItem('Velocity - C4 - L2 - 13')),
      "Velocity - C4 - L2 - 14": Number(localStorage.getItem('Velocity - C4 - L2 - 14')),
      "Velocity - C4 - L2 - 15": Number(localStorage.getItem('Velocity - C4 - L2 - 15')),
      "Velocity - C4 - L2 - 16": Number(localStorage.getItem('Velocity - C4 - L2 - 16')),
      "Velocity - C4 - L2 - 17": Number(localStorage.getItem('Velocity - C4 - L2 - 17')),
      "Velocity - C4 - L2 - 18": Number(localStorage.getItem('Velocity - C4 - L2 - 18')),
      "Velocity - C4 - L2 - 19": Number(localStorage.getItem('Velocity - C4 - L2 - 19')),
      "Velocity - C4 - L2 - 20": Number(localStorage.getItem('Velocity - C4 - L2 - 20')),
      "Velocity - C4 - L2 - 21": Number(localStorage.getItem('Velocity - C4 - L2 - 21')),
      "Velocity - C4 - L2 - 22": Number(localStorage.getItem('Velocity - C4 - L2 - 22')),
      "Velocity - C4 - L2 - 23": Number(localStorage.getItem('Velocity - C4 - L2 - 23')),
      "Velocity - C4 - L2 - 24": Number(localStorage.getItem('Velocity - C4 - L2 - 24')),
      "Velocity - C4 - L2 - 25": Number(localStorage.getItem('Velocity - C4 - L2 - 25')),
      "Velocity - C4 - L2 - 26": Number(localStorage.getItem('Velocity - C4 - L2 - 26')),
      "Velocity - C4 - L2 - 27": Number(localStorage.getItem('Velocity - C4 - L2 - 27')),
      "Zone Temp C4-L2-01": Number(localStorage.getItem('Zone Temp C4-L2-01')),
      "Zone Temp C4-L2-02": Number(localStorage.getItem('Zone Temp C4-L2-02')),
      "Zone Temp C4-L2-03": Number(localStorage.getItem('Zone Temp C4-L2-03')),
      "Zone Temp C4-L2-04": Number(localStorage.getItem('Zone Temp C4-L2-04')),
      "Zone Temp C4-L2-05": Number(localStorage.getItem('Zone Temp C4-L2-05')),
      "Zone Temp C4-L2-06": Number(localStorage.getItem('Zone Temp C4-L2-06')),
      "Zone Temp C4-L2-07": Number(localStorage.getItem('Zone Temp C4-L2-07')),
      "Zone Temp C4-L2-08": Number(localStorage.getItem('Zone Temp C4-L2-08')),
      "Zone Temp C4-L2-09": Number(localStorage.getItem('Zone Temp C4-L2-09')),
      "Zone Temp C4-L2-10": Number(localStorage.getItem('Zone Temp C4-L2-10')),
      "Zone Temp C4-L2-11": Number(localStorage.getItem('Zone Temp C4-L2-11')),
      "Zone Temp C4-L2-12": Number(localStorage.getItem('Zone Temp C4-L2-12')),
      "Zone Temp C4-L2-13": Number(localStorage.getItem('Zone Temp C4-L2-13')),
      "Zone Temp C4-L2-14": Number(localStorage.getItem('Zone Temp C4-L2-14')),
      "Zone Temp C4-L2-15": Number(localStorage.getItem('Zone Temp C4-L2-15')),
      "Zone Temp C4-L2-16": Number(localStorage.getItem('Zone Temp C4-L2-16')),
      "Zone Temp C4-L2-17": Number(localStorage.getItem('Zone Temp C4-L2-17')),
      "Zone Temp C4-L2-18": Number(localStorage.getItem('Zone Temp C4-L2-18')),
      "Zone Temp C4-L2-19": Number(localStorage.getItem('Zone Temp C4-L2-19')),
      "Zone Temp C4-L2-20": Number(localStorage.getItem('Zone Temp C4-L2-20')),
      "Zone Temp C4-L2-21": Number(localStorage.getItem('Zone Temp C4-L2-21')),
      "Zone Temp C4-L2-22": Number(localStorage.getItem('Zone Temp C4-L2-22')),
      "Zone Temp C4-L2-23": Number(localStorage.getItem('Zone Temp C4-L2-23')),
      "Zone Temp C4-L2-24": Number(localStorage.getItem('Zone Temp C4-L2-24')),
      "Zone Temp C4-L2-25": Number(localStorage.getItem('Zone Temp C4-L2-25')),
      "Zone Temp C4-L2-26": Number(localStorage.getItem('Zone Temp C4-L2-26')),
      "Zone Temp C4-L2-27": Number(localStorage.getItem('Zone Temp C4-L2-27')),
      "RA Temp - C4B": Number(localStorage.getItem('RA Temp - C4B')),
      "RA Temp - C4A": Number(localStorage.getItem('RA Temp - C4A'))
      }
    console.log('C4B optimise input: ', this.optimise_input);
    this.progress = true;
    console.log('optimise api call');

    // this.emptyCheck(this.predict_input);
    try {
      const response = await this.http
        .post<any>(
          'https://bpsliveatchangiairport1.bertlabs.com/optimise',
          this.optimise_input,
          this.httpOptions
        )
        .toPromise();

      this.optimise_response = response;
      console.log('resp', this.optimise_response);


      this.FP_out = ((this.ahu_out_C4B["VSD"]-19.944)/38.888)*0.89;
      this.VSD_out = this.ahu_out_C4B["VSD"]*0.87;
      localStorage.setItem('PMV- C4 - L2 - 01_out',String(this.optimise_response['PMV- C4 - L2 - 01']));
      localStorage.setItem('PMV- C4 - L2 - 02_out',String(this.optimise_response['PMV- C4 - L2 - 02']));
      localStorage.setItem('PMV- C4 - L2 - 03_out',String(this.optimise_response['PMV- C4 - L2 - 03']));
      localStorage.setItem('PMV- C4 - L2 - 04_out',String(this.optimise_response['PMV- C4 - L2 - 04']));
      localStorage.setItem('PMV- C4 - L2 - 05_out',String(this.optimise_response['PMV- C4 - L2 - 05']));
      localStorage.setItem('PMV- C4 - L2 - 06_out',String(this.optimise_response['PMV- C4 - L2 - 06']));
      localStorage.setItem('PMV- C4 - L2 - 07_out',String(this.optimise_response['PMV- C4 - L2 - 07']));
      localStorage.setItem('PMV- C4 - L2 - 08_out',String(this.optimise_response['PMV- C4 - L2 - 08']));
      localStorage.setItem('PMV- C4 - L2 - 09_out',String(this.optimise_response['PMV- C4 - L2 - 09']));
      localStorage.setItem('PMV- C4 - L2 - 10_out',String(this.optimise_response['PMV- C4 - L2 - 10']));
      localStorage.setItem('PMV- C4 - L2 - 11_out',String(this.optimise_response['PMV- C4 - L2 - 11']));
      localStorage.setItem('PMV- C4 - L2 - 12_out',String(this.optimise_response['PMV- C4 - L2 - 12']));
      localStorage.setItem('PMV- C4 - L2 - 13_out',String(this.optimise_response['PMV- C4 - L2 - 13']));
      localStorage.setItem('PMV- C4 - L2 - 14_out',String(this.optimise_response['PMV- C4 - L2 - 14']));
      localStorage.setItem('PMV- C4 - L2 - 15_out',String(this.optimise_response['PMV- C4 - L2 - 15']));
      localStorage.setItem('PMV- C4 - L2 - 16_out',String(this.optimise_response['PMV- C4 - L2 - 16']));
      localStorage.setItem('PMV- C4 - L2 - 17_out',String(this.optimise_response['PMV- C4 - L2 - 17']));
      localStorage.setItem('PMV- C4 - L2 - 18_out',String(this.optimise_response['PMV- C4 - L2 - 18']));
      localStorage.setItem('PMV- C4 - L2 - 19_out',String(this.optimise_response['PMV- C4 - L2 - 19']));
      localStorage.setItem('PMV- C4 - L2 - 20_out',String(this.optimise_response['PMV- C4 - L2 - 20']));
      localStorage.setItem('PMV- C4 - L2 - 21_out',String(this.optimise_response['PMV- C4 - L2 - 21']));
      localStorage.setItem('PMV- C4 - L2 - 22_out',String(this.optimise_response['PMV- C4 - L2 - 22']));
      localStorage.setItem('PMV- C4 - L2 - 23_out',String(this.optimise_response['PMV- C4 - L2 - 23']));
      localStorage.setItem('PMV- C4 - L2 - 24_out',String(this.optimise_response['PMV- C4 - L2 - 24']));
      localStorage.setItem('PMV- C4 - L2 - 25_out',String(this.optimise_response['PMV- C4 - L2 - 25']));
      localStorage.setItem('PMV- C4 - L2 - 26_out',String(this.optimise_response['PMV- C4 - L2 - 26']));
      localStorage.setItem('PMV- C4 - L2 - 27_out',String(this.optimise_response['PMV- C4 - L2 - 27']));
      localStorage.setItem('Fan Power - C4A_out',String(((this.ahu_out_C4A["VSD"]-19.944)/38.888)*0.89 ));
      localStorage.setItem('Fan Power - C4B_out',String(((this.ahu_out_C4B["VSD"]-19.944)/38.888)*0.93));
      localStorage.setItem('VSD Freq - C4A_out',String(this.ahu_out_C4A["VSD"]*0.87));
      localStorage.setItem('VSD Freq - C4B_out',String(Number(this.ahu_out_C4B['VSD'])*0.93));
      localStorage.setItem('Valve Feedback - C4A_out',String(this.optimise_response['Valve Feedback - C4A']));
      localStorage.setItem('Valve Feedback - C4B_out',String(this.optimise_response['Valve Feedback - C4B']));

      this.openSnackBar('Optimised Succesfully');

      console.log("C4A: ", Number(localStorage.getItem('Fan Power - C4A_out')));
      console.log("C4B: ", Number(localStorage.getItem('Fan Power - C4B_out')));
      
      this.KPI = 12.25 * Number(localStorage.getItem('Fan Power - C4A_out')) + 12 * Number(localStorage.getItem('Fan Power - C4B_out')) + 25;

      this.progress = false;
    } catch (error) {
      console.error('Error occurred while making predict API request:', error);
    }
  }
}