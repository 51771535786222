import { Component } from '@angular/core';

@Component({
  selector: 'D-pier',
  templateUrl: './d-pier.component.html',
  styleUrls: ['./d-pier.component.css']
})
export class DPierComponent {

}
