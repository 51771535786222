import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'Card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {

  @Input() item:any;
  @Input() title!:string;
  @Input() image!:string;
  @Input() disabled!:boolean;
  
  constructor(private router:Router){

  }
  



}
