<div
  
  class="c-pier"

>
<svg id="c-pier-svg" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 311.684 286.035">
  <g id="Group_2372" data-name="Group 2372" transform="translate(-1409.666 -249.918)">
    <path stroke="#313131" stroke-width="1.5" id="Path_4497" data-name="Path 4497" d="M17199.086,5625.114l36.609,36.849-75.594,75.411h-82.256v173.775h-97.158V5737.374h-56.668l-.008-52.045h214.771Z" transform="translate(-15514.346 -5375.196)" fill="#2666d2"/>
    <text id="C" transform="translate(1495.5 399)" fill="#fff" stroke="#fff" stroke-width="1" font-size="52" font-family="Numans, sans-serif"><tspan x="0" y="47">C</tspan></text>
    <text id="TRANSFER" transform="translate(1479.5 384.102)" fill="#fff" stroke="#fff" stroke-width="0.5" font-size="12" font-family="Numans, sans-serif"><tspan x="0" y="11">TRANSFER</tspan></text>
  </g>
</svg>

  
  
</div>