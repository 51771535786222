import { Component } from '@angular/core';

@Component({
  selector: 'Valve',
  templateUrl: './valve.component.html',
  styleUrls: ['./valve.component.css']
})
export class ValveComponent {

}
