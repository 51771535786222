<div
  
  class="d-pier"

>

  <svg id="d-pier-svg" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 310.832 280.453">
    <g id="Group_2370" data-name="Group 2370" transform="translate(-1410.15 -535.803)">
      <path stroke="#313131" stroke-width="1.5" id="Path_4538" data-name="Path 4538" d="M17196.457,5916.773l35.875-35.785-80.242-80.147h-77.461V5636.321h-96.881v167.553H16921.5V5852.4h210.412Z" transform="translate(-15511.35 -5100.518)" fill="#2666d2"/>
      <text id="D" transform="translate(1495.5 623)" fill="#fff" stroke="#fff" stroke-width="1" font-size="52" font-family="Numans, sans-serif"><tspan x="0" y="47">D</tspan></text>
      <text id="TRANSFER" transform="translate(1479.5 608.5)" fill="#fff" stroke="#fff" stroke-width="0.5" font-size="12" font-family="Numans, sans-serif"><tspan x="0" y="11">TRANSFER</tspan></text>
    </g>
  </svg>
  
  
</div>