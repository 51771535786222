<div style="position: relative; height: 100%; width: 100%;">
  
    <div
      style="
        width: 100%;
        height: 100%;
        min-width: 1200px;
        display: flex;
        flex-direction: column;
        position: absolute;
      "
    >

    <mat-progress-bar
    mode="indeterminate"
    [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"
  ></mat-progress-bar>
    
      
      <div style="display: flex; flex-direction: row; height: 100%; width: 100%">

       
      <!-- main body -->
      <div
      style="
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        align-items: center;
        gap: 10px;
        overflow-y: scroll;
        padding-left: 120px;
       
      "
    >

        <div
          style="
            height: 550px;
            width: 1000px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          "
        >
          <!-- componets -->
          <div style="height: 500px; width: 1000px; position: absolute">
            <div style="margin-top: 2px;
            margin-left: 0px;
            height: 497px;">
              <C-pier-security-screening-room-two [pmv]="pmv_avg"></C-pier-security-screening-room-two>
            </div>
          </div>
  
          <!-- map -->
          <div
            style="
              height: 500px;
              width: 1000px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
            "
          >
            <img
              src="/assets/images/security-screen-2.svg"
              width="1000px"
              height="500px"
            />
          </div>
  
          <!-- fields -->

          <!-- 1 -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 287px; margin-left: 540px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input [ngModel]="pmv_out13 | number : '1.2-2'"
                (ngModelChange)="pmv_out13 = $event"/>
                
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 322px; margin-left: 540px">
              <div class="input-group">
                <img src="assets/images/temp.svg" />
                <input [ngModel]="pmv_out13 | number : '1.2-2'"
                (ngModelChange)="pmv_out13 = $event"/>
                
              </div>
            </div>
          </div>
          <!-- 2 -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 290px; margin-left: 350px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input [ngModel]="pmv_out14 | number : '1.2-2'"
                (ngModelChange)="pmv_out14 = $event"/>
                
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 325px; margin-left: 350px">
              <div class="input-group">
                <img src="assets/images/temp.svg" />
                <input [ngModel]="pmv_out14 | number : '1.2-2'"
                (ngModelChange)="pmv_out14 = $event"/>
                
              </div>
            </div>
          </div>
          <!-- 3 -->
          <!-- <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 395px; margin-left: 455px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input [ngModel]="pmv_out15 | number : '1.2-2'"
                (ngModelChange)="pmv_out15 = $event"/>
                
              </div>
            </div>
          </div> -->
          <!-- <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 430px; margin-left: 455px">
              <div class="input-group">
                <img src="assets/images/temp.svg" />
                <input [ngModel]="pmv_out15 | number : '1.2-2'"
                (ngModelChange)="pmv_out15 = $event"/>
                
              </div>
            </div>
          </div> -->
        </div>

         <!-- legends -->
         <div
          style="display: flex; flex-direction: row; width: 1000px; gap: 10px"
        >
          <div
            class="pmv-pallet"
            style="background-color: #6A87BB; color:#e3e3e3; font-weight:500;"
          >
            <span style="font-size: 12px">-3 COLD</span>
          </div>
          <div class="pmv-pallet" style="background-color: #9DB2D5; font-weight:500;">
            <span style="font-size: 12px">-2 COOL</span>
          </div>
          <div class="pmv-pallet" style="background-color: #C2C8D2; font-weight:500;">
            <span style="font-size: 12px">-1 SLIGHTLY COOL</span>
          </div>
          <div class="pmv-pallet" style=" background-color: #ECEFDF; font-weight:500;">
            <span style="font-size: 12px; line-height:14px;">0 NEUTRAL <br/> (COMFORT)</span>
          </div>
          <div class="pmv-pallet" style="background-color: #D9B7B7; font-weight:500;">
            <span style="font-size: 12px">+1 SLIGHTLY WARM</span>
          </div>
          <div class="pmv-pallet" style="background-color: #C89594; font-weight:500;">
            <span style="font-size: 12px">+2 WARM</span>
          </div>
          <div class="pmv-pallet" style="background-color: #A7514F; color:#e3e3e3; font-weight:500;">
            <span style="font-size: 12px">+3 HOT</span>
          </div>
        </div>
      </div>

      <!-- input side bar -->
      <div
      style="
        width: 450px;
        height: 100%;
        box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.06),
          0px 0px 2px rgba(25, 25, 25, 0.12);
      "
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          padding: 10px;
          justify-content: space-between;
        "
      >

      <div style="display: flex;flex-direction: column;gap: 15px;">

         <!-- form-1 -->
         <div style="display: flex;flex-direction: column; gap: 10px;">
             <span class="span-text-dark" style="text-align: center"
                 >VAV-AHU C4-L2-13</span
             >
     
             <div
             style="
               display: flex;
               flex-direction: row;
               gap: 10px;
               justify-content: space-between;
             "
           >
             <div
               style="
                 display: flex;
                 flex-direction: row;
                 justify-content: space-between;
                 align-items: center;
                 width: 50%;
               "
             >
               <span
                 class="span-text-dark"
                 style="font-size: 10px; font-weight: 500;line-height: 12px;"
                 >ZONE<br>TEMP</span
               >
 
               <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                 (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->
 
               <div class="input-group" style="width: 100px; margin-left:5px;">
                 <img src="/assets/images/temperature-celcius.png" />
                 <input [(ngModel)]="ZT13" />
               </div>
             </div>
 
             <div
               style="
                 display: flex;
                 flex-direction: row;
                 justify-content: space-between;
                 align-items: center;
                 width: 50%;
               "
             >
               <span
                 class="span-text-dark"
                 style="font-size: 10px; font-weight: 500"
                 >VELOCITY</span
               >
 
               <div class="input-group" style="width: 100px; margin-left:5px;">
                 <img src="/assets/images/velocity.png" />
                 <input [(ngModel)]="Velocity13" />
                 <span>m/s</span>
               </div>
             </div>

           </div>
 
           <div
             style="
               display: flex;
               flex-direction: row;
               gap: 10px;
               justify-content: space-between;
             "
           >
             <div
               style="
                 display: flex;
                 flex-direction: row;
                 justify-content: space-between;
                 align-items: center;
                 width: 50%;
               "
             >
               <span
                 class="span-text-dark"
                 style="font-size: 10px; font-weight: 500;line-height: 12px;"
                 >RH</span
               >
 
               <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                 (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->
 
               <div class="input-group" style="width: 100px; margin-left:5px;">
                 <img src="/assets/images/percentage.png" />
                 <input [(ngModel)]="ZT13" />
               </div>
             </div>
 
             <div
               style="
                 display: flex;
                 flex-direction: row;
                 justify-content: space-between;
                 align-items: center;
                 width: 50%;
               "
             >
               <span
                 class="span-text-dark"
                 style="font-size: 10px; font-weight: 500; line-height:12px;"
                 >AIR <br> FLOW</span
               >
 
               <div class="input-group" style="width: 100px; margin-left:5px;">
                 <img src="/assets/images/velocity.png" />
                 <input [(ngModel)]="Velocity13" />
                 <span>cfm</span>
               </div>
             </div>

           </div>
           
          <div
          style="
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: space-between;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 50%;
            "
          >
            <span
              class="span-text-dark"
              style="font-size: 10px; font-weight: 500;line-height: 12px;"
              >DAMPER <br> POSITION</span
            >

            <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
              (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

            <div class="input-group" style="width: 100px; margin-left:5px;">
              <img src="/assets/images/percentage.png" />
              <input [(ngModel)]="RH13" />
            </div>

          </div>
          <div style="width: 50%;"></div>
        </div>
     
        
         </div>

         <!-- form 2 -->

        
         <div style="display: flex;flex-direction: column; gap: 10px;">
          <span class="span-text-dark" style="text-align: center"
              >VAV-AHU C4-L2-14</span
          >
  
          <div
          style="
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: space-between;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 50%;
            "
          >
            <span
              class="span-text-dark"
              style="font-size: 10px; font-weight: 500;line-height: 12px;"
              >ZONE<br>TEMP</span
            >

            <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
              (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

            <div class="input-group" style="width: 100px; margin-left:5px;">
              <img src="/assets/images/temperature-celcius.png" />
              <input [(ngModel)]="ZT13" />
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 50%;
            "
          >
            <span
              class="span-text-dark"
              style="font-size: 10px; font-weight: 500"
              >VELOCITY</span
            >

            <div class="input-group" style="width: 100px; margin-left:5px;">
              <img src="/assets/images/velocity.png" />
              <input [(ngModel)]="Velocity13" />
              <span>m/s</span>
            </div>
          </div>

        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: space-between;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 50%;
            "
          >
            <span
              class="span-text-dark"
              style="font-size: 10px; font-weight: 500;line-height: 12px;"
              >RH</span
            >

            <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
              (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

            <div class="input-group" style="width: 100px; margin-left:5px;">
              <img src="/assets/images/percentage.png" />
              <input [(ngModel)]="ZT13" />
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 50%;
            "
          >
            <span
              class="span-text-dark"
              style="font-size: 10px; font-weight: 500; line-height:12px;"
              >AIR <br> FLOW</span
            >

            <div class="input-group" style="width: 100px; margin-left:5px;">
              <img src="/assets/images/velocity.png" />
              <input [(ngModel)]="Velocity13" />
              <span>cfm</span>
            </div>
          </div>

        </div>
        
       <div
       style="
         display: flex;
         flex-direction: row;
         gap: 10px;
         justify-content: space-between;
       "
     >
       <div
         style="
           display: flex;
           flex-direction: row;
           justify-content: space-between;
           align-items: center;
           width: 50%;
         "
       >
         <span
           class="span-text-dark"
           style="font-size: 10px; font-weight: 500;line-height: 12px;"
           >DAMPER <br> POSITION</span
         >

         <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
           (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

         <div class="input-group" style="width: 100px; margin-left:5px;">
           <img src="/assets/images/percentage.png" />
           <input [(ngModel)]="RH13" />
         </div>

       </div>
       <div style="width: 50%;"></div>
     </div>
  
     
      </div>

         <!-- form 3 -->
        <div style="display: flex;flex-direction: column; gap: 10px;">
          <!-- <span class="span-text-dark" style="text-align: center"
              >VAV-AHU C4-L2-15</span
          >
  
          <div
          style="
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: space-between;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 50%;
            "
          >
            <span
              class="span-text-dark"
              style="font-size: 10px; font-weight: 500;line-height: 12px;"
              >ZONE<br>TEMP</span
            >

            
            <div class="input-group" style="width: 100px; margin-left:5px;">
              <img src="/assets/images/temperature-celcius.png" />
              <input [(ngModel)]="ZT13" />
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 50%;
            "
          >
            <span
              class="span-text-dark"
              style="font-size: 10px; font-weight: 500"
              >VELOCITY</span
            >

            <div class="input-group" style="width: 100px; margin-left:5px;">
              <img src="/assets/images/velocity.png" />
              <input [(ngModel)]="Velocity13" />
              <span>m/s</span>
            </div>
          </div>

        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: space-between;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 50%;
            "
          >
            <span
              class="span-text-dark"
              style="font-size: 10px; font-weight: 500;line-height: 12px;"
              >RH</span
            >

            <div class="input-group" style="width: 100px; margin-left:5px;">
              <img src="/assets/images/percentage.png" />
              <input [(ngModel)]="ZT13" />
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 50%;
            "
          >
            <span
              class="span-text-dark"
              style="font-size: 10px; font-weight: 500; line-height:12px;"
              >AIR <br> FLOW</span
            >

            <div class="input-group" style="width: 100px; margin-left:5px;">
              <img src="/assets/images/velocity.png" />
              <input [(ngModel)]="Velocity13" />
              <span>cfm</span>
            </div>
          </div>

        </div>
        
       <div
       style="
         display: flex;
         flex-direction: row;
         gap: 10px;
         justify-content: space-between;
       "
     >
       <div
         style="
           display: flex;
           flex-direction: row;
           justify-content: space-between;
           align-items: center;
           width: 50%;
         "
       >
         <span
           class="span-text-dark"
           style="font-size: 10px; font-weight: 500;line-height: 12px;"
           >DAMPER <br> POSITION</span
         >

         
         <div class="input-group" style="width: 100px; margin-left:5px;">
           <img src="/assets/images/percentage.png" />
           <input [(ngModel)]="RH13" />
         </div>

       </div>
       <div style="width: 50%;"></div>
     </div> -->
     <div
            style="
                display: flex;
                flex-direction: row;
                width: 100%;
                gap: 10px;
                justify-content: flex-end;
                margin-top: 10px;
                margin-bottom: 10px;
            "
            >
            <button
                style="
                width: 30%;
                background-color: var(--blue-one);
                border-radius: 20px;
                border: none;
                height: auto;
                padding: 7px;
                color: white;
                cursor: pointer;
                "
                (click)="predictData()"
            >
                Predict
            </button>
            <button
                style="
                width: 30%;
                border-radius: 20px;
                border: none;
                height: auto;
                padding: 7px;
                color: white;
                cursor: pointer;
                "
                class="button-disabled"
                
            >
                Optimise
            </button>
            </div>
  
     
      </div>
     </div>
         
       

        <!-- <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 5px;
          "
        >
          <span class="span-text-dark" style="font-size: 12px"
            >powered by
          </span>
          <img
            src="/assets/logos/bertlabs-logo.png"
            height="20px"
            width="100px"
          />
        </div> -->
      </div>
    </div> 
      <!-- <div
      style="
        width: 450px;
        height: 100%;
        box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.06),
          0px 0px 2px rgba(25, 25, 25, 0.12);
      "
    >
    </div> -->

      </div>
   
    </div>
  </div>
  