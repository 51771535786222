import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-gate-hold-room-d-two-map',
  templateUrl: './gate-hold-room-d-two-map.component.html',
  styleUrls: ['./gate-hold-room-d-two-map.component.css']
})
export class GateHoldRoomDTwoMapComponent {
  progress: boolean = false;
  disableOptimise: boolean = true;
  predict_input: any;
  predict_response: any;
  xinput: any;

  //predict input params
  // inputs from form

  ZT18: number;
  RH18:number;
  Velocity18: number;

  ZT20: number;
  RH20:number;
  Velocity20: number;

  // output fields predict
  pmv_out18: number;
  pmv_out20: number;

  pmv_avg:number;

 
  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {

   
    // 'Zone Temp D7-L2-18': Number(this.ZT18),
    // 'RH D7-L2-18': Number(this.RH18),
    // 'Velocity D7-L2-18': Number(this.Velocity18),
    // 'Zone Temp D7-L2-20': Number(this.ZT20),
    // 'RH D7-L2-20': Number(this.RH20),
    // 'Velocity D7-L2-20': Number(this.Velocity20),
    if(localStorage.getItem('gate-two-map')){
      this.xinput=localStorage.getItem('gate-two-map');
      this.xinput=JSON.parse(this.xinput);

      this.ZT18 = Number( this.xinput['Zone Temp D7-L2-18']);
      this.Velocity18 = Number( this.xinput['Velocity D7-L2-18']);
      this.RH18 = Number( this.xinput['RH D7-L2-18']);
      this.ZT20 = Number( this.xinput['Zone Temp D7-L2-20']);
      this.Velocity20 = Number( this.xinput['Velocity D7-L2-20']);
      this.RH20 = Number( this.xinput['RH D7-L2-20']);
    }else{
      this.ZT18 = 0;
      this.Velocity18 = 0;
      this.RH18 = 0;
      this.ZT20 = 0;
      this.Velocity20 = 0;
      this.RH20 = 0;
    }

    if(localStorage.getItem('PMV D7-L2-18')){
      this.pmv_out18= Number(localStorage.getItem('PMV D7-L2-18'));
     }else{
       this.pmv_out18 = 0;
     }
 
     
     if(localStorage.getItem('PMV D7-L2-20')){
       this.pmv_out20= Number(localStorage.getItem('PMV D7-L2-20'));
      }else{
        this.pmv_out20 = 0;
      }

      if(localStorage.getItem('PMV D7-L2-18') && localStorage.getItem('PMV D7-L2-20')){
        this.pmv_avg=(this.pmv_out18+this.pmv_out20)/2;
       }else{
        this.pmv_avg=0;
      }
 
  }


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
   
    if(this.ZT18<22 || this.ZT18>24.8){
      this.openSnackBar('ZT18 should be in range [22,24.8]');
      return false;
    }else if(this.Velocity18<0.64 || this.Velocity18>4.85){
      this.openSnackBar('Velocity18 should be in range [0.64,4.85]');
      return false;
    }else if(this.RH18<42.62 || this.RH18>53.92){
      this.openSnackBar('RH18 should be in range [42.62,53.92]');
      return false;
    }else if(this.ZT20<21.8 || this.ZT20>24.7){
      this.openSnackBar('ZT20 should be in range [21.8,24.7]');
      return false;
    }else if(this.Velocity20<0.42 || this.Velocity20>3.11){
      this.openSnackBar('Velocity20 should be in range [0.42,3.11]');
      return false;
    }else if(this.RH20<43.14 || this.RH20>54.55){
      this.openSnackBar('RH20 should be in range [43.14,54.55]');
      return false;
    }
    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }


  // this fuction is use to predict PMV
  async predictData(){
    
    if (this.validate()) {
      this.progress = true;
      console.log('predict input', this.predict_input);

      this.predict_input= {
        'Zone Temp D7-L2-18': Number(this.ZT18),
        'RH D7-L2-18': Number(this.RH18),
        'Velocity D7-L2-18': Number(this.Velocity18),
        'Zone Temp D7-L2-20': Number(this.ZT20),
        'RH D7-L2-20': Number(this.RH20),
        'Velocity D7-L2-20': Number(this.Velocity20),
        'model_no.': 4,
      };

    
      try {
        const response = await this.http
          .post<any>(
            'https://bpsliveatchangiairport.bertlabs.com/predictsec4/',
            this.predict_input,
            this.httpOptions
          )
          .toPromise();

        this.predict_response = response;
        console.log('predict outout', this.predict_response);

        this.openSnackBar('Predicted Succesfully');

        this.pmv_out18 = this.predict_response['PMV D7-L2-18'];
        this.pmv_out20 = this.predict_response['PMV D7-L2-20'];

        this.pmv_avg=(this.pmv_out18+this.pmv_out20)/2;


        this.progress = false;
        this.disableOptimise = false;
        localStorage.setItem('model_four', JSON.stringify(this.pmv_avg));

        localStorage.setItem('PMV D7-L2-18',JSON.stringify(this.pmv_out18));
        localStorage.setItem('PMV D7-L2-20',JSON.stringify(this.pmv_out20));

        localStorage.setItem('gate-two-map', JSON.stringify(this.predict_input));

      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }

}   
