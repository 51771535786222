import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'C-pier-security-screening-room-one-map',
  templateUrl: './c-pier-security-screening-room-one-map.component.html',
  styleUrls: ['./c-pier-security-screening-room-one-map.component.css']
})
// export class CPierSecurityScreeningRoomOneMapComponent {
//    progress:boolean=false;
// }
export class CPierSecurityScreeningRoomOneMapComponent {
  progress: boolean = false;
  disableOptimise: boolean = true;
  predict_input: any;
  predict_response: any;
  security_input: any;

  //predict input params
  // inputs from form

  ZT24: number;
  RH24:number;
  Velocity24: number;

  ZT27: number;
  RH27:number;
  Velocity27: number;

  // output fields predict
  pmv_out24: number;
  pmv_out27: number;

  pmv_avg:number;

 
  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {

    if(localStorage.getItem('security_input_1')){
      this.security_input=localStorage.getItem('security_input_1');
      this.security_input=JSON.parse(this.security_input);
      this.ZT24 = this.security_input['Zone Temp C4-L2-24'];
      this.RH24 = this.security_input['RH-L2-24'];
      this.Velocity24 = this.security_input['Velocity - C4 - L2 - 24'];
      this.ZT27 = this.security_input['Zone Temp C4-L2-27'];
      this.RH27 = this.security_input['RH-L2-27'];
      this.Velocity27 = this.security_input['Velocity - C4 - L2 - 27'];
     }else{
      this.ZT24 = 0;
      this.RH24 = 0;
      this.Velocity24 = 0;
      this.ZT27 = 0;
      this.RH27 = 0;
      this.Velocity27 = 0;
     }
   
    // this.ZT24 = 23;
    // this.Velocity24 = 0.7;
    // this.RH24=43;

    // this.ZT27 = 22;
    // this.Velocity27 = 0.5;
    // this.RH27=44;

    if(localStorage.getItem('PMV- C4 - L2 - 24_out') || localStorage.getItem('PMV- C4 - L2 - 24')){
      this.pmv_out24= Number(localStorage.getItem('PMV- C4 - L2 - 24_out') || localStorage.getItem('PMV- C4 - L2 - 24'));
     }else{
       this.pmv_out24 = 0;
     }
 
     
    if(localStorage.getItem('PMV- C4 - L2 - 27_out') || localStorage.getItem('PMV- C4 - L2 - 27')){
      this.pmv_out27= Number(localStorage.getItem('PMV- C4 - L2 - 27_out') || localStorage.getItem('PMV- C4 - L2 - 27'));
    }else{
      this.pmv_out27 = 0;
    }

    // PMV- C4 - L2 - 24'
    // PMV- C4 - L2 - 24'
    // PMV- C4 - L2 - 24'
    // PMV- C4 - L2 - 24'

    if(localStorage.getItem('PMV- C4 - L2 - 24') && localStorage.getItem('PMV- C4 - L2 - 27')){
      this.pmv_avg=(this.pmv_out24+this.pmv_out27)/2;
      }else{
      this.pmv_avg=0;
    }
 
  }


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
   
    if(this.ZT24<22 || this.ZT24>24.8){
      this.openSnackBar('ZT24 should be in range [22,24.8]');
      return false;
    }else if(this.Velocity24<0.64 || this.Velocity24>4.85){
      this.openSnackBar('Velocity24 should be in range [0.64,4.85]');
      return false;
    }else if(this.RH24<42.62 || this.RH24>53.92){
      this.openSnackBar('RH24 should be in range [42.62,53.92]');
      return false;
    }else if(this.ZT27<21.8 || this.ZT27>24.7){
      this.openSnackBar('ZT27 should be in range [21.8,24.7]');
      return false;
    }else if(this.Velocity27<0.42 || this.Velocity27>3.11){
      this.openSnackBar('Velocity27 should be in range [0.42,3.11]');
      return false;
    }else if(this.RH27<43.14 || this.RH27>54.55){
      this.openSnackBar('RH27 should be in range [43.14,54.55]');
      return false;
    }
    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }


  // this fuction is use to predict PMV
  async predictData(){
    
    if (this.validate()) {
      this.progress = true;
      console.log('predict input', this.predict_input);

      this.predict_input= {
        "Zone Temp C4-L2-24":Number(this.ZT24),
        "RH-L2-24":Number(this.RH24),
        "Velocity - C4 - L2 - 24":Number(this.Velocity24),
        "Zone Temp C4-L2-27":Number(this.ZT27),
        "RH-L2-27":Number(this.RH27),
        "Velocity - C4 - L2 - 27":Number(this.Velocity27),
        "model_no.":1,
      };

      localStorage.setItem('RH-L2-24', String(this.RH24));
      localStorage.setItem('RH-L2-27', String(this.RH27));
      localStorage.setItem('Zone Temp C4-L2-24', String(this.ZT24));
      localStorage.setItem('Zone Temp C4-L2-27', String(this.ZT27));
      localStorage.setItem('Velocity - C4 - L2 - 24', String(this.Velocity24));
      localStorage.setItem('Velocity - C4 - L2 - 27', String(this.Velocity27));

      localStorage.setItem('security_input_1', JSON.stringify(this.predict_input));
    
      try {
        const response = await this.http
          .post<any>(
            'https://bpsliveatchangiairport1.bertlabs.com/sec_1',
            // 'https://bpsliveatchangiairport.bertlabs.com/predictsec4/',
            this.predict_input,
            this.httpOptions
          )
          .toPromise();
        
        this.predict_response = response;
        console.log('predict outout', this.predict_response);

        localStorage.removeItem('PMV- C4 - L2 - 01_out');
        localStorage.removeItem('PMV- C4 - L2 - 02_out');
        localStorage.removeItem('PMV- C4 - L2 - 03_out');
        localStorage.removeItem('PMV- C4 - L2 - 04_out');
        localStorage.removeItem('PMV- C4 - L2 - 05_out');
        localStorage.removeItem('PMV- C4 - L2 - 06_out');
        localStorage.removeItem('PMV- C4 - L2 - 07_out');
        localStorage.removeItem('PMV- C4 - L2 - 08_out');
        localStorage.removeItem('PMV- C4 - L2 - 09_out');
        localStorage.removeItem('PMV- C4 - L2 - 10_out');
        localStorage.removeItem('PMV- C4 - L2 - 11_out');
        localStorage.removeItem('PMV- C4 - L2 - 12_out');
        localStorage.removeItem('PMV- C4 - L2 - 13_out');
        localStorage.removeItem('PMV- C4 - L2 - 14_out');
        localStorage.removeItem('PMV- C4 - L2 - 15_out');
        localStorage.removeItem('PMV- C4 - L2 - 16_out');
        localStorage.removeItem('PMV- C4 - L2 - 17_out');
        localStorage.removeItem('PMV- C4 - L2 - 18_out');
        localStorage.removeItem('PMV- C4 - L2 - 19_out');
        localStorage.removeItem('PMV- C4 - L2 - 20_out');
        localStorage.removeItem('PMV- C4 - L2 - 21_out');
        localStorage.removeItem('PMV- C4 - L2 - 22_out');
        localStorage.removeItem('PMV- C4 - L2 - 23_out');
        localStorage.removeItem('PMV- C4 - L2 - 24_out');
        localStorage.removeItem('PMV- C4 - L2 - 25_out');
        localStorage.removeItem('PMV- C4 - L2 - 26_out');
        localStorage.removeItem('PMV- C4 - L2 - 27_out');
        localStorage.removeItem('Fan Power - C4A_out');
        localStorage.removeItem('Fan Power - C4B_out');
        localStorage.removeItem('VSD Freq - C4A_out');
        localStorage.removeItem('VSD Freq - C4B_out');
        localStorage.removeItem('Valve Feedback - C4A_out');
        localStorage.removeItem('Valve Feedback - C4B_out');

        this.openSnackBar('Predicted Succesfully');

        this.pmv_out24 = this.predict_response['PMV- C4 - L2 - 24'];
        this.pmv_out27 = this.predict_response['PMV- C4 - L2 - 27'];

        this.pmv_avg=(this.pmv_out24+this.pmv_out27)/2;


        this.progress = false;
        this.disableOptimise = false;
        localStorage.setItem('model_one', JSON.stringify(this.pmv_avg));

        localStorage.setItem('PMV- C4 - L2 - 24',JSON.stringify(this.pmv_out24));
        localStorage.setItem('PMV- C4 - L2 - 27',JSON.stringify(this.pmv_out27));
      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }

}
