import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-first-ahu',
  templateUrl: './first-ahu.component.html',
  styleUrls: ['./first-ahu.component.css'],
})
export class FirstAhuComponent {
  type!: number;
  predict_response: any = {};
  predict_input: any = {};
  optimise_input: any = {};
  optimise_response: any = {};
  progress: boolean = false;
  disableOptimise: boolean = true;
  disablePMV:boolean=true;
  ahu_out: any;
  ahu_input:any;

  // input form fields
  RAT: number;
  SAT: number;
  CHWVF: number;
  VSD: number;
  OCT: number;
  CHWRT: number;
  CHWST: number;

  // output form fields
 
  FP_out: number;
  OCT_out:number;
  SADPT_out:number;
  RAT_out: number;
  SAT_out: number;
  CHWVF_out: number;
  VSD_out: number;
  CHWST_out:number;
  CHWRT_out:number;
  hasdata:boolean=true;
  
  

  // KPI DATA
  KPI: number;

  constructor(
    private activeroute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {
    if (localStorage.getItem('Abs Fan Power kWh') != null) {
      this.KPI = ((1.81 - Number(localStorage.getItem('Abs Fan Power kWh'))) * 100) / 1.81;
    }else{
      this.KPI = 0;
    }


    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.type = Number(this.activeroute.snapshot.paramMap.get('type'));
      }
    });

    // input field intialisation
    // 'RA Temp': Number(this.RAT),
    // 'Off Coil Temp': Number(this.OCT),
    // 'SA Temp': Number(this.SAT),
    // 'VSD Freq': Number(this.VSD),
    // 'CHWS Temp': Number(this.CHWST),
    // 'CHWR Temp': Number(this.CHWRT),
    // 'Valve Feedback': Number(this.CHWVF),

    if(localStorage.getItem('ahu_input')){
      this.ahu_input=localStorage.getItem('ahu_input');
      this.ahu_input=JSON.parse(this.ahu_input);
      this.RAT = Number(this.ahu_input['RA Temp']);
      this.SAT = Number(this.ahu_input['SA Temp']);
      this.CHWVF = Number(this.ahu_input['Valve Feedback']);
      this.VSD = Number(this.ahu_input['VSD Freq']);
      this.OCT = Number(this.ahu_input['Off Coil Temp']);
      this.CHWRT = Number(this.ahu_input['CHWR Temp']);
      this.CHWST = Number(this.ahu_input['CHWS Temp']);
     }else{
      this.RAT = 0;
      this.SAT = 0;
      this.CHWVF = 0;
      this.VSD = 0;
      this.OCT = 0;
      this.CHWRT = 0;
      this.CHWST = 0;
     }
 

      // this.RAT = 0;
      // this.SAT = 0;
      // this.CHWVF = 0;
      // this.VSD = 0;
      // this.OCT = 0;
      // this.CHWRT = 0;
      // this.CHWST = 0;

 

    // output field initialisation


    
     if(localStorage.getItem('ahu_out')){
      this.ahu_out=localStorage.getItem('ahu_out');
      this.ahu_out=JSON.parse(this.ahu_out);
      console.log(this.ahu_out);
      this.FP_out=this.ahu_out['Abs Fan Power kWh'];
      this.OCT_out=this.ahu_out['Off Coil Temp'];
      this.SADPT_out=this.ahu_out['SA DPT'];
      this.RAT_out=this.ahu_out['RA Temp'];
      this.SAT_out=this.ahu_out['SAT'];
      this.VSD_out=this.ahu_out['VSD'];
      this.CHWST_out=this.ahu_out['CHWST'];
      this.CHWRT_out=this.ahu_out['CHWRT'];
      this.CHWVF_out=this.ahu_out['CHWVF'];
      this.disablePMV=false;
     }else{
      this.FP_out=0;
      this.OCT_out=0;
      this.SADPT_out=0;
      this.RAT_out=0;
      this.SAT_out=0;
      this.VSD_out=0;
      this.CHWST_out=0;
      this.CHWRT_out=0;
      this.CHWVF_out=0;
     }
    
      

      for (let i = 1; i < 24; i++) {
        if(localStorage.getItem('PMV D7-L2-'+i)===null){
          this.hasdata=false;
        }
      }
      
      this.disableOptimise=!this.hasdata;
      
      console.log('hasdata',this.hasdata);
      console.log('disbale',this.disableOptimise);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if (this.RAT < 20.2 || this.RAT > 25.3) {
      this.openSnackBar('RAT should be in range [22.2,25.3]');
      return false;
    } else if (this.CHWVF < 32.5 || this.CHWVF > 100) {
      this.openSnackBar('CHWVF should be in range [32.5,100]');
      return false;
    } else if (this.OCT < 10.7 || this.OCT > 14) {
      this.openSnackBar('OCT should be in range [10.7,14]');
      return false;
    } else if (this.VSD < 25 || this.VSD > 50) {
      this.openSnackBar('VSD should be in range [25,50]');
      return false;
    } else if (this.CHWST < 3.7 || this.CHWST > 11) {
      this.openSnackBar('CHWST should be in range [3.7,11]');
      return false;
    } else if (this.CHWRT < 8.9 || this.CHWRT > 16.6) {
      this.openSnackBar('CHWRT should be in range [8.9,16.6]');
      return false;
    } else if (this.SAT < 12.5 || this.SAT > 15) {
      this.openSnackBar('SAT should be in range [12.5,15]');
      return false;
    }

    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  async predictData() {
   
    if(this.validate()){
      this.progress=true;
    this.predict_input = {
      'RA Temp': Number(this.RAT),
      'Off Coil Temp': Number(this.OCT),
      'SA Temp': Number(this.SAT),
      'VSD Freq': Number(this.VSD),
      'CHWS Temp': Number(this.CHWST),
      'CHWR Temp': Number(this.CHWRT),
      'Valve Feedback': Number(this.CHWVF),
    };

    // console.log('predict input', this.predict_input);

    try {
      const response = await this.http
        .post<any>(
          'https://bpsliveatchangiairport.bertlabs.com/ahupredictdpier',
          this.predict_input,
          this.httpOptions
        )
        .toPromise();


      this.predict_response = response;
      console.log('predict output', this.predict_response);

      this.openSnackBar('Predicted Succesfully');

      this.FP_out=this.predict_response["Abs Fan Power kWh"]+0.3;
      this.OCT_out=this.predict_response["Off Coil Temp"];
      this.RAT_out=this.predict_response["RA Temp"];
      this.SADPT_out=this.predict_response["SA DPT"];
      this.SAT_out=this.SAT;
      this.VSD_out=this.VSD;
      this.CHWST_out=this.CHWST;
      this.CHWRT_out=this.CHWRT;
      this.CHWVF_out=this.CHWVF;

      this.ahu_out={
        'Abs Fan Power kWh': this.FP_out,
        'Off Coil Temp': this.OCT_out,
        'RA Temp': this.RAT_out,
        'SA DPT': this.SADPT_out,
        'SAT':this.SAT_out,
        'VSD': this.VSD_out,
        'CHWST': this.CHWST_out,
        'CHWRT':this.CHWRT_out,
        'CHWVF':this.CHWVF_out
        
      }
      
      localStorage.setItem('ahu_out', JSON.stringify(this.ahu_out));
      localStorage.setItem('ahu_input', JSON.stringify(this.predict_input));

      this.progress = false;
      this.disablePMV=false;

     
      this.disableOptimise=!this.hasdata;

    } catch (error) {
      console.error('Error occurred while making predict API request:', error);
    }
    }
  }

  async optimiseData() {

    this.optimise_input={
      "PMV D7-L2-01": Number(localStorage.getItem('PMV D7-L2-1')),
      "PMV D7-L2-02": Number(localStorage.getItem('PMV D7-L2-2')),
      "PMV D7-L2-03": Number(localStorage.getItem('PMV D7-L2-3')),
      "PMV D7-L2-04": Number(localStorage.getItem('PMV D7-L2-4')),
      "PMV D7-L2-05": Number(localStorage.getItem('PMV D7-L2-5')),
      "PMV D7-L2-06": Number(localStorage.getItem('PMV D7-L2-6')),
      "PMV D7-L2-07": Number(localStorage.getItem('PMV D7-L2-7')),
      "PMV D7-L2-08": Number(localStorage.getItem('PMV D7-L2-8')),
      "PMV D7-L2-09": Number(localStorage.getItem('PMV D7-L2-9')),
      "PMV D7-L2-10": Number(localStorage.getItem('PMV D7-L2-10')),
      "PMV D7-L2-11": Number(localStorage.getItem('PMV D7-L2-11')),
      "PMV D7-L2-12": Number(localStorage.getItem('PMV D7-L2-12')),
      "PMV D7-L2-13": Number(localStorage.getItem('PMV D7-L2-13')),
      "PMV D7-L2-14": Number(localStorage.getItem('PMV D7-L2-14')),
      "PMV D7-L2-15": Number(localStorage.getItem('PMV D7-L2-15')),
      "PMV D7-L2-16": Number(localStorage.getItem('PMV D7-L2-16')),
      "PMV D7-L2-17": Number(localStorage.getItem('PMV D7-L2-17')),
      "PMV D7-L2-18": Number(localStorage.getItem('PMV D7-L2-18')),
      "PMV D7-L2-19": Number(localStorage.getItem('PMV D7-L2-19')),
      "PMV D7-L2-20": Number(localStorage.getItem('PMV D7-L2-20')),
      "PMV D7-L2-21": Number(localStorage.getItem('PMV D7-L2-21')),
      "PMV D7-L2-22": Number(localStorage.getItem('PMV D7-L2-22')),
      "PMV D7-L2-23": Number(localStorage.getItem('PMV D7-L2-23')),
      "model_no.": 1
    }
    console.log('data', this.optimise_input);
    this.progress = true;
    console.log('optimise api call');

    // this.emptyCheck(this.predict_input);
    try {
      const response = await this.http
        .post<any>(
          'https://bpsliveatchangiairport.bertlabs.com/power',
          this.optimise_input,
          this.httpOptions
        )
        .toPromise();

      this.optimise_response = response;
      console.log('resp', this.optimise_response);

      this.FP_out=this.optimise_response['Abs Fan Power kWh'];
      this.VSD_out=this.optimise_response['VSD Freq'];
      localStorage.setItem('PMV D7-L2-1',this.optimise_response['PMV D7-L2-01']);
      localStorage.setItem('PMV D7-L2-2',this.optimise_response['PMV D7-L2-02']);
      localStorage.setItem('PMV D7-L2-3',this.optimise_response['PMV D7-L2-03']);
      localStorage.setItem('PMV D7-L2-4',this.optimise_response['PMV D7-L2-04']);
      localStorage.setItem('PMV D7-L2-5',this.optimise_response['PMV D7-L2-05']);
      localStorage.setItem('PMV D7-L2-6',this.optimise_response['PMV D7-L2-06']);
      localStorage.setItem('PMV D7-L2-7',this.optimise_response['PMV D7-L2-07']);
      localStorage.setItem('PMV D7-L2-8',this.optimise_response['PMV D7-L2-08']);
      localStorage.setItem('PMV D7-L2-9',this.optimise_response['PMV D7-L2-09']);
      localStorage.setItem('PMV D7-L2-10',this.optimise_response['PMV D7-L2-10']);
      localStorage.setItem('PMV D7-L2-11',this.optimise_response['PMV D7-L2-11']);
      localStorage.setItem('PMV D7-L2-12',this.optimise_response['PMV D7-L2-12']);
      localStorage.setItem('PMV D7-L2-13',this.optimise_response['PMV D7-L2-13']);
      localStorage.setItem('PMV D7-L2-14',this.optimise_response['PMV D7-L2-14']);
      localStorage.setItem('PMV D7-L2-15',this.optimise_response['PMV D7-L2-15']);
      localStorage.setItem('PMV D7-L2-16',this.optimise_response['PMV D7-L2-16']);
      localStorage.setItem('PMV D7-L2-17',this.optimise_response['PMV D7-L2-17']);
      localStorage.setItem('PMV D7-L2-18',this.optimise_response['PMV D7-L2-18']);
      localStorage.setItem('PMV D7-L2-19',this.optimise_response['PMV D7-L2-19']);
      localStorage.setItem('PMV D7-L2-20',this.optimise_response['PMV D7-L2-20']);
      localStorage.setItem('PMV D7-L2-21',this.optimise_response['PMV D7-L2-21']);
      localStorage.setItem('PMV D7-L2-22',this.optimise_response['PMV D7-L2-22']);
      localStorage.setItem('PMV D7-L2-23',this.optimise_response['PMV D7-L2-23']);
      localStorage.setItem('Abs Fan Power kWh',this.optimise_response['Abs Fan Power kWh']);

      this.openSnackBar('Optimised Succesfully');
      
      
      this.KPI = ((1.81 - Number(localStorage.getItem('Abs Fan Power kWh'))) * 100) / 1.81;
      

      this.progress = false;
    } catch (error) {
      console.error('Error occurred while making predict API request:', error);
    }
  }

 
}
