<div
  class="jewel"
>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 131 222">
  <g id="Group_2371" data-name="Group 2371" transform="translate(-1314 -438.804)">
    <rect stroke="#313131" stroke-width="1.5" id="Rectangle_1343" data-name="Rectangle 1343" width="131" height="220" rx="58" transform="translate(1314 439.804)" fill="#64a9de"/>
    <text id="JEWEL" transform="translate(1353.5 659.804) rotate(-90)" fill="#fff" stroke="#fff" stroke-width="1" font-size="44" font-family="Numans, sans-serif"><tspan x="40" y="40">JEWEL</tspan></text>
  </g>
</svg>

  
</div>