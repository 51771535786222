import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-c17-part6',
  templateUrl: './c17-part6.component.html',
  styleUrls: ['./c17-part6.component.css']
})


export class C17Part6Component  {
  isLoading = true;
  progress: boolean = false;
  disableOptimise: boolean = true;
  predict_input: any;
  predict_response: any;
  gate_input:any;
  predictionResult: any = {};
  jsonData: any = {};

  interface_input: any = {};

  ZONE_TEMP_out_C4_L2_02: any = 0;
  ZONE_TEMP_out_C4_L2_03: any = 0;

  Zone_Temp_C4_L2_02_out_L: any = 0;
  Zone_Temp_C4_L2_02_out_U: any = 0;
  Zone_Temp_C4_L2_03_out_L: any = 0;
  Zone_Temp_C4_L2_03_out_U: any = 0;

  Zone_Temp_C4_L2_02_out: any = 0;
  PMV_C4_L2_02_out: any = 0;
  room_RH_C4_L2_02_out: any = 0;
  room_temp_C4_L2_02_out: any = 0;
  radiantTemp_C4_L2_02_out: any = 0;
  Zone_Temp_C4_L2_03_out: any = 0;
  PMV_C4_L2_03_out: any = 0;
  room_RH_C4_L2_03_out: any = 0;
  room_temp_C4_L2_03_out: any = 0;
  radiantTemp_C4_L2_03_out: any = 0;


  ZONE_TEMP_out_C4_L2_05: any = 0;
  ZONE_TEMP_out_C4_L2_06: any = 0;

  Zone_Temp_C4_L2_05_out_L: any = 0;
  Zone_Temp_C4_L2_05_out_U: any = 0;
  Zone_Temp_C4_L2_06_out_L: any = 0;
  Zone_Temp_C4_L2_06_out_U: any = 0;

  Zone_Temp_C4_L2_05_out: any = 0;
  PMV_C4_L2_05_out: any = 0;
  room_RH_C4_L2_05_out: any = 0;
  room_temp_C4_L2_05_out: any = 0;
  radiantTemp_C4_L2_05_out: any = 0;
  Zone_Temp_C4_L2_06_out: any = 0;
  PMV_C4_L2_06_out: any = 0;
  room_RH_C4_L2_06_out: any = 0;
  room_temp_C4_L2_06_out: any = 0;
  radiantTemp_C4_L2_06_out: any = 0;

  time: string = '';

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {

    if(localStorage.getItem('interface_input')){
      this.interface_input=localStorage.getItem('interface_input');
      this.interface_input=JSON.parse(this.interface_input);

      this.Zone_Temp_C4_L2_02_out = this.interface_input['Zone_Temp_C4-L2-02'];
      this.PMV_C4_L2_02_out = this.interface_input['PMV_C4-L2-02'];
      this.room_RH_C4_L2_02_out = this.interface_input['room_RH_C4-L2-02'];
      this.room_temp_C4_L2_02_out = this.interface_input['room_temp_C4-L2-02'];
      this.radiantTemp_C4_L2_02_out = this.interface_input['radiantTemp_C4-L2-02'];
      this.Zone_Temp_C4_L2_03_out = this.interface_input['Zone_Temp_C4-L2-03'];
      this.PMV_C4_L2_03_out = this.interface_input['PMV_C4-L2-03'];
      this.room_RH_C4_L2_03_out = this.interface_input['room_RH_C4-L2-03'];
      this.room_temp_C4_L2_03_out = this.interface_input['room_temp_C4-L2-03'];
      this.radiantTemp_C4_L2_03_out = this.interface_input['radiantTemp_C4-L2-03'];

      this.Zone_Temp_C4_L2_05_out = this.interface_input['Zone_Temp_C4-L2-05'];
      this.PMV_C4_L2_05_out = this.interface_input['PMV_C4-L2-05'];
      this.room_RH_C4_L2_05_out = this.interface_input['room_RH_C4-L2-05'];
      this.room_temp_C4_L2_05_out = this.interface_input['room_temp_C4-L2-05'];
      this.radiantTemp_C4_L2_05_out = this.interface_input['radiantTemp_C4-L2-05'];
      this.Zone_Temp_C4_L2_06_out = this.interface_input['Zone_Temp_C4-L2-06'];
      this.PMV_C4_L2_06_out = this.interface_input['PMV_C4-L2-06'];
      this.room_RH_C4_L2_06_out = this.interface_input['room_RH_C4-L2-06'];
      this.room_temp_C4_L2_06_out = this.interface_input['room_temp_C4-L2-06'];
      this.radiantTemp_C4_L2_06_out = this.interface_input['radiantTemp_C4-L2-06'];
      this.time = this.interface_input['Time'];

     }else{
      this.Zone_Temp_C4_L2_02_out = 0;
      this.PMV_C4_L2_02_out = 0;
      this.room_RH_C4_L2_02_out = 0;
      this.room_temp_C4_L2_02_out = 0;
      this.radiantTemp_C4_L2_02_out = 0;
      this.Zone_Temp_C4_L2_03_out = 0;
      this.PMV_C4_L2_03_out = 0;
      this.room_RH_C4_L2_03_out = 0;
      this.room_temp_C4_L2_03_out = 0;
      this.radiantTemp_C4_L2_03_out = 0;

      this.Zone_Temp_C4_L2_05_out = 0;
      this.PMV_C4_L2_05_out = 0;
      this.room_RH_C4_L2_05_out = 0;
      this.room_temp_C4_L2_05_out = 0;
      this.radiantTemp_C4_L2_05_out = 0;
      this.Zone_Temp_C4_L2_06_out = 0;
      this.PMV_C4_L2_06_out = 0;
      this.room_RH_C4_L2_06_out = 0;
      this.room_temp_C4_L2_06_out = 0;
      this.radiantTemp_C4_L2_06_out = 0;
     }


     if(localStorage.getItem('predictionResult')){
        this.predictionResult = localStorage.getItem('predictionResult');
        this.predictionResult = JSON.parse(this.predictionResult);
        this.ZONE_TEMP_out_C4_L2_02 = this.predictionResult['Zone_Temp_C4-L2-02'];
        this.ZONE_TEMP_out_C4_L2_03 = this.predictionResult['Zone_Temp_C4-L2-03'];
        this.Zone_Temp_C4_L2_02_out_L = this.ZONE_TEMP_out_C4_L2_02 - 0.5;
        this.Zone_Temp_C4_L2_02_out_U = this.ZONE_TEMP_out_C4_L2_02 + 0.5;
        this.Zone_Temp_C4_L2_03_out_L = this.ZONE_TEMP_out_C4_L2_03 - 0.5;
        this.Zone_Temp_C4_L2_03_out_U = this.ZONE_TEMP_out_C4_L2_03 + 0.5;

        this.ZONE_TEMP_out_C4_L2_05 = this.predictionResult['Zone_Temp_C4-L2-05'];
        this.ZONE_TEMP_out_C4_L2_06 = this.predictionResult['Zone_Temp_C4-L2-06'];
        this.Zone_Temp_C4_L2_05_out_L = this.ZONE_TEMP_out_C4_L2_05 - 0.5;
        this.Zone_Temp_C4_L2_05_out_U = this.ZONE_TEMP_out_C4_L2_05 + 0.5;
        this.Zone_Temp_C4_L2_06_out_L = this.ZONE_TEMP_out_C4_L2_06 - 0.5;
        this.Zone_Temp_C4_L2_06_out_U = this.ZONE_TEMP_out_C4_L2_06 + 0.5;
     }


    // output_field




  }


  ngOnInit(): void {
    this.getInputResult();
    this.getPredictionResult();
    setInterval(() => {
      this.getPredictionResult();
      this.getInputResult();
    }, 300000); // 300000 milliseconds = 5 minutes
    setTimeout(() => {
      this.isLoading = false;
    }, 400); // Wait for 1 second before displaying the content
  }

  async getInputResult() {
    this.http.get<any>('https://airportinternational.bertlabs.com/get_result')
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Input response:', response);

            // Check if the response is a string and parse it if necessary
            if (typeof response.result === 'string') {
              this.interface_input = JSON.parse(response.result);
            } else {
              this.interface_input = response.result;
            }

            console.log('Interface result:', this.interface_input);
            this.Zone_Temp_C4_L2_02_out = this.interface_input['Zone_Temp_C4-L2-02'];
            this.PMV_C4_L2_02_out = this.interface_input['PMV_C4-L2-02'];
            this.room_RH_C4_L2_02_out = this.interface_input['room_RH_C4-L2-02'];
            this.room_temp_C4_L2_02_out = this.interface_input['room_temp_C4-L2-02'];
            this.radiantTemp_C4_L2_02_out = this.interface_input['radiantTemp_C4-L2-02'];
            this.Zone_Temp_C4_L2_03_out = this.interface_input['Zone_Temp_C4-L2-03'];
            this.PMV_C4_L2_03_out = this.interface_input['PMV_C4-L2-03'];
            this.room_RH_C4_L2_03_out = this.interface_input['room_RH_C4-L2-03'];
            this.room_temp_C4_L2_03_out = this.interface_input['room_temp_C4-L2-03'];
            this.radiantTemp_C4_L2_03_out = this.interface_input['radiantTemp_C4-L2-03'];

            this.Zone_Temp_C4_L2_05_out = this.interface_input['Zone_Temp_C4-L2-05'];
            this.PMV_C4_L2_05_out = this.interface_input['PMV_C4-L2-05'];
            this.room_RH_C4_L2_05_out = this.interface_input['room_RH_C4-L2-05'];
            this.room_temp_C4_L2_05_out = this.interface_input['room_temp_C4-L2-05'];
            this.radiantTemp_C4_L2_05_out = this.interface_input['radiantTemp_C4-L2-05'];
            this.Zone_Temp_C4_L2_06_out = this.interface_input['Zone_Temp_C4-L2-06'];
            this.PMV_C4_L2_06_out = this.interface_input['PMV_C4-L2-06'];
            this.room_RH_C4_L2_06_out = this.interface_input['room_RH_C4-L2-06'];
            this.room_temp_C4_L2_06_out = this.interface_input['room_temp_C4-L2-06'];
            this.radiantTemp_C4_L2_06_out = this.interface_input['radiantTemp_C4-L2-06'];
            // this.Zone_Temp_C4_L2_10_out= this.interface_input['Zone_Temp_C4-L2-10'];
            // console.log("Zone_Temp_C4_L2_15", this.Zone_Temp_C4_L2_10_out);
            const adjustedTime = this.adjustTime(this.interface_input['Time']);
            console.log("Adjusted Time:", adjustedTime);

            // Update the time in the interface_input object and store it in localStorage
            this.interface_input['Time'] = adjustedTime;
            localStorage.setItem('interface_input', JSON.stringify(this.interface_input));

            this.time = adjustedTime; // Now set the adjusted time to display it
            console.log("Time to display:", this.time);

            this.jsonData['interface_input'] = this.interface_input;
            console.log(this.jsonData);
            localStorage.setItem('interface_input', JSON.stringify(this.interface_input));
            console.log('predict_output', this.predict_response);
          } catch (error) {
            console.error('Error parsing Input result:', error);
          }
        },
        (error) => {
          console.error('Error fetching Input result:', error);
        }
      );
  }

  adjustTime(timeString: string): string {
    // Parse the time string into a Date object
    const date = new Date(timeString);

    date.setHours(date.getHours());
    date.setMinutes(date.getMinutes());
    date.setSeconds(date.getSeconds());

    // Format the date back to YYYY-MM-DD HH:mm:ss
    const adjustedDatePart = date.toISOString().split('T')[0];
    const adjustedTimePart = date.toTimeString().split(' ')[0];

    return `${adjustedDatePart} ${adjustedTimePart}`;
  }

 async getPredictionResult() {
  this.http.get<any>('https://airportinternational.bertlabs.com/get_prediction_result')
    .subscribe(
      (response: any) => {
        try {
          // Log the response to check its content
          console.log('Prediction response:', response);

          // Check if the response is a string and parse it if necessary
          if (typeof response.result === 'string') {
            this.predictionResult = JSON.parse(response.result);
          } else {
            this.predictionResult = response.result;
          }
          this.ZONE_TEMP_out_C4_L2_02 = this.predictionResult['Zone_Temp_C4-L2-02'];
          this.ZONE_TEMP_out_C4_L2_03 = this.predictionResult['Zone_Temp_C4-L2-03'];
          this.Zone_Temp_C4_L2_02_out_L = this.ZONE_TEMP_out_C4_L2_02 - 0.5;
          this.Zone_Temp_C4_L2_02_out_U = this.ZONE_TEMP_out_C4_L2_02 + 0.5;
          this.Zone_Temp_C4_L2_03_out_L = this.ZONE_TEMP_out_C4_L2_03 - 0.5;
          this.Zone_Temp_C4_L2_03_out_U = this.ZONE_TEMP_out_C4_L2_03 + 0.5;

          this.ZONE_TEMP_out_C4_L2_05 = this.predictionResult['Zone_Temp_C4-L2-05'];
          this.ZONE_TEMP_out_C4_L2_06 = this.predictionResult['Zone_Temp_C4-L2-06'];
          this.Zone_Temp_C4_L2_05_out_L = this.ZONE_TEMP_out_C4_L2_05 - 0.5;
          this.Zone_Temp_C4_L2_05_out_U = this.ZONE_TEMP_out_C4_L2_05 + 0.5;
          this.Zone_Temp_C4_L2_06_out_L = this.ZONE_TEMP_out_C4_L2_06 - 0.5;
          this.Zone_Temp_C4_L2_06_out_U = this.ZONE_TEMP_out_C4_L2_06 + 0.5;
          console.log('Prediction result:', this.predictionResult);
          this.jsonData['predictionResult'] = this.predictionResult;
          console.log(this.jsonData);
          localStorage.setItem('predictionResult', JSON.stringify(this.predictionResult));
          console.log('predict_output', this.predict_response);
        } catch (error) {
          console.error('Error parsing prediction result:', error);
        }
      },
      (error) => {
        console.error('Error fetching prediction result:', error);
      }
    );
}








  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };



  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  // this fuction is use to predict PMV

}

