import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { MainSideBarComponent } from './components/main-side-bar/main-side-bar.component';
import { CardComponent } from './components/homescreen-components/card/card.component';
import { BertComponentsModule } from '../../libs/bert-components/src/index';
import {HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxGaugeModule } from 'ngx-gauge';



// angular material
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { ChillerPlantComponent } from './pages/chiller-plant/chiller-plant.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { WaterMarkComponent } from './components/water-mark/water-mark.component';
import { FirstAhuComponent } from './pages/d-pier/first-ahu/first-ahu.component';
import { SecondAhuComponent } from './pages/c-pier/second-ahu/second-ahu.component';
import { PmvComponent } from './pages/d-pier/pmv/pmv.component';
import { ElectricLoadCenterOneComponent } from './components/d-pier-components/electric-load-center-one/electric-load-center-one.component';
import { ElectricLoadCenterTwoComponent } from './components/d-pier-components/electric-load-center-two/electric-load-center-two.component';
import { FemaleWashroomComponent } from './components/d-pier-components/female-washroom/female-washroom.component';
import { GateHoldRoomDOneComponent } from './components/d-pier-components/gate-hold-room-d-one/gate-hold-room-d-one.component';
import { GateHoldRoomDTwoComponent } from './components/d-pier-components/gate-hold-room-d-two/gate-hold-room-d-two.component';
import { GateHoldRoomDThreeComponent } from './components/d-pier-components/gate-hold-room-d-three/gate-hold-room-d-three.component';
import { SecurityScreeningAreaComponent } from './components/d-pier-components/security-screening-area/security-screening-area.component';
import { MaleWashroomComponent } from './components/d-pier-components/male-washroom/male-washroom.component';
import { ElectricLoadCentreOneMapComponent } from './pages/d-pier/electric-load-centre-one-map/electric-load-centre-one-map.component';
import { SecurityScreeningAreaMapComponent } from './pages/d-pier/security-screening-area-map/security-screening-area-map.component';
import { LadiesWashroomMapComponent } from './pages/d-pier/ladies-washroom-map/ladies-washroom-map.component';
import { GateHoldRoomDTwoMapComponent } from './pages/d-pier/gate-hold-room-d-two-map/gate-hold-room-d-two-map.component';
import { ElectricLoadCentreTwoMapComponent } from './pages/d-pier/electric-load-centre-two-map/electric-load-centre-two-map.component';
import { GateHoldRoomDOneMapComponent } from './pages/d-pier/gate-hold-room-d-one-map/gate-hold-room-d-one-map.component';

// home-components
import { CPierComponent } from './components/homescreen-components/c-pier/c-pier.component';
import { DPierComponent } from './components/homescreen-components/d-pier/d-pier.component';
import { JewelComponent } from './components/homescreen-components/jewel/jewel.component';
import { TerminalFourComponent } from './components/homescreen-components/terminal-four/terminal-four.component';
import { TerminalThreeComponent } from './components/homescreen-components/terminal-three/terminal-three.component';
import { TerminalTwoComponent } from './components/homescreen-components/terminal-two/terminal-two.component';

// c-pier-components
import { CPierPmvComponent } from './pages/c-pier/c-pier-pmv/c-pier-pmv.component';
import { CPierSecurityScreeningRoomOneComponent } from './components/c-pier-components/c-pier-security-screening-room-one/c-pier-security-screening-room-one.component';
import { CPierSecurityScreeningRoomTwoComponent } from './components/c-pier-components/c-pier-security-screening-room-two/c-pier-security-screening-room-two.component';
import { CPierGateHoldRoomOneComponent } from './components/c-pier-components/c-pier-gate-hold-room-one/c-pier-gate-hold-room-one.component';
import { CPierGateHoldRoomTwoComponent } from './components/c-pier-components/c-pier-gate-hold-room-two/c-pier-gate-hold-room-two.component';

// c-peir-maps
import { CPierSecurityScreeningRoomOneMapComponent } from './pages/c-pier/c-pier-security-screening-room-one-map/c-pier-security-screening-room-one-map.component';
import { CPierSecurityScreeningRoomTwoMapComponent } from './pages/c-pier/c-pier-security-screening-room-two-map/c-pier-security-screening-room-two-map.component';
import { CPierGateHoldRoomOneMapComponent } from './pages/c-pier/c-pier-gate-hold-room-one-map/c-pier-gate-hold-room-one-map.component';
import { CPierGateHoldRoomTwoMapComponent } from './pages/c-pier/c-pier-gate-hold-room-two-map/c-pier-gate-hold-room-two-map.component';
import { ThirdAhuComponent } from './pages/c-pier/third-ahu/third-ahu.component';
import { LoadingScreenComponent } from './pages/loading-screen/loading-screen.component';
import { CPierHomeCompComponent } from './pages/c-pier-home-comp/c-pier-home-comp.component';
import { C17Part1Component } from './pages/c-pier/c17-part1/c17-part1.component';
import { C17Part2Component } from './pages/c-pier/c17-part2/c17-part2.component';
import { C17Part3aComponent } from './pages/c-pier/c17-part3a/c17-part3a.component';
import { C17Part3bComponent } from './pages/c-pier/c17-part3b/c17-part3b.component';
import { C18Part1Component } from './pages/c-pier/c18-part1/c18-part1.component';
import { C18Part2Component } from './pages/c-pier/c18-part2/c18-part2.component';
import { C18Part3Component } from './pages/c-pier/c18-part3/c18-part3.component';
import { C18Part3bComponent } from './pages/c-pier/c18-part3b/c18-part3b.component';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './auth/auth.service';
import { C18Part4Component } from './pages/c-pier/c18-part4/c18-part4.component';
import { C18Part5Component } from './pages/c-pier/c18-part5/c18-part5.component';
import { C18Part6Component } from './pages/c-pier/c18-part6/c18-part6.component';
import { C17Part4Component } from './pages/c-pier/c17-part4/c17-part4.component';
import { C17Part5Component } from './pages/c-pier/c17-part5/c17-part5.component';
import { C17Part6Component } from './pages/c-pier/c17-part6/c17-part6.component';
// import { AuthInterceptor } from './auth.interceptor';


const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    HomeComponent,
    MainSideBarComponent,
    CardComponent,
    ChillerPlantComponent,
    WaterMarkComponent,
    FirstAhuComponent,
    SecondAhuComponent,
    PmvComponent,
    ElectricLoadCenterOneComponent,
    ElectricLoadCenterTwoComponent,
    FemaleWashroomComponent,
    GateHoldRoomDOneComponent,
    GateHoldRoomDTwoComponent,
    GateHoldRoomDThreeComponent,
    SecurityScreeningAreaComponent,
    MaleWashroomComponent,
    ElectricLoadCentreOneMapComponent,
    SecurityScreeningAreaMapComponent,
    LadiesWashroomMapComponent,
    GateHoldRoomDTwoMapComponent,
    ElectricLoadCentreTwoMapComponent,
    GateHoldRoomDOneMapComponent,
    CPierComponent,
    DPierComponent,
    JewelComponent,
    TerminalFourComponent,
    TerminalThreeComponent,
    TerminalTwoComponent,
    CPierPmvComponent,
    CPierSecurityScreeningRoomOneComponent,
    CPierSecurityScreeningRoomTwoComponent,
    CPierGateHoldRoomOneComponent,
    CPierGateHoldRoomTwoComponent,
    CPierSecurityScreeningRoomOneMapComponent,
    CPierSecurityScreeningRoomTwoMapComponent,
    CPierGateHoldRoomOneMapComponent,
    CPierGateHoldRoomTwoMapComponent,
    ThirdAhuComponent,
    LoadingScreenComponent,
    CPierHomeCompComponent,
    C17Part1Component,
    C17Part2Component,
    C17Part3aComponent,
    C17Part3bComponent,
    C18Part1Component,
    C18Part2Component,
    C18Part3Component,
    C18Part3bComponent,
    C18Part4Component,
    C18Part5Component,
    C18Part6Component,
    C17Part4Component,
    C17Part5Component,
    C17Part6Component,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatSidenavModule,
    MatListModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatInputModule,
    ReactiveFormsModule,
    NavBarComponent,
    LogoutDialogComponent,
    BertComponentsModule,
    HttpClientModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    NgxPaginationModule,
    NgxGaugeModule,
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true, direction: 'ltr' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: NativeDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    AuthService,AuthGuard,AdminGuard,
    // {
    //   provide:HTTP_INTERCEPTORS,
    //   useClass:AuthInterceptor,
    //   multi:true
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
