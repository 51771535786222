import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { BehaviorSubject, timer } from 'rxjs';
import { Router } from '@angular/router';
// import { AuthService } from '../../services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ErrorStateMatcher} from '@angular/material/core';
import { AuthService } from 'src/app/auth/auth.service';
import { HttpClient } from '@angular/common/http';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'Login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})

export class LoginComponent {
  @ViewChild('form') form!: NgForm;
  email:string;
  password:string;

  usernameFormControl = new FormControl('', [Validators.required]);
  passwordFormControl = new FormControl('', [Validators.required]);

  matcher = new MyErrorStateMatcher();

  public $error = new BehaviorSubject<{
    message: string;
    status: number;
  } | null>(null);

  constructor(
    public authService: AuthService,
    private router: Router,
    private snackBar:MatSnackBar,
    private http:HttpClient
  ) {
    this.email="";
    this.password="";
  }


  openSnackBar(message:string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition:"start",
      duration: 5000
    });

  }
  handleLogin(): void {
    const data = {
      username: this.email,
      password: this.password
    };
    console.log("dataaaaaa",data)
   if(this.email=="bertlabs" && this.password=="bert_admin"){
    console.log(sessionStorage.getItem('userRole'),"hiiiiiii")

    this.router.navigate(['./loading-screen']).then(() => {
      sessionStorage.setItem('accessToken', '1234@');
              setTimeout(() => {
                this.router.navigate(['./dashboard/home']);
                this.openSnackBar('Successfully Logged In');
              }, 5000);
            });
   }
   else {
    this.openSnackBar('Login failed. Please  try again.');
   }
    // this.http.post<any>('http://4.224.40.114/login', data).subscribe(

    //   (response: any) => {
    //     console.log("Login response", response);

    //     // Check if the response contains a token
    //     if (response && response.access_token ) {

    //       // Store the token and role in session storage
    //       sessionStorage.setItem('accessToken', response.access_token);
    //       sessionStorage.setItem('useRole',response.role)
    //       // sessionStorage.setItem('userRole', response.role);

    //       // Navigate to the dashboard
    //       this.router.navigate(['./loading-screen']).then(() => {
    //         setTimeout(() => {
    //           this.router.navigate(['./dashboard/home']);
    //           this.openSnackBar('Successfully Logged In');
    //         }, 5000);
    //       });
    //     } else {
    //       // this.resetLoginForm();

    //       // If no token is provided, show an error message
    //       this.openSnackBar('Incorrect credentials or role selected. Please select the correct role and try again.');

    //     }
    //   },
    //   (error: any) => {
    //     // this.resetLoginForm();

    //     console.log("Login error", error);
    //     this.openSnackBar('Login failed. Please  try again.');

    //   }
    // );
  }


  ngOnInit(): void {
    // this.authService.isAuthenticated().subscribe(isAuth => {
    //   // goto app if already logged in
    //   if (isAuth) this.router.navigate(['app']);
    // });
  }
}
