<div style="position: relative; height: 100%; width: 100%">
  <!-- <Water-mark></Water-mark> -->

  <div
    style="
      width: 100%;
      height: 100%;
      min-width: 1200px;
      display: flex;
      flex-direction: column;
      position: absolute;
    "
  >
    <mat-progress-bar
      mode="indeterminate"
      [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"
    ></mat-progress-bar>
    <div
      style="
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        align-items: center;
        gap: 10px;
      "
    >
      <div
        style="
          height: 350px;
          width: 1000px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        "
      >
        <div
          style="
            height: 350px;
            width: 1000px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
          "
        >
          <img src="/assets/images/ahu-one.svg" width="1000px" height="350px" />
        </div>

        <!-- componets -->
        <div style="height: 350px; width: 1000px; position: absolute">
          <!-- FAN -->
          <div
            style="
              height: 70px;
              width: 70px;
              margin-top: 163px;
              margin-left: 469px;
            "
          >
            <Fan [state]="'active'"></Fan>
          </div>

          <!-- RAT -->

          <div
            class="input-group"
            style="width: 80px; margin-top: -183px; margin-left: 139px"
          >
            <img src="/assets/images/temperature-celcius.png" />
            <input
              [ngModel]="RAT_out | number : '1.2-2'"
              (ngModelChange)="RAT_out = $event"
            />
          </div>

          <!-- CHWR TEMP -->
          <div
            class="input-group"
            style="width: 80px; margin-top: 192px; margin-left: 289px"
          >
            <img src="/assets/images/temperature-celcius.png" />
            <input
              [ngModel]="CHWRT_out | number : '1.2-2'"
              (ngModelChange)="CHWRT_out = $event"
            />
          </div>

          <!-- CHWV -->
          <div
            class="input-group"
            style="width: 75px; margin-top: -30px; margin-left: 178px"
          >
            <img src="/assets/images/percentage.png" />
            <input
              [ngModel]="CHWVF_out | number : '1.2-2'"
              (ngModelChange)="CHWVF_out = $event"
            />
          </div>

          <!-- CHWS TEMP -->
          <div
            class="input-group"
            style="width: 75px; margin-top: 2px; margin-left: 233px"
          >
            <img src="/assets/images/temperature-celcius.png" />
            <input
              [ngModel]="CHWST_out | number : '1.2-2'"
              (ngModelChange)="CHWST_out = $event"
            />
          </div>

          <!-- VSD FAN SPEED -->
          <div
            class="input-group"
            style="width: 100px; margin-top: -268px; margin-left: 410px"
          >
            <img src="/assets/images/hertz.png" />
            <input
              [ngModel]="VSD_out | number : '1.2-2'"
              (ngModelChange)="VSD_out = $event"
            />
            <!-- <span>Hz</span> -->
          </div>

          <!--FAN POWER OUT  -->
          <div
            class="input-group"
            style="width: 110px; margin-top: -30px; margin-left: 519px"
          >
            <img src="/assets/images/power.png" />
            <input
              [ngModel]="FP_out | number : '1.2-2'"
              (ngModelChange)="FP_out = $event"
            />
            <span>kWh</span>
          </div>

          <!-- SAT_out -->
          <div
            class="input-group"
            style="width: 100px; margin-top: 30px; margin-left: 736px"
          >
            <img src="/assets/images/temperature-celcius.png" />
            <input
              [ngModel]="SAT_out | number : '1.2-2'"
              (ngModelChange)="SAT_out = $event"
            />
          </div>

          <!-- SADPT_out -->
          <div
            class="input-group"
            style="width: 110px; margin-top: 24px; margin-left: 657px"
          >
            <img src="/assets/images/differential-pressure-transmitter.png" />
            <input
              [ngModel]="SADPT_out | number : '1.2-2'"
              (ngModelChange)="SADPT_out = $event"
            />
            <span>Pa</span>
          </div>

          <div style="width: 100px; margin-top: -76px; margin-left: 0px">
            <span class="span-text-dark">FRESH <br />AIR</span>
          </div>

          <!-- OFFCOIL TEMP_out -->
          <div
            class="input-group"
            style="width: 75px; margin-top: -73px; margin-left: 376px"
          >
            <img src="/assets/images/temperature-celcius.png" />
            <input
              [ngModel]="OCT_out | number : '1.2-2'"
              (ngModelChange)="OCT_out = $event"
            />
          </div>

          <div style="margin-top: -42px; margin-left: 954px">
            <button
              style="
                border-radius: 10px;
                border: none;
                height: auto;
                height: 40px;
                width: 70px;
                color: white;
                cursor: pointer;
              "
              [ngClass]="{
                'button-disabled': disablePMV,
                'button-enabled': !disablePMV
              }"

              [disabled]="disablePMV"
              routerLink="../pmv"
            >
              PMV
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        height: auto;
        width: 100%;
        min-width: 1000px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 7px;
        align-items: center;
        gap: 10px;
      "
    >
      <div
        style="
          width: 100%;
          display: flex;
          flex-direction: row;
          height: auto;
          justify-content: center;
        "
      >
        <div style="width: 475px; height: 100%; margin: 0px 20px 0px 50px">
          <div
            class="form"
            style="
              padding: 20px;
              flex-direction: row;
              gap: 30px;
              align-items: start;
            "
          >
            <div
              style="
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 10px;
              "
            >
              <!-- RAT -->
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >RAT</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/temperature-celcius.png" />
                  <input [(ngModel)]="RAT" />
                </div>
              </div>

              <!-- off coil temp -->
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >OFF COIL TEMP.</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/temperature-celcius.png" />
                  <input [(ngModel)]="OCT" />
                </div>
              </div>

              <!-- CHWR TEMP. -->
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >CHWR TEMP.</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/temperature-celcius.png" />
                  <input [(ngModel)]="CHWRT" />
                </div>
              </div>

              <!-- FAN SPEED -->
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >FAN SPEED</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/hertz.png" />
                  <input [(ngModel)]="VSD" />
                </div>
              </div>
            </div>

            <div
              style="
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 10px;
                justify-content: space-between;
              "
            >
              <div style="display: flex; flex-direction: column; gap: 10px">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >SAT</span
                  >

                  <div class="input-group" style="width: 100px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="SAT" />
                  </div>
                </div>

                <!-- chws temp. -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >CHWS TEMP.</span
                  >

                  <div class="input-group" style="width: 100px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="CHWST" />
                  </div>
                </div>

                <!-- CHW VALVE -->

                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >CHW VALVE</span
                  >

                  <div class="input-group" style="width: 100px">
                    <img src="/assets/images/percentage.png" />
                    <input [(ngModel)]="CHWVF" />
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  gap: 10px;
                  justify-content: end;
                  margin-top: 10px;
                "
              >
                <button
                  style="
                    width: 50%;
                    background-color: var(--blue-one);
                    border-radius: 20px;
                    border: none;
                    height: auto;
                    padding: 7px;
                    color: white;
                    cursor: pointer;
                  "
                  (click)="predictData()"
                >
                  Predict
                </button>
                <button
                  style="
                    width: 50%;
                    border-radius: 20px;
                    border: none;
                    height: auto;
                    padding: 7px;
                    color: white;
                    cursor: pointer;
                  "
                  (click)="optimiseData()"
                  [ngClass]="{
                    'button-disabled': disableOptimise,
                    'button-enabled': !disableOptimise
                  }"
                  [disabled]="disableOptimise"
                >
                  Optimise
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- KPI -->

        <div class="form" style="height: 200px; width: 250px">
          <span
            class="span-text-dark"
            style="font-weight: bold; text-align: center"
            >KEY PERFORMANCE INDICATOR</span
          >

          <div style="height: 30px"></div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span
              class="span-text-dark"
              style="font-size: 12px; font-weight: 500"
              >FAN POWER SAVING</span
            >

            <div class="input-group" style="width: 120px">
              <img src="/assets/images/power.png" />
              <input
                [ngModel]="KPI | number : '1.2-2'"
                (ngModelChange)="KPI = $event"
              />
              <span>%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
