import { Component } from '@angular/core';

@Component({
  selector: 'c-pier-home-comp',
  templateUrl: './c-pier-home-comp.component.html',
  styleUrls: ['./c-pier-home-comp.component.css']
})
export class CPierHomeCompComponent {

}
