<div style="position: relative; height: 100%; width: 100%">
  <div
    style="
      width: 100%;
      height: 100%;
      min-width: 1200px;
      display: flex;
      flex-direction: column;
      position: absolute;
    "
  >
    <mat-progress-bar
      mode="indeterminate"
      [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"
    ></mat-progress-bar>

    <div style="display: flex; flex-direction: row; height: 100%; width: 100%">
      <!-- main-body -->
      <div
        style="
          height: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px;
          align-items: center;
          gap: 10px;
          overflow-y: scroll;
          padding-left: 120px;
        "
      >
        <div
          style="
            height: 550px;
            width: 1000px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          "
        >
          <!-- componets -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="margin-top: 29px; margin-left: 3px; height: 494px">
              <Electric-load-center-one [pmv]="pmv_out"></Electric-load-center-one>
            </div>
          </div>

          <!-- map -->
          <div
            style="
              height: 550px;
              width: 1000px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
            "
          >
            <img
              src="/assets/images/electric-load-centre-one-map.svg"
              width="1000px"
              height="500px"
            />
          </div>

          <!-- fields -->

          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 227px; margin-left: 516px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input [ngModel]="pmv_out | number : '1.2-2'"
                (ngModelChange)="pmv_out = $event"/>
                
              </div>
            </div>
          </div>
        </div>

        <!-- legends -->
        <div
          style="display: flex; flex-direction: row; width: 1000px; gap: 10px"
        >
          <div
            class="pmv-pallet"
            style="background-color: var(--pmv-neg-three)"
          >
            <span style="font-size: 12px">-1 SLIGHTLY COOL</span>
          </div>
          <div class="pmv-pallet" style="background-color: var(--pmv-neg-two)">
            <span style="font-size: 12px">-0.5</span>
          </div>
          <!-- <div class="pmv-pallet" style="background-color: var(--pmv-neg-one)">
            <span style="font-size: 12px">-0.2</span>
          </div> -->
          <div class="pmv-pallet" style="background-color: var(--pmv-zero)">
            <span style="font-size: 12px">0 NEUTRAL</span>
          </div>
          <!-- <div class="pmv-pallet" style="background-color: var(--pmv-one)">
            <span style="font-size: 12px">0.2</span>
          </div> -->
          <div class="pmv-pallet" style="background-color: var(--pmv-two)">
            <span style="font-size: 12px">0.5</span>
          </div>
          <div class="pmv-pallet" style="background-color: var(--pmv-three)">
            <span style="font-size: 12px">1 SLIGHTLY WARM</span>
          </div>
        </div>
       </div>

      <!-- input side bar -->
      <div
        style="
          width: 450px;
          height: 100%;
          box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.06),
            0px 0px 2px rgba(25, 25, 25, 0.12);
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 10px;
            justify-content: space-between;
          "
        >
        <div style="display: flex;flex-direction: column; gap: 10px;">
            <span class="span-text-dark" style="text-align: center"
            >VAV-AHU D7-L2-01</span
          >

         

          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500;line-height: 12px;"
                >Zone<br>Temp</span
              >

              <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/temperature-celcius.png" />
                <input [(ngModel)]="ZT" />
                
              </div>
              
            </div>

           

            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500"
                >Velocity</span
              >

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/velocity.png" />
                <input [(ngModel)]="Velocity" />
                <span>m/s</span>
              </div>
            </div>
          </div>
          <!-- <div
          style="
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: space-between;
          "
        >
          <mat-error *ngIf="(ZT<22.6) || (ZT>24.7)" style="width: 50%;" >
            <span style="font-size: 10px;">ZT range [22.6,24.7]</span>
          </mat-error>

          <mat-error *ngIf="(ZT<22.6) || (ZT>24.7)" style="width: 50%;" >
            <span style="font-size: 10px;">ZT range [22.6,24.7]</span>
          </mat-error>

          </div> -->
          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500;line-height: 12px;"
                >RH</span
              >

              <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/percentage.png" />
                <input [(ngModel)]="RH" />
              </div>
            </div>

            <div style="width: 50%;"></div>

          </div>

         
          <div
          style="
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 10px;
            justify-content: flex-end;
            margin-top: 10px;
            margin-bottom: 10px;
          "
        >
          <button
            style="
              width: 30%;
              background-color: #01295f;
              border-radius: 20px;
              border: none;
              height: auto;
              padding: 7px;
              color: white;
              cursor: pointer;
            "
            (click)="predictData()"
          >
            Predict
          </button>
          <!-- <button
            style="
              width: 30%;
              border-radius: 20px;
              border: none;
              height: auto;
              padding: 7px;
              color: white;
              cursor: pointer;
            "
           class="button-disabled"
            
          >
            Optimise
          </button> -->
          </div>
        </div>
         

          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              gap: 5px;
            "
          >
            <span class="span-text-dark" style="font-size: 12px"
              >powered by
            </span>
            <img
              src="/assets/logos/bertlabs-logo.png"
              height="20px"
              width="100px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
