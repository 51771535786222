<div
  [ngClass]="{
    'pmv-hot-three': pmv >= 1,
    'pmv-hot-two': (pmv >= 0.5) && (pmv<1),
    'pmv-neutral': (pmv > -0.5) && (pmv<0.5),
    'pmv-cold-two': (pmv > -1) && (pmv<=-0.5),
    'pmv-cold-three': (pmv <=-1),
    'pmv-disabled':pmv===-4,
    'pmv-default':pmv===4
  }"

  style="height: 100%;width: 100%;"
>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 640.642 284.385">
    <path id="Path_4291" data-name="Path 4291" d="M8607.821,5547.89s548.073,1.9,548.073.044-.007-166.253-.007-166.253l-119.635-117.317-51.373,50.669-22.351-22.066-43.715,43.24L8684,5336.65s-2.058,17.988-15.569,17.5-13.593-7.736-13.593-7.736H8515.253l.511,53.435,91.689.57Z" transform="translate(-8515.253 -5264.364)" fill="#f4e2e2"/>
  </svg>
  
  
</div>
