import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(private router: Router) {

    const savedToken = sessionStorage.getItem('accessToken');
    if (savedToken) {
      // Token exists, user is logged in
    }
  }

  // login(username: string, password: string): boolean {
  //   // This method is not needed anymore as login is handled in LoginComponent
  //   return false;
  // }

  logout(): void {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('userRole');
    this.router.navigate(['/login']);
  }

  isLoggedIn(): boolean {
    return sessionStorage.getItem('accessToken') !== null;
  }

  getUserRole(): string | null {
    return sessionStorage.getItem('userRole');
  }
  showMessage(message: string): void {
    alert(message); // Use a custom implementation or third-party library for better UX
  }


}
