<div style="position: relative; height: 100%; width: 100%">

  <div
    style="
      width: 100%;
      height: 100%;
      min-width: 1200px;
      display: flex;
      flex-direction: column;
      position: absolute;
    "
  >
  <div>
    <mat-progress-bar
  mode="indeterminate"
  [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"
></mat-progress-bar>
  </div>
  
    <div style="display: flex; flex-direction: row;height: 100%;width: 100%;">

        <!-- main body -->
        <div
        style="
          height: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px;
          align-items: center;
          gap: 10px;
          overflow-y: scroll;
          padding-left: 120px;
         
        "
      >
      <div
        style="
          height: 550px;
          width: 1000px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        "
      >
        <!-- componets -->
        <div style="height: 500px; width: 1000px; position: absolute">
          <div style="margin-top: 5px; margin-left: 0px; height: 489px">
            <Gate-hold-room-d-one [pmv]="pmv_avg"></Gate-hold-room-d-one>
          </div>
        </div>

        <!-- map -->
        <div
          style="
            height: 500px;
            width: 1000px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
          "
        >
          <img
            src="/assets/images/gate-hold-room-d-one-map.svg"
            width="1000px"
            height="500px"
          />
        </div>

        <!-- fields -->

        <div style="height: 500px; width: 1000px; position: absolute">
          <!-- field 1 -->
          <div style="width: 100px; margin-top: 81px; margin-left: 32px">
            <div class="input-group">
              <img src="assets/images/pmv.png" />
              <input [ngModel]="pmv_out12 | number : '1.2-2'"
                (ngModelChange)="pmv_out12 = $event"/>
            </div>
          </div>

          <!-- field -2 -->
          <div style="width: 100px; margin-top: -31px; margin-left: 136px">
            <div class="input-group">
              <img src="assets/images/pmv.png" />
              <input [ngModel]="pmv_out11 | number : '1.2-2'"
                (ngModelChange)="pmv_out11 = $event"/>
            </div>
          </div>

          <!-- field -3 -->
          <div style="width: 100px; margin-top: 77px; margin-left: 89px">
            <div class="input-group">
              <img src="assets/images/pmv.png" />
              <input [ngModel]="pmv_out10 | number : '1.2-2'"
                (ngModelChange)="pmv_out10 = $event"/>
            </div>
          </div>

          <!-- field - 4 -->

          <div style="width: 100px; margin-top: -141px; margin-left: 353px">
            <div class="input-group">
              <img src="assets/images/pmv.png" />
              <input [ngModel]="pmv_out15 | number : '1.2-2'"
                (ngModelChange)="pmv_out15 = $event"/>
            </div>
          </div>

          <!-- field -5 -->

          <div style="width: 100px; margin-top: 88px; margin-left: 476px">
            <div class="input-group">
              <img src="assets/images/pmv.png" />
              <input [ngModel]="pmv_out14 | number : '1.2-2'"
                (ngModelChange)="pmv_out14 = $event"/>
            </div>
          </div>

          <!-- field -6 -->
          <div style="width: 100px; margin-top: 131px; margin-left: 355px">
            <div class="input-group">
              <img src="assets/images/pmv.png" />
              <input [ngModel]="pmv_out13 | number : '1.2-2'"
                (ngModelChange)="pmv_out13 = $event"/>
            </div>
          </div>

          <!-- field 7 -->
          <div style="width: 100px; margin-top: -208px; margin-left: 724px">
            <div class="input-group">
              <img src="assets/images/pmv.png" />
              <input [ngModel]="pmv_out16 | number : '1.2-2'"
                (ngModelChange)="pmv_out16 = $event"/>
            </div>
          </div>

          <!-- field 8 -->
          <div style="width: 100px; margin-top: -111px; margin-left: 807px">
            <div class="input-group">
              <img src="assets/images/pmv.png" />
              <input [ngModel]="pmv_out17 | number : '1.2-2'"
                (ngModelChange)="pmv_out17 = $event"/>
            </div>
          </div>

          <!-- field 9 -->
          <div style="width: 100px;
          margin-top: 71px;
          margin-left: 892px;">
            <div class="input-group">
              <img src="assets/images/pmv.png" />
              <input [ngModel]="pmv_out19 | number : '1.2-2'"
                (ngModelChange)="pmv_out19 = $event"/>
            </div>
          </div>
        </div>
      </div>

      
         <!-- legends -->
         <div
        style="display: flex; flex-direction: row; width: 1000px; gap: 10px"
      >
        <div
          class="pmv-pallet"
          style="background-color: var(--pmv-neg-three)"
        >
          <span style="font-size: 12px">-1 SLIGHTLY COOL</span>
        </div>
        <div class="pmv-pallet" style="background-color: var(--pmv-neg-two)">
          <span style="font-size: 12px">-0.5</span>
        </div>
        <!-- <div class="pmv-pallet" style="background-color: var(--pmv-neg-one)">
          <span style="font-size: 12px">-0.2</span>
        </div> -->
        <div class="pmv-pallet" style="background-color: var(--pmv-zero)">
          <span style="font-size: 12px">0 NEUTRAL</span>
        </div>
        <!-- <div class="pmv-pallet" style="background-color: var(--pmv-one)">
          <span style="font-size: 12px">0.2</span>
        </div> -->
        <div class="pmv-pallet" style="background-color: var(--pmv-two)">
          <span style="font-size: 12px">0.5</span>
        </div>
        <div class="pmv-pallet" style="background-color: var(--pmv-three)">
          <span style="font-size: 12px">1 SLIGHTLY WARM</span>
        </div>
      </div>

      </div>

       <!-- input side bar -->
       <div
       style="
         width: 450px;
         height: 100%;
         display: flex;
         flex-direction: column;
         box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.06),
           0px 0px 2px rgba(25, 25, 25, 0.12);
       "
     >
       <div
         style="
           display: flex;
           flex-direction: column;
           width: 100%;
           height: 100%;
           padding: 10px;
           justify-content: space-between;
           overflow-x: scroll;
         "
       >
 
       <div style="display: flex;flex-direction: column;gap: 15px;">
 
          <!-- form-1 -->
          <div style="display: flex;flex-direction: column; gap: 10px;">
              <span class="span-text-dark" style="text-align: center"
                  >VAV-AHU D7-L2-10</span
              >
      
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >Zone<br />Temp</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="ZT10" />
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500"
                    >Velocity</span
                  >

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/velocity.png" />
                    <input [(ngModel)]="Velocity10" />
                    <span>m/s</span>
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >RH</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/percentage.png" />
                    <input [(ngModel)]="RH10" />
                  </div>
                </div>
                <div style="width: 50%;"></div>
              </div>

              
             
          </div>
 
          <!-- form 2 -->
 
          <div style="display: flex;flex-direction: column; gap: 10px;">
              <span class="span-text-dark" style="text-align: center"
                  >VAV-AHU D7-L2-11</span
              >
      
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >Zone<br />Temp</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="ZT11" />
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500"
                    >Velocity</span
                  >

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/velocity.png" />
                    <input [(ngModel)]="Velocity11" />
                    <span>m/s</span>
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >RH</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/percentage.png" />
                    <input [(ngModel)]="RH11" />
                  </div>
                </div>
                <div style="width: 50%;"></div>
              </div>
 
      
             
          </div>

           <!-- form 3 -->
 
           <div style="display: flex;flex-direction: column; gap: 10px;">
            <span class="span-text-dark" style="text-align: center"
                >VAV-AHU D7-L2-12</span
            >
    
            <div
            style="
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500; line-height: 12px"
                >Zone<br />Temp</span
              >

              <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
            (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/temperature-celcius.png" />
                <input [(ngModel)]="ZT12" />
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500"
                >Velocity</span
              >

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/velocity.png" />
                <input [(ngModel)]="Velocity12" />
                <span>m/s</span>
              </div>
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500; line-height: 12px"
                >RH</span
              >

              <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
            (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/percentage.png" />
                <input [(ngModel)]="RH12" />
              </div>
            </div>
            <div style="width: 50%;"></div>
          </div>

    
           
        </div>

         <!-- form 4 -->
 
         <div style="display: flex;flex-direction: column; gap: 10px;">
            <span class="span-text-dark" style="text-align: center"
                >VAV-AHU D7-L2-13</span
            >
    
            <div
            style="
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500; line-height: 12px"
                >Zone<br />Temp</span
              >

              <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
            (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/temperature-celcius.png" />
                <input [(ngModel)]="ZT13" />
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500"
                >Velocity</span
              >

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/velocity.png" />
                <input [(ngModel)]="Velocity13" />
                <span>m/s</span>
              </div>
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500; line-height: 12px"
                >RH</span
              >

              <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
            (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/percentage.png" />
                <input [(ngModel)]="RH13" />
              </div>
            </div>
            <div style="width: 50%;"></div>
          </div>
    
           
        </div>

        
         <!-- form 5 -->
 
         <div style="display: flex;flex-direction: column; gap: 10px;">
            <span class="span-text-dark" style="text-align: center"
                >VAV-AHU D7-L2-14</span
            >
    
            <div
            style="
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500; line-height: 12px"
                >Zone<br />Temp</span
              >

              <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
            (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/temperature-celcius.png" />
                <input [(ngModel)]="ZT14" />
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500"
                >Velocity</span
              >

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/velocity.png" />
                <input [(ngModel)]="Velocity14" />
                <span>m/s</span>
              </div>
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500; line-height: 12px"
                >RH</span
              >

              <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
            (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/percentage.png" />
                <input [(ngModel)]="RH14" />
              </div>
            </div>
            <div style="width: 50%;"></div>
          </div>

    
           
        </div>

        
         <!-- form 6 -->
 
         <div style="display: flex;flex-direction: column; gap: 10px;">
            <span class="span-text-dark" style="text-align: center"
                >VAV-AHU D7-L2-15</span
            >
    
            <div
            style="
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500; line-height: 12px"
                >Zone<br />Temp</span
              >

              <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
            (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/temperature-celcius.png" />
                <input [(ngModel)]="ZT15" />
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500"
                >Velocity</span
              >

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/velocity.png" />
                <input [(ngModel)]="Velocity15" />
                <span>m/s</span>
              </div>
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500; line-height: 12px"
                >RH</span
              >

              <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
            (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/percentage.png" />
                <input [(ngModel)]="RH15" />
              </div>
            </div>
            <div style="width: 50%;"></div>
          </div>

    
           
        </div>

        
         <!-- form 7 -->
 
         <div style="display: flex;flex-direction: column; gap: 10px;">
            <span class="span-text-dark" style="text-align: center"
                >VAV-AHU D7-L2-16</span
            >
    
            <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >Zone<br />Temp</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="ZT16" />
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500"
                    >Velocity</span
                  >

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/velocity.png" />
                    <input [(ngModel)]="Velocity16" />
                    <span>m/s</span>
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >RH</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/percentage.png" />
                    <input [(ngModel)]="RH16" />
                  </div>
                </div>
                <div style="width: 50%;"></div>
              </div>

    
           
        </div>

         <!-- form 8 -->
        <div style="display: flex;flex-direction: column; gap: 10px;">
            <span class="span-text-dark" style="text-align: center"
                >VAV-AHU D7-L2-17</span
            >
    
            <div
            style="
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500; line-height: 12px"
                >Zone<br />Temp</span
              >

              <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
            (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/temperature-celcius.png" />
                <input [(ngModel)]="ZT17" />
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500"
                >Velocity</span
              >

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/velocity.png" />
                <input [(ngModel)]="Velocity17" />
                <span>m/s</span>
              </div>
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 10px; font-weight: 500; line-height: 12px"
                >RH</span
              >

              <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
            (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

              <div class="input-group" style="width: 105px">
                <img src="/assets/images/percentage.png" />
                <input [(ngModel)]="RH17" />
              </div>
            </div>
            <div style="width: 50%;"></div>
          </div>

    
           
        </div>


 
          <!-- form 9 -->
 
          <div style="display: flex;flex-direction: column; gap: 10px;">
             <span class="span-text-dark" style="text-align: center"
                 >VAV-AHU D7-L2-19</span
             >
     
             <div
             style="
               display: flex;
               flex-direction: row;
               gap: 10px;
               justify-content: space-between;
             "
           >
             <div
               style="
                 display: flex;
                 flex-direction: row;
                 justify-content: space-between;
                 align-items: center;
                 width: 50%;
               "
             >
               <span
                 class="span-text-dark"
                 style="font-size: 10px; font-weight: 500; line-height: 12px"
                 >Zone<br />Temp</span
               >

               <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
             (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

               <div class="input-group" style="width: 105px">
                 <img src="/assets/images/temperature-celcius.png" />
                 <input [(ngModel)]="ZT19" />
               </div>
             </div>

             <div
               style="
                 display: flex;
                 flex-direction: row;
                 justify-content: space-between;
                 align-items: center;
                 width: 50%;
               "
             >
               <span
                 class="span-text-dark"
                 style="font-size: 10px; font-weight: 500"
                 >Velocity</span
               >

               <div class="input-group" style="width: 105px">
                 <img src="/assets/images/velocity.png" />
                 <input [(ngModel)]="Velocity19" />
                 <span>m/s</span>
               </div>
             </div>
           </div>

           <div
             style="
               display: flex;
               flex-direction: row;
               gap: 10px;
               justify-content: space-between;
             "
           >
             <div
               style="
                 display: flex;
                 flex-direction: row;
                 justify-content: space-between;
                 align-items: center;
                 width: 50%;
               "
             >
               <span
                 class="span-text-dark"
                 style="font-size: 10px; font-weight: 500; line-height: 12px"
                 >RH</span
               >

               <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
             (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

               <div class="input-group" style="width: 105px">
                 <img src="/assets/images/percentage.png" />
                 <input [(ngModel)]="RH19" />
               </div>
             </div>
             <div style="width: 50%;"></div>
           </div>
 
           
            
         </div>
      </div>
          
        
       </div>
       

       <div style="height: auto;">

        <div
        style="
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 10px;
            justify-content: flex-end;
            padding: 10px;
            box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.06),
           0px 0px 2px rgba(25, 25, 25, 0.12);
        "
        >
        <button
            style="
            width: 50%;
            background-color: var(--blue-one);
            border-radius: 20px;
            border: none;
            height: auto;
            padding: 7px;
            color: white;
            cursor: pointer;
            "
            (click)="predictData()"
        >
            Predict
        </button>
        <!-- <button
            style="
            width: 50%;
            border-radius: 20px;
            border: none;
            height: auto;
            padding: 7px;
            color: white;
            cursor: pointer;
            "
            class="button-disabled"
            
        >
            Optimise
        </button> -->
        </div>

        </div>
   

    </div>
    </div>
  </div>
</div>
