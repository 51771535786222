import { Component } from '@angular/core';

@Component({
  selector: 'C-pier',
  templateUrl: './c-pier.component.html',
  styleUrls: ['./c-pier.component.css']
})
export class CPierComponent {

}
