<div
  class="terminal-2"
>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 705.943 403.188">
  <g id="Group_2369" data-name="Group 2369" transform="translate(-704.205 -603.329)">
    <path stroke="#313131" stroke-width="1.5" id="Path_4496" data-name="Path 4496" d="M16752.234,6126.988v27.26H16722.9v91.005l98.98,99-35.98,35.787-114.141-113.878V6154.23l-192.492.018v116.464l-111.07,111-35.826-35.754,95.717-95.721V6127h-210.537v-50.957h210.584l.037-97.522h324.088v99.807h171.234v48.657Z" transform="translate(-15513.346 -5375.196)" fill="#cf2c3d"/>
    <text id="E" transform="translate(1175.5 627.5)" fill="#fff" stroke="#fff" stroke-width="1" font-size="52" font-family="Numans, sans-serif"><tspan x="0" y="47">E</tspan></text>
    <text id="F" transform="translate(944.5 627.5)" fill="#fff" stroke="#fff" stroke-width="1" font-size="52" font-family="Numans, sans-serif"><tspan x="0" y="47">F</tspan></text>
    <text id="TRANSFER" transform="translate(927.5 612.5)" fill="#fff" stroke="#fff" stroke-width="0.5" font-size="12" font-family="Numans, sans-serif"><tspan x="0" y="11">TRANSFER</tspan></text>
    <text id="TRANSFER-2" data-name="TRANSFER" transform="translate(1158.5 612.5)" fill="#fff" stroke="#fff" stroke-width="0.5" font-size="12" font-family="Numans, sans-serif"><tspan x="0" y="11">TRANSFER</tspan></text>
  </g>
</svg>

  
  
</div>