<!-- Zone C-17 6 -->
<div style="position: relative; height: 100%; width: 100%">
  <!-- <Water-mark></Water-mark> -->
  <div
    style="
      width: 100%;
      height: 100%;
      min-width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
    "
  >
    <mat-progress-bar
      mode="indeterminate"
      [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"
    ></mat-progress-bar>

    <!-- Loading GIF -->
    <div
      *ngIf="isLoading"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <img src="" alt="" style="height: 10%; width: auto;"/>
    </div>

    <div
      *ngIf="!isLoading"
      style="
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 96%;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 50px;
      "
    >
      <!-- Button 1 -->
      <div class="button" style="padding-right: 20px;">
        <button
          routerLink="/dashboard/T1A1"
          style="z-index: 99999; border: 0; background: white; cursor: pointer"
        >
          <img
            src="assets/logos/arrow-up-2-bold.svg"
            alt=""
            style="width: 20px"
          />
        </button>
      </div>

      <!-- Main content area -->
      <div
        style="
          flex-grow: 0.8;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        "
      >
        <!-- Image div -->
        <div
          style="
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
        <div class="image-container" style="position: relative;">
          <img
            src="/assets/images/ZONE T1A2.png"
            alt="Centered Image"
            style="max-width: 100%; height: auto; z-index: 0;"
          />

          <div class="overlay-content" style="top: 26.7%; left: 48%;">
            <input
              class="no-background-quartz"
              readonly
              readonly
              [ngModel]="room_temp_C4_L2_13_out | number: '1.2-2'"
              (ngModelChange)="room_temp_C4_L2_13_out = $event"
            />
            <input
              class="no-background-quartz"
              readonly
              readonly
              [ngModel]="room_RH_C4_L2_13_out | number: '1.2-2'"
              (ngModelChange)="room_RH_C4_L2_13_out = $event"
            />
          </div>
          <div class="overlay-content" style="top: 55.2%; left: 85%; scale: 1.2;">
            <input
              class="no-background"
              readonly
              readonly
              [ngModel]="PMV_C4_L2_13_out | number: '1.2-2'"
              (ngModelChange)="PMV_C4_L2_13_out = $event"
            />
          </div>
          <div class="overlay-content" style="top: 55.2%; left: 19%; scale: 1.2;">
            <input
              class="no-background"
              readonly
              readonly
              [ngModel]="PMV_C4_L2_11_out | number: '1.2-2'"
              (ngModelChange)="PMV_C4_L2_11_out = $event"
            />
          </div>
        </div>
        </div>
        <!-- Table div -->
        <div
          style="
            width: 50%;
            display: flex;
            justify-content: center;
            padding: 40px;
            display: flex;
            flex-direction: column;
          "
        >
          <table class="styled-table" style="table-layout: fixed;">
            <!-- Table content -->
            <thead>
              <tr>
                <th rowspan="2">VAV-AHU</th>
                <th colspan="2" style="text-align: center;">Recommended</th>
                <th rowspan="2">Zone Temp (°C)</th>
                <th rowspan="2">PMV</th>
              </tr>
              <tr>
                <th>Set Point Lower (°C)</th>
                <th>Set Point Upper (°C)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>VAV-8</td>
                <td><input class="no-background"
                  readonly
                  [ngModel]="Zone_Temp_C4_L2_11_out_L | number : '1.2-2'"
                  (ngModelChange)="Zone_Temp_C4_L2_11_out_L = $event"
                /></td>
                <td><input class="no-background"
                  readonly
                  [ngModel]="Zone_Temp_C4_L2_11_out_U | number : '1.2-2'"
                  (ngModelChange)="Zone_Temp_C4_L2_11_out_U = $event"
                /></td>
                <td><input class="no-background"
                  readonly
                  [ngModel]="Zone_Temp_C4_L2_11_out | number : '1.2-2'"
                  (ngModelChange)="Zone_Temp_C4_L2_11_out = $event"
                /></td>
                <td><input class="no-background"
                  readonly
                  [ngModel]="PMV_C4_L2_11_out | number : '1.2-2'"
                  (ngModelChange)="PMV_C4_L2_11_out = $event"
                /></td>
              </tr>
              <tr>
                <td>VAV-9</td>
                <td><input class="no-background"
                  readonly
                  [ngModel]="Zone_Temp_C4_L2_13_out_L | number : '1.2-2'"
                  (ngModelChange)="Zone_Temp_C4_L2_13_out_L = $event"
                /></td>
                <td><input class="no-background"
                  readonly
                  [ngModel]="Zone_Temp_C4_L2_13_out_U | number : '1.2-2'"
                  (ngModelChange)="Zone_Temp_C4_L2_13_out_U = $event"
                /></td>
                <td><input class="no-background"
                  readonly
                  [ngModel]="Zone_Temp_C4_L2_13_out | number : '1.2-2'"
                  (ngModelChange)="Zone_Temp_C4_L2_13_out = $event"
                /></td>
                <td><input class="no-background"
                  readonly
                  [ngModel]="PMV_C4_L2_13_out | number : '1.2-2'"
                  (ngModelChange)="PMV_C4_L2_13_out = $event"
                /></td>
              </tr>
            </tbody>
          </table>
          <!-- <div style="text-align: right; margin-top: -2vh;">
            Last Updated: {{ time | date:'dd-MM-yyyy HH:mm:ss' }} UTC
        </div> -->
        </div>
      </div>

      <!-- Button 2 -->
      <div class="button">
        <button
          routerLink="/dashboard/T1A3"
          style="z-index: 99999; border: 0; background: white; cursor: pointer"
        >
          <img
            src="assets/logos/arrow-up-2-bold.svg"
            alt=""
            style="width: 20px; transform: rotate(180deg)"
          />
        </button>
      </div>
    </div>
  </div>
</div>


