<div
  [ngClass]="{
    'pmv-hot-three': pmv >= 1,
    'pmv-hot-two': pmv >= 0.5 && pmv < 1,
    'pmv-neutral': pmv > -0.5 && pmv < 0.5,
    'pmv-cold-two': pmv > -1 && pmv <= -0.5,
    'pmv-cold-three': pmv <= -1,
    'pmv-disabled': pmv === -4,
    'pmv-default': pmv === 4
  }"
  style="height: 100%; width: 100%"
>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 552.541 237.589">
    <path id="Path_4535" data-name="Path 4535" d="M16852.6,7267.182v-75.414h494.041v110.41l-59.527,119.514-164.555-28.11-19.844,35.194-189.383-34.944-17.988,35.526-101.244-19.033V7267.182Z" transform="translate(-16794.1 -7191.768)" fill="#e0c3c3"/>
  </svg>
  
</div>
