import { Component, Input } from '@angular/core';

@Component({
  selector: 'Vapour-liquid-separator',
  templateUrl: './vapour-liquid-separator.component.html',
  styleUrls: ['./vapour-liquid-separator.component.css']
})
export class VapourLiquidSeparatorComponent {
  @Input() state: string;



  urlActive: string;

  urlInActive: string;



  constructor() {
    this.state = 'active';
    this.textValue = ""
    this.urlActive = "/assets/bert-components/active-vapour-liquid-separator.svg";

    this.urlInActive = "/assets/bert-components/inactive-vapour-liquid-separator.svg";

  }

  @Input() textValue: string;

}
