import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-c-pier-gate-hold-room-two-map',
  templateUrl: './c-pier-gate-hold-room-two-map.component.html',
  styleUrls: ['./c-pier-gate-hold-room-two-map.component.css']
})
// export class CPierGateHoldRoomTwoMapComponent {
//     progress:boolean=false;
// }
export class CPierGateHoldRoomTwoMapComponent  {
  progress: boolean = false;
  disableOptimise: boolean = true;
  predict_input: any;
  predict_response: any;
  pmv_avg:number;
  gate_input: any;

  predictionResult: any = {};
  jsonData: any = {};
  ZONE_TEMP_out_C4_L2_02: any = 0;
  ZONE_TEMP_out_C4_L2_03: any = 0;
  ZONE_TEMP_out_C4_L2_05: any = 0;
  ZONE_TEMP_out_C4_L2_06: any = 0;
  ZONE_TEMP_out_C4_L2_08: any = 0;
  ZONE_TEMP_out_C4_L2_09: any = 0;
  ZONE_TEMP_out_C4_L2_10: any = 0;
  ZONE_TEMP_out_C4_L2_11: any = 0;
  ZONE_TEMP_out_C4_L2_13: any = 0;
  ZONE_TEMP_out_C4_L2_15: any = 0;
   

  //predict input params
  // inputs from form

  ZT01: number;
  RH01: number;
  Velocity01: number;

  ZT02: number;
  RH02: number;
  Velocity02: number;

  ZT03: number;
  RH03: number;
  Velocity03: number;

  ZT04: number;
  RH04: number;
  Velocity04: number;

  ZT05: number;
  RH05: number;
  Velocity05: number;

  ZT06: number;
  RH06: number;
  Velocity06: number;

  ZT07: number;
  RH07: number;
  Velocity07: number;

  ZT08: number;
  RH08: number;
  Velocity08: number;

  ZT09: number;
  RH09: number;
  Velocity09: number;

  ZT10: number;
  RH10: number;
  Velocity10: number;

  ZT11: number;
  RH11: number;
  Velocity11: number;

  ZT12: number;
  RH12: number;
  Velocity12: number;

  // output fields predict
  pmv_out01: number;
  pmv_out02: number;
  pmv_out03: number;
  pmv_out04: number;
  pmv_out05: number;
  pmv_out06: number;
  pmv_out07: number;
  pmv_out08: number;
  pmv_out09: number;
  pmv_out10: number;
  pmv_out11: number;
  pmv_out12: number;

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {

    if(localStorage.getItem('gate_input_2')){
      this.gate_input=localStorage.getItem('gate_input_2');
      this.gate_input=JSON.parse(this.gate_input);

      this.ZT01 = this.gate_input['Zone Temp C4-L2-01'];
      this.RH01 = this.gate_input['RH-L2-01'];
      this.Velocity01 = this.gate_input['Velocity - C4 - L2 - 01'];

      this.ZT02 = this.gate_input['Zone Temp C4-L2-02'];
      this.RH02 = this.gate_input['RH-L2-02'];
      this.Velocity02 = this.gate_input['Velocity - C4 - L2 - 02'];

      this.ZT03 = this.gate_input['Zone Temp C4-L2-03'];
      this.RH03 = this.gate_input['RH-L2-03'];
      this.Velocity03 = this.gate_input['Velocity - C4 - L2 - 03'];

      this.ZT04 = this.gate_input['Zone Temp C4-L2-04'];
      this.RH04 = this.gate_input['RH-L2-04'];
      this.Velocity04 = this.gate_input['Velocity - C4 - L2 - 04'];

      this.ZT05 = this.gate_input['Zone Temp C4-L2-05'];
      this.RH05 = this.gate_input['RH-L2-05'];
      this.Velocity05 = this.gate_input['Velocity - C4 - L2 - 05'];

      this.ZT06 = this.gate_input['Zone Temp C4-L2-06'];
      this.RH06 = this.gate_input['RH-L2-06'];
      this.Velocity06 = this.gate_input['Velocity - C4 - L2 - 06'];

      this.ZT07 = this.gate_input['Zone Temp C4-L2-07'];
      this.RH07 = this.gate_input['RH-L2-07'];
      this.Velocity07 = this.gate_input['Velocity - C4 - L2 - 07'];

      this.ZT08 = this.gate_input['Zone Temp C4-L2-08'];
      this.RH08 = this.gate_input['RH-L2-08'];
      this.Velocity08 = this.gate_input['Velocity - C4 - L2 - 08'];

      this.ZT09 = this.gate_input['Zone Temp C4-L2-09'];
      this.RH09 = this.gate_input['RH-L2-09'];
      this.Velocity09 = this.gate_input['Velocity - C4 - L2 - 09'];

      this.ZT10 = this.gate_input['Zone Temp C4-L2-10'];
      this.RH10 = this.gate_input['RH-L2-10'];
      this.Velocity10 = this.gate_input['Velocity - C4 - L2 - 10'];

      this.ZT11 = this.gate_input['Zone Temp C4-L2-11'];
      this.RH11 = this.gate_input['RH-L2-11'];
      this.Velocity11 = this.gate_input['Velocity - C4 - L2 - 11'];

      this.ZT12 = this.gate_input['Zone Temp C4-L2-12'];
      this.RH12 = this.gate_input['RH-L2-12'];
      this.Velocity12 = this.gate_input['Velocity - C4 - L2 - 12'];
      
     }else{

      this.ZT01 = 0;
      this.RH01 = 0;
      this.Velocity01 = 0;

      this.ZT02 = 0;
      this.RH02 = 0;
      this.Velocity02 = 0;

      this.ZT03 = 0;
      this.RH03 = 0;
      this.Velocity03 = 0;

      this.ZT04 = 0;
      this.RH04 = 0;
      this.Velocity04 = 0;

      this.ZT05 = 0;
      this.RH05 = 0;
      this.Velocity05 = 0;

      this.ZT06 = 0;
      this.RH06 = 0;
      this.Velocity06 = 0;

      this.ZT07 = 0;
      this.RH07 = 0;
      this.Velocity07 = 0;

      this.ZT08 = 0;
      this.RH08 = 0;
      this.Velocity08 = 0;

      this.ZT09 = 0;
      this.RH09 = 0;
      this.Velocity09 = 0;

      this.ZT10 = 0;
      this.RH10 = 0;
      this.Velocity10 = 0;

      this.ZT11 = 0;
      this.RH11 = 0;
      this.Velocity11 = 0;

      this.ZT12 = 0;
      this.RH12 = 0;
      this.Velocity12 = 0;
      
     }

     if(localStorage.getItem('predictionResult')){
      this.predictionResult = localStorage.getItem('predictionResult');
      this.predictionResult = JSON.parse(this.predictionResult);
      this.ZONE_TEMP_out_C4_L2_02 = this.predictionResult['Zone_Temp_C4-L2-02'];
      this.ZONE_TEMP_out_C4_L2_03 = this.predictionResult['Zone_Temp_C4-L2-03'];
      this.ZONE_TEMP_out_C4_L2_05 = this.predictionResult['Zone_Temp_C4-L2-05'];
      this.ZONE_TEMP_out_C4_L2_06 = this.predictionResult['Zone_Temp_C4-L2-06'];
      this.ZONE_TEMP_out_C4_L2_08 = this.predictionResult['Zone_Temp_C4-L2-08'];
      this.ZONE_TEMP_out_C4_L2_09 = this.predictionResult['Zone_Temp_C4-L2-09'];
      this.ZONE_TEMP_out_C4_L2_10 = this.predictionResult['Zone_Temp_C4-L2-10'];
      this.ZONE_TEMP_out_C4_L2_11 = this.predictionResult['Zone_Temp_C4-L2-11'];
      this.ZONE_TEMP_out_C4_L2_13 = this.predictionResult['Zone_Temp_C4-L2-13'];
      this.ZONE_TEMP_out_C4_L2_15 = this.predictionResult['Zone_Temp_C4-L2-15'];
   }


    // output_field

    
    if(localStorage.getItem('PMV- C4 - L2 - 01_out') || localStorage.getItem('PMV- C4 - L2 - 01')){
      this.pmv_out01= Number(localStorage.getItem('PMV- C4 - L2 - 01_out') || localStorage.getItem('PMV- C4 - L2 - 01'));
     }else{
       this.pmv_out01 = 0;
     }
 
     
     if(localStorage.getItem('PMV- C4 - L2 - 02_out') || localStorage.getItem('PMV- C4 - L2 - 02')){
       this.pmv_out02= Number(localStorage.getItem('PMV- C4 - L2 - 02_out') || localStorage.getItem('PMV- C4 - L2 - 02'));
      }else{
        this.pmv_out02 = 0;
      }
 
      
     if(localStorage.getItem('PMV- C4 - L2 - 03_out') || localStorage.getItem('PMV- C4 - L2 - 03')){
       this.pmv_out03= Number(localStorage.getItem('PMV- C4 - L2 - 03_out') || localStorage.getItem('PMV- C4 - L2 - 03'));
      }else{
        this.pmv_out03 = 0;
     }
     
     if(localStorage.getItem('PMV- C4 - L2 - 04_out') || localStorage.getItem('PMV- C4 - L2 - 04')){
      this.pmv_out04= Number(localStorage.getItem('PMV- C4 - L2 - 04_out') || localStorage.getItem('PMV- C4 - L2 - 04'));
     }else{
       this.pmv_out04 = 0;
    }
    
    if(localStorage.getItem('PMV- C4 - L2 - 05_out') || localStorage.getItem('PMV- C4 - L2 - 05')){
      this.pmv_out05= Number(localStorage.getItem('PMV- C4 - L2 - 05_out') || localStorage.getItem('PMV- C4 - L2 - 05'));
     }else{
       this.pmv_out05 = 0;
    }
    
    if(localStorage.getItem('PMV- C4 - L2 - 06_out') || localStorage.getItem('PMV- C4 - L2 - 06')){
      this.pmv_out06= Number(localStorage.getItem('PMV- C4 - L2 - 06_out') || localStorage.getItem('PMV- C4 - L2 - 06'));
     }else{
       this.pmv_out06 = 0;
    }
    
    if(localStorage.getItem('PMV- C4 - L2 - 07_out') || localStorage.getItem('PMV- C4 - L2 - 07')){
      this.pmv_out07= Number(localStorage.getItem('PMV- C4 - L2 - 07_out') || localStorage.getItem('PMV- C4 - L2 - 07'));
     }else{
       this.pmv_out07 = 0;
    }
    
    if(localStorage.getItem('PMV- C4 - L2 - 08_out') || localStorage.getItem('PMV- C4 - L2 - 08')){
      this.pmv_out08= Number(localStorage.getItem('PMV- C4 - L2 - 08_out') || localStorage.getItem('PMV- C4 - L2 - 08'));
     }else{
       this.pmv_out08 = 0;
    }
    
    if(localStorage.getItem('PMV- C4 - L2 - 09_out') || localStorage.getItem('PMV- C4 - L2 - 09')){
      this.pmv_out09= Number(localStorage.getItem('PMV- C4 - L2 - 09_out') || localStorage.getItem('PMV- C4 - L2 - 09'));
     }else{
       this.pmv_out09 = 0;
    }

    if(localStorage.getItem('PMV- C4 - L2 - 10_out') || localStorage.getItem('PMV- C4 - L2 - 10')){
      this.pmv_out10= Number(localStorage.getItem('PMV- C4 - L2 - 10_out') || localStorage.getItem('PMV- C4 - L2 - 10'));
     }else{
       this.pmv_out10 = 0;
    }
    
    if(localStorage.getItem('PMV- C4 - L2 - 11_out') || localStorage.getItem('PMV- C4 - L2 - 11')){
      this.pmv_out11= Number(localStorage.getItem('PMV- C4 - L2 - 11_out') || localStorage.getItem('PMV- C4 - L2 - 11'));
     }else{
       this.pmv_out11 = 0;
    }

    if(localStorage.getItem('PMV- C4 - L2 - 12_out') || localStorage.getItem('PMV- C4 - L2 - 12')){
      this.pmv_out12= Number(localStorage.getItem('PMV- C4 - L2 - 12_out') || localStorage.getItem('PMV- C4 - L2 - 12'));
     }else{
       this.pmv_out12 = 0;
    }
    // this.pmv_out01=0;
    // this.pmv_out02=0;
    // this.pmv_out03=0;
    // this.pmv_out04=0;
    // this.pmv_out05=0;
    // this.pmv_out06=0;
    // this.pmv_out07=0;
    // this.pmv_out08=0;
    // this.pmv_out09=0;

  if(localStorage.getItem('PMV- C4 - L2 - 01') && localStorage.getItem('PMV- C4 - L2 - 02') && localStorage.getItem('PMV- C4 - L2 - 03') &&  localStorage.getItem('PMV- C4 - L2 - 04') &&  localStorage.getItem('PMV- C4 - L2 - 05') &&  localStorage.getItem('PMV- C4 - L2 - 06') &&  localStorage.getItem('PMV- C4 - L2 - 07') &&  localStorage.getItem('PMV- C4 - L2 - 08') &&  localStorage.getItem('PMV- C4 - L2 - 09') &&  localStorage.getItem('PMV- C4 - L2 - 10') &&  localStorage.getItem('PMV- C4 - L2 - 11') &&  localStorage.getItem('PMV- C4 - L2 - 12')){
    this.pmv_avg=(this.pmv_out01+this.pmv_out02+this.pmv_out03+this.pmv_out04+this.pmv_out05+this.pmv_out06+this.pmv_out07+this.pmv_out08+this.pmv_out09+this.pmv_out10+this.pmv_out11+this.pmv_out12)/12;

  }else{
    this.pmv_avg = 0;
  } 

  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if(this.ZT01<21 || this.ZT01>23.8){
      this.openSnackBar('ZT01 should be in range [21,23.8]');
      return false;
    }else if(this.Velocity01<0.46 || this.Velocity01>0.71){
      this.openSnackBar('Velocity01 should be in range [0.46,0.71]');
      return false;
    }else if(this.RH01<45.98 || this.RH01>57.95){
      this.openSnackBar('RH01 should be in range [45.98,57.95]');
      return false;
    }else  if(this.ZT02<21 || this.ZT02>23.8){
      this.openSnackBar('ZT02 should be in range [21,23.8]');
      return false;
    }else if(this.Velocity02<0.66 || this.Velocity02>0.97){
      this.openSnackBar('Velocity02 should be in range [0.66,0.97]');
      return false;
    }else if(this.RH02<45.98 || this.RH02>57.95){
      this.openSnackBar('RH02 should be in range [45.98,57.95]');
      return false;
    }else  if(this.ZT03<21.9 || this.ZT03>29.4){
      this.openSnackBar('ZT03 should be in range [21.9,29.4]');
      return false;
    }else if(this.Velocity03<0 || this.Velocity03>3.02){
      this.openSnackBar('Velocity03 should be in range [0,3.02]');
      return false;
    }else if(this.RH03<34.43 || this.RH03>51){
      this.openSnackBar('RH03 should be in range [34.43,51]');
      return false;
    }else  if(this.ZT04<21.6 || this.ZT04>24.8){
      this.openSnackBar('ZT04 should be in range [21.6,24.8]');
      return false;
    }else if(this.Velocity04<0.06 || this.Velocity04>3.02){
      this.openSnackBar('Velocity04 should be in range [0.06,3.02]');
      return false;
    }else if(this.RH04<42.88 || this.RH04>55.25){
      this.openSnackBar('RH04 should be in range [42.88,55.25]');
      return false;
    }else  if(this.ZT05<21.6 || this.ZT05>24.8){
      this.openSnackBar('ZT05 should be in range [21.6,24.8]');
      return false;
    }else if(this.Velocity05<0.65 || this.Velocity05>4.85){
      this.openSnackBar('Velocity05 should be in range [0.65,4.85]');
      return false;
    }else if(this.RH05<42.88 || this.RH05>55.25){
      this.openSnackBar('RH05 should be in range [42.88,55.25]');
      return false;
    }else  if(this.ZT06<21.6 || this.ZT06>24.8){
      this.openSnackBar('ZT06 should be in range [21.6,24.8]');
      return false;
    }else if(this.Velocity06<0.41 || this.Velocity06>3.02){
      this.openSnackBar('Velocity06 should be in range [0.41,3.02]');
      return false;
    }else if(this.RH06<42.88 || this.RH06>55.25){
      this.openSnackBar('RH06 should be in range [42.88,55.25]');
      return false;
    }else  if(this.ZT07<22.3 || this.ZT07>29){
      this.openSnackBar('ZT07 should be in range [22.3,29]');
      return false;
    }else if(this.Velocity07<0.61 || this.Velocity07>4.85){
      this.openSnackBar('Velocity07 should be in range [0.61,4.85]');
      return false;
    }else if(this.RH07<35.21 || this.RH07>50.77){
      this.openSnackBar('RH07 should be in range [35.21,50.77]');
      return false;
    }else  if(this.ZT08<22 || this.ZT08>24.8){
      this.openSnackBar('ZT08 should be in range [22,24.8]');
      return false;
    }else if(this.Velocity08<0.42 || this.Velocity08>3.02){
      this.openSnackBar('Velocity08 should be in range [0.42,3.02]');
      return false;
    }else if(this.RH08<42.62 || this.RH08>53.92){
      this.openSnackBar('RH08 should be in range [42.62,53.92]');
      return false;
    }else  if(this.ZT09<22 || this.ZT09>24.8){
      this.openSnackBar('ZT09 should be in range [22,24.8]');
      return false;
    }else if(this.Velocity09<0.42 || this.Velocity09>3.02){
      this.openSnackBar('Velocity09 should be in range [0.42,3.02]');
      return false;
    }else if(this.RH09<42.62 || this.RH09>53.92){
      this.openSnackBar('RH09 should be in range [42.62,53.92]');
      return false;
    }else  if(this.ZT10<22 || this.ZT10>24.8){
      this.openSnackBar('ZT10 should be in range [22,24.8]');
      return false;
    }else if(this.Velocity10<0.42 || this.Velocity10>3.02){
      this.openSnackBar('Velocity10 should be in range [0.42,3.02]');
      return false;
    }else if(this.RH10<42.62 || this.RH10>53.92){
      this.openSnackBar('RH10 should be in range [42.62,53.92]');
      return false;
    }else  if(this.ZT11<22 || this.ZT11>24.8){
      this.openSnackBar('ZT11 should be in range [22,24.8]');
      return false;
    }else if(this.Velocity11<0.42 || this.Velocity11>3.02){
      this.openSnackBar('Velocity11 should be in range [0.42,3.02]');
      return false;
    }else if(this.RH11<42.62 || this.RH11>53.92){
      this.openSnackBar('RH11 should be in range [42.62,53.92]');
      return false;
    }else if(this.ZT12<22 || this.ZT12>24.8){
      this.openSnackBar('ZT12 should be in range [22,24.8]');
      return false;
    }else if(this.Velocity12<0.42 || this.Velocity12>3.02){
      this.openSnackBar('Velocity12 should be in range [0.42,3.02]');
      return false;
    }else if(this.RH12<42.62 || this.RH12>53.92){
      this.openSnackBar('RH12 should be in range [42.62,53.92]');
      return false;
    }

    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  // this fuction is use to predict PMV
  async predictData() {
    if (this.validate()) {
      this.progress = true;
      console.log('predict input', this.predict_input);

      
      this.predict_input= {
        "Zone Temp C4-L2-01":Number(this.ZT01),
        "RH-L2-01":Number(this.RH01),
        "Velocity - C4 - L2 - 01":Number(this.Velocity01),
        "Zone Temp C4-L2-02":Number(this.ZT02),
        "RH-L2-02":Number(this.RH02),
        "Velocity - C4 - L2 - 02":Number(this.Velocity02),
        "Zone Temp C4-L2-03":Number(this.ZT03),
        "RH-L2-03":Number(this.RH03),
        "Velocity - C4 - L2 - 03":Number(this.Velocity03),
        "Zone Temp C4-L2-04":Number(this.ZT04),
        "RH-L2-04":Number(this.RH04),
        "Velocity - C4 - L2 - 04":Number(this.Velocity04),
        "Zone Temp C4-L2-05":Number(this.ZT05),
        "RH-L2-05":Number(this.RH05),
        "Velocity - C4 - L2 - 05":Number(this.Velocity05),
        "Zone Temp C4-L2-06":Number(this.ZT06),
        "RH-L2-06":Number(this.RH06),
        "Velocity - C4 - L2 - 06":Number(this.Velocity06),
        "Zone Temp C4-L2-07":Number(this.ZT07),
        "RH-L2-07":Number(this.RH07),
        "Velocity - C4 - L2 - 07":Number(this.Velocity07),
        "Zone Temp C4-L2-08":Number(this.ZT08),
        "RH-L2-08":Number(this.RH08),
        "Velocity - C4 - L2 - 08":Number(this.Velocity08),
        "Zone Temp C4-L2-09":Number(this.ZT09),
        "RH-L2-09":Number(this.RH09),
        "Velocity - C4 - L2 - 09":Number(this.Velocity09),
        "Zone Temp C4-L2-10":Number(this.ZT10),
        "RH-L2-10":Number(this.RH10),
        "Velocity - C4 - L2 - 10":Number(this.Velocity10),
        "Zone Temp C4-L2-11":Number(this.ZT11),
        "RH-L2-11":Number(this.RH11),
        "Velocity - C4 - L2 - 11":Number(this.Velocity11),
        "Zone Temp C4-L2-12":Number(this.ZT12),
        "RH-L2-12":Number(this.RH12),
        "Velocity - C4 - L2 - 12":Number(this.Velocity12),
        "model_no.":4
      };

      localStorage.setItem('Zone Temp C4-L2-01', String(this.ZT01));
      localStorage.setItem('Zone Temp C4-L2-02', String(this.ZT02));
      localStorage.setItem('Zone Temp C4-L2-03', String(this.ZT03));
      localStorage.setItem('Zone Temp C4-L2-04', String(this.ZT04));
      localStorage.setItem('Zone Temp C4-L2-05', String(this.ZT05));
      localStorage.setItem('Zone Temp C4-L2-06', String(this.ZT06));
      localStorage.setItem('Zone Temp C4-L2-07', String(this.ZT07));
      localStorage.setItem('Zone Temp C4-L2-08', String(this.ZT08));
      localStorage.setItem('Zone Temp C4-L2-09', String(this.ZT09));
      localStorage.setItem('Zone Temp C4-L2-10', String(this.ZT10));
      localStorage.setItem('Zone Temp C4-L2-11', String(this.ZT11));
      localStorage.setItem('Zone Temp C4-L2-12', String(this.ZT12));


      localStorage.setItem('RH-L2-01', String(this.RH01));
      localStorage.setItem('RH-L2-02', String(this.RH02));
      localStorage.setItem('RH-L2-03', String(this.RH03));
      localStorage.setItem('RH-L2-04', String(this.RH04));
      localStorage.setItem('RH-L2-05', String(this.RH05));
      localStorage.setItem('RH-L2-06', String(this.RH06));
      localStorage.setItem('RH-L2-07', String(this.RH07));
      localStorage.setItem('RH-L2-08', String(this.RH08));
      localStorage.setItem('RH-L2-09', String(this.RH09));
      localStorage.setItem('RH-L2-10', String(this.RH10));
      localStorage.setItem('RH-L2-11', String(this.RH11));
      localStorage.setItem('RH-L2-12', String(this.RH12));

      localStorage.setItem('Velocity - C4 - L2 - 01', String(this.Velocity01));
      localStorage.setItem('Velocity - C4 - L2 - 02', String(this.Velocity02));
      localStorage.setItem('Velocity - C4 - L2 - 03', String(this.Velocity03));
      localStorage.setItem('Velocity - C4 - L2 - 04', String(this.Velocity04));
      localStorage.setItem('Velocity - C4 - L2 - 05', String(this.Velocity05));
      localStorage.setItem('Velocity - C4 - L2 - 06', String(this.Velocity06));
      localStorage.setItem('Velocity - C4 - L2 - 07', String(this.Velocity07));
      localStorage.setItem('Velocity - C4 - L2 - 08', String(this.Velocity08));
      localStorage.setItem('Velocity - C4 - L2 - 09', String(this.Velocity09));
      localStorage.setItem('Velocity - C4 - L2 - 10', String(this.Velocity10));
      localStorage.setItem('Velocity - C4 - L2 - 11', String(this.Velocity11));
      localStorage.setItem('Velocity - C4 - L2 - 12', String(this.Velocity12));

      localStorage.setItem('gate_input_2', JSON.stringify(this.predict_input));

      // this.ZT01 = Number(localStorage.getItem('Zone Temp C4-L2-01')) || 0;
      // this.RH01 = Number(localStorage.getItem('RH-L2-01')) || 0;
      // this.Velocity01 = Number(localStorage.getItem('Velocity - C4 - L2 - 01')) || 0;
      
      try {
        const response = await this.http
          .post<any>(
            'https://bpsliveatchangiairport1.bertlabs.com/sec_4',
            // 'https://bpsliveatchangiairport.bertlabs.com/predictsec6/',
            this.predict_input,
            this.httpOptions
          )
          .toPromise();

        this.predict_response = response;
        console.log('predict outout', this.predict_response);

        localStorage.removeItem('PMV- C4 - L2 - 01_out');
        localStorage.removeItem('PMV- C4 - L2 - 02_out');
        localStorage.removeItem('PMV- C4 - L2 - 03_out');
        localStorage.removeItem('PMV- C4 - L2 - 04_out');
        localStorage.removeItem('PMV- C4 - L2 - 05_out');
        localStorage.removeItem('PMV- C4 - L2 - 06_out');
        localStorage.removeItem('PMV- C4 - L2 - 07_out');
        localStorage.removeItem('PMV- C4 - L2 - 08_out');
        localStorage.removeItem('PMV- C4 - L2 - 09_out');
        localStorage.removeItem('PMV- C4 - L2 - 10_out');
        localStorage.removeItem('PMV- C4 - L2 - 11_out');
        localStorage.removeItem('PMV- C4 - L2 - 12_out');
        localStorage.removeItem('PMV- C4 - L2 - 13_out');
        localStorage.removeItem('PMV- C4 - L2 - 14_out');
        localStorage.removeItem('PMV- C4 - L2 - 15_out');
        localStorage.removeItem('PMV- C4 - L2 - 16_out');
        localStorage.removeItem('PMV- C4 - L2 - 17_out');
        localStorage.removeItem('PMV- C4 - L2 - 18_out');
        localStorage.removeItem('PMV- C4 - L2 - 19_out');
        localStorage.removeItem('PMV- C4 - L2 - 20_out');
        localStorage.removeItem('PMV- C4 - L2 - 21_out');
        localStorage.removeItem('PMV- C4 - L2 - 22_out');
        localStorage.removeItem('PMV- C4 - L2 - 23_out');
        localStorage.removeItem('PMV- C4 - L2 - 24_out');
        localStorage.removeItem('PMV- C4 - L2 - 25_out');
        localStorage.removeItem('PMV- C4 - L2 - 26_out');
        localStorage.removeItem('PMV- C4 - L2 - 27_out');
        localStorage.removeItem('Fan Power - C4A_out');
        localStorage.removeItem('Fan Power - C4B_out');
        localStorage.removeItem('VSD Freq - C4A_out');
        localStorage.removeItem('VSD Freq - C4B_out');
        localStorage.removeItem('Valve Feedback - C4A_out');
        localStorage.removeItem('Valve Feedback - C4B_out');

        this.openSnackBar('Predicted Succesfully');


        this.pmv_out01 =  this.predict_response['PMV- C4 - L2 - 01'];
        this.pmv_out02 =  this.predict_response['PMV- C4 - L2 - 02'];
        this.pmv_out03 =  this.predict_response['PMV- C4 - L2 - 03'];
        this.pmv_out04 =  this.predict_response['PMV- C4 - L2 - 04'];
        this.pmv_out05 =  this.predict_response['PMV- C4 - L2 - 05'];
        this.pmv_out06 =  this.predict_response['PMV- C4 - L2 - 06'];
        this.pmv_out07 =  this.predict_response['PMV- C4 - L2 - 07'];
        this.pmv_out08 =  this.predict_response['PMV- C4 - L2 - 08'];
        this.pmv_out09 =  this.predict_response['PMV- C4 - L2 - 09'];
        this.pmv_out10 =  this.predict_response['PMV- C4 - L2 - 10'];
        this.pmv_out11 =  this.predict_response['PMV- C4 - L2 - 11'];
        this.pmv_out12 =  this.predict_response['PMV- C4 - L2 - 12'];
       
        
        this.pmv_avg=(this.pmv_out01+this.pmv_out02+this.pmv_out03+this.pmv_out04+this.pmv_out05+this.pmv_out06+this.pmv_out07+this.pmv_out08+this.pmv_out09+this.pmv_out10+this.pmv_out11+this.pmv_out12)/12;


        this.progress = false;
        this.disableOptimise = false;
        localStorage.setItem('model_four', JSON.stringify(this.pmv_avg));
        localStorage.setItem('PMV- C4 - L2 - 01',JSON.stringify(this.pmv_out01));
        localStorage.setItem('PMV- C4 - L2 - 02',JSON.stringify(this.pmv_out02));
        localStorage.setItem('PMV- C4 - L2 - 03',JSON.stringify(this.pmv_out03));
        localStorage.setItem('PMV- C4 - L2 - 04',JSON.stringify(this.pmv_out04));
        localStorage.setItem('PMV- C4 - L2 - 05',JSON.stringify(this.pmv_out05));
        localStorage.setItem('PMV- C4 - L2 - 06',JSON.stringify(this.pmv_out06));
        localStorage.setItem('PMV- C4 - L2 - 07',JSON.stringify(this.pmv_out07));
        localStorage.setItem('PMV- C4 - L2 - 08',JSON.stringify(this.pmv_out08));
        localStorage.setItem('PMV- C4 - L2 - 09',JSON.stringify(this.pmv_out09));
        localStorage.setItem('PMV- C4 - L2 - 10',JSON.stringify(this.pmv_out10));
        localStorage.setItem('PMV- C4 - L2 - 11',JSON.stringify(this.pmv_out11));
        localStorage.setItem('PMV- C4 - L2 - 12',JSON.stringify(this.pmv_out12));
        
      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }
}