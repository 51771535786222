<div
  [ngClass]="{
    'pmv-hot-three': pmv >= 1,
    'pmv-hot-two': (pmv >= 0.5) && (pmv<1),
    'pmv-neutral': (pmv > -0.5) && (pmv<0.5),
    'pmv-cold-two': (pmv > -1) && (pmv<=-0.5),
    'pmv-cold-three': (pmv <=-1),
    'pmv-disabled':pmv===-4,
    'pmv-default':pmv===4
  }"

  style="width: 100%; height: 100%;"
>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 527.255 489.197">
    <path id="Path_4309" data-name="Path 4309" d="M9283.232,5547.3v-51.531h65.705v-48.641h47.287v-46.7h72.406V5097.465h-72.406v-34.154H9326.49v8.1h-37.5V5058.1H9053.032l-111.656,111.656,214.594,210.39.78,167.151" transform="translate(-8941.376 -5058.099)" fill="#e0c3c3"/>
  </svg>
  
</div>
