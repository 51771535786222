<!-- <div class="scaffold">
    <div style="height: 100%; width: 100%; position: relative">
      <div
        style="
          height: 100%;
          width: 100%;

          background:
            url('/assets/images/changi-mall-background.png');
          position: absolute;
          object-fit: cover;
          background-repeat: no-repeat;
          background-size: cover;
        "
      ></div>

      <div
        style="
          height: 100%;
          width: 100%;
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          style="
            width: 90%;
            max-width: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            gap: 10px;
          "
        >
          <img
            src="/assets/logos/changi-mall-logo.png"
            width="530px"
            style=" border-radius: 5px; margin-bottom:30px"
          />



          <span class="span-text-light" style="font-size: 30px; margin-bottom:20px;" >Powered by:</span>

          <div style="background-color: white;border-radius: 10px;padding: 5px 10px 5px 10px; margin-bottom:30px">
            <img src="/assets/logos/bertlabs-logo.png" width="500px" />

          </div>


        </div>
      </div>
    </div>
  </div>
   -->

   <div class="scaffold">
    <div class="background-image"></div>

    <div class="animation-container">
      <div class="animation-element">
        <img class="changi-logo" src="/assets/changi-images/logo-loading.svg"  alt="CHangi Logo">
        <img class="changi-logo-powered-by" src="/assets/changi-images/logo animation mulish 1.gif" alt="Powered By GIF" width="270px"
          style=" border-radius: 5px; z-index: 1; position: absolute;
          margin-top: 114px; margin-left:0px; ">
      </div>

      <!-- <div class="animation-element">
        <span class="span-text-light powered-by">Powered by:</span>
      </div> -->

      <div class="animation-element">
        <!-- <div class="bert-labs-logo">
          <img src="/assets/logos/bertlabs-logo.png" alt="Bert Labs Logo" width="100%">
        </div> -->
      </div>
    </div>
  </div>
