<div class="scrollable-content">
  <!-- Title -->
  <div style="display: flex; gap: 5%">
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        position: absolute;
      "
    >
      <div style="padding-top: 5%">
        <label
          style="
            color: #0366de;
            font-size: 2.25vh;
            font-weight: 550;
            padding-right: 0.5vw;
          "
          >Room :</label
        >
        <select
          [(ngModel)]="selectedRoom"
          (ngModelChange)="onRoomChange()"
          style="background-color: white; border-color: #0366de"
        >
          <option *ngFor="let room of rooms" [value]="room.value">
            {{ room.name }}
          </option>
        </select>
      </div>
      <div style="padding: 6%">
        <label
          style="
            color: #0366de;
            font-size: 2.25vh;
            font-weight: 550;
            padding-right: 0.5vw;
          "
          >VAV :</label
        >
        <select
          [(ngModel)]="selectedVav"
          (ngModelChange)="onChange()"
          style="background-color: white; border-color: #0366de"
        >
          <option *ngFor="let vav of getVavOptions()" [value]="vav.value">
            {{ vav.name }}
          </option>
        </select>
      </div>

      <!-- Date and Time Selectors -->
      <div
        style="
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          gap: 2vw;
        "
      >
        <div style="display: flex; align-items: center">
          <label
            style="
              color: #0366de;
              font-size: 2.25vh;
              font-weight: 550;
              padding-right: 0.5vw;
            "
            >From Date :</label
          >
          <input
            matInput
            [matDatepicker]="fromDatePicker"
            [(ngModel)]="fromDate"
            (ngModelChange)="onChange()"
            placeholder="Choose a date"
            style="
              background-color: white;
              border: 1.2px solid #0366de;
              height: 32px;
              border-radius: 7px;
              padding-left: 10px;
            "
            readonly
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="fromDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
        </div>
        <div>
          <label
            style="
              color: #0366de;
              font-size: 2.25vh;
              font-weight: 550;
              padding-right: 0.5vw;
            "
            >Time :</label
          >
          <!-- <input
        matInput
        [ngxTimepicker]="fromTimePicker"
        [(ngModel)]="fromTime"
        placeholder="Select time"
        readonly
      /> -->
          <!-- <ngx-material-timepicker #fromTimePicker></ngx-material-timepicker> -->
          <select
            [(ngModel)]="fromTime"
            style="background-color: white; border-color: #0366de"
            (ngModelChange)="onChange()"
          >
            <option *ngFor="let time of times" [value]="time.value">
              {{ time.name }}
            </option>
          </select>
        </div>
      </div>

      <div
        style="
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          gap: 2vw;
          padding: 6%;
        "
      >
        <div style="display: flex; align-items: center">
          <label
            style="
              color: #0366de;
              font-size: 2.25vh;
              font-weight: 550;
              padding-right: 0.5vw;
            "
            >To Date :</label
          >
          <input
            matInput
            [matDatepicker]="toDatePicker"
            [(ngModel)]="toDate"
            (ngModelChange)="onChange()"
            placeholder="Choose a date"
            style="
              background-color: white;
              border: 1.2px solid #0366de;
              height: 32px;
              border-radius: 7px;
              padding-left: 10px;
            "
            readonly
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="toDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
        </div>
        <div>
          <label
            style="
              color: #0366de;
              font-size: 2.25vh;
              font-weight: 550;
              padding-right: 0.5vw;
            "
            >Time :</label
          >
          <!-- <input
    matInput
    [ngxTimepicker]="fromTimePicker"
    [(ngModel)]="fromTime"
    placeholder="Select time"
    readonly
  /> -->
          <!-- <ngx-material-timepicker #fromTimePicker></ngx-material-timepicker> -->
          <select
            [(ngModel)]="toTime"
            style="background-color: white; border-color: #0366de"
            (ngModelChange)="onChange()"
          >
            <option
              *ngFor="let time of times"
              [value]="time.value"
              style="max-height: 1000px; overflow-y: auto"
            >
              {{ time.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- Type Selector -->
      <div
        style="
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          gap: 2vw;
        "
      >
        <div>
          <label
            style="
              color: #0366de;
              font-size: 2.25vh;
              font-weight: 550;
              padding-right: 0.5vw;
            "
            >PMV / Zonal Temp :</label
          >
          <select
            [(ngModel)]="selectedType"
            style="background-color: white; border-color: #0366de"
            (ngModelChange)="onChange()"
          >
            <option *ngFor="let type of types" [value]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
      <div style="padding: 6%">
        <button class="databtn" (click)="SendData()">Show Data</button>
      </div>
    </div>
    <div style="width: 50%; margin-left: 50%">
      <div *ngIf="showData === true && tableData.length > 0">
        <div
          style="
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            gap: 2vh;
            padding-top: 2vh;
            padding-bottom: 2vh;
          "
        >
          <div style="display: flex; padding-bottom: 2vh">
            <select
              [(ngModel)]="downloadtype"
              style="background-color: white; border-color: #0366de"
            >
              <option *ngFor="let room of downloadTypes" [value]="room.value">
                {{ room.name }}
              </option>
            </select>

            <button
              *ngIf="tableData.length > 0"
              (click)="downloadtype === 'CSV' ? downloadCSV() : downloadPDF()"
              style="
                width: 10vw;
                font-size: medium;
                border-radius: 5px;
                padding: 5px;
                color: white;
                background-color: #0366de;
                border: none;
                cursor: pointer;
                margin-left: 2vh;
              "
            >
              Download
            </button>
          </div>
          <div *ngIf="selectedType === 'pmv' && showData === true">
            <!-- Add gauge -->
            <ngx-gauge
              [type]="'semi'"
              [value]="outOfRangePercentage"
              [size]="125"
              [duration]="1500"
              [thick]="14"
              [thresholds]="thresholdConfig"
            >
              <ngx-gauge-label
                style="
                  font-size: 12px;
                  font-weight: bold;
                  margin-top: -10%;
                  color: #81c91d;
                "
              >
                PMV (-0.5 to +0.5) :
              </ngx-gauge-label>
              <ngx-gauge-value
                style="font-size: 20px; color: #81c91d; font-weight: 650"
              >
                {{ outOfRangePercentage }}%
              </ngx-gauge-value>
            </ngx-gauge>
          </div>
        </div>
        <div
          *ngIf="tableData.length > 0 && selectedTab === 'tabular'"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          "
        >
          <!-- Items Per Page Selector -->
          <div style="padding-bottom: 3%">
            <label
              for="itemsPerPage"
              style="
                color: #0366de;
                font-size: 2vh;
                font-weight: 550;
                padding-right: 0.5vw;
              "
              >Items per page : &nbsp;&nbsp;
            </label>
            <select
              id="itemsPerPage"
              [(ngModel)]="itemsPerPage"
              (change)="onItemsPerPageChange()"
              style="background-color: white; border-color: #0366de"
            >
              <option
                *ngFor="let option of itemsPerPageOptions"
                [value]="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
          <table style="width: 70%; border-collapse: collapse">
            <thead>
              <!-- Table Header -->
              <tr
                style="
                  background-color: #4b99f8;
                  color: #ffffff;
                  text-align: center;
                  border-bottom: 1px solid #dddddd;
                "
              >
                <th
                  style="
                    padding: 1.5vh;
                    border-bottom: 2px solid #0366de;
                    border-right: 1px solid #dddddd;
                    width: 20%;
                  "
                >
                  S.No
                </th>
                <th
                  style="
                    padding: 1.5vh;
                    border-bottom: 2px solid #0366de;
                    border-right: 1px solid #dddddd;
                    width: 20%;
                  "
                >
                  Date
                </th>
                <th
                  style="
                    padding: 1.5vh;
                    border-bottom: 2px solid #0366de;
                    border-right: 1px solid #dddddd;
                    width: 20%;
                  "
                >
                  Time
                </th>
                <th
                  style="
                    padding: 1.5vh;
                    border-bottom: 2px solid #0366de;
                    width: 40%;
                  "
                >
                  {{ selectedtypeintable }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let row of tableData
                    | paginate
                      : {
                          itemsPerPage: itemsPerPage,
                          currentPage: currentPage
                        };
                  let i = index
                "
                [style.background-color]="i % 2 === 0 ? '#c3deff' : '#e1edfc'"
              >
                <td
                  style="
                    padding: 1.5vh;
                    text-align: center;
                    border-right: 1px solid #dddddd;
                    width: 20%;
                  "
                >
                  {{ row.serialNo }}
                </td>
                <td
                  style="
                    padding: 1.5vh;
                    text-align: center;
                    border-right: 1px solid #dddddd;
                    width: 20%;
                  "
                >
                  {{ row.date }}
                </td>
                <td
                  style="
                    padding: 1.5vh;
                    text-align: center;
                    border-right: 1px solid #dddddd;
                    width: 20%;
                  "
                >
                  {{ row.time }}
                </td>
                <td style="padding: 1.5vh; text-align: center; width: 40%">
                  {{ row.value
                  }}<span *ngIf="selectedtypeintable === 'zonetemp'"> °C</span>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination-controls
            style="padding-top: 5%"
            (pageChange)="currentPage = $event"
          ></pagination-controls>
        </div>

        <div
          style="
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            gap: 2vw;
            padding: 3vh;
          "
        >
          <div>
            <select
              [(ngModel)]="downloadtype"
              style="background-color: white; border-color: #0366de"
            >
              <option *ngFor="let room of downloadTypes" [value]="room.value">
                {{ room.name }}
              </option>
            </select>

            <button
              *ngIf="tableData.length > 0"
              (click)="downloadtype === 'CSV' ? downloadCSV() : downloadPDF()"
              style="
                width: 10vw;
                font-size: medium;
                border-radius: 5px;
                padding: 5px;
                color: white;
                background-color: #0366de;
                border: none;
                cursor: pointer;
                margin: 2vh;
              "
            >
              Download
            </button>
          </div>
        </div>
      </div>

      <div
        *ngIf="showData === true && tableData.length == 0"
        style="color: red; font-weight: 200"
      >
        {{ aftermessage }}
      </div>
    </div>
  </div>
</div>
