import { ElementRef, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, elementAt } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ButtonService {
  elementRef!: ElementRef;
  constructor(private http: HttpClient) {
    //catch element ref
    elementRef: ElementRef;
  }

  catchElementRef(elementRef:ElementRef){
    this.elementRef = elementRef;
    console.log("catched elementRef: ", this.elementRef)
  }

  processElementA(): void {
    const buttonB = this.elementRef.nativeElement.querySelectorAll('[id="optimise-C4-B"]');
    if (buttonB) {
      console.log("button B will be pressed");
      // Trigger a click event on the button
      console.log(buttonB)
    } else {
      // Handle the case where the button was not found
      console.log('Button B not found.');
    }
  }

  processElementB(): void {
    const buttonA = this.elementRef.nativeElement.querySelectorAll('[id="optimise-C4-A"]');
    if (buttonA) {
      console.log("button A will be pressed");
      console.log(buttonA)
    } else {
      // Handle the case where the button was not found
      console.log('Button A not found.');
    }
  }
}
