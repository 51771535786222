<div style="height: 100%; width: 100%">

    <ng-container *ngIf="state === 'active'; else inActiveTemplate">

      <img src="{{ urlActive }}" alt="fan" height="100%" width="100%" />

    </ng-container>

 

    <ng-template #inActiveTemplate>

      <ng-container *ngIf="state === 'inActive'; else tripTemplate">

          <img src={{urlInActive}} alt="fan" height="100%" width="100%"/>

      </ng-container>

 

      <ng-template #tripTemplate>

          <img src={{urlTrip}} alt="fan" height="100%" width="100%"/>

      </ng-template>

    </ng-template>

  </div>


