<div
  [ngClass]="{
    'pmv-hot-three': pmv >= 1,
    'pmv-hot-two': pmv >= 0.5 && pmv < 1,
    'pmv-neutral': pmv > -0.5 && pmv < 0.5,
    'pmv-cold-two': pmv > -1 && pmv <= -0.5,
    'pmv-cold-three': pmv <= -1,
    'pmv-disabled': pmv === -4,
    'pmv-default': pmv === 4
  }"
  style="height: 100%; width: 100%"
>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 621.363 238.165">
    <path id="Path_4532" data-name="Path 4532" d="M16503.99,7231.189v21.321h-30.143v19.044h24.164v80.956h32.277v23.284h-28.9v17.8h-215.62l-17.093,34.52-189.481-34.169-19.248,35.156-151.021-29.533V7252.51h98.42v-61.575h473.127v40.253Z" transform="translate(-15908.926 -7190.936)" fill="#f4f6ed"/>
  </svg>
  
</div>
