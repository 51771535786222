<div
  
  [ngClass]="{
    'pmv-hot-three': pmv >= 1,
    'pmv-hot-two': (pmv >= 0.5) && (pmv<1),
    'pmv-neutral': (pmv > -0.5) && (pmv<0.5),
    'pmv-cold-two': (pmv > -1) && (pmv<=-0.5),
    'pmv-cold-three': (pmv <=-1),
    'pmv-disabled':pmv===-4,
    'pmv-default':pmv===4
  }"

  style="height: 100%; width: 100%;"
>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 281.95 209.989">
    <path id="Path_4531" data-name="Path 4531" d="M16007.334,7190.409v61.909h-98.626V7400.4l-36.884-7.478v-10.477h-121.993l-18.1-93.344h-6.344v-98.689Z" transform="translate(-15725.384 -7190.409)" fill="#a5b7d7"/>
  </svg>
  
  
</div>
