<div
  [ngClass]="{
    'pmv-hot-three': pmv >= 1,
    'pmv-hot-two': pmv >= 0.5 && pmv < 1,
    'pmv-neutral': pmv > -0.5 && pmv < 0.5,
    'pmv-cold-two': pmv > -1 && pmv <= -0.5,
    'pmv-cold-three': pmv <= -1,
    'pmv-disabled': pmv === -4,
    'pmv-default': pmv === 4
  }"
  style="height: 100%; width: 100%"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 247.443 218.711"
  >
    <path
      id="Path_4534"
      data-name="Path 4534"
      d="M16793.352,7410.039V7267.181h59.248v-75.853h-242.1v41.363h-5.348v38.1h15.029v119.257h129.361v12.639Z"
      transform="translate(-16605.156 -7191.328)"
      fill="#ecf0f7"
    />
  </svg>
</div>
