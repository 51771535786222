import { Component, Input } from '@angular/core';

@Component({
  selector: 'Insulator',
  templateUrl: './insulator.component.html',
  styleUrls: ['./insulator.component.css']
})
export class InsulatorComponent {
  @Input() state!:string;
  urlActive:string;
  urlInActive:string;
  urlTrip:string;
  constructor(){
    this.urlActive="/assets/bert-components/active-insulator.svg";
    this.urlInActive="/assets/bert-components/active-insulator.svg";
    this.urlTrip="/assets/bert-components/active-insulator.svg";
  }
}
