<div
  [ngClass]="{
    'active-field-container': !disabled,
    'disabled-field-container': disabled
  }"
>
  <div
    [ngClass]="{
      'active-icon-container': !disabled,
      'disabled-icon-container': disabled
    }"
  >
    <img src="{{ icon }}" height="15px" />
  </div>
  <div
    style="
      height: 30px;
      width: 54px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    "
  >
    <input
      type="text"
      width="54px"
      height="30px"
      [ngClass]="{ 'active-field': !disabled, 'disabled-field': disabled }"
    />
  </div>
  <div [ngClass]="{ 'active-unit': true }">
    <span
      [ngClass]="{
        'active-unit-container': !disabled,
        'disabled-unit-container': disabled
      }"
      >{{ units }}</span
    >
  </div>
</div>
