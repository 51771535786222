import { Component, Input } from '@angular/core';

@Component({
  selector: 'C-pier-security-screening-room-one',
  templateUrl: './c-pier-security-screening-room-one.component.html',
  styleUrls: ['./c-pier-security-screening-room-one.component.css']
})
export class CPierSecurityScreeningRoomOneComponent {
  @Input()pmv!:number;
  
  constructor(){
    this.pmv=0;
  }

}
