<div style="position: relative; height: 100%; width: 100%">
  <div
    style="
      width: 100%;
      height: 100%;
      min-width: 1200px;
      display: flex;
      flex-direction: column;
      position: absolute;
    "
  >

  <div>
    <mat-progress-bar
    mode="indeterminate"
    [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"
  ></mat-progress-bar>
  </div>
   
    <div style="display: flex; flex-direction: row; height: 100%; width: 100%">
      <!-- main body -->
      <div
        style="
          height: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px;
          align-items: center;
          gap: 10px;
          overflow-y: scroll;
          padding-left: 120px;
        "
      >
        <div
          style="
            height: 550px;
            width: 1000px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          "
        >
          <!-- componets -->
          <div style="height: 500px; width: 1000px; position: absolute">
            <div style="margin-top: 14px; margin-left: 2px; height: 494px">
              <Security-screening-area [pmv]="pmv_avg"></Security-screening-area>
            </div>
          </div>

          <!-- map -->
          <div
            style="
              height: 500px;
              width: 1000px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
            "
          >
            <img
              src="/assets/images/security-screening-area-map.svg"
              width="1000px"
              height="500px"
            />
          </div>

          <!-- fields -->

          <div style="height: 500px; width: 1000px; position: absolute">
            <!-- field 1 -->
            <div style="width: 100px; margin-top: 156px; margin-left: -8px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out4 | number : '1.2-2'"
                  (ngModelChange)="pmv_out4 = $event"
                />
              </div>
            </div>

            <!-- field 2 -->
            <div style="width: 100px; margin-top: -77px; margin-left: 66px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out3 | number : '1.2-2'"
                  (ngModelChange)="pmv_out3 = $event"
                />
              </div>
            </div>

            <!-- field 3 -->
            <div style="width: 100px; margin-top: 10px; margin-left: 139px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out2 | number : '1.2-2'"
                  (ngModelChange)="pmv_out2 = $event"
                />
              </div>
            </div>

            <!-- field 4 -->

            <div style="width: 100px; margin-top: -74px; margin-left: 217px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out5 | number : '1.2-2'"
                  (ngModelChange)="pmv_out5 = $event"
                />
              </div>
            </div>

            <!-- field 5 -->

            <div style="width: 100px; margin-top: 258px; margin-left: 6px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out8 | number : '1.2-2'"
                  (ngModelChange)="pmv_out8 = $event"
                />
              </div>
            </div>

            <!-- field 6 -->

            <div style="width: 100px; margin-top: -58px; margin-left: 162px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out7 | number : '1.2-2'"
                  (ngModelChange)="pmv_out7 = $event"
                />
              </div>
            </div>

            <!-- field 7 -->

            <div style="width: 100px; margin-top: 6px; margin-left: 219px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out6 | number : '1.2-2'"
                  (ngModelChange)="pmv_out6 = $event"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- legends -->
        <div
        style="display: flex; flex-direction: row; width: 1000px; gap: 10px"
      >
        <div
          class="pmv-pallet"
          style="background-color: var(--pmv-neg-three)"
        >
          <span style="font-size: 12px">-1 SLIGHTLY COOL</span>
        </div>
        <div class="pmv-pallet" style="background-color: var(--pmv-neg-two)">
          <span style="font-size: 12px">-0.5</span>
        </div>
        <!-- <div class="pmv-pallet" style="background-color: var(--pmv-neg-one)">
          <span style="font-size: 12px">-0.2</span>
        </div> -->
        <div class="pmv-pallet" style="background-color: var(--pmv-zero)">
          <span style="font-size: 12px">0 NEUTRAL</span>
        </div>
        <!-- <div class="pmv-pallet" style="background-color: var(--pmv-one)">
          <span style="font-size: 12px">0.2</span>
        </div> -->
        <div class="pmv-pallet" style="background-color: var(--pmv-two)">
          <span style="font-size: 12px">0.5</span>
        </div>
        <div class="pmv-pallet" style="background-color: var(--pmv-three)">
          <span style="font-size: 12px">1 SLIGHTLY WARM</span>
        </div>
      </div>
      </div>
      <!-- input side bar -->
      <div
        style="
          width: 450px;
          height: 100%;
          display: flex;
          flex-direction: column;
          box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.06),
            0px 0px 2px rgba(25, 25, 25, 0.12);
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 10px;
            justify-content: space-between;
            overflow-x: scroll;
          "
        >
          <div style="display: flex; flex-direction: column; gap: 15px">
            <!-- form-1 -->
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="span-text-dark" style="text-align: center"
                >VAV-AHU D7-L2-02</span
              >

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >Zone<br />Temp</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="ZT2" />
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500"
                    >Velocity</span
                  >

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/velocity.png" />
                    <input [(ngModel)]="Velocity2" />
                    <span>m/s</span>
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >RH</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/percentage.png" />
                    <input [(ngModel)]="RH2" />
                  </div>
                </div>

                <div style="width: 50%;"></div>
              </div>
            </div>

            <!-- form 2 -->

            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="span-text-dark" style="text-align: center"
                >VAV-AHU D7-L2-03</span
              >

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >Zone<br />Temp</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="ZT3" />
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500"
                    >Velocity</span
                  >

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/velocity.png" />
                    <input [(ngModel)]="Velocity3" />
                    <span>m/s</span>
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >RH</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/percentage.png" />
                    <input [(ngModel)]="RH3" />
                  </div>
                </div>
                <div style="width: 50%;"></div>
              </div>
            </div>

            <!-- form 3 -->

            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="span-text-dark" style="text-align: center"
                >VAV-AHU D7-L2-04</span
              >

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >Zone<br />Temp</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="ZT4" />
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500"
                    >Velocity</span
                  >

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/velocity.png" />
                    <input [(ngModel)]="Velocity4" />
                    <span>m/s</span>
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >RH</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/percentage.png" />
                    <input [(ngModel)]="RH4" />
                  </div>
                </div>
                <div style="width: 50%;"></div>
              </div>
            </div>

            <!-- form 4 -->

            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="span-text-dark" style="text-align: center"
                >VAV-AHU D7-L2-05</span
              >

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >Zone<br />Temp</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="ZT5" />
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500"
                    >Velocity</span
                  >

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/velocity.png" />
                    <input [(ngModel)]="Velocity5" />
                    <span>m/s</span>
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >RH</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/percentage.png" />
                    <input [(ngModel)]="RH5" />
                  </div>
                </div>
                <div style="width: 50%;"></div>
              </div>
            </div>

            <!-- form 5 -->

            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="span-text-dark" style="text-align: center"
                >VAV-AHU D7-L2-06</span
              >

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >Zone<br />Temp</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="ZT6" />
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500"
                    >Velocity</span
                  >

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/velocity.png" />
                    <input [(ngModel)]="Velocity6" />
                    <span>m/s</span>
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >RH</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/percentage.png" />
                    <input [(ngModel)]="RH6" />
                  </div>
                </div>
                <div style="width: 50%;"></div>
              </div>
            </div>

            <!-- form 6 -->

            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="span-text-dark" style="text-align: center"
                >VAV-AHU D7-L2-07</span
              >

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >Zone<br />Temp</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="ZT7" />
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500"
                    >Velocity</span
                  >

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/velocity.png" />
                    <input [(ngModel)]="Velocity7" />
                    <span>m/s</span>
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >RH</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/percentage.png" />
                    <input [(ngModel)]="RH7" />
                  </div>
                </div>
                <div style="width: 50%;"></div>
              </div>
            </div>

            <!-- form 7 -->

            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="span-text-dark" style="text-align: center"
                >VAV-AHU D7-L2-08</span
              >

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >Zone<br />Temp</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                  (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="ZT8" />
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500"
                    >Velocity</span
                  >

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/velocity.png" />
                    <input [(ngModel)]="Velocity8" />
                    <span>m/s</span>
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 10px; font-weight: 500; line-height: 12px"
                    >RH</span
                  >

                  <!-- [ngModel]="VFD_Feedback_Pr_Pump_1 | number : '1.2-2'"
                  (ngModelChange)="VFD_Feedback_Pr_Pump_1 = $event" -->

                  <div class="input-group" style="width: 105px">
                    <img src="/assets/images/percentage.png" />
                    <input [(ngModel)]="RH8" />
                  </div>
                </div>
                <div style="width: 50%;"></div>
              </div>
            </div>
          </div>
        </div>

        <div style="height: auto">
          <div
            style="
              display: flex;
              flex-direction: row;
              width: 100%;
              gap: 10px;
              justify-content: flex-end;
              padding: 10px;
              box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.06),
                0px 0px 2px rgba(25, 25, 25, 0.12);
            "
          >
            <button
              style="
                width: 50%;
                background-color: var(--blue-one);
                border-radius: 20px;
                border: none;
                height: auto;
                padding: 7px;
                color: white;
                cursor: pointer;
              "
              (click)="predictData()"
            >
              Predict
            </button>
            <!-- <button
              style="
                width: 50%;
                border-radius: 20px;
                border: none;
                height: auto;
                padding: 7px;
                color: white;
                cursor: pointer;
              "
              class="button-disabled"
            >
              Optimise
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


