<div
  [ngClass]="{
    'pmv-hot-three': pmv >= 1,
    'pmv-hot-two': (pmv >= 0.5) && (pmv<1),
    'pmv-neutral': (pmv > -0.5) && (pmv<0.5),
    'pmv-cold-two': (pmv > -1) && (pmv<=-0.5),
    'pmv-cold-three': (pmv <=-1),
    'pmv-disabled':pmv===-4,
    'pmv-default':pmv===4
  }"

  style="height: 100%; width: 100%;"
>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 243.043 150.364">
    <path id="Path_4305" data-name="Path 4305" d="M8458.766,5104.571v-57.485h45.99v-92.879h197.053v150.364Z" transform="translate(-8458.766 -4954.207)" fill="#ecf0f7"/>
  </svg>
  
</div>
