import { Component } from '@angular/core';

@Component({
  selector: 'Filter-three',
  templateUrl: './filter-three.component.html',
  styleUrls: ['./filter-three.component.css']
})
export class FilterThreeComponent {

}
