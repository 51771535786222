import { Component, Input } from '@angular/core';

@Component({
  selector: 'Security-screening-area',
  templateUrl: './security-screening-area.component.html',
  styleUrls: ['./security-screening-area.component.css']
})
export class SecurityScreeningAreaComponent {
  @Input()pmv!:number;

  constructor(){
    this.pmv=0;
  }
}
