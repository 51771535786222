<div style=" 
    width: 100%;
    height: 100%;
">
<div>
    <img src="/assets/bert-components/filter-one.svg">
</div>

</div>

