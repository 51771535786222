import { Component, Input } from '@angular/core';

@Component({
  selector: 'Electric-load-center-one',
  templateUrl: './electric-load-center-one.component.html',
  styleUrls: ['./electric-load-center-one.component.css']
})
export class ElectricLoadCenterOneComponent {

  @Input()pmv!:number;

  constructor(){
    this.pmv=0;
  }


}
