<div style="position: relative ; height: 100%; justify-content: center; align-items: center; width: 100%">
  <!-- <Water-mark></Water-mark> -->
  <div
    style="
      width: 100%;
      height: 100%;
      min-width: 1200px;
      display: flex;
      flex-direction: column;
      position: absolute;
    "
  >
    <div style="display: flex; flex-direction: row;  justify-content: center; align-items: center; height: 100%; width: 100%">
      <!-- main body -->
      <div
        style="
          height: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px;
          align-items: center;
          gap: 10px;
          justify-content: center;
          overflow-y: scroll;
        "
      >
        <div
          style="
            height: 600px;
            width: 1000px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          "
        >
          <!-- map -->
          <div
            style="
              height: 600px;
              display: flex;
              width: 1000px;
              align-items: center;
              justify-content: center;
              position: absolute;
            "
          >
            <img
              src="/assets/images/home.svg"
              width="1000px"
              height="600px"
            />
          </div>
          <span style="margin-top: -34%; margin-left: 30%; z-index: 999; color: white; font-size: 300%">T1</span>
          <!-- components -->
          <div style="height: 600px; width: 1000px; position: absolute">
            <div
              style="
                display: flex;
                width: 20.4%;
                margin-top: 3.5%;
                margin-left: 50.5%;
                position:absolute;
              "
            >
                <c-pier-home-comp routerLink="/dashboard/T1"></c-pier-home-comp>

            </div>


            <!-- <div style="    margin-top: 177px;
            margin-left: 946px; position:absolute">
              <button
                mat-raised-button
                class="pmv-button"
                style="
                  height: 35px;
                  width:110px;
                  background-color: var(--blue-one);
                  color: white;
                  padding:0px;
                  border-radius:10px;
                "
                onmouseover="this.style.backgroundColor='#73099F';" onmouseout="this.style.backgroundColor='#4B1A5F'"
                routerLink="../ahuc4a"
              >
                 AHU C4A
              </button>
            </div>
            <div style="    margin-top: 227px;
            margin-left: 946px; position:absolute">
              <button
                mat-raised-button
                class="pmv-button"
                style="
                  height: 35px;
                  width:110px;
                  background-color: var(--blue-one);
                  color: white;
                  padding:0px;
                  border-radius:10px;
                "
                onmouseover="this.style.backgroundColor='#73099F';" onmouseout="this.style.backgroundColor='#4B1A5F'"
                routerLink="../ahuc4b"
              >
                 AHU C4B
              </button>
            </div> -->

            <!-- <div style="
            display: flex;
            width:233px;
            margin-top: 182px;
            margin-left: 805px;
            position:absolute;
            color:#4B1A5F;
            font-size:7px;
          " class="ahu-one-hover"
          >
              <span  style="width: 233px; height: 400px;">Thermal Comfort</span>
            </div> -->

            <!-- <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                margin-top: 225px;
                margin-left: 895px;
                position:absolute;
              "
            >
              <div class="ahu-one-hover" routerLink="/dashboard/ahuc4b">
                <span style="font-size: 15px; font-weight: 600; color: #873685">
                  AHU C4B</span>
              </div>
            </div> -->

            <!-- <div style="margin-top: 270px;
            margin-left: 739.5px;
            height: 188px;">
              <D-pier routerLink="../ahud7a"></D-pier>
            </div>

            <div style="margin-top: -377px;
            margin-left: 739px;
            height: 190px;">
              <C-pier routerLink="../ahuc4a"></C-pier>
            </div>

            <div
              style="
                margin-top: -65px;
                margin-left: 695.5px;
                height: 148px;
                width: 100px;
              "
            >
              <Jewel></Jewel>
            </div>
            <div style="
            margin-top: -40px;
            margin-left: 297px;
            height: 272px;
            width: 470px;">
              <Terminal-two></Terminal-two>
            </div>

            <div style="margin-top: -520px;
            margin-left: 247px;
            height: 206px;
            width: 520px;">
              <Terminal-three></Terminal-three>
            </div>


          <div style="margin-top: 54px;
          margin-left: 7.6px;
          height: 186px;
          width: 230px;">
            <Terminal-four></Terminal-four>
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
