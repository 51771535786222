<div style="position: relative; height: 100%; width: 100%">
  <div
    style="
      width: 100%;
      height: 100%;
      min-width: 1200px;
      display: flex;
      flex-direction: column;
      position: absolute;
    "
  >
    <mat-progress-bar
      mode="indeterminate"
      [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"
    ></mat-progress-bar>

    <div style="display: flex; flex-direction: row; height: 100%; width: 100%">
      <!-- main body -->
      <div
        style="
          height: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px;
          align-items: center;
          gap: 10px;
          overflow-y: scroll;
          
          margin-top:4%;
          
        "
      >
        <div
          style="
            height: 550px;
            width: 1200px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          "
        >
          <!-- componets -->
          <div style="height: 500px; width: 1100px; position: absolute">
            <div style="margin-top: 0px; margin-left: 0px; height: 499px">
              <!-- <C-pier-gate-hold-room-one [pmv]="pmv_avg"></C-pier-gate-hold-room-one> -->
            </div>
          </div>

          <!-- map -->
          <div
            style="
              height: 500px;
              width: 1200px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
            "
          >
            <img
              src="/assets/images/Changi-GATE HOLD ROOM c - 18.svg"
              width="auto"
              height="420px"
            />
          </div>

          <!-- fields -->
          <!-- 1 -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 278px; margin-left: 825px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out16 | number : '1.2-2'"
                  (ngModelChange)="pmv_out16 = $event"
                />
              </div>
            </div>
          </div>
          
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 320px; margin-left: 825px">
              <div class="input-group">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="ZONE_TEMP_out_C4_L2_16 | number : '1.2-2'"
                  (ngModelChange)="ZONE_TEMP_out_C4_L2_16 = $event"
                />
              </div>
            </div>
          </div>
          <!-- 2 -->
          <!-- <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 305px; margin-left: 563px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out17 | number : '1.2-2'"
                  (ngModelChange)="pmv_out17 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 339px; margin-left: 563px">
              <div class="input-group">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="pmv_out17 | number : '1.2-2'"
                  (ngModelChange)="pmv_out17 = $event"
                />
              </div>
            </div>
          </div> -->
          <!-- 3 -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 238px; margin-left: 1050px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out18 | number : '1.2-2'"
                  (ngModelChange)="pmv_out18 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 280px; margin-left: 1050px">
              <div class="input-group">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="ZONE_TEMP_out_C4_L2_18 | number : '1.2-2'"
                  (ngModelChange)="ZONE_TEMP_out_C4_L2_18 = $event"
                />
              </div>
            </div>
          </div>
          <!-- 4 -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 328px; margin-left: 1050px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out19 | number : '1.2-2'"
                  (ngModelChange)="pmv_out19 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 370px; margin-left: 1050px">
              <div class="input-group">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="ZONE_TEMP_out_C4_L2_19 | number : '1.2-2'"
                  (ngModelChange)="ZONE_TEMP_out_C4_L2_19 = $event"
                />
              </div>
            </div>
          </div>
          <!-- 5 -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 171px; margin-left: 793px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out20 | number : '1.2-2'"
                  (ngModelChange)="pmv_out20 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 213px; margin-left: 793px">
              <div class="input-group">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="ZONE_TEMP_out_C4_L2_20 | number : '1.2-2'"
                  (ngModelChange)="ZONE_TEMP_out_C4_L2_20 = $event"
                />
              </div>
            </div>
          </div>
          <!-- 6 -->
          <!-- <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 313px; margin-left: 395px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out21 | number : '1.2-2'"
                  (ngModelChange)="pmv_out21 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 348px; margin-left: 355px">
              <div class="input-group">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="pmv_out21 | number : '1.2-2'"
                  (ngModelChange)="pmv_out21 = $event"
                />
              </div>
            </div>
          </div> -->
          <!-- 7 -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 278px; margin-left: 555px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out22 | number : '1.2-2'"
                  (ngModelChange)="pmv_out22 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 320px; margin-left: 555px">
              <div class="input-group">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="ZONE_TEMP_out_C4_L2_22 | number : '1.2-2'"
                  (ngModelChange)="ZONE_TEMP_out_C4_L2_22 = $event"
                />
              </div>
            </div>
          </div>
          <!-- 8 -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 170px; margin-left: 518px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out23 | number : '1.2-2'"
                  (ngModelChange)="pmv_out23 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 212px; margin-left: 518px">
              <div class="input-group">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="ZONE_TEMP_out_C4_L2_23 | number : '1.2-2'"
                  (ngModelChange)="ZONE_TEMP_out_C4_L2_23 = $event"
                />
              </div>
            </div>
          </div>
          <!-- 9 -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 302px; margin-left: 288px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out25 | number : '1.2-2'"
                  (ngModelChange)="pmv_out25 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 344px; margin-left: 288px">
              <div class="input-group">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="ZONE_TEMP_out_C4_L2_25 | number : '1.2-2'"
                  (ngModelChange)="ZONE_TEMP_out_C4_L2_25 = $event"
                />
              </div>
            </div>
          </div>
          <!-- 10 -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 178px; margin-left: 288px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 220px; margin-left: 288px">
              <div class="input-group">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="ZONE_TEMP_out_C4_L2_26 | number : '1.2-2'"
                  (ngModelChange)="ZONE_TEMP_out_C4_L2_26 = $event"
                />
              </div>
            </div>
          </div>

          <!-- 11 -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 215px; margin-left: -5px">
              <div class="input-group">
                <img src="assets/images/pmv.png" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 257px; margin-left: -5px">
              <div class="input-group">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="ZONE_TEMP_out_C4_L2_27 | number : '1.2-2'"
                  (ngModelChange)="ZONE_TEMP_out_C4_L2_27 = $event"
                />
              </div>
            </div>
          </div>


          <!-- TH1  -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 55px; margin-left: 890px">
              <div class="input-groupP">
                <img src="assets/images/percentage.png" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 100px; margin-left: 890px">
              <div class="input-groupP">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>


          <!-- TH2  -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 422px; margin-left: 823px">
              <div class="input-groupP">
                <img src="assets/images/percentage.png" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 466px; margin-left: 823px">
              <div class="input-groupP">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>

          <!-- TH3  -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 55px; margin-left: 576px">
              <div class="input-groupP">
                <img src="assets/images/percentage.png" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 99px; margin-left: 576px">
              <div class="input-groupP">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>

          <!-- TH4  -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 426px; margin-left: 540px">
              <div class="input-groupP">
                <img src="assets/images/percentage.png" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 470px; margin-left: 540px">
              <div class="input-groupP">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>


          <!-- TH5  -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 54px; margin-left: 360px">
              <div class="input-groupP">
                <img src="assets/images/percentage.png" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 98px; margin-left: 360px">
              <div class="input-groupP">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>


          <!-- TH6  -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 57px; margin-left: 20px">
              <div class="input-groupP">
                <img src="assets/images/percentage.png" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 101px; margin-left: 20px">
              <div class="input-groupP">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>

          <!-- R1 -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 136px; margin-left: 735px">
              <div class="input-groupP">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>
          <!-- R2 -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 135px; margin-left: 188px">
              <div class="input-groupP">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>
          <!-- R3   -->
          <div style="height: 550px; width: 1000px; position: absolute">
            <div style="width: 100px; margin-top: 173px; margin-left: -30px">
              <div class="input-groupP">
                <img src="assets/images/temp.svg" />
                <input
                  [ngModel]="pmv_out26 | number : '1.2-2'"
                  (ngModelChange)="pmv_out26 = $event"
                />
              </div>
            </div>
          </div>


        </div>

        <!-- legends -->
        <!-- <div
          style="display: flex; flex-direction: row; width: 1000px; gap: 10px"
        >
          <div
            class="pmv-pallet"
            style="background-color: #6A87BB; color:#e3e3e3; font-weight:500;"
          >
            <span style="font-size: 12px">-3 COLD</span>
          </div>
          <div class="pmv-pallet" style="background-color: #9DB2D5; font-weight:500;">
            <span style="font-size: 12px">-2 COOL</span>
          </div>
          <div class="pmv-pallet" style="background-color: #C2C8D2; font-weight:500;">
            <span style="font-size: 12px">-1 SLIGHTLY COOL</span>
          </div>
          <div class="pmv-pallet" style=" background-color: #ECEFDF; font-weight:500;">
            <span style="font-size: 12px; line-height:14px;">0 NEUTRAL <br/> (COMFORT)</span>
          </div>
          <div class="pmv-pallet" style="background-color: #D9B7B7; font-weight:500;">
            <span style="font-size: 12px">+1 SLIGHTLY WARM</span>
          </div>
          <div class="pmv-pallet" style="background-color: #C89594; font-weight:500;">
            <span style="font-size: 12px">+2 WARM</span>
          </div>
          <div class="pmv-pallet" style="background-color: #A7514F; color:#e3e3e3; font-weight:500;">
            <span style="font-size: 12px">+3 HOT</span>
          </div>
        </div> -->
      </div>

      <!-- input side bar -->
      
      <!-- <div
      style="
        width: 450px;
        height: 100%;
        box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.06),
          0px 0px 2px rgba(25, 25, 25, 0.12);
      "
    >
    </div> -->
    </div>
  </div>
</div>
