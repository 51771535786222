import { Component, Input } from '@angular/core';

@Component({
  selector: 'Gate-hold-room-d-two',
  templateUrl: './gate-hold-room-d-two.component.html',
  styleUrls: ['./gate-hold-room-d-two.component.css']
})
export class GateHoldRoomDTwoComponent {
  @Input()pmv!:number;

  constructor(){
    this.pmv=0;
  }
}
