<div style="height: 100%; width: 100%">
    <ng-container *ngIf="state === 'pre-heating'; else heatingTemplate">
        <img src="{{ urlPreHeating }}" alt="kiln" height="100%" width="100%" class="image"/>
    </ng-container>
    <ng-template #heatingTemplate>
        <ng-container *ngIf="state === 'oxidation' ; else postOxidationTemplate">
            <img src={{urlOxidation}} alt="kiln" height="100%" width="100%" class="image"/>
        </ng-container>
        <ng-template #postOxidationTemplate>
            <ng-container *ngIf="state === 'reduction'; else cooling">
                <img src={{urlReduction}} alt="kiln" height="100%" width="100%" class="image"/>
            </ng-container>
            <ng-template #cooling>
                <img src={{urlCooling}} alt="kiln" height="100%" width="100%" class="image"/>
            </ng-template>
        </ng-template>        
    </ng-template>
</div>

  
