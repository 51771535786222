import { Component, Input } from '@angular/core';

@Component({
  selector: 'Pump',
  templateUrl: './pump.component.html',
  styleUrls: ['./pump.component.css']
})
export class PumpComponent {
  @Input() state!:string;

 

  urlActive:string;

  urlInActive:string;

 

  constructor(){

    this.urlActive="/assets/bert-components/active-pump.svg";

    this.urlInActive="/assets/bert-components/inactive-pump.svg";

  }

}
