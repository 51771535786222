import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';

@Component({
  selector: 'Electric-load-centre-one-map',
  templateUrl: './electric-load-centre-one-map.component.html',
  styleUrls: ['./electric-load-centre-one-map.component.css'],
})
export class ElectricLoadCentreOneMapComponent {
  progress: boolean = false;
  disableOptimise: boolean = true;
  predict_input: any;
  predict_response: any;
  xinput:any;

  //predict input params
  // inputs from form

  ZT: number;
  RH:number;
  Velocity: number;

  // output fields predict
  pmv_out: number;

 
  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {

    if(localStorage.getItem('electric-one-map')){
      this.xinput=localStorage.getItem('electric-one-map');
      this.xinput=JSON.parse(this.xinput);

      this.ZT = Number( this.xinput['Zone Temp D7-L2-01']);
      this.RH = Number( this.xinput['RH D7-L2-01']);
      this.Velocity = Number( this.xinput['Velocity D7-L2-01']);
    }else{
      this.ZT = 0;
      this.Velocity = 0;
      this.RH=0;
    }

    if(localStorage.getItem('PMV D7-L2-1')){
      this.pmv_out = Number(localStorage.getItem('PMV D7-L2-1'));
    }else{
      this.pmv_out = 0;
    }
    
  }


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if(this.ZT<22.6 || this.ZT>24.7){
      this.openSnackBar('ZT should be in range [22.6,24.7]');
      return false;
    }else if(this.Velocity<0.19 || this.Velocity>3.95){
      this.openSnackBar('Velocity should be in range [0.19,3.95]');
      return false;
    }else if(this.RH<43.66 || this.RH>53.58){
      this.openSnackBar('RH should be in range [43.66,53.58]');
      return false;
    }

    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }


  // this fuction is use to predict PMV
  async predictData(){
    
    if (this.validate()) {
      this.progress = true;
      // console.log('predict input', this.predict_input);

      this.predict_input= {
        'Zone Temp D7-L2-01': Number(this.ZT),
        'RH D7-L2-01': Number(this.RH),
        'Velocity D7-L2-01': Number(this.Velocity),
        'model_no.': 1,
      };
    

      try {
        const response = await this.http
          .post<any>(
            // 'http://4.224.40.114:5001/predictsec1/',
            'https://bpsliveatchangiairport.bertlabs.com/predictsec1/',
            this.predict_input,
            this.httpOptions
          )
          .toPromise();

        this.predict_response = response;
        console.log('predict outout', this.predict_response);

        this.openSnackBar('Predicted Succesfully');

        this.pmv_out = this.predict_response['PMV D7-L2-01'];
        this.progress = false;
        this.disableOptimise = false;
       
        
        
        localStorage.setItem('model_one', JSON.stringify(this.pmv_out));
      
        localStorage.setItem('PMV D7-L2-1',JSON.stringify(this.pmv_out));
       
        localStorage.setItem('electric-one-map', JSON.stringify(this.predict_input));
       
        
      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }
}
