import { Component } from '@angular/core';

@Component({
  selector: 'Terminal-three',
  templateUrl: './terminal-three.component.html',
  styleUrls: ['./terminal-three.component.css']
})
export class TerminalThreeComponent {

}
